/*
// .block-space
*/
@import '../variables';
@import '../mixins/header';


@include desktop-header-variant-selector {
    @if($header-layout == classic) {
        .block-space--layout--spaceship-ledge-height {
            display: none;
        }
    }
}
