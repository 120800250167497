/*
// .tag-badge
*/
@import '../variables';
@import '../constants';
@import '../mixins/direction';
@import '../mixins/tag-badge';


.tag-badge {
    display: inline-block;
    position: relative;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: $font-weight-medium;
    line-height: 1;
    z-index: 0;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: -1;
        border-radius: 2.5px;
        transform-origin: center;
    }

    @include tag-badge-size(18px, 7px, -20deg);
}


@each $name, $scheme in $tag-badges {
    .tag-badge--#{$name} {
        color: map_get($scheme, opposite);

        &:before {
            background: map_get($scheme, main);
        }
    }
}
