/*
// .footer-texts
*/

.footer-texts {
    display: block;
    text-align: center;
}
.footer-texts__list {
    font-size: 15px;
    line-height: 20px;
    list-style: none;
    padding: 0;
    margin: 0;

    &--content {
        color: inherit;
        transition: 0.15s;
    }
}
.footer-texts__item {
    padding: 5px 0;
}