/* ----------------------------------------
// Table of Contents:
// - Components
//   - .breadcrumb
// - Header
//   - .account-menu
//   - .departments
//   - .dropcart
//   - .header
//   - .indicator
//   - .logo
//   - .main-menu
//   - .megamenu
//   - .megamenu-links
//   - .menu
//   - .phone
//   - .search
//   - .suggestions
//   - .topbar
//   - .vehicle-picker
// - Pages
//   - .about
// - Blocks
//   - .block-header
//   - .block-space
// - Blog
//   - .post-header
// ---------------------------------------- */
/* ----------------------------------------
// Components
// ---------------------------------------- */
/*
// .breadcrumb
*/
.site--desktop-header--classic-three .breadcrumb__spaceship-safe-area {
  display: none;
}

/* ----------------------------------------
// Header
// ---------------------------------------- */
/*
// .account-menu
*/
.site--desktop-header--classic-three .account-menu {
  display: block;
  width: 280px;
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  border-radius: 1.5px;
  color: #262626;
}
.site--desktop-header--classic-three .account-menu__form {
  padding: 0 30px 32px;
}
.site--desktop-header--classic-three .account-menu__form-title {
  text-align: center;
  padding: 32px 0 26px;
  font-weight: 500;
}
.site--desktop-header--classic-three .account-menu__form-button {
  margin-top: 32px;
  text-align: center;
}
.site--desktop-header--classic-three .account-menu__form-button .btn {
  padding-left: 28px;
  padding-right: 28px;
}
.site--desktop-header--classic-three .account-menu__form-link {
  font-size: 14px;
  text-align: center;
}
.site--desktop-header--classic-three .account-menu__form-link a {
  color: #6c757d;
  transition: color 0.1s;
}
.site--desktop-header--classic-three .account-menu__form-link a:hover {
  color: inherit;
}
.site--desktop-header--classic-three .account-menu__form-forgot {
  position: relative;
}
.site--desktop-header--classic-three .account-menu__form-forgot-link {
  position: absolute;
  top: 5px;
  bottom: 5px;
  border-radius: 2px;
  font-size: 12px;
  background: fff;
  color: #6c757d;
  display: flex;
  align-items: center;
  padding: 0 7px;
  transition: background 0.1s, color 0.1s;
}
[dir=ltr] .site--desktop-header--classic-three .account-menu__form-forgot-link {
  right: 5px;
}
[dir=rtl] .site--desktop-header--classic-three .account-menu__form-forgot-link {
  left: 5px;
}
.site--desktop-header--classic-three .account-menu__form-forgot-link:hover {
  background: #333;
  color: #fff;
}
.site--desktop-header--classic-three .account-menu__form-forgot-link:active {
  background: #4d4d4d;
  color: #fff;
}
.site--desktop-header--classic-three .account-menu__divider {
  height: 1px;
  background: #ebebeb;
}
.site--desktop-header--classic-three .account-menu__user {
  display: flex;
  align-items: center;
  padding: 14px 20px;
  color: inherit;
}
.site--desktop-header--classic-three .account-menu__user:hover {
  color: inherit;
  background: #f2f2f2;
}
.site--desktop-header--classic-three .account-menu__user-avatar {
  width: 44px;
  flex-shrink: 0;
}
[dir=ltr] .site--desktop-header--classic-three .account-menu__user-avatar {
  margin-right: 14px;
}
[dir=rtl] .site--desktop-header--classic-three .account-menu__user-avatar {
  margin-left: 14px;
}
.site--desktop-header--classic-three .account-menu__user-avatar img {
  border-radius: 100%;
  max-width: 100%;
}
.site--desktop-header--classic-three .account-menu__user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.site--desktop-header--classic-three .account-menu__user-name {
  font-size: 15px;
  line-height: 20px;
}
.site--desktop-header--classic-three .account-menu__user-email {
  font-size: 14px;
  line-height: 18px;
  color: #6c757d;
  margin-top: 1px;
}
.site--desktop-header--classic-three .account-menu__links {
  list-style: none;
  padding: 12px 0;
  margin: 0;
}
.site--desktop-header--classic-three .account-menu__links a,
.site--desktop-header--classic-three .account-menu__links button {
  display: block;
  color: inherit;
  font-size: 15px;
  font-family: inherit;
  line-height: inherit;
  padding: 5px 20px;
  border: none;
  width: 100%;
  background: transparent;
  font-weight: 500;
}
[dir=ltr] .site--desktop-header--classic-three .account-menu__links a,
[dir=ltr] .site--desktop-header--classic-three .account-menu__links button {
  text-align: left;
}
[dir=rtl] .site--desktop-header--classic-three .account-menu__links a,
[dir=rtl] .site--desktop-header--classic-three .account-menu__links button {
  text-align: right;
}
.site--desktop-header--classic-three .account-menu__links a:hover,
.site--desktop-header--classic-three .account-menu__links button:hover {
  background: #f2f2f2;
}
.site--desktop-header--classic-three .account-menu__links a:focus,
.site--desktop-header--classic-three .account-menu__links button:focus {
  outline: none;
}

/*
// .departments
*/
.site--desktop-header--classic-three .departments {
  display: block;
  position: relative;
}
.site--desktop-header--classic-three .departments__button {
  position: relative;
  fill: currentColor;
  border: none;
  border-radius: 2px;
  font-family: inherit;
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  transition: background-color 0.2s, color 0.2s;
}
[dir=ltr] .site--desktop-header--classic-three .departments__button {
  padding-left: 35px;
  padding-right: 11px;
  text-align: left;
}
[dir=rtl] .site--desktop-header--classic-three .departments__button {
  padding-right: 35px;
  padding-left: 11px;
  text-align: right;
}
.site--desktop-header--classic-three .departments__button:focus {
  outline: none;
}
.site--desktop-header--classic-three .departments__button-icon {
  top: 10px;
  position: absolute;
  fill: currentColor;
  display: inline-block;
  transition: color 0.2s;
}
[dir=ltr] .site--desktop-header--classic-three .departments__button-icon {
  left: 10px;
  transform: scaleX(1);
}
[dir=rtl] .site--desktop-header--classic-three .departments__button-icon {
  right: 10px;
  transform: scaleX(-1);
}
.site--desktop-header--classic-three .departments__button-icon svg {
  display: block;
}
.site--desktop-header--classic-three .departments__button-arrow {
  display: none;
  fill: currentColor;
  position: absolute;
  top: calc(50% - 4px);
  transition: transform 0.2s, color 0.2s;
}
[dir=ltr] .site--desktop-header--classic-three .departments__button-arrow {
  right: 14px;
}
[dir=rtl] .site--desktop-header--classic-three .departments__button-arrow {
  left: 14px;
}
.site--desktop-header--classic-three .departments__button-arrow svg {
  display: block;
}
.site--desktop-header--classic-three .departments__menu {
  position: absolute;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  top: 100%;
  transform: translateY(-10px);
  transition: opacity 0.2s, transform 0.2s, visibility 0s 0.2s;
}
.site--desktop-header--classic-three .departments__body {
  border-radius: 1.5px;
  pointer-events: auto;
  display: flex;
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  color: #262626;
}
[dir=ltr] .site--desktop-header--classic-three .departments__body {
  float: left;
}
[dir=rtl] .site--desktop-header--classic-three .departments__body {
  float: right;
}
.site--desktop-header--classic-three .departments__list {
  flex-shrink: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
}
.site--desktop-header--classic-three .departments__item-link {
  color: inherit;
  display: block;
  position: relative;
}
.site--desktop-header--classic-three .departments__item-arrow {
  fill: #bfbfbf;
  position: absolute;
  top: calc(50% - 6px);
}
.site--desktop-header--classic-three .departments__item-arrow svg {
  display: block;
}
.site--desktop-header--classic-three .departments__item-menu {
  display: none;
}
.site--desktop-header--classic-three .departments__item--hover .departments__item-link {
  background: #f2f2f2;
  color: inherit;
}
.site--desktop-header--classic-three .departments__megamenu {
  min-height: 100%;
  display: none;
}
[dir=ltr] .site--desktop-header--classic-three .departments__megamenu {
  box-shadow: 1px 0 #ebebeb inset;
}
[dir=rtl] .site--desktop-header--classic-three .departments__megamenu {
  box-shadow: -1px 0 #ebebeb inset;
}
.site--desktop-header--classic-three .departments__megamenu--open {
  display: block;
}
.site--desktop-header--classic-three .departments__arrow {
  position: absolute;
  width: 31px;
  height: 21px;
  pointer-events: none;
  bottom: 100%;
  overflow: hidden;
}
.site--desktop-header--classic-three .departments__arrow:before {
  position: absolute;
  display: block;
  content: "";
  width: 7px;
  height: 7px;
  top: 100%;
  margin-top: -3.5px;
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  border-radius: 1px;
}
[dir=ltr] .site--desktop-header--classic-three .departments__arrow:before {
  left: 50%;
  margin-left: -3.5px;
  transform: rotate(45deg) translateX(-0.5px) translateY(-0.5px);
}
[dir=rtl] .site--desktop-header--classic-three .departments__arrow:before {
  right: 50%;
  margin-right: -3.5px;
  transform: rotate(45deg) translateX(0.5px) translateY(-0.5px);
}
.site--desktop-header--classic-three .departments--open .departments__menu {
  transition-delay: 0s;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
.site--desktop-header--classic-three .departments--open .departments__button-arrow {
  transform: rotate(180deg);
}
.site--desktop-header--classic-three .departments__button {
  height: 100%;
  width: 230px;
  padding-top: 5px;
  padding-bottom: 5px;
}
[dir=ltr] .site--desktop-header--classic-three .departments__button {
  padding-left: 43px;
  padding-right: 37px;
}
[dir=rtl] .site--desktop-header--classic-three .departments__button {
  padding-right: 43px;
  padding-left: 37px;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .site--desktop-header--classic-three .departments__button {
    width: 210px;
  }
}
.site--desktop-header--classic-three .departments__button-icon {
  top: calc(50% - 6px);
}
[dir=ltr] .site--desktop-header--classic-three .departments__button-icon {
  left: 14px;
}
[dir=rtl] .site--desktop-header--classic-three .departments__button-icon {
  right: 14px;
}
.site--desktop-header--classic-three .departments__button-arrow {
  display: block;
}
.site--desktop-header--classic-three .departments__arrow {
  width: 41px;
}
@media (min-width: 1400px) {
  .site--desktop-header--classic-three .departments__list {
    width: 230px;
  }
  .site--desktop-header--classic-three .departments__list-padding {
    height: 14px;
  }
  .site--desktop-header--classic-three .departments__item-link {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  [dir=ltr] .site--desktop-header--classic-three .departments__item-link {
    padding-left: 20px;
    padding-right: 29px;
  }
  [dir=rtl] .site--desktop-header--classic-three .departments__item-link {
    padding-right: 20px;
    padding-left: 29px;
  }
  [dir=ltr] .site--desktop-header--classic-three .departments__item-arrow {
    right: 14px;
    transform: scaleX(1);
  }
  [dir=rtl] .site--desktop-header--classic-three .departments__item-arrow {
    left: 14px;
    transform: scaleX(-1);
  }
  .site--desktop-header--classic-three .departments__megamenu {
    padding: 27px 30px 30px;
  }
  .site--desktop-header--classic-three .departments__megamenu--size--xl {
    width: 1120px;
  }
  .site--desktop-header--classic-three .departments__megamenu--size--lg {
    width: 902px;
  }
  .site--desktop-header--classic-three .departments__megamenu--size--md {
    width: 684px;
  }
  .site--desktop-header--classic-three .departments__megamenu--size--nl {
    width: 466px;
  }
  .site--desktop-header--classic-three .departments__megamenu--size--sm {
    width: 248px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .site--desktop-header--classic-three .departments__list {
    width: 210px;
  }
  .site--desktop-header--classic-three .departments__list-padding {
    height: 10px;
  }
  .site--desktop-header--classic-three .departments__item-link {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  [dir=ltr] .site--desktop-header--classic-three .departments__item-link {
    padding-left: 17px;
    padding-right: 26px;
  }
  [dir=rtl] .site--desktop-header--classic-three .departments__item-link {
    padding-right: 17px;
    padding-left: 26px;
  }
  [dir=ltr] .site--desktop-header--classic-three .departments__item-arrow {
    right: 11px;
    transform: scaleX(1);
  }
  [dir=rtl] .site--desktop-header--classic-three .departments__item-arrow {
    left: 11px;
    transform: scaleX(-1);
  }
  .site--desktop-header--classic-three .departments__megamenu {
    padding: 17px 20px 20px;
  }
  .site--desktop-header--classic-three .departments__megamenu .row {
    margin: 0 -10px;
  }
  .site--desktop-header--classic-three .departments__megamenu .col {
    padding: 0 10px;
  }
  .site--desktop-header--classic-three .departments__megamenu .col-1 {
    padding: 0 10px;
  }
  .site--desktop-header--classic-three .departments__megamenu .col-2 {
    padding: 0 10px;
  }
  .site--desktop-header--classic-three .departments__megamenu .col-3 {
    padding: 0 10px;
  }
  .site--desktop-header--classic-three .departments__megamenu .col-4 {
    padding: 0 10px;
  }
  .site--desktop-header--classic-three .departments__megamenu .col-5 {
    padding: 0 10px;
  }
  .site--desktop-header--classic-three .departments__megamenu .col-6 {
    padding: 0 10px;
  }
  .site--desktop-header--classic-three .departments__megamenu .col-7 {
    padding: 0 10px;
  }
  .site--desktop-header--classic-three .departments__megamenu .col-8 {
    padding: 0 10px;
  }
  .site--desktop-header--classic-three .departments__megamenu .col-9 {
    padding: 0 10px;
  }
  .site--desktop-header--classic-three .departments__megamenu .col-10 {
    padding: 0 10px;
  }
  .site--desktop-header--classic-three .departments__megamenu .col-11 {
    padding: 0 10px;
  }
  .site--desktop-header--classic-three .departments__megamenu .col-12 {
    padding: 0 10px;
  }
  .site--desktop-header--classic-three .departments__megamenu .col-1of1 {
    padding: 0 10px;
  }
  .site--desktop-header--classic-three .departments__megamenu .col-1of2 {
    padding: 0 10px;
  }
  .site--desktop-header--classic-three .departments__megamenu .col-1of3 {
    padding: 0 10px;
  }
  .site--desktop-header--classic-three .departments__megamenu .col-1of4 {
    padding: 0 10px;
  }
  .site--desktop-header--classic-three .departments__megamenu .col-1of5 {
    padding: 0 10px;
  }
  .site--desktop-header--classic-three .departments__megamenu--size--xl {
    width: 910px;
  }
  .site--desktop-header--classic-three .departments__megamenu--size--lg {
    width: 732px;
  }
  .site--desktop-header--classic-three .departments__megamenu--size--md {
    width: 554px;
  }
  .site--desktop-header--classic-three .departments__megamenu--size--nl {
    width: 376px;
  }
  .site--desktop-header--classic-three .departments__megamenu--size--sm {
    width: 198px;
  }
}
.site--desktop-header--classic-three .departments__button {
  background: #e52727;
  color: #fff;
  font-weight: 500;
}
@media (max-resolution: 1dppx) {
  .site--desktop-header--classic-three .departments__button {
    font-weight: 400;
  }
}
.site--desktop-header--classic-three .departments__button .departments__button-icon {
  color: #fff;
}
.site--desktop-header--classic-three .departments__button .departments__button-arrow {
  color: rgba(0, 0, 0, 0.25);
}
.site--desktop-header--classic-three .departments__button:hover,
.site--desktop-header--classic-three .departments--open .departments__button {
  background: #333;
  color: #fff;
}
.site--desktop-header--classic-three .departments__button:hover .departments__button-icon,
.site--desktop-header--classic-three .departments--open .departments__button .departments__button-icon {
  color: #fff;
}
.site--desktop-header--classic-three .departments__button:hover .departments__button-arrow,
.site--desktop-header--classic-three .departments--open .departments__button .departments__button-arrow {
  color: #808080;
}

/*
// .dropcart
*/
.site--desktop-header--classic-three .dropcart {
  display: block;
  width: 320px;
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  border-radius: 1.5px;
  padding: 24px;
  color: #262626;
}
.site--desktop-header--classic-three .dropcart__empty {
  text-align: center;
  font-size: 15px;
  padding: 20px 0;
}
.site--desktop-header--classic-three .dropcart__list {
  list-style: none;
  padding: 0;
  margin: -8px 0 0;
}
.site--desktop-header--classic-three .dropcart__divider {
  height: 1px;
  background: #ebebeb;
  margin: 8px 0;
}
.site--desktop-header--classic-three .dropcart__item {
  display: flex;
}
.site--desktop-header--classic-three .dropcart__item-image {
  flex-shrink: 0;
  width: 70px;
}
.site--desktop-header--classic-three .dropcart__item-image img {
  max-width: 100%;
}
.site--desktop-header--classic-three .dropcart__item-info {
  padding: 4px 0 5px;
  flex-grow: 1;
  margin: 0 10px;
}
.site--desktop-header--classic-three .dropcart__item-name {
  font-size: 15px;
  line-height: 18px;
}
.site--desktop-header--classic-three .dropcart__item-name a {
  color: inherit;
  transition: color 0.2s;
}
.site--desktop-header--classic-three .dropcart__item-name a:hover {
  color: #cb1818;
}
.site--desktop-header--classic-three .dropcart__item-features {
  list-style: none;
  padding: 0;
  margin-top: 2px;
  font-size: 13px;
  line-height: 17px;
  color: #6c757d;
}
.site--desktop-header--classic-three .dropcart__item-meta {
  display: flex;
  align-items: center;
  margin-top: 4px;
}
.site--desktop-header--classic-three .dropcart__item-remove {
  position: relative;
  flex-shrink: 0;
  width: 26px;
  height: 26px;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  fill: currentColor;
  background-color: #fff;
  color: #ccc;
}
.site--desktop-header--classic-three .dropcart__item-remove:hover {
  background-color: #f2f2f2;
  color: #999;
}
.site--desktop-header--classic-three .dropcart__item-remove:active {
  background-color: #ebebeb;
  color: #808080;
}
[dir=ltr] .site--desktop-header--classic-three .dropcart__item-remove {
  margin-right: -13px;
}
[dir=rtl] .site--desktop-header--classic-three .dropcart__item-remove {
  margin-left: -13px;
}
.site--desktop-header--classic-three .dropcart__item-remove:focus {
  outline: none;
}
.site--desktop-header--classic-three .dropcart__item-remove svg {
  display: block;
}
.site--desktop-header--classic-three .dropcart__item-remove.dropcart__item-remove--loading {
  fill: transparent;
  cursor: default;
  background: transparent;
}
.site--desktop-header--classic-three .dropcart__item-remove.dropcart__item-remove--loading:after {
  left: calc(50% - 8px);
  top: calc(50% - 8px);
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}
.site--desktop-header--classic-three .dropcart__item-quantity,
.site--desktop-header--classic-three .dropcart__item-price {
  position: relative;
  height: 16px;
  z-index: 0;
  font-size: 11px;
  padding: 1px 10.82352px 0;
}
.site--desktop-header--classic-three .dropcart__item-quantity:before,
.site--desktop-header--classic-three .dropcart__item-price:before {
  position: absolute;
  display: block;
  content: "";
  width: calc(100% - 5.82352px);
  height: 100%;
  top: 0;
  z-index: -1;
  border-radius: 2.5px 3px;
}
[dir=ltr] .site--desktop-header--classic-three .dropcart__item-quantity:before,
[dir=ltr] .site--desktop-header--classic-three .dropcart__item-price:before {
  left: 0;
  transform: skewX(-20deg);
  transform-origin: left bottom;
}
[dir=rtl] .site--desktop-header--classic-three .dropcart__item-quantity:before,
[dir=rtl] .site--desktop-header--classic-three .dropcart__item-price:before {
  right: 0;
  transform: skewX(20deg);
  transform-origin: right bottom;
}
.site--desktop-header--classic-three .dropcart__item-quantity {
  color: #fff;
  font-weight: 500;
}
@media (max-resolution: 1dppx) {
  .site--desktop-header--classic-three .dropcart__item-quantity {
    font-weight: 400;
  }
}
[dir=ltr] .site--desktop-header--classic-three .dropcart__item-quantity {
  padding-left: 6px;
  margin-right: -2.82352px;
}
[dir=rtl] .site--desktop-header--classic-three .dropcart__item-quantity {
  padding-right: 6px;
  margin-left: -2.82352px;
}
.site--desktop-header--classic-three .dropcart__item-quantity:before, .site--desktop-header--classic-three .dropcart__item-quantity:after {
  background: #e52727;
}
.site--desktop-header--classic-three .dropcart__item-quantity:after {
  position: absolute;
  display: block;
  content: "";
  width: 8.32352px;
  height: 100%;
  top: 0;
  z-index: -1;
}
[dir=ltr] .site--desktop-header--classic-three .dropcart__item-quantity:after {
  left: 0;
  border-top-left-radius: 2.5px;
  border-bottom-left-radius: 2.5px;
}
[dir=rtl] .site--desktop-header--classic-three .dropcart__item-quantity:after {
  right: 0;
  border-top-right-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
}
.site--desktop-header--classic-three .dropcart__item-price {
  color: #262626;
  font-weight: 500;
}
.site--desktop-header--classic-three .dropcart__item-price:before {
  background: #ebebeb;
}
.site--desktop-header--classic-three .dropcart__totals {
  font-size: 15px;
  margin-top: 18px;
  margin-bottom: 24px;
}
.site--desktop-header--classic-three .dropcart__totals table {
  width: 100%;
}
.site--desktop-header--classic-three .dropcart__totals th, .site--desktop-header--classic-three .dropcart__totals td {
  padding: 0;
}
.site--desktop-header--classic-three .dropcart__totals th {
  font-weight: 500;
}
[dir=ltr] .site--desktop-header--classic-three .dropcart__totals td {
  text-align: right;
}
[dir=rtl] .site--desktop-header--classic-three .dropcart__totals td {
  text-align: left;
}
.site--desktop-header--classic-three .dropcart__totals tr + tr > * {
  padding-top: 4px;
}
.site--desktop-header--classic-three .dropcart__actions {
  display: flex;
}
.site--desktop-header--classic-three .dropcart__actions > * {
  flex-grow: 1;
}
[dir=ltr] .site--desktop-header--classic-three .dropcart__actions > * + * {
  margin-left: 8px;
}
[dir=rtl] .site--desktop-header--classic-three .dropcart__actions > * + * {
  margin-right: 8px;
}

/*
// .header
*/
.site--desktop-header--classic-three .header {
  display: grid;
  grid-template-columns: calc(100% * .5 - 1350px * .5) min-content auto max-content calc(100% * .5 - 1350px * .5);
  grid-template-rows: 34px auto auto;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .site--desktop-header--classic-three .header {
    grid-template-columns: calc(100% * .5 - 1110px * .5) min-content auto max-content calc(100% * .5 - 1110px * .5);
  }
}
.site--desktop-header--classic-three .header__megamenu-area {
  grid-column: 2/5;
  grid-row: 1;
}
.site--desktop-header--classic-three .header__topbar-classic-bg {
  grid-column: 1/6;
  grid-row: 1;
}
.site--desktop-header--classic-three .header__topbar-classic {
  grid-column: 2/5;
  grid-row: 1;
}
.site--desktop-header--classic-three .header__logo {
  grid-column: 2;
  grid-row: 2;
}
.site--desktop-header--classic-three .header__search {
  grid-column: 3/3;
  grid-row: 2;
}
.site--desktop-header--classic-three .header__navbar {
  grid-column: 1/6;
  grid-row: 3;
}
.site--desktop-header--classic-three .header__indicators {
  grid-column: 4;
  grid-row: 2;
}
.site--desktop-header--classic-three .header {
  position: relative;
  z-index: 10;
}
@media (max-width: 1199.98px) {
  .site--desktop-header--classic-three .header {
    display: none;
  }
}
.site--desktop-header--classic-three .header__navbar {
  align-self: center;
  display: flex;
  align-items: center;
}
.site--desktop-header--classic-three .header__logo {
  min-width: 230px;
}
[dir=ltr] .site--desktop-header--classic-three .header__logo {
  margin-right: 24px;
}
[dir=rtl] .site--desktop-header--classic-three .header__logo {
  margin-left: 24px;
}
.site--desktop-header--classic-three .header__logo .logo__image {
  justify-content: center;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .site--desktop-header--classic-three .header__logo {
    min-width: 210px;
  }
}
.site--desktop-header--classic-three .header__navbar {
  padding: 7px calc((100% - 1350px) * 0.5);
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .site--desktop-header--classic-three .header__navbar {
    padding: 7px calc((100% - 1110px) * 0.5);
  }
}
.site--desktop-header--classic-three .header__navbar-menu {
  flex-grow: 1;
  height: 100%;
}
.site--desktop-header--classic-three .header__navbar-departments {
  display: flex;
  height: 100%;
}
[dir=ltr] .site--desktop-header--classic-three .header__navbar-departments {
  margin-right: 24px;
}
[dir=rtl] .site--desktop-header--classic-three .header__navbar-departments {
  margin-left: 24px;
}
.site--desktop-header--classic-three .header__navbar-departments {
  position: relative;
}
.site--desktop-header--classic-three .header__navbar-departments:after {
  display: block;
  position: absolute;
  content: "";
  background: transparent;
  width: 1px;
  height: 100%;
  top: 0;
}
[dir=ltr] .site--desktop-header--classic-three .header__navbar-departments:after {
  right: -12px;
}
[dir=rtl] .site--desktop-header--classic-three .header__navbar-departments:after {
  left: -12px;
}
.site--desktop-header--classic-three .header__navbar-phone {
  height: 100%;
}
.site--desktop-header--classic-three .header__search {
  align-self: center;
}
[dir=ltr] .site--desktop-header--classic-three .header__search {
  margin-right: 24px;
}
[dir=rtl] .site--desktop-header--classic-three .header__search {
  margin-left: 24px;
}
.site--desktop-header--classic-three .header__indicators {
  align-self: center;
  display: flex;
  justify-content: flex-end;
  padding: 16px 0;
}
[dir=ltr] .site--desktop-header--classic-three .header__indicators .indicator + .indicator {
  margin-left: 4px;
}
[dir=rtl] .site--desktop-header--classic-three .header__indicators .indicator + .indicator {
  margin-right: 4px;
}
.site--desktop-header--classic-three .header {
  background: #fff;
  color: #262626;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}
.site--desktop-header--classic-three .header__topbar-classic-bg {
  background: #333;
}
.site--desktop-header--classic-three .header__navbar {
  height: 52px;
  background: #fff;
  color: #262626;
}
.site--desktop-header--classic-three .header__navbar {
  border-top: 1px solid #ebebeb;
  height: 53px;
}

/*
// .indicator
*/
.site--desktop-header--classic-three {
  /*
  // .indicator--trigger--click.indicator--open,
  // .indicator--trigger--hover:hover
  */
}
.site--desktop-header--classic-three .indicator {
  position: relative;
}
.site--desktop-header--classic-three .indicator__button {
  padding: 3px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 54px;
  min-width: 54px;
  color: inherit;
  border-radius: 2px;
  background: transparent;
  border: none;
}
[dir=ltr] .site--desktop-header--classic-three .indicator__button {
  padding-right: 12px;
}
[dir=rtl] .site--desktop-header--classic-three .indicator__button {
  padding-left: 12px;
}
.site--desktop-header--classic-three .indicator__button:focus {
  outline: none;
}
.site--desktop-header--classic-three .indicator__button:hover {
  color: inherit;
  text-decoration: none;
}
.site--desktop-header--classic-three .indicator__icon {
  position: absolute;
  padding: 8px;
  display: block;
  width: 48px;
  height: 48px;
}
.site--desktop-header--classic-three .indicator__icon svg {
  display: block;
}
[dir=ltr] .site--desktop-header--classic-three .indicator__icon svg {
  transform: scaleX(1);
}
[dir=rtl] .site--desktop-header--classic-three .indicator__icon svg {
  transform: scaleX(-1);
}
.site--desktop-header--classic-three .indicator__counter {
  position: absolute;
  top: 5px;
  font-size: 10px;
  line-height: 1;
  padding: 2px 3px 1px;
  border-radius: 6.5px;
  text-align: center;
  z-index: 0;
}
[dir=ltr] .site--desktop-header--classic-three .indicator__counter {
  right: 4px;
}
[dir=rtl] .site--desktop-header--classic-three .indicator__counter {
  left: 4px;
}
.site--desktop-header--classic-three .indicator__counter:before {
  display: block;
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 2.5px;
}
[dir=ltr] .site--desktop-header--classic-three .indicator__counter:before {
  left: 0;
  right: 0;
  transform: skewX(-11deg);
}
[dir=rtl] .site--desktop-header--classic-three .indicator__counter:before {
  right: 0;
  left: 0;
  transform: skewX(11deg);
}
.site--desktop-header--classic-three .indicator__title,
.site--desktop-header--classic-three .indicator__value {
  display: block;
  white-space: nowrap;
}
[dir=ltr] .site--desktop-header--classic-three .indicator__title,
[dir=ltr] .site--desktop-header--classic-three .indicator__value {
  margin-left: 50px;
}
[dir=rtl] .site--desktop-header--classic-three .indicator__title,
[dir=rtl] .site--desktop-header--classic-three .indicator__value {
  margin-right: 50px;
}
.site--desktop-header--classic-three .indicator__title {
  padding-top: 3px;
  margin-bottom: -3px;
  font-size: 13px;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.site--desktop-header--classic-three .indicator__value {
  font-size: 18px;
  font-weight: 500;
}
.site--desktop-header--classic-three .indicator__content {
  pointer-events: none;
  top: 100%;
  position: absolute;
  visibility: hidden;
  transform-origin: top;
  transform: rotateX(60deg);
  opacity: 0;
  transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
}
[dir=ltr] .site--desktop-header--classic-three .indicator__content {
  right: 0;
}
[dir=rtl] .site--desktop-header--classic-three .indicator__content {
  left: 0;
}
.site--desktop-header--classic-three .indicator--trigger--click.indicator--open .indicator__content,
.site--desktop-header--classic-three .indicator--trigger--hover:hover .indicator__content {
  pointer-events: auto;
  z-index: 1;
  transition-delay: 0s, 0s, 0s;
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
}
.site--desktop-header--classic-three .indicator--mobile .indicator__button {
  padding: 0 0 2px;
  height: 50px;
  width: 44px;
  min-width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
}
.site--desktop-header--classic-three .indicator--mobile .indicator__icon {
  padding: 0;
  position: relative;
  height: auto;
  width: auto;
}
.site--desktop-header--classic-three .indicator--mobile .indicator__counter {
  top: -7px;
}
[dir=ltr] .site--desktop-header--classic-three .indicator--mobile .indicator__counter {
  right: -7px;
}
[dir=rtl] .site--desktop-header--classic-three .indicator--mobile .indicator__counter {
  left: -7px;
}
.site--desktop-header--classic-three .indicator__icon {
  fill: #262626;
}
.site--desktop-header--classic-three .indicator__title {
  color: #999;
}
.site--desktop-header--classic-three .indicator__value {
  font-weight: 500;
}
.site--desktop-header--classic-three .indicator:hover .indicator__button,
.site--desktop-header--classic-three .indicator--open .indicator__button {
  background: #f2f2f2;
}
.site--desktop-header--classic-three .indicator__counter {
  color: #fff;
  font-weight: 500;
}
@media (max-resolution: 1dppx) {
  .site--desktop-header--classic-three .indicator__counter {
    font-weight: 400;
  }
}
.site--desktop-header--classic-three .indicator__counter:before {
  background-color: #e52727;
}

/*
// .logo
*/
.site--desktop-header--classic-three .logo__slogan {
  padding-top: 11px;
  font-size: 11px;
  line-height: 1;
  text-transform: uppercase;
}
.site--desktop-header--classic-three .logo__image {
  display: flex;
  align-items: center;
  height: 50px;
}
.site--desktop-header--classic-three .logo__image {
  height: 100%;
}
.site--desktop-header--classic-three .logo__slogan {
  display: none;
}
.site--desktop-header--classic-three .logo__part-primary {
  fill: #e52727;
}
.site--desktop-header--classic-three .logo__part-secondary {
  fill: #404040;
}

/*
// .main-menu
*/
.site--desktop-header--classic-three .main-menu {
  height: 100%;
}
.site--desktop-header--classic-three .main-menu__list {
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}
.site--desktop-header--classic-three .main-menu__link {
  display: block;
  padding: 5px 11px;
  border-radius: 2px;
  color: inherit;
  white-space: nowrap;
  font-size: 15px;
}
.site--desktop-header--classic-three .main-menu__link:hover {
  color: inherit;
}
.site--desktop-header--classic-three .main-menu__submenu {
  color: #262626;
}
.site--desktop-header--classic-three .main-menu__item--has-submenu .main-menu__link {
  position: relative;
}
[dir=ltr] .site--desktop-header--classic-three .main-menu__item--has-submenu .main-menu__link {
  padding-right: 23px;
}
[dir=rtl] .site--desktop-header--classic-three .main-menu__item--has-submenu .main-menu__link {
  padding-left: 23px;
}
.site--desktop-header--classic-three .main-menu__item--has-submenu .main-menu__link svg {
  top: 14px;
  position: absolute;
  fill: currentColor;
}
[dir=ltr] .site--desktop-header--classic-three .main-menu__item--has-submenu .main-menu__link svg {
  right: 10px;
}
[dir=rtl] .site--desktop-header--classic-three .main-menu__item--has-submenu .main-menu__link svg {
  left: 10px;
}
.site--desktop-header--classic-three .main-menu__item--submenu--menu {
  position: relative;
}
.site--desktop-header--classic-three .main-menu__item--submenu--menu .main-menu__submenu {
  position: absolute;
  z-index: 1;
  top: 100%;
  opacity: 0;
  visibility: hidden;
  transform-origin: top;
  transform: rotateX(45deg);
  transition: transform 0.2s, opacity 0.2s;
}
[dir=ltr] .site--desktop-header--classic-three .main-menu__item--submenu--menu .main-menu__submenu {
  left: 0;
}
[dir=rtl] .site--desktop-header--classic-three .main-menu__item--submenu--menu .main-menu__submenu {
  right: 0;
}
.site--desktop-header--classic-three .main-menu__item--submenu--megamenu .main-menu__submenu {
  position: absolute;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transform-origin: top;
  transform: rotateX(45deg);
  transition: transform 0.2s, opacity 0.2s;
}
.site--desktop-header--classic-three .main-menu__item--has-submenu.main-menu__item--hover .main-menu__submenu {
  visibility: visible;
  opacity: 1;
  transform: rotateX(0);
}
.site--desktop-header--classic-three .main-menu__megamenu {
  padding: 26px 30px 30px;
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
}
@media (min-width: 1400px) {
  .site--desktop-header--classic-three .main-menu__megamenu--size--xxl {
    width: calc((1350px - 30px) / 6 * 6 + 30px);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .site--desktop-header--classic-three .main-menu__megamenu--size--xxl {
    width: calc((1110px - 30px) / 6 * 6 + 30px);
  }
}
@media (min-width: 1400px) {
  .site--desktop-header--classic-three .main-menu__megamenu--size--xl {
    width: calc((1350px - 30px) / 6 * 5 + 30px);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .site--desktop-header--classic-three .main-menu__megamenu--size--xl {
    width: calc((1110px - 30px) / 6 * 5 + 30px);
  }
}
@media (min-width: 1400px) {
  .site--desktop-header--classic-three .main-menu__megamenu--size--lg {
    width: calc((1350px - 30px) / 6 * 4 + 30px);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .site--desktop-header--classic-three .main-menu__megamenu--size--lg {
    width: calc((1110px - 30px) / 6 * 4 + 30px);
  }
}
@media (min-width: 1400px) {
  .site--desktop-header--classic-three .main-menu__megamenu--size--md {
    width: calc((1350px - 30px) / 6 * 3 + 30px);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .site--desktop-header--classic-three .main-menu__megamenu--size--md {
    width: calc((1110px - 30px) / 6 * 3 + 30px);
  }
}
@media (min-width: 1400px) {
  .site--desktop-header--classic-three .main-menu__megamenu--size--nl {
    width: calc((1350px - 30px) / 6 * 2 + 30px);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .site--desktop-header--classic-three .main-menu__megamenu--size--nl {
    width: calc((1110px - 30px) / 6 * 2 + 30px);
  }
}
@media (min-width: 1400px) {
  .site--desktop-header--classic-three .main-menu__megamenu--size--sm {
    width: calc((1350px - 30px) / 6 * 1 + 30px);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .site--desktop-header--classic-three .main-menu__megamenu--size--sm {
    width: calc((1110px - 30px) / 6 * 1 + 30px);
  }
}
.site--desktop-header--classic-three .main-menu__link {
  height: 100%;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
}
.site--desktop-header--classic-three .main-menu__item--has-submenu .main-menu__link svg {
  top: calc(50% - 2px);
}
.site--desktop-header--classic-three .main-menu .main-menu__link {
  font-weight: 500;
}
.site--desktop-header--classic-three .main-menu .main-menu__item--has-submenu .main-menu__link svg {
  color: #bfbfbf;
}
.site--desktop-header--classic-three .main-menu .main-menu__item:hover .main-menu__link {
  background-color: #f2f2f2;
}

/*
// .megamenu
*/
.site--desktop-header--classic-three .megamenu {
  position: relative;
}
.site--desktop-header--classic-three .megamenu__image {
  position: absolute;
  bottom: 0;
}
[dir=ltr] .site--desktop-header--classic-three .megamenu__image {
  right: 0;
  transform-origin: bottom right;
}
[dir=rtl] .site--desktop-header--classic-three .megamenu__image {
  left: 0;
  transform-origin: bottom left;
}
.site--desktop-header--classic-three .megamenu__image img,
.site--desktop-header--classic-three .megamenu__image picture {
  display: block;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .site--desktop-header--classic-three .megamenu__image {
    transform: scale(0.81);
  }
}

/*
// .megamenu-links
*/
.site--desktop-header--classic-three .megamenu-links {
  list-style: none;
  padding: 0;
  margin: 0;
}
.site--desktop-header--classic-three .megamenu-links__item {
  line-height: 18px;
}
.site--desktop-header--classic-three .megamenu-links__item + .megamenu-links__item {
  margin-top: 10px;
}
.site--desktop-header--classic-three .megamenu-links__item--has-submenu + .megamenu-links__item {
  margin-top: 24px;
}
.site--desktop-header--classic-three .megamenu-links__item-link {
  font-size: 16px;
  color: inherit;
  transition: color 0.15s;
  font-weight: 500;
}
.site--desktop-header--classic-three .megamenu-links__item-link:hover {
  color: #cb1818;
}
.site--desktop-header--classic-three .megamenu-links__item-link + .megamenu-links {
  border-top: 1px solid #ebebeb;
  padding-top: 12px;
  margin-top: 5px;
}
.site--desktop-header--classic-three .megamenu-links:not(.megamenu-links--root) .megamenu-links__item {
  line-height: 16px;
}
.site--desktop-header--classic-three .megamenu-links:not(.megamenu-links--root) .megamenu-links__item + .megamenu-links__item {
  margin-top: 9px;
}
.site--desktop-header--classic-three .megamenu-links:not(.megamenu-links--root) .megamenu-links__item-link {
  font-weight: 400;
  font-size: 14px;
  color: #6c757d;
}
.site--desktop-header--classic-three .megamenu-links:not(.megamenu-links--root) .megamenu-links__item-link:hover {
  color: #cb1818;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .site--desktop-header--classic-three .megamenu-links__item-link {
    font-size: 15px;
  }
}

/*
// .menu
*/
.site--desktop-header--classic-three .menu {
  display: block;
  color: #262626;
  background-color: #fff;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 200px;
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
}
.site--desktop-header--classic-three .menu__list {
  list-style: none;
  margin: 0;
  padding: 8px 0;
}
.site--desktop-header--classic-three .menu__submenu {
  position: absolute;
  top: -8px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s;
}
[dir=ltr] .site--desktop-header--classic-three .menu__submenu {
  left: 100%;
}
[dir=rtl] .site--desktop-header--classic-three .menu__submenu {
  right: 100%;
}
.site--desktop-header--classic-three .menu__link {
  display: block;
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
}
[dir=ltr] .site--desktop-header--classic-three .menu__link {
  padding-left: 16px;
  padding-right: 28px;
}
[dir=rtl] .site--desktop-header--classic-three .menu__link {
  padding-right: 16px;
  padding-left: 28px;
}
.site--desktop-header--classic-three .menu__link, .site--desktop-header--classic-three .menu__link:hover {
  color: inherit;
}
.site--desktop-header--classic-three .menu__arrow {
  fill: #bfbfbf;
  position: absolute;
  top: calc(50% - 5px);
}
[dir=ltr] .site--desktop-header--classic-three .menu__arrow {
  right: 14px;
}
[dir=rtl] .site--desktop-header--classic-three .menu__arrow {
  left: 14px;
}
.site--desktop-header--classic-three .menu__arrow svg {
  display: block;
}
[dir=ltr] .site--desktop-header--classic-three .menu__arrow svg {
  transform: scaleX(1);
}
[dir=rtl] .site--desktop-header--classic-three .menu__arrow svg {
  transform: scaleX(-1);
}
.site--desktop-header--classic-three .menu__item:hover > .menu__link {
  background: #f2f2f2;
}
.site--desktop-header--classic-three .menu__item:hover > .menu__submenu {
  opacity: 1;
  visibility: visible;
}
.site--desktop-header--classic-three .menu__item--has-submenu {
  position: relative;
}

/*
// .phone
*/
.site--desktop-header--classic-three .phone__body {
  display: flex;
  align-items: center;
  color: inherit;
  line-height: 1;
  height: 100%;
  padding: 4px 11px;
  border-radius: 2px;
  transition: background 0.12s;
  font-size: 17px;
}
.site--desktop-header--classic-three .phone__body:hover {
  color: inherit;
}
.site--desktop-header--classic-three .phone__title {
  font-size: 15px;
  margin-top: 1px;
}
[dir=ltr] .site--desktop-header--classic-three .phone__title {
  margin-right: 5px;
}
[dir=rtl] .site--desktop-header--classic-three .phone__title {
  margin-left: 5px;
}
.site--desktop-header--classic-three .phone__body:hover {
  background: #f2f2f2;
}
.site--desktop-header--classic-three .phone__title {
  color: #999;
}
.site--desktop-header--classic-three .phone__number {
  font-weight: 500;
}

/*
// .search
*/
.site--desktop-header--classic-three .search__dropdown {
  color: #262626;
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 100%;
  border-radius: 1.5px;
  opacity: 0;
  transform: translateY(26px);
  visibility: hidden;
  transition: opacity 0.25s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0s 0.25s;
}
[dir=ltr] .site--desktop-header--classic-three .search__dropdown {
  left: 0;
  right: 0;
}
[dir=rtl] .site--desktop-header--classic-three .search__dropdown {
  right: 0;
  left: 0;
}
.site--desktop-header--classic-three .search__dropdown--open {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  transition-delay: 0s;
}
.site--desktop-header--classic-three .search__button-icon svg {
  display: block;
}

.site--desktop-header--classic-three .search__body {
  display: flex;
  z-index: 0;
  position: relative;
  max-width: 560px;
  height: 38px;
}
.site--desktop-header--classic-three .search__input,
.site--desktop-header--classic-three .search__button {
  background: transparent;
  border: none;
  font-family: inherit;
  padding: 0;
  flex-basis: 0;
}
.site--desktop-header--classic-three .search__input:focus,
.site--desktop-header--classic-three .search__button:focus {
  outline: none;
}
.site--desktop-header--classic-three .search__button {
  flex-shrink: 0;
  border: 2px solid transparent;
  fill: currentColor;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 9px;
  white-space: nowrap;
}
.site--desktop-header--classic-three .search__button-icon {
  flex-shrink: 0;
}
.site--desktop-header--classic-three .search__button-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin-top: 1px;
}
[dir=ltr] .site--desktop-header--classic-three .search__button-title {
  margin-left: 9px;
}
[dir=rtl] .site--desktop-header--classic-three .search__button-title {
  margin-right: 9px;
}
.site--desktop-header--classic-three .search__input {
  color: #262626;
  background-color: #ededed;
  border-color: #ededed;
  flex-grow: 1;
  font-size: 15px;
  padding-top: 0;
  padding-bottom: 0;
  border-width: 1px;
  border-style: solid;
  border-radius: 2.5px;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s;
}
.site--desktop-header--classic-three .search__input::placeholder {
  color: #6c757d;
}
[dir=ltr] .site--desktop-header--classic-three .search__input {
  padding-left: 13px;
  padding-right: 37px;
}
[dir=rtl] .site--desktop-header--classic-three .search__input {
  padding-right: 13px;
  padding-left: 37px;
}
.site--desktop-header--classic-three .search__input:hover {
  border-color: #e0e0e0;
}
.site--desktop-header--classic-three .search__input:focus {
  background-color: #fff;
  border-color: #e5e5e5;
}
.site--desktop-header--classic-three .search__button--start {
  order: -1;
  padding-top: 0;
  padding-bottom: 0;
  border: 1px solid transparent;
  background-repeat: no-repeat;
  background-size: 5px 10px;
  border-radius: 2.5px;
  transition: color 0.15s, background-color 0.15s;
  background-color: #ffdf40;
  color: #262626;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 10'%3E%3Cpath fill='rgba(0, 0, 0, 0.35)' d='M4.503,4 L0.497,4 C0.094,4 -0.142,3.492 0.089,3.122 L2.095,0.233 C2.293,-0.084 2.712,-0.084 2.911,0.233 L4.911,3.122 C5.142,3.492 4.906,4 4.503,4 ZM0.497,6 L4.503,6 C4.906,6 5.142,6.504 4.911,6.871 L2.905,9.737 C2.707,10.052 2.288,10.052 2.089,9.737 L0.089,6.871 C-0.142,6.504 0.094,6 0.497,6 Z'/%3E%3C/svg%3E%0A");
}
.site--desktop-header--classic-three .search__button--start .search__button-icon {
  fill: rgba(0, 0, 0, 0.75);
}
[dir=ltr] .site--desktop-header--classic-three .search__button--start {
  padding-left: 12px;
  padding-right: 27px;
  margin-right: 5px;
  background-position: right 12px center;
}
[dir=rtl] .site--desktop-header--classic-three .search__button--start {
  padding-right: 12px;
  padding-left: 27px;
  margin-left: 5px;
  background-position: left 12px center;
}
.site--desktop-header--classic-three .search__button--start:hover {
  background-color: #ffd226;
}
.site--desktop-header--classic-three .search__button--start:active {
  transition-duration: 0s;
  background-color: #ffca16;
}
.site--desktop-header--classic-three .search__button--end {
  transition: color 0.2s;
  position: absolute;
  height: 38px;
  width: 38px;
  border: none;
  color: #b2b2b2;
}
[dir=ltr] .site--desktop-header--classic-three .search__button--end {
  right: 0;
}
[dir=rtl] .site--desktop-header--classic-three .search__button--end {
  left: 0;
}
.site--desktop-header--classic-three .search__button--end:hover {
  color: #404040;
}
.site--desktop-header--classic-three .search__dropdown--vehicle-picker {
  max-width: 440px;
}
.site--desktop-header--classic-three .search__dropdown-arrow {
  position: absolute;
  width: 46px;
  height: 21px;
  pointer-events: none;
  bottom: 100%;
  overflow: hidden;
}
.site--desktop-header--classic-three .search__dropdown-arrow:before {
  position: absolute;
  display: block;
  content: "";
  width: 7px;
  height: 7px;
  top: 100%;
  margin-top: -3.5px;
  background-color: #fff;
  border-radius: 1px;
}
[dir=ltr] .site--desktop-header--classic-three .search__dropdown-arrow:before {
  left: 50%;
  margin-left: -3.5px;
  transform: rotate(45deg) translateX(-0.5px) translateY(-0.5px);
}
[dir=rtl] .site--desktop-header--classic-three .search__dropdown-arrow:before {
  right: 50%;
  margin-right: -3.5px;
  transform: rotate(45deg) translateX(0.5px) translateY(-0.5px);
}
.site--desktop-header--classic-three .search__input {
  color: #262626;
  background-color: #ededed;
  border-color: #ededed;
}
.site--desktop-header--classic-three .search__input::placeholder {
  color: #6c757d;
}
.site--desktop-header--classic-three .search__input:hover {
  border-color: #e0e0e0;
}
.site--desktop-header--classic-three .search__input:focus {
  background-color: #fff;
  border-color: #e5e5e5;
}
.site--desktop-header--classic-three .search__button--end {
  color: #b2b2b2;
}
.site--desktop-header--classic-three .search__button--end:hover {
  color: #404040;
}
/*
// .suggestions
*/
.site--desktop-header--classic-three .suggestions {
  padding: 14px 0;
}
.site--desktop-header--classic-three .suggestions__group + .suggestions__group {
  margin-top: 10px;
}
.site--desktop-header--classic-three .suggestions__group-title {
  padding: 3px 20px 3px;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  color: #999;
}
.site--desktop-header--classic-three .suggestions__category {
  display: block;
  padding: 6px 20px;
  color: inherit;
  font-size: 15px;
  line-height: 17px;
}
.site--desktop-header--classic-three .suggestions__category:hover {
  color: inherit;
  background: #f2f2f2;
}
.site--desktop-header--classic-three .suggestions__product {
  display: flex;
  padding: 6px 20px;
  color: inherit;
}
.site--desktop-header--classic-three .suggestions__product:hover {
  color: inherit;
  background: #f2f2f2;
}
.site--desktop-header--classic-three .suggestions__product-image {
  position: relative;
  border-radius: 1px;
  overflow: hidden;
  flex-shrink: 0;
}
[dir=ltr] .site--desktop-header--classic-three .suggestions__product-image {
  margin-right: 10px;
}
[dir=rtl] .site--desktop-header--classic-three .suggestions__product-image {
  margin-left: 10px;
}
.site--desktop-header--classic-three .suggestions__product-image:before {
  display: block;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: inherit;
  pointer-events: none;
}
.site--desktop-header--classic-three .suggestions__product-image img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.site--desktop-header--classic-three .suggestions__product-info {
  flex-grow: 1;
  flex-shrink: 1;
  padding-top: 4px;
  min-width: 0;
}
.site--desktop-header--classic-three .suggestions__product-name {
  font-size: 15px;
  line-height: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.site--desktop-header--classic-three .suggestions__product-rating {
  display: flex;
  margin-top: 2px;
}
[dir=ltr] .site--desktop-header--classic-three .suggestions__product-rating-stars {
  margin-right: 8px;
}
[dir=rtl] .site--desktop-header--classic-three .suggestions__product-rating-stars {
  margin-left: 8px;
}
.site--desktop-header--classic-three .suggestions__product-rating-label {
  font-size: 13px;
  line-height: 1;
  color: #6c757d;
}
.site--desktop-header--classic-three .suggestions__product-pno {
  display: flex;
  margin-top: 2px;
}
[dir=ltr] .site--desktop-header--classic-three .suggestions__product-pno-stars {
  margin-right: 8px;
}
[dir=rtl] .site--desktop-header--classic-three .suggestions__product-pno-stars {
  margin-left: 8px;
}
.site--desktop-header--classic-three .suggestions__product-pno-label {
  font-size: 13px;
  line-height: 1;
  color: #6c757d;
}
.site--desktop-header--classic-three .suggestions__product-price {
  white-space: nowrap;
  flex-shrink: 0;
  font-size: 13px;
  font-weight: 700;
  padding-top: 3px;
  width: 70px;
}
[dir=ltr] .site--desktop-header--classic-three .suggestions__product-price {
  margin-left: 14px;
  text-align: right;
}
[dir=rtl] .site--desktop-header--classic-three .suggestions__product-price {
  margin-right: 14px;
  text-align: left;
}

/*
// .topbar
*/
.site--desktop-header--classic-three .topbar {
  display: flex;
  height: 100%;
  font-size: 14px;
  line-height: 1;
}
.site--desktop-header--classic-three .topbar__item-spring {
  flex-grow: 1;
}
.site--desktop-header--classic-three .topbar__item-text {
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.site--desktop-header--classic-three .topbar__link {
  transition: color 0.12s;
}
.site--desktop-header--classic-three .topbar__link-label {
  transition: inherit;
}
.site--desktop-header--classic-three .topbar__button {
  display: flex;
  align-items: center;
  position: relative;
  background: transparent;
  font-family: inherit;
  border: none;
  padding: 0 10px;
  transition: background 0.12s;
  height: 100%;
}
.site--desktop-header--classic-three .topbar__button:focus {
  outline: none;
}
.site--desktop-header--classic-three .topbar__button-title,
.site--desktop-header--classic-three .topbar__button-label {
  transition: color 0.12s;
}
[dir=ltr] .site--desktop-header--classic-three .topbar__button-label {
  margin-right: 3px;
}
[dir=rtl] .site--desktop-header--classic-three .topbar__button-label {
  margin-left: 3px;
}
[dir=ltr] .site--desktop-header--classic-three .topbar__button--has-arrow {
  padding-right: 18px;
  padding-right: 22px;
}
[dir=rtl] .site--desktop-header--classic-three .topbar__button--has-arrow {
  padding-left: 18px;
  padding-left: 22px;
}
.site--desktop-header--classic-three .topbar__button-arrow {
  position: absolute;
  fill: currentColor;
  top: calc(50% - 3px);
}
[dir=ltr] .site--desktop-header--classic-three .topbar__button-arrow {
  right: 8px;
  right: 10px;
}
[dir=rtl] .site--desktop-header--classic-three .topbar__button-arrow {
  left: 8px;
  left: 10px;
}
.site--desktop-header--classic-three .topbar__button-arrow svg {
  display: block;
}
.site--desktop-header--classic-three .topbar__menu {
  position: relative;
}
.site--desktop-header--classic-three .topbar__menu-body {
  visibility: hidden;
  transform-origin: top;
  transform: rotateX(60deg);
  opacity: 0;
  transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
}
.site--desktop-header--classic-three .topbar__menu-body {
  color: #262626;
  background-color: #fff;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 150px;
  padding: 0.5rem 0;
  font-size: 14px;
  line-height: 26px;
  z-index: 1;
  font-weight: 500;
}
[dir=ltr] .site--desktop-header--classic-three .topbar__menu-body {
  right: 0;
}
[dir=rtl] .site--desktop-header--classic-three .topbar__menu-body {
  left: 0;
}
.site--desktop-header--classic-three .topbar__menu-item {
  display: block;
  padding: 3px 1rem;
  background: transparent;
  color: #262626;
  font-family: inherit;
  font-weight: inherit;
  border: none;
  width: 100%;
}
[dir=ltr] .site--desktop-header--classic-three .topbar__menu-item {
  text-align: left;
}
[dir=rtl] .site--desktop-header--classic-three .topbar__menu-item {
  text-align: right;
}
.site--desktop-header--classic-three .topbar__menu-item:focus {
  outline: none;
}
.site--desktop-header--classic-three .topbar__menu-item:hover {
  color: #262626;
  background: #f2f2f2;
}
[dir=ltr] .site--desktop-header--classic-three .topbar__menu-item img {
  margin-right: 8px;
}
[dir=rtl] .site--desktop-header--classic-three .topbar__menu-item img {
  margin-left: 8px;
}
.site--desktop-header--classic-three .topbar__menu-item span {
  position: relative;
  top: 1px;
}
.site--desktop-header--classic-three .topbar__menu--open .topbar__menu-body {
  visibility: visible;
  transition-delay: 0s, 0s, 0s;
  opacity: 1;
  transform: rotateX(0deg);
}
.site--desktop-header--classic-three .topbar--spaceship-end {
  justify-content: flex-end;
}
.site--desktop-header--classic-three .topbar--classic {
  background: #333;
}
.site--desktop-header--classic-three .topbar--classic .topbar__item-text {
  color: #fff;
  font-weight: 500;
}
@media (max-resolution: 1dppx) {
  .site--desktop-header--classic-three .topbar--classic .topbar__item-text {
    font-weight: 400;
  }
}
.site--desktop-header--classic-three .topbar--classic .topbar__link {
  color: #9e9e9e;
  font-weight: 400;
}
.site--desktop-header--classic-three .topbar--classic .topbar__link:hover {
  color: #fff;
}
.site--desktop-header--classic-three .topbar--classic .topbar__button-label {
  color: #9e9e9e;
  font-weight: 400;
}
.site--desktop-header--classic-three .topbar--classic .topbar__button-title {
  color: #fff;
  font-weight: 500;
}
@media (max-resolution: 1dppx) {
  .site--desktop-header--classic-three .topbar--classic .topbar__button-title {
    font-weight: 400;
  }
}
.site--desktop-header--classic-three .topbar--classic .topbar__button-arrow {
  color: #808080;
}
.site--desktop-header--classic-three .topbar--classic .topbar__menu--open .topbar__menu-button,
.site--desktop-header--classic-three .topbar--classic .topbar__button:hover {
  background: rgba(255, 255, 255, 0.08);
}
.site--desktop-header--classic-three .topbar--classic .topbar__menu--open .topbar__menu-button .topbar__button-label,
.site--desktop-header--classic-three .topbar--classic .topbar__button:hover .topbar__button-label {
  color: #fff;
}

/*
// .vehicle-picker
*/
.site--desktop-header--classic-three .vehicle-picker__panel {
  display: none;
}
.site--desktop-header--classic-three .vehicle-picker__panel--active {
  display: block;
}
.site--desktop-header--classic-three .vehicle-picker__panel-body {
  padding: 20px 20px 28px;
}
.site--desktop-header--classic-three .vehicle-picker__text {
  font-size: 15px;
  line-height: 18px;
  color: #6c757d;
  margin-bottom: 12px;
}
.site--desktop-header--classic-three .vehicle-picker__text:first-child {
  margin-top: -3px;
}
.site--desktop-header--classic-three .vehicle-picker__actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;
}
[dir=ltr] .site--desktop-header--classic-three .vehicle-picker__actions > * + * {
  margin-left: 20px;
}
[dir=rtl] .site--desktop-header--classic-three .vehicle-picker__actions > * + * {
  margin-right: 20px;
}
.site--desktop-header--classic-three .search__car-selector-link a {
  font-size: 14px;
  color: #999;
}
.site--desktop-header--classic-three .search__car-selector-link a:hover {
  text-decoration: underline;
}

/* ----------------------------------------
// Pages
// ---------------------------------------- */
/*
// .about
*/
/* ----------------------------------------
// Blocks
// ---------------------------------------- */
/*
// .block-header
*/
/*
// .block-space
*/
.site--desktop-header--classic-three .block-space--layout--spaceship-ledge-height {
  display: none;
}

/* ----------------------------------------
// Blog
// ---------------------------------------- */
/*
// .post-header
*/