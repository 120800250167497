/*
// .typography
*/
@import '../variables';
@import '../mixins/typography';


.typography {
    @include make-typography;
}
