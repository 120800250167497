/*
// .layout-switcher
*/
@import '../variables';
@import '../mixins/scheme';


.layout-switcher {}
.layout-switcher__list {
    display: flex;
}
.layout-switcher__button {
    padding: 7px;
    margin: 0;
    border: none;
    display: block;
    border-radius: 2px;

    @include define-button-state($layout-switcher-scheme, normal);

    &:focus {
        outline: none;
    }

    svg {
        display: block;
        fill: currentColor;
    }
}
.layout-switcher__button:hover {
    @include define-button-state($layout-switcher-scheme, hover);
}
.layout-switcher__button--active {
    @include define-button-state($layout-switcher-scheme, current);

    &:hover {
        background-color: transparent;
    }
}
