/*
// .owl-prevent-click
*/


.owl-prevent-click.owl-carousel::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 100;
}
