/*
// .posts-view
*/
@import '../variables';


.posts-view {}
.posts-view__pagination {
    margin-top: 48px;
    display: flex;
    justify-content: center;
}
