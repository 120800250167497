// --------------------------------------------------------------------------
// -- You can add your custom CSS here and it will overwrite template styles.
// --------------------------------------------------------------------------


/* hide item start */
.hide {
    display: none;
    opacity: 0;
    visibility: hidden;
}
/* hide item end */

.f-14 {
    font-size: 14px !important;
}

.cart-table__column--price--old,
.wishlist__column--price--old,
.compare-table__column--product--old {
    font-weight: 400;
    text-decoration: line-through;
    color: #999;
}

.checkout-featured-address {
    display: flex;

    @media (max-width: 474px) {
        flex-wrap: wrap;
    }

    .addresses-list__item {
        width: calc((100% - 16px - 0.5px) / 2);

        @media (max-width: 474px) {
            width: calc((100%) / 1);
        }
    }
}

@include media-breakpoint-down(md) {
    .cart-table__column--price--old {
        font-size: 12px;
    }
}

@include media-breakpoint-down(xs) {
    .cart-table__column--price--old {
        font-size: 12px;
    }
}

@media (max-width: 474px) {
    .cart-table__column--price--old {
        font-size: 12px;
    }
}

.address-card__name{
    margin-top: 0.625rem;
}

/* adding quantity product card start */
.product-card__footer__before {
    padding: 16px;
    align-items: center;
    display: flex;
    order: 2;
}

.product-card__footer {
    .product__actions-fix {
        padding: 0;
        width: 100%;
    }
}
/* adding quantity product card end */

/* new menu start */
.site--desktop-header--classic-one .header__navbar {
    position: relative;
}

.site--desktop-header--classic-one .main-menu__list,
.site--desktop-header--classic-one .main-menu__item--submenu--menu {
    position: unset !important;
}
.site--desktop-header--classic-one .main-menu__item--submenu--menu .main-menu__submenu{
    z-index: 0 !important;
}

.site--desktop-header--classic-one .menu__list {
    justify-content: center !important;
}
/* new menu end */

/* topbar disable start */
.site--desktop-header--classic-one .header {
    grid-template-rows: 0px auto auto !important;
}
/* topbar disable end */

/* brand new start*/
.product-card--layout--grid .product-card__brand {
    margin: 0;
    padding: 4px 8px;
    margin-top: 4px;
    position: absolute;
    top: 0;
    background: #e52727;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
}

.product-card--layout--grid .product-card__brand a{
    color: white;
}


.products-list[data-layout=grid] .product-card .product-card__brand{
    margin: 0;
    padding: 4px 8px;
    margin-top: 4px;
    position: absolute;
    top: 0;
    background: #e52727;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
}

.products-list[data-layout=grid] .product-card .product-card__brand a{
    color: white;
}
/* brand new end*/

/* code new start */
.product-card--layout--grid .product-card__code {
    margin: 0;
    padding: 4px 8px;
    margin-top: 34px;
    position: absolute;
    top: 0;
    background: #262626;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
}

.product-card--layout--grid .product-card__code a{
    color: white;
}

.products-list[data-layout=grid] .product-card .product-card__code{
    margin: 0;
    padding: 4px 8px;
    margin-top: 34px;
    position: absolute;
    top: 0;
    background: #262626;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
}

.products-list[data-layout=grid] .product-card .product-card__code a{
    color: white;
}
/* code new end*/