/*
// .about
*/
@import '../variables';
@import '../mixins/card';
@import '../mixins/direction';
@import '../mixins/breakpoints';
@import '../mixins/decor';


.about {}
.about__body {
    display: grid;
    z-index: 0;
}

@include media-breakpoint-up(xl) {
    .about__body {
        grid-template-columns: 1fr 380px (1110px - 380px) 1fr;
        grid-template-rows: 100px auto auto;
    }
    .about__image {
        grid-column: 1 / 5;
        grid-row: 1 / 3;
    }
    .about__card {
        grid-column: 2;
        grid-row: 2 / 4;
    }
    .about__indicators {
        grid-column: 3;
        grid-row: 3;
    }
}
@include media-breakpoint-down(lg) {
    .about__image {
        grid-column: 1 / 4;
        grid-row: 1 / 3;
    }
    .about__card {
        grid-column: 2;
        grid-row: 2 / 4;
    }
    .about__indicators {
        grid-column: 2;
        grid-row: 4;
    }
}
@include media-breakpoint-between(md, lg) {
    .about__body {
        grid-template-columns: 1fr 690px 1fr;
        grid-template-rows: 80px auto 60px auto;
    }
}
@include media-breakpoint-only(sm) {
    .about__body {
        grid-template-columns: 1fr 510px 1fr;
        grid-template-rows: 40px auto 60px auto;
    }
}
@include media-breakpoint-only(xs) {
    .about__body {
        grid-template-columns: 20px calc(100% - 40px) 20px;
        grid-template-rows: 20px auto 60px auto;
    }
}


/*
// .about__image
*/
.about__image {
    position: relative;
    overflow: hidden;
    z-index: -1;
}
.about__image-decor {
    position: absolute;
    bottom: -1px;

    @include decor-style(map_get($body-scheme, main));
}
.about__image-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center;
    z-index: -1;

    &:before {
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        background: $about-image-overlay-bg;
    }
}


/*
// .about__card
*/
.about__card {
    @include card;

    border-radius: 2px;
    padding: 32px 36px;
    display: flex;
    flex-direction: column;
}
.about__card-title {
    font-size: 40px;
    font-weight: $font-weight-bold;
    padding: 16px 0 40px;
    text-align: center;
    margin-bottom: 0;
}
.about__card-text {
    text-align: center;
    line-height: 1.75;
}
.about__card-author {
    color: map_get($card-scheme, opposite-alt);
    font-size: 14px;
    position: relative;
    align-self: center;
    margin-top: 16px;

    @include direction {
        #{$padding-inline-start}: 28px;
    }

    &:before {
        display: block;
        height: 1px;
        width: 20px;
        background: currentColor;
        content: '';
        opacity: .8;
        position: absolute;
        top: 10px;

        @include direction {
            #{$inset-inline-start}: 0;
        }
    }
}
.about__card-signature {
    padding: 40px 0 12px;
    margin-top: auto;
    align-self: center;
}


@include media-breakpoint-up(xl) {
    .about__card {
        min-height: 530px;
    }
}
@include media-breakpoint-down(lg) {
    .about__card {
        padding: 32px 64px;
        margin: 0 auto;
        max-width: 510px;
    }
}
@include media-breakpoint-down(sm) {
    .about__card {
        padding: 32px 36px;
    }
}
@include media-breakpoint-down(xs) {
    .about__card-title {
        padding: 8px 0 24px;
        font-size: 32px;
    }
    .about__card {
        padding: 24px 28px;
        font-size: 15px;
    }
}


/*
// .about__indicators
*/
@mixin local-indicators-layout($columns, $gutter) {
    .about__indicators-body {
        margin: -($gutter * .5);
    }
    .about__indicators-item {
        margin: ($gutter * .5);
        width: calc(100% / #{$columns} - #{$gutter});
    }
}


@include local-indicators-layout(3, 24px);


.about__indicators {
    align-self: center;
}
.about__indicators-body {
    display: flex;
    flex-wrap: wrap;
}
.about__indicators-item-value {
    font-size: 48px;
    font-weight: bold;
    line-height: 1;
}
.about__indicators-item-title {
    color: map_get($body-scheme, opposite-alt);
    font-size: 15px;
    margin-top: 6px;
    line-height: 18px;
}


@include media-breakpoint-up(xl) {
    .about__indicators {
        margin-top: 40px;
        margin-bottom: 40px;

        @include direction {
            #{$margin-inline-start}: 52px;
        }
    }
}
@include media-breakpoint-down(lg) {
    .about__indicators {
        text-align: center;
        margin-top: 48px;
        width: 100%;
    }
}
@include media-breakpoint-down(sm) {
    .about__indicators {
        margin-top: 40px;
    }
    .about__indicators-item-value {
        font-size: 36px;
    }
}
@include media-breakpoint-down(xs) {
    @include local-indicators-layout(1, 32px);
}
