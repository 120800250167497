@charset "UTF-8";
/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
@import '~@fortawesome/fontawesome-free/css/all.min.css';
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1380px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xxl,
.col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xxl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xxl-first {
    order: -1;
  }

  .order-xxl-last {
    order: 13;
  }

  .order-xxl-0 {
    order: 0;
  }

  .order-xxl-1 {
    order: 1;
  }

  .order-xxl-2 {
    order: 2;
  }

  .order-xxl-3 {
    order: 3;
  }

  .order-xxl-4 {
    order: 4;
  }

  .order-xxl-5 {
    order: 5;
  }

  .order-xxl-6 {
    order: 6;
  }

  .order-xxl-7 {
    order: 7;
  }

  .order-xxl-8 {
    order: 8;
  }

  .order-xxl-9 {
    order: 9;
  }

  .order-xxl-10 {
    order: 10;
  }

  .order-xxl-11 {
    order: 11;
  }

  .order-xxl-12 {
    order: 12;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.375rem 0.625rem;
  margin-left: 0;
  line-height: 1.25;
  color: #007bff;
  background-color: transparent;
  border: 0 solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #f0f0f0;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-none {
    display: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1400px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-left {
    float: left !important;
  }

  .float-xxl-right {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1400px) {
  .m-xxl-0 {
    margin: 0 !important;
  }

  .mt-xxl-0,
.my-xxl-0 {
    margin-top: 0 !important;
  }

  .mr-xxl-0,
.mx-xxl-0 {
    margin-right: 0 !important;
  }

  .mb-xxl-0,
.my-xxl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xxl-0,
.mx-xxl-0 {
    margin-left: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .mt-xxl-1,
.my-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xxl-1,
.mx-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xxl-1,
.my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xxl-1,
.mx-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .mt-xxl-2,
.my-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xxl-2,
.mx-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xxl-2,
.my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xxl-2,
.mx-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .mt-xxl-3,
.my-xxl-3 {
    margin-top: 1rem !important;
  }

  .mr-xxl-3,
.mx-xxl-3 {
    margin-right: 1rem !important;
  }

  .mb-xxl-3,
.my-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xxl-3,
.mx-xxl-3 {
    margin-left: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .mt-xxl-4,
.my-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xxl-4,
.mx-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xxl-4,
.my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xxl-4,
.mx-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .mt-xxl-5,
.my-xxl-5 {
    margin-top: 3rem !important;
  }

  .mr-xxl-5,
.mx-xxl-5 {
    margin-right: 3rem !important;
  }

  .mb-xxl-5,
.my-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xxl-5,
.mx-xxl-5 {
    margin-left: 3rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .pt-xxl-0,
.py-xxl-0 {
    padding-top: 0 !important;
  }

  .pr-xxl-0,
.px-xxl-0 {
    padding-right: 0 !important;
  }

  .pb-xxl-0,
.py-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xxl-0,
.px-xxl-0 {
    padding-left: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .pt-xxl-1,
.py-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xxl-1,
.px-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xxl-1,
.py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xxl-1,
.px-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .pt-xxl-2,
.py-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xxl-2,
.px-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xxl-2,
.py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xxl-2,
.px-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .pt-xxl-3,
.py-xxl-3 {
    padding-top: 1rem !important;
  }

  .pr-xxl-3,
.px-xxl-3 {
    padding-right: 1rem !important;
  }

  .pb-xxl-3,
.py-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xxl-3,
.px-xxl-3 {
    padding-left: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .pt-xxl-4,
.py-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xxl-4,
.px-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xxl-4,
.py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xxl-4,
.px-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .pt-xxl-5,
.py-xxl-5 {
    padding-top: 3rem !important;
  }

  .pr-xxl-5,
.px-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-5,
.py-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xxl-5,
.px-xxl-5 {
    padding-left: 3rem !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xxl-n1,
.my-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xxl-n1,
.mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xxl-n1,
.my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xxl-n1,
.mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xxl-n2,
.my-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xxl-n2,
.mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xxl-n2,
.my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xxl-n2,
.mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .mt-xxl-n3,
.my-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xxl-n3,
.mx-xxl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xxl-n3,
.my-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xxl-n3,
.mx-xxl-n3 {
    margin-left: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xxl-n4,
.my-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xxl-n4,
.mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xxl-n4,
.my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xxl-n4,
.mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -3rem !important;
  }

  .mt-xxl-n5,
.my-xxl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xxl-n5,
.mx-xxl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xxl-n5,
.my-xxl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xxl-n5,
.mx-xxl-n5 {
    margin-left: -3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mt-xxl-auto,
.my-xxl-auto {
    margin-top: auto !important;
  }

  .mr-xxl-auto,
.mx-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-auto,
.my-xxl-auto {
    margin-bottom: auto !important;
  }

  .ml-xxl-auto,
.mx-xxl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .text-xxl-left {
    text-align: left !important;
  }

  .text-xxl-right {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/* ----------------------------------------
// Table of Contents:
// - Common
//   - animations
//   - base
//   - .document
//   - grid
//   - header
//   - .site
// - Components
//   - .alert
//   - .arrow
//   - .breadcrumb
//   - .btn
//   - .button-toggle
//   - .card
//   - .card-table
//   - .collapse-area
//   - .container
//   - .decor
//   - .filters-button
//   - .form
//   - .form-control
//   - .image
//   - .input-check
//   - .input-check-color
//   - .input-number
//   - .input-radio
//   - .input-radio-color
//   - .input-radio-label
//   - .layout-switcher
//   - .loading-bar
//   - .modal
//   - .ngx-slider
//   - .ngx-toastr
//   - .nouislider
//   - .owl-prevent-click
//   - .pagination
//   - .rating
//   - .section-header
//   - .select2
//   - .share-links
//   - .shop-features
//   - .sidebar
//   - .social-links
//   - .status-badge
//   - .tag-badge
//   - .tags
//   - .teammate
//   - .timer
//   - .tooltip
//   - .typography
//   - .vehicle-form
//   - .vehicle-select
//   - .vehicles-list
//   - .view-options
//   - .widget
// - Filters
//   - .filter
//   - .filter-category
//   - .filter-color
//   - .filter-list
//   - .filter-range
//   - .filter-rating
//   - .filter-vehicle
// - Footer
//   - .footer-contacts
//   - .footer-links
//   - .footer-texts
//   - .footer-newsletter
//   - .site-footer
// - Pages
//   - .about
//   - .contacts
//   - .faq
//   - .not-found
// - Blocks
//   - .block-banners
//   - .block-brands
//   - .block-categories
//   - .block-empty
//   - .block-features
//   - .block-finder
//   - .block-header
//   - .block-map
//   - .block-posts-carousel
//   - .block-products-carousel
//   - .block-products-columns
//   - .block-reviews
//   - .block-sale
//   - .block-slideshow
//   - .block-space
//   - .block-split
//   - .block-teammates
//   - .block-zone
// - Widgets
//   - .widget-about-us
//   - .widget-categories
//   - .widget-categories-list
//   - .widget-comments
//   - .widget-filters
//   - .widget-newsletter
//   - .widget-posts
//   - .widget-products
//   - .widget-search
//   - .widget-tags
// - Shop
//   - .analogs-table
//   - .applied-filters
//   - .cart
//   - .cart-table
//   - .categories-list
//   - .category-card
//   - .checkout
//   - .compare
//   - .compare-table
//   - .order-header
//   - .order-list
//   - .order-success
//   - .payment-methods
//   - .product
//   - .product-card
//   - .product-form
//   - .product-gallery
//   - .product-tabs
//   - .products-list
//   - .products-view
//   - .quickview
//   - .review
//   - .reviews-list
//   - .reviews-view
//   - .spec
//   - .vehicle-picker-modal
//   - .wishlist
// - Blog
//   - .blog-view
//   - .comment
//   - .comments-list
//   - .comments-view
//   - .post
//   - .post-card
//   - .post-header
//   - .post-navigation
//   - .post-view
//   - .posts-list
//   - .posts-view
// - Account
//   - .account-nav
//   - .address-card
//   - .addresses-list
//   - .dashboard
//   - .profile-card
// ---------------------------------------- */
/* ----------------------------------------
// Common
// ---------------------------------------- */
/*
// animations
*/
@keyframes loader-animation {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
/*
// base
*/
html {
  height: 100%;
}

body {
  height: 100%;
  background: #fafafa;
  color: #262626;
  font-family: "Roboto", "sans-serif";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  overflow-y: scroll;
  backface-visibility: hidden;
}
[dir=ltr] body {
  text-align: left;
  direction: ltr;
}
[dir=rtl] body {
  text-align: right;
  direction: rtl;
}

a {
  color: #007bff;
}

a:hover {
  color: #007bff;
  text-decoration: none;
}

label {
  margin-bottom: 0.25rem;
  font-size: 15px;
}

h1 {
  font-size: 2.5rem;
  font-weight: 500;
}

h2 {
  font-size: 2rem;
  font-weight: 500;
}

h3 {
  font-size: 1.75rem;
  font-weight: 500;
}

h4 {
  font-size: 1.5rem;
  font-weight: 500;
}

h5 {
  font-size: 1.25rem;
  font-weight: 500;
}

h6 {
  font-size: 1rem;
  font-weight: 500;
}

[dir=ltr] .reflect-rtl {
  transform: scaleX(1);
}
[dir=rtl] .reflect-rtl {
  transform: scaleX(-1);
}

/*
// .document
*/
.document {
  max-width: 760px;
  margin: 0 auto;
}

.document__header {
  text-align: center;
  padding: 44px 0 52px;
}

.document__title {
  font-weight: 700;
  letter-spacing: 0.01em;
  margin: 0;
}

.document__subtitle {
  font-size: 15px;
  color: #999;
  margin-top: 8px;
  margin-bottom: -4px;
}

.document__content {
  padding: 50px;
}

.document__signature {
  margin-top: 2.5rem;
}

@media (max-width: 1199.98px) {
  .document__header {
    padding: 44px 0 48px;
  }

  .document__subtitle {
    margin-top: 4px;
  }
}
@media (max-width: 991.98px) {
  .document__content {
    padding: 40px;
  }
}
@media (max-width: 767.98px) {
  .document__header {
    padding: 36px 0 40px;
  }

  .document__title {
    font-size: 36px;
  }

  .document__content {
    padding: 28px;
  }
}
@media (max-width: 575.98px) {
  .document__title {
    font-size: 32px;
  }

  .document__subtitle {
    font-size: 14px;
  }

  .document__content {
    padding: 24px;
  }
  .document__content .typography {
    font-size: 15px;
  }

  .document__signature {
    margin-top: 24px;
  }
}
/*
// grid
*/
.col-1of5 {
  padding: 0 15px;
  flex: 0 0 20%;
  max-width: 20%;
  position: relative;
  width: 100%;
}

.col-2of5 {
  padding: 0 15px;
  flex: 0 0 40%;
  max-width: 40%;
  position: relative;
  width: 100%;
}

.col-3of5 {
  padding: 0 15px;
  flex: 0 0 60%;
  max-width: 60%;
  position: relative;
  width: 100%;
}

.col-4of5 {
  padding: 0 15px;
  flex: 0 0 80%;
  max-width: 80%;
  position: relative;
  width: 100%;
}

.col-5of5 {
  padding: 0 15px;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
}

/*
// header
*/
@media (min-width: 1200px) {
  .mobile-header,
.mobile-menu {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .header {
    display: none;
  }
}
/*
// .site
*/
.site {
  height: 100%;
}

.site__container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.site__header {
  flex-shrink: 0;
}

.site__body {
  flex-grow: 1;
}

.site__footer {
  flex-shrink: 0;
}

/* ----------------------------------------
// Components
// ---------------------------------------- */
/*
// .alert
*/
.alert {
  border: none;
  border-radius: 2px;
  font-size: 15px;
  line-height: 20px;
  padding: 0.75rem 1rem;
}
.alert a {
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  color: currentColor;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 1.5px;
  padding: 0 3px;
}
.alert a:hover {
  background: rgba(255, 255, 255, 0.85);
}
.alert a:active {
  background: rgba(255, 255, 255, 0.5);
}
.alert hr {
  border-color: currentColor;
  opacity: 0.12;
}
.alert .close svg {
  display: block;
}

.alert-primary {
  background: #b2d9ff;
  color: #36414d;
}

.alert-secondary {
  background: #d9d9d9;
  color: #4d4d4d;
}

.alert-info {
  background: #b8e2e5;
  color: #2d3e40;
}

.alert-success {
  background: #bde5a1;
  color: #34402d;
}

.alert-danger {
  background: #ffb8b8;
  color: #473333;
}

.alert-warning {
  background: #f2e291;
  color: #4d472e;
}

.alert-xs {
  font-size: 14px;
  line-height: 1.25;
  padding: 6px 9px;
}

.alert-sm {
  font-size: 15px;
  padding: 7px 12px;
}

.alert-lg {
  font-size: 16px;
  padding: 1.125rem 1.375rem;
}

[dir=ltr] .alert-dismissible {
  padding-right: 3rem;
}
[dir=rtl] .alert-dismissible {
  padding-left: 3rem;
}

.alert-dismissible .close {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  height: 100%;
  transition: all 0.2s;
  fill: rgba(0, 0, 0, 0.3);
  background: transparent;
}
[dir=ltr] .alert-dismissible .close {
  right: 0;
  border-top-right-radius: 1.5px;
  border-bottom-right-radius: 1.5px;
}
[dir=rtl] .alert-dismissible .close {
  left: 0;
  border-top-left-radius: 1.5px;
  border-bottom-left-radius: 1.5px;
}
.alert-dismissible .close:focus {
  outline: none;
}

.alert-dismissible .close:not(:disabled):not(.disabled):focus,
.alert-dismissible .close:not(:disabled):not(.disabled):hover {
  background: rgba(0, 0, 0, 0.08);
  fill: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

/*
// .arrow
*/
.arrow {
  display: block;
}

.arrow__button {
  display: flex;
  position: relative;
  z-index: 0;
  align-items: center;
  border: none;
  background: transparent;
  pointer-events: none;
  transition: color 0.15s;
  color: #fff;
}
.arrow__button:before {
  background: #e52727;
}
.arrow__button svg {
  fill: currentColor;
  display: block;
}
[dir=ltr] .arrow__button svg {
  transform: scaleX(1);
}
[dir=rtl] .arrow__button svg {
  transform: scaleX(-1);
}
.arrow__button:focus {
  outline: none;
}
.arrow__button:before {
  position: absolute;
  display: block;
  content: "";
  height: 100%;
  transform-origin: center center;
  z-index: -1;
  border-radius: 2px;
  pointer-events: auto;
  transition: background 0.15s;
}
.arrow__button:hover {
  color: #fff;
}
.arrow__button:hover:before {
  background: #333;
}
.arrow__button:active {
  color: #fff;
}
.arrow__button:active:before {
  background: #474747;
}
.arrow__button:active, .arrow__button:active:before {
  transition-duration: 0s;
}
.arrow__button:after {
  position: absolute;
  display: block;
  content: "";
  top: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  transition: background 0.2s;
  opacity: 0.01;
}
[dir=ltr] .arrow__button:after {
  left: -2px;
}
[dir=rtl] .arrow__button:after {
  right: -2px;
}
.arrow__button:hover:after {
  background: rgba(0, 0, 0, 0.01);
}
.arrow__button:active:after {
  background: rgba(255, 255, 255, 0.01);
  transition-duration: 0s;
}

[dir=ltr] .arrow--next svg {
  margin-right: -1px;
}
[dir=rtl] .arrow--next svg {
  margin-left: -1px;
}

[dir=ltr] .arrow--prev svg {
  margin-left: -1px;
}
[dir=rtl] .arrow--prev svg {
  margin-right: -1px;
}

.arrow__button {
  height: 23px;
  padding: 0 14.37131px;
}
.arrow__button:before {
  width: calc(100% - 8.37131px);
}
[dir=ltr] .arrow__button:before {
  left: 4.185655px;
}
[dir=rtl] .arrow__button:before {
  right: 4.185655px;
}

[dir=ltr] .arrow__button:before {
  transform: skewX(-20deg);
}
[dir=rtl] .arrow__button:before {
  transform: skewX(20deg);
}

/*
// .breadcrumb
*/
.breadcrumb {
  background: transparent;
  padding: 0;
  margin: 0;
  line-height: 20px;
  font-size: 14px;
  color: #6c757d;
}
.breadcrumb:after {
  content: "";
  clear: both;
}

.breadcrumb__list {
  display: block;
  list-style: none;
  padding: 0;
  margin: -4px 0 0;
  width: 100%;
}

.breadcrumb__item {
  margin-top: 4px;
}
[dir=ltr] .breadcrumb__item {
  margin-right: -7.12435px;
  float: left;
}
[dir=rtl] .breadcrumb__item {
  margin-left: -7.12435px;
  float: right;
}

.breadcrumb__item-link {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;
  height: 21px;
  padding: 0 15.12435px;
  pointer-events: none;
}
.breadcrumb__item-link, .breadcrumb__item-link:hover {
  color: inherit;
}
.breadcrumb__item-link:before, .breadcrumb__item-link:after {
  pointer-events: auto;
  position: absolute;
  background-color: #ebebeb;
  top: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 3px;
  transition: background 0.1s;
}
.breadcrumb__item-link:before {
  content: "";
}
[dir=ltr] .breadcrumb__item-link:before {
  left: 6.062175px;
  right: 6.062175px;
  transform: skewX(-30deg);
}
[dir=rtl] .breadcrumb__item-link:before {
  right: 6.062175px;
  left: 6.062175px;
  transform: skewX(30deg);
}

[dir=ltr] .breadcrumb__item--first .breadcrumb__item-link {
  padding-left: 7px;
}
[dir=rtl] .breadcrumb__item--first .breadcrumb__item-link {
  padding-right: 7px;
}
.breadcrumb__item--first .breadcrumb__item-link:after {
  content: "";
}
[dir=ltr] .breadcrumb__item--first .breadcrumb__item-link:after {
  left: 0;
  right: 12.12435px;
}
[dir=rtl] .breadcrumb__item--first .breadcrumb__item-link:after {
  right: 0;
  left: 12.12435px;
}

.breadcrumb__item--parent .breadcrumb__item-link:hover:before, .breadcrumb__item--parent .breadcrumb__item-link:hover:after {
  background-color: #e5e5e5;
}
.breadcrumb__item--parent .breadcrumb__item-link:active:before, .breadcrumb__item--parent .breadcrumb__item-link:active:after {
  transition-duration: 0s;
  background-color: #e0e0e0;
}

[dir=ltr] .breadcrumb__item--last {
  margin-right: 0;
}
[dir=rtl] .breadcrumb__item--last {
  margin-left: 0;
}

.breadcrumb__title-safe-area {
  display: block;
  height: 21px;
  width: calc(50% + var(--block-header-title-width, 0px) * .5 + 64px);
  margin-top: 4px;
}
[dir=ltr] .breadcrumb__title-safe-area {
  float: left;
}
[dir=rtl] .breadcrumb__title-safe-area {
  float: right;
}
@media (max-width: 1199.98px) {
  .breadcrumb__title-safe-area {
    display: none;
  }
}

.breadcrumb__spaceship-safe-area {
  display: block;
  height: 21px;
  width: calc(50% + 480px * .5 + 20px);
  margin-top: 4px;
}
[dir=ltr] .breadcrumb__spaceship-safe-area {
  float: right;
}
[dir=rtl] .breadcrumb__spaceship-safe-area {
  float: left;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .breadcrumb__spaceship-safe-area {
    width: calc(50% + 440px * .5 + 20px);
  }
}
@media (max-width: 1199.98px) {
  .breadcrumb__spaceship-safe-area {
    display: none;
  }
}

/*
// .btn
*/
.btn {
  border: none;
  border-radius: 2px;
  fill: currentColor;
  font-weight: 500;
}
.btn.disabled, .btn:disabled {
  cursor: default;
}

.btn-icon {
  padding: 0;
}
.btn-icon svg {
  display: block;
  margin: auto;
}

.btn-loading {
  position: relative;
}
.btn-loading:after {
  /* nl size (default) */
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
  border-width: 2px;
  left: calc(50% - 12px);
  top: calc(50% - 12px);
  width: 24px;
  height: 24px;
  border-radius: 12px;
}
.btn-loading.btn-xl:after {
  /* xl size */
  left: calc(50% - 21px);
  top: calc(50% - 21px);
  width: 42px;
  height: 42px;
  border-radius: 21px;
}
.btn-loading.btn-lg:after, .btn-group-lg > .btn-loading.btn:after {
  /* lg size */
  left: calc(50% - 16px);
  top: calc(50% - 16px);
  width: 32px;
  height: 32px;
  border-radius: 16px;
}
.btn-loading.btn-sm:after, .btn-group-sm > .btn-loading.btn:after {
  /* sm size */
  left: calc(50% - 10px);
  top: calc(50% - 10px);
  width: 20px;
  height: 20px;
  border-radius: 10px;
}
.btn-loading.btn-xs:after {
  /* xs size */
  left: calc(50% - 8px);
  top: calc(50% - 8px);
  width: 16px;
  height: 16px;
  border-radius: 8px;
}

.btn:not(.btn-icon) {
  font-size: 16px;
  line-height: 19px;
  padding: calc(17px * .5) 16px;
}
.btn.btn-icon {
  height: 36px;
  width: 36px;
}

.btn-sm:not(.btn-icon), .btn-group-sm > .btn:not(.btn-icon) {
  font-size: 14px;
  line-height: 17px;
  padding: calc(13px * .5) 12px;
}
.btn-sm.btn-icon, .btn-group-sm > .btn-icon.btn {
  height: 30px;
  width: 30px;
}

.btn-xs:not(.btn-icon) {
  font-size: 13px;
  line-height: 15px;
  padding: calc(9px * .5) 9px;
}
.btn-xs.btn-icon {
  height: 24px;
  width: 24px;
}

.btn-lg:not(.btn-icon), .btn-group-lg > .btn:not(.btn-icon) {
  font-size: 18px;
  line-height: 22px;
  padding: calc(20px * .5) 24px;
}
.btn-lg.btn-icon, .btn-group-lg > .btn-icon.btn {
  height: 42px;
  width: 42px;
}

.btn-xl:not(.btn-icon) {
  font-size: 20px;
  line-height: 26px;
  padding: calc(34px * .5) 40px;
}
.btn-xl.btn-icon {
  height: 60px;
  width: 60px;
}

.btn-primary:hover {
  border-color: #333;
  background: #333;
  color: #fff;
}
.btn-primary, .btn-primary.disabled, .btn-primary:disabled {
  border-color: #e52727;
  background: #e52727;
  color: #fff;
}
.btn-primary.focus, .btn-primary:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus {
  border-color: #333;
  background: #333;
  color: #fff;
  box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus {
  border-color: #4d4d4d;
  background: #4d4d4d;
  color: #fff;
}
.btn-primary.btn-loading:not(:disabled):not(.disabled), .btn-primary.btn-loading:not(:disabled):not(.disabled):active, .btn-primary.btn-loading:not(:disabled):not(.disabled).active {
  border-color: #e52727;
  background: #e52727;
  cursor: default;
}
.btn-primary.btn-loading {
  /* hide button content */
}
.btn-primary.btn-loading, .btn-primary.btn-loading:hover, .btn-primary.btn-loading.focus, .btn-primary.btn-loading:focus, .btn-primary.btn-loading:not(:disabled):not(.disabled).active, .btn-primary.btn-loading:not(:disabled):not(.disabled):active, .btn-primary.btn-loading.disabled, .btn-primary.btn-loading:disabled {
  color: transparent;
  fill: transparent;
}
.btn-primary.btn-loading:after {
  border-color: rgba(255, 255, 255, 0.1);
  border-top-color: rgba(255, 255, 255, 0.5);
}

.btn-secondary:hover {
  border-color: #e5e5e5;
  background: #e5e5e5;
  color: #333;
}
.btn-secondary, .btn-secondary.disabled, .btn-secondary:disabled {
  border-color: #f0f0f0;
  background: #f0f0f0;
  color: #333;
}
.btn-secondary.focus, .btn-secondary:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus {
  border-color: #e5e5e5;
  background: #e5e5e5;
  color: #333;
  box-shadow: none;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus {
  border-color: #d6d6d6;
  background: #d6d6d6;
  color: #333;
}
.btn-secondary.btn-loading:not(:disabled):not(.disabled), .btn-secondary.btn-loading:not(:disabled):not(.disabled):active, .btn-secondary.btn-loading:not(:disabled):not(.disabled).active {
  border-color: #f0f0f0;
  background: #f0f0f0;
  cursor: default;
}
.btn-secondary.btn-loading {
  /* hide button content */
}
.btn-secondary.btn-loading, .btn-secondary.btn-loading:hover, .btn-secondary.btn-loading.focus, .btn-secondary.btn-loading:focus, .btn-secondary.btn-loading:not(:disabled):not(.disabled).active, .btn-secondary.btn-loading:not(:disabled):not(.disabled):active, .btn-secondary.btn-loading.disabled, .btn-secondary.btn-loading:disabled {
  color: transparent;
  fill: transparent;
}
.btn-secondary.btn-loading:after {
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
}

.btn-light:hover {
  border-color: #f0f0f0;
  background: #f0f0f0;
  color: #333;
}
.btn-light, .btn-light.disabled, .btn-light:disabled {
  border-color: transparent;
  background: transparent;
  color: #333;
}
.btn-light.focus, .btn-light:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus {
  border-color: #f0f0f0;
  background: #f0f0f0;
  color: #333;
  box-shadow: none;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus {
  border-color: #e1e1e1;
  background: #e1e1e1;
  color: #333;
}
.btn-light.btn-loading:not(:disabled):not(.disabled), .btn-light.btn-loading:not(:disabled):not(.disabled):active, .btn-light.btn-loading:not(:disabled):not(.disabled).active {
  border-color: transparent;
  background: transparent;
  cursor: default;
}
.btn-light.btn-loading {
  /* hide button content */
}
.btn-light.btn-loading, .btn-light.btn-loading:hover, .btn-light.btn-loading.focus, .btn-light.btn-loading:focus, .btn-light.btn-loading:not(:disabled):not(.disabled).active, .btn-light.btn-loading:not(:disabled):not(.disabled):active, .btn-light.btn-loading.disabled, .btn-light.btn-loading:disabled {
  color: transparent;
  fill: transparent;
}
.btn-light.btn-loading:after {
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
}

.btn-muted:hover {
  border-color: #f2f2f2;
  background: #f2f2f2;
  color: #4d4d4d;
}
.btn-muted, .btn-muted.disabled, .btn-muted:disabled {
  border-color: #fff;
  background: #fff;
  color: #ccc;
}
.btn-muted.focus, .btn-muted:focus, .btn-muted:not(:disabled):not(.disabled).active:focus, .btn-muted:not(:disabled):not(.disabled):active:focus {
  border-color: #f2f2f2;
  background: #f2f2f2;
  color: #4d4d4d;
  box-shadow: none;
}
.btn-muted:not(:disabled):not(.disabled):active, .btn-muted:not(:disabled):not(.disabled).active, .btn-muted:not(:disabled):not(.disabled).active:focus, .btn-muted:not(:disabled):not(.disabled):active:focus {
  border-color: #ebebeb;
  background: #ebebeb;
  color: #4d4d4d;
}
.btn-muted.btn-loading:not(:disabled):not(.disabled), .btn-muted.btn-loading:not(:disabled):not(.disabled):active, .btn-muted.btn-loading:not(:disabled):not(.disabled).active {
  border-color: #fff;
  background: #fff;
  cursor: default;
}
.btn-muted.btn-loading {
  /* hide button content */
}
.btn-muted.btn-loading, .btn-muted.btn-loading:hover, .btn-muted.btn-loading.focus, .btn-muted.btn-loading:focus, .btn-muted.btn-loading:not(:disabled):not(.disabled).active, .btn-muted.btn-loading:not(:disabled):not(.disabled):active, .btn-muted.btn-loading.disabled, .btn-muted.btn-loading:disabled {
  color: transparent;
  fill: transparent;
}
.btn-muted.btn-loading:after {
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
}

/*
// .button-toggle
*/
.button-toggle__list {
  display: flex;
}

.button-toggle__item {
  margin: 0;
}

.button-toggle__button {
  margin: 0;
  background: #f0f0f0;
  color: #333;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  padding: 5px 9px;
  cursor: pointer;
  user-select: none;
  transition: background 0.12s, color 0.12s;
}
.button-toggle__button:hover {
  background: #e5e5e5;
  color: #333;
}
.button-toggle__button:active {
  background: #d6d6d6;
  color: #333;
}

[dir=ltr] .button-toggle__item:first-child .button-toggle__button {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
[dir=rtl] .button-toggle__item:first-child .button-toggle__button {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

[dir=ltr] .button-toggle__item:last-child .button-toggle__button {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
[dir=rtl] .button-toggle__item:last-child .button-toggle__button {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.button-toggle__input {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.button-toggle__input:checked ~ .button-toggle__button {
  background: #e52727;
  color: #fff;
}

/*
// .card
*/
.card {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  border: none;
  border-radius: 0;
}

.card__loader {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.card__loader:before {
  position: absolute;
  display: block;
  content: "";
  left: 0px;
  top: 0px;
  width: calc(100% + 0px);
  height: calc(100% + 0px);
  background: #fff;
  opacity: 0.9;
}
.card__loader:after {
  left: calc(50% - 24px);
  top: calc(50% - 24px);
  width: 48px;
  height: 48px;
  border-radius: 24px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}
.card__loader:before {
  opacity: 1;
}

.card--loading .card__loader {
  pointer-events: auto;
  opacity: 1;
}

.card-body--padding--2 {
  padding: 2rem;
}
@media (max-width: 575.98px) {
  .card-body--padding--2 {
    padding: 1.5rem;
  }
}

.card-divider {
  height: 1px;
  background: #ebebeb;
}

.card-header {
  border-radius: 0;
  background: transparent;
  padding: 1.25rem 2rem;
  border: none;
}
.card-header > h5 {
  margin-bottom: 0;
}

.card-footer {
  border-radius: 0;
  background: transparent;
  padding: 1.25rem 2rem;
  border: none;
}

.card-title {
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 8px;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 2rem;
  margin-top: -4px;
}

.card-title--lg {
  font-size: 28px;
}

/*
// .card-table
*/
.card-table {
  font-size: 15px;
}
.card-table a:hover {
  text-decoration: underline;
}
.card-table table {
  width: 100%;
  min-width: 500px;
}
[dir=ltr] .card-table table {
  text-align: left;
}
[dir=rtl] .card-table table {
  text-align: right;
}
.card-table thead th {
  border-bottom: 1px solid #ebebeb;
  text-transform: uppercase;
  font-size: 13px;
  padding: 10px 12px;
}
.card-table tbody th,
.card-table tbody td,
.card-table tfoot th,
.card-table tfoot td {
  padding: 12px 12px;
}
.card-table tfoot th,
.card-table tfoot td {
  padding-top: 16px;
  padding-bottom: 16px;
}
.card-table tbody + tbody tr th,
.card-table tbody + tbody tr td,
.card-table tbody + tfoot tr th,
.card-table tbody + tfoot tr td,
.card-table tbody tr + tr th,
.card-table tbody tr + tr td {
  border-top: 1px solid #ebebeb;
}
.card-table tbody tr:hover > *,
.card-table tfoot tr:hover > * {
  background-color: #f7f7f7;
}
[dir=ltr] .card-table th:first-child, [dir=ltr] .card-table td:first-child {
  padding-left: 2rem;
}
[dir=ltr] .card-table th:last-child, [dir=ltr] .card-table td:last-child {
  padding-right: 2rem;
}
[dir=rtl] .card-table th:first-child, [dir=rtl] .card-table td:first-child {
  padding-right: 2rem;
}
[dir=rtl] .card-table th:last-child, [dir=rtl] .card-table td:last-child {
  padding-left: 2rem;
}
.card-table .card-table__body--merge-rows th,
.card-table .card-table__body--merge-rows td {
  padding-top: 3px;
  padding-bottom: 3px;
}
.card-table .card-table__body--merge-rows tr:first-child th,
.card-table .card-table__body--merge-rows tr:first-child td {
  padding-top: 12px;
}
.card-table .card-table__body--merge-rows tr:last-child th,
.card-table .card-table__body--merge-rows tr:last-child td {
  padding-bottom: 12px;
}
.card-table .card-table__body--merge-rows tr + tr th,
.card-table .card-table__body--merge-rows tr + tr td {
  border-top: none;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .card-table {
    font-size: 14px;
  }
  [dir=ltr] .card-table th:first-child, [dir=ltr] .card-table td:first-child {
    padding-left: 1.5rem;
  }
  [dir=ltr] .card-table th:last-child, [dir=ltr] .card-table td:last-child {
    padding-right: 1.5rem;
  }
  [dir=rtl] .card-table th:first-child, [dir=rtl] .card-table td:first-child {
    padding-right: 1.5rem;
  }
  [dir=rtl] .card-table th:last-child, [dir=rtl] .card-table td:last-child {
    padding-left: 1.5rem;
  }
}
@media (max-width: 767.98px) {
  .card-table {
    font-size: 14px;
  }
  [dir=ltr] .card-table th:first-child, [dir=ltr] .card-table td:first-child {
    padding-left: 1.375rem;
  }
  [dir=ltr] .card-table th:last-child, [dir=ltr] .card-table td:last-child {
    padding-right: 1.375rem;
  }
  [dir=rtl] .card-table th:first-child, [dir=rtl] .card-table td:first-child {
    padding-right: 1.375rem;
  }
  [dir=rtl] .card-table th:last-child, [dir=rtl] .card-table td:last-child {
    padding-left: 1.375rem;
  }
}
/*
// .collapse-area
*/
.collapse-area {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: height 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.collapse-area--open {
  height: auto;
  opacity: 1;
}

/*
// .container
*/
@media (min-width: 576px) {
  .container--max--sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container--max--md {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container--max--lg {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container--max--xl {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container--max--xxl {
    max-width: 1380px;
  }
}

.container .container {
  padding: 0;
}

/*
// .decor
*/
.decor {
  display: block;
}

.decor--type--center .decor__body {
  overflow: hidden;
  position: relative;
}
.decor--type--center .decor__start,
.decor--type--center .decor__end {
  position: absolute;
}
.decor--type--center .decor__start {
  transform-origin: right top;
}
.decor--type--center .decor__end {
  transform-origin: left top;
}
.decor--type--center .decor__center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.decor--type--center .decor__body {
  height: 31px;
  margin: 0 -10px -10px;
}
.decor--type--center .decor__start,
.decor--type--center .decor__end {
  height: 21px;
  width: calc(50% - 22.12435px);
}
.decor--type--center .decor__start {
  left: 10px;
  transform: skewX(30deg);
}
.decor--type--center .decor__end {
  right: 10px;
  transform: skewX(-30deg);
}
.decor--type--center .decor__center {
  height: 21px;
  width: 26.2487px;
}

.decor--type--bottom {
  width: 100%;
  position: relative;
  pointer-events: none;
  overflow: hidden;
  height: 31px;
}
.decor--type--bottom .decor__start,
.decor--type--bottom .decor__end {
  position: absolute;
  width: calc((100% - 1350px - 160px) * 0.5);
  bottom: 0;
}
.decor--type--bottom .decor__start {
  left: 0;
  transform-origin: right bottom;
}
.decor--type--bottom .decor__end {
  right: 0;
  transform-origin: left bottom;
}
.decor--type--bottom .decor__start,
.decor--type--bottom .decor__end {
  height: 21px;
}
.decor--type--bottom .decor__start {
  border-top-right-radius: 2px;
}
.decor--type--bottom .decor__end {
  border-top-left-radius: 2px;
}
.decor--type--bottom .decor__start {
  transform: skewX(30deg);
}
.decor--type--bottom .decor__end {
  transform: skewX(-30deg);
}

/*
// .filters-button
*/
.filters-button {
  display: flex;
  background: #f0f0f0;
  border: none;
  border-radius: 2px;
  font-size: 15px;
  line-height: 1;
  align-items: center;
  color: inherit;
  font-family: inherit;
  transition: background-color 0.12s;
  padding-top: 7px;
  padding-bottom: 7px;
}
[dir=ltr] .filters-button {
  padding-left: 9px;
  padding-right: 7px;
}
[dir=rtl] .filters-button {
  padding-right: 9px;
  padding-left: 7px;
}
.filters-button:focus {
  outline: none;
}
.filters-button:hover {
  background: #ebebeb;
}
.filters-button:active {
  background: #e0e0e0;
}

.filters-button__icon {
  fill: #404040;
}
[dir=ltr] .filters-button__icon {
  margin-right: 7px;
}
[dir=rtl] .filters-button__icon {
  margin-left: 7px;
}
.filters-button__icon svg {
  display: block;
}

.filters-button__title {
  padding-top: 1px;
}

.filters-button__counter {
  background: #e52727;
  color: #fff;
  padding: 3px 4px 2px;
  font-size: 11px;
  border-radius: 1.5px;
}
[dir=ltr] .filters-button__counter {
  margin-left: 6px;
}
[dir=rtl] .filters-button__counter {
  margin-right: 6px;
}

/*
// .form
*/
[dir=ltr] .form-check {
  padding-left: 1.5rem;
}
[dir=rtl] .form-check {
  padding-right: 1.5rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.1875rem;
}
[dir=ltr] .form-check-input {
  margin-left: -1.5rem;
}
[dir=rtl] .form-check-input {
  margin-right: -1.5rem;
}

/*
// .form-control
*/
.form-control {
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  background-clip: border-box;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0.15s ease-in-out, opacity 0.15s ease-in-out;
  color: #262626;
  background-color: #ebebeb;
  border-color: #ebebeb;
  height: 36px;
  padding: calc(15px * .5) 10px;
  font-size: 16px;
  line-height: 19px;
}
.form-control::placeholder {
  color: #6c757d;
}
.form-control[readonly] {
  color: #262626;
  background-color: #ebebeb;
  border-color: #ebebeb;
}
.form-control[readonly]::placeholder {
  color: #6c757d;
}
.form-control:hover {
  border-color: #d9d9d9;
}
.form-control:focus {
  box-shadow: none;
  background-color: #fff;
  border-color: #d9d9d9;
}
.form-control:disabled {
  opacity: 0.7;
  color: #262626;
  background-color: #ebebeb;
  border-color: #ebebeb;
}
.form-control:disabled::placeholder {
  color: #6c757d;
}
.was-validated .form-control:invalid:focus, .was-validated .form-control:valid:focus, .form-control.is-invalid:focus, .form-control.is-valid:focus {
  box-shadow: none;
}
.was-validated .form-control:invalid, .was-validated .form-control:valid, .form-control.is-invalid, .form-control.is-valid {
  background-image: none;
}
.was-validated .form-control:valid, .form-control.is-valid {
  color: #262626;
  background-color: #e2f2da;
  border-color: #e2f2da;
}
.was-validated .form-control:valid::placeholder, .form-control.is-valid::placeholder {
  color: #6c757d;
}
.was-validated .form-control:valid:hover, .form-control.is-valid:hover {
  border-color: #c3d9b8;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  background-color: #fff;
  border-color: #88cc66;
}
.was-validated .form-control:invalid, .form-control.is-invalid {
  color: #262626;
  background-color: #f2dada;
  border-color: #f2dada;
}
.was-validated .form-control:invalid::placeholder, .form-control.is-invalid::placeholder {
  color: #6c757d;
}
.was-validated .form-control:invalid:hover, .form-control.is-invalid:hover {
  border-color: #d9a3a3;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  background-color: #fff;
  border-color: #cc6666;
}

.form-control-lg {
  height: 42px;
  padding: calc(19px * .5) 12px;
  font-size: 18px;
  line-height: 21px;
}

.form-control-sm {
  height: 30px;
  padding: calc(11px * .5) 8px;
  font-size: 14px;
  line-height: 17px;
}

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select.form-control:not(:disabled) {
  cursor: pointer;
}
select.form-control, .was-validated select.form-control:invalid, .was-validated select.form-control:valid, select.form-control.is-invalid, select.form-control.is-valid {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 10'%3E%3Cpath fill='rgba(0, 0, 0, 0.55)' d='M4.503,4 L0.497,4 C0.094,4 -0.142,3.492 0.089,3.122 L2.095,0.233 C2.293,-0.084 2.712,-0.084 2.911,0.233 L4.911,3.122 C5.142,3.492 4.906,4 4.503,4 ZM0.497,6 L4.503,6 C4.906,6 5.142,6.504 4.911,6.871 L2.905,9.737 C2.707,10.052 2.288,10.052 2.089,9.737 L0.089,6.871 C-0.142,6.504 0.094,6 0.497,6 Z'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 5px 10px;
}
select.form-control::-ms-expand {
  display: none;
}
[dir=ltr] select.form-control {
  padding-right: 27px;
  background-position: right 10px center;
}
[dir=rtl] select.form-control {
  padding-left: 27px;
  background-position: left 10px center;
}

[dir=ltr] select.form-control-lg {
  padding-right: 31px;
  background-position: right 12px center;
}
[dir=rtl] select.form-control-lg {
  padding-left: 31px;
  background-position: left 12px center;
}

[dir=ltr] select.form-control-sm {
  padding-right: 23px;
  background-position: right 8px center;
}
[dir=rtl] select.form-control-sm {
  padding-left: 23px;
  background-position: left 8px center;
}

.valid-feedback {
  color: #388020;
}

.invalid-feedback {
  color: #b32d2d;
}

/*
// .image
*/
.image--type--product .image__body,
.image--type--category .image__body {
  display: block;
  position: relative;
  width: 100%;
}
.image--type--product .image__tag,
.image--type--category .image__tag {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.image--type--product .image__body {
  padding-bottom: 100%;
}

.image--type--category .image__body {
  padding-bottom: 100%;
}

/*
// .input-check
*/
.input-check {
  display: inline-block;
}

.input-check__body {
  display: block;
  position: relative;
  width: 16px;
  height: 16px;
}

.input-check__input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: inherit;
}

.input-check__box {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 1.2px;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset;
  transition: background 0.15s, box-shadow 0.15s;
}

.input-check__icon {
  pointer-events: none;
  position: absolute;
  left: 4px;
  top: 5px;
  fill: #fff;
  transform: scale(0);
  transition: fill 0.15s, transform 0.15s, visibility 0s 0.15s;
}
.input-check__icon svg {
  display: block;
}

.input-check__input:checked ~ .input-check__box {
  background: #e52727;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) inset;
}
.input-check__input:checked ~ .input-check__icon {
  fill: #fff;
  transition-delay: 0s, 0s, 0s;
  transform: scale(1);
}

.input-check__input:disabled ~ .input-check__box {
  background: #f2f2f2;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
}
.input-check__input:disabled ~ .input-check__icon {
  fill: rgba(0, 0, 0, 0.2);
}

/*
// .input-check-color
*/
.input-check-color {
  display: inline-block;
}

.input-check-color__body {
  display: block;
  position: relative;
  width: 22px;
  height: 22px;
  border-radius: 1.2px;
  overflow: hidden;
  cursor: inherit;
  margin: 0;
}

.input-check-color__input {
  position: absolute;
  visibility: hidden;
}

.input-check-color__box {
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 1.2px;
  background: currentColor;
  cursor: inherit;
}

.input-check-color__icon {
  position: absolute;
  left: 5px;
  top: 6px;
  fill: #fff;
  transform: scale(0);
  transition: transform 0.15s;
}
.input-check-color__icon svg {
  display: block;
}

.input-check-color__input:checked ~ .input-check-color__icon {
  transform: scale(1);
}

.input-check-color__input:disabled {
  cursor: default;
}

.input-check-color__input:disabled ~ .input-check-color__box {
  position: relative;
  left: 0px;
  top: 0px;
  width: 22px;
  height: 22px;
  opacity: 0.5;
  cursor: default;
}
.input-check-color__input:disabled ~ .input-check-color__stick {
  pointer-events: none;
  display: block;
  position: absolute;
  width: 2px;
  border-radius: 1px;
  height: 34px;
  left: calc(50% - 1px);
  top: -6px;
  background: rgba(102, 102, 102, 0.9);
  transform: rotateZ(45deg);
  transform-origin: center center;
}

.input-check-color--light .input-check-color__icon {
  fill: #3d464d;
}

.input-check-color--white .input-check-color__box {
  box-shadow: 0 0 0 2px #d9d9d9 inset;
}
.input-check-color--white .input-check-color__icon {
  fill: #bfbfbf;
}

/*
// .input-number
*/
.input-number {
  display: block;
  width: 100%;
  position: relative;
}

.input-number__input {
  -moz-appearance: textfield;
  display: block;
  width: 100%;
  min-width: 88px;
  padding-left: 24px;
  padding-right: 24px;
  text-align: center;
}
.input-number__input::-webkit-inner-spin-button, .input-number__input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.input-number__sub,
.input-number__add {
  position: absolute;
  height: 100%;
  width: 24px;
  top: 0;
  cursor: pointer;
  user-select: none;
  opacity: 0.3;
  transition: opacity 0.18s;
}
.input-number__sub:before, .input-number__sub:after,
.input-number__add:before,
.input-number__add:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: currentColor;
}
.input-number__sub:before,
.input-number__add:before {
  width: 8px;
  height: 2px;
}
.input-number__sub:hover,
.input-number__add:hover {
  opacity: 1;
}

[dir=ltr] .input-number__sub {
  left: 1px;
}
[dir=rtl] .input-number__sub {
  right: 1px;
}

[dir=ltr] .input-number__add {
  right: 1px;
}
[dir=rtl] .input-number__add {
  left: 1px;
}
.input-number__add:after {
  width: 2px;
  height: 8px;
}

/*
// .input-radio
*/
.input-radio {
  display: inline-block;
}

.input-radio__body {
  display: block;
  position: relative;
  width: 16px;
  height: 16px;
}

.input-radio__input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: inherit;
}

.input-radio__circle {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.17) inset;
  transition: background 0.2s, box-shadow 0.2s;
}
.input-radio__circle:after {
  display: block;
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: #fff;
  transform: scale(0);
  transition: background 0.2s, transform 0.2s, visibility 0s 0.2s;
  visibility: hidden;
}

.input-radio__input:checked ~ .input-radio__circle {
  background: #e52727;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08) inset;
}
.input-radio__input:checked ~ .input-radio__circle:after {
  background: #fff;
  visibility: visible;
  transition-delay: 0s, 0s, 0s;
  transform: scale(1);
}

.input-radio__input:disabled ~ .input-radio__circle {
  background: #f2f2f2;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12) inset;
}
.input-radio__input:disabled ~ .input-radio__circle:after {
  background: rgba(0, 0, 0, 0.2);
}

/*
// .input-radio-color
*/
.input-radio-color {
  display: block;
}

.input-radio-color__list {
  padding: 1px 0;
  display: flex;
  margin: -2px;
}

.input-radio-color__item {
  width: 28px;
  height: 28px;
  display: block;
  margin: 2px;
  cursor: pointer;
}
.input-radio-color__item input {
  pointer-events: none;
  opacity: 0;
  position: absolute;
}
.input-radio-color__item span {
  position: relative;
  width: 28px;
  height: 28px;
  display: block;
  border-radius: 14px;
  background: currentColor;
  transform: scale(0.7857142857);
  transition: transform 0.2s;
}
.input-radio-color__item span:before, .input-radio-color__item span:after {
  display: block;
  position: absolute;
  content: "";
  border-radius: 1000px;
}
.input-radio-color__item span:before {
  left: 3px;
  top: 3px;
  width: 22px;
  height: 22px;
  background: #fff;
  transform: scale(0);
  transition: transform 0.2s;
}
.input-radio-color__item span:after {
  left: 7px;
  top: 7px;
  width: 14px;
  height: 14px;
  background: currentColor;
  transform: scale(0);
  transition: transform 0.2s;
}
.input-radio-color__item:hover input:enabled ~ span {
  transform: scale(0.8571428571);
}

.input-radio-color__item input:enabled:checked ~ span, .input-radio-color__item input:enabled:checked ~ span:before, .input-radio-color__item input:enabled:checked ~ span:after {
  transform: scale(1);
}

.input-radio-color__item--disabled {
  cursor: default;
}
.input-radio-color__item--disabled span {
  transform: scale(0.75);
}
.input-radio-color__item--disabled span:before {
  transform: scale(1.33) rotateZ(45deg);
  left: 13px;
  top: -1px;
  width: 2px;
  height: calc(100% + 2px);
  background: rgba(0, 0, 0, 0.3);
}

.input-radio-color__item--white span {
  background: #fff;
  box-shadow: inset 0 0 0 2px #ccc;
}
.input-radio-color__item--white span:after {
  box-shadow: inset 0 0 0 2px #ccc;
}

/*
// .input-radio-label
*/
.input-radio-label__list {
  display: flex;
  flex-wrap: wrap;
  margin: -3px;
}

.input-radio-label__item {
  margin: 3px;
}

.input-radio-label__input {
  position: absolute;
  pointer-events: none;
  opacity: 0;
}

.input-radio-label__title {
  display: flex;
  align-items: center;
  background: #fff;
  height: 28px;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  font-size: 13px;
  font-weight: 500;
  padding: 0 12px 2px;
  cursor: pointer;
  user-select: none;
  transition: box-shadow 0.15s;
}
.input-radio-label__title:hover {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1) inset;
}
.input-radio-label__title:active {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2) inset;
}

.input-radio-label__input:checked ~ .input-radio-label__title {
  box-shadow: 0 0 0 2px #e52727 inset;
}

.input-radio-label__input:disabled ~ .input-radio-label__title {
  color: #a6a6a6;
  font-weight: 400;
  cursor: default;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
}

/*
// .layout-switcher
*/
.layout-switcher__list {
  display: flex;
}

.layout-switcher__button {
  padding: 7px;
  margin: 0;
  border: none;
  display: block;
  border-radius: 2px;
  background-color: transparent;
  color: #95a0a6;
}
.layout-switcher__button:focus {
  outline: none;
}
.layout-switcher__button svg {
  display: block;
  fill: currentColor;
}

.layout-switcher__button:hover {
  background-color: #f0f0f0;
}

.layout-switcher__button--active {
  color: #e52727;
}
.layout-switcher__button--active:hover {
  background-color: transparent;
}

/*
// .loading-bar
*/
.loading-bar {
  position: fixed;
  z-index: 9999;
  height: 2px;
  width: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.loading-bar__inner {
  position: relative;
  height: inherit;
  width: 100%;
  transition: transform 0s 0s, opacity 0s 0s;
  background-color: #fff;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.17), 0 0 0 1px rgba(0, 0, 0, 0.02);
}
[dir=ltr] .loading-bar__inner {
  transform: translateX(-110%);
}
[dir=rtl] .loading-bar__inner {
  transform: translateX(110%);
}
.loading-bar__inner:before {
  content: "";
  display: block;
  height: 100%;
  width: 48px;
  background-color: #e52727;
  box-shadow: 0 0 6px 1px rgba(229, 39, 39, 0.5);
}
[dir=ltr] .loading-bar__inner:before {
  margin-right: -1px;
  float: right;
}
[dir=rtl] .loading-bar__inner:before {
  margin-left: -1px;
  float: left;
}

.loading-bar--start .loading-bar__inner {
  transition: transform 0.8s 0s, opacity 0s 0s;
}
[dir=ltr] .loading-bar--start .loading-bar__inner {
  transform: translateX(-80%);
}
[dir=rtl] .loading-bar--start .loading-bar__inner {
  transform: translateX(80%);
}

.loading-bar--complete .loading-bar__inner {
  transform: translateX(0);
  transition: transform 0.8s 0s, opacity 0.2s 0.9s;
  opacity: 0;
}
[dir=ltr] .loading-bar--complete .loading-bar__inner {
  transform: translateX(0);
}
[dir=rtl] .loading-bar--complete .loading-bar__inner {
  transform: translateX(0);
}

/*
// .modal
*/
.modal {
  -webkit-overflow-scrolling: touch;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.modal-backdrop {
  background: rgba(51, 51, 51, 0.8);
}

.modal-backdrop.show {
  opacity: 1;
}

.modal-content {
  border: none;
  border-radius: 2px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
}

/*
// .ngx-slider
*/
.ngx-slider-custom {
  height: 16px;
  padding: 5px 0;
}
.ngx-slider-custom .ngx-slider {
  display: block;
  height: 6px;
  margin: 0;
}
.ngx-slider-custom .ngx-slider .ngx-slider-bubble {
  display: none;
}
.ngx-slider-custom .ngx-slider .ngx-slider-bar {
  height: 6px;
  border-radius: 3px;
}
.ngx-slider-custom .ngx-slider .ngx-slider-pointer {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  top: -5px;
}
.ngx-slider-custom .ngx-slider .ngx-slider-pointer::after {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  top: 5px;
  left: 5px;
}
.ngx-slider-custom .ngx-slider .ngx-slider-bar {
  background: #e0e0e0;
}
.ngx-slider-custom .ngx-slider .ngx-slider-selection {
  background: #e52727;
}
.ngx-slider-custom .ngx-slider .ngx-slider-pointer,
.ngx-slider-custom .ngx-slider .ngx-slider-pointer:hover {
  cursor: ew-resize;
  background: #e52727;
  box-shadow: 0 0 0 0 rgba(229, 39, 39, 0);
  transition: box-shadow 0.2s;
}
.ngx-slider-custom .ngx-slider .ngx-slider-pointer::after,
.ngx-slider-custom .ngx-slider .ngx-slider-pointer:hover::after {
  background: #fff;
  transition: transform 0.2s;
}
.ngx-slider-custom .ngx-slider .ngx-slider-pointer:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(229, 39, 39, 0.3);
}
.ngx-slider-custom .ngx-slider .ngx-slider-pointer:focus::after {
  transform: scale(0);
}

/*
// .ngx-toastr
*/
.toast-container {
  max-width: calc(100% - 24px);
  z-index: 1000;
}
.toast-container .ngx-toastr {
  user-select: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  padding: 0.875rem 1rem;
  transition: background 0.12s ease-in-out;
  max-width: 100%;
}
.toast-container .ngx-toastr:hover {
  transition-duration: 0.1s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.07);
}
.toast-container .ngx-toastr:active {
  transition-duration: 0s;
}
.toast-container .ngx-toastr {
  background: #b2d9ff;
  color: #36414d;
}
.toast-container .ngx-toastr:hover {
  background: #9ecfff;
}
.toast-container .toast-warning {
  background: #f2e291;
  color: #4d472e;
}
.toast-container .toast-warning:hover {
  background: #f0dd7f;
}
.toast-container .toast-info {
  background: #b8e2e5;
  color: #2d3e40;
}
.toast-container .toast-info:hover {
  background: #a9dce0;
}
.toast-container .toast-error {
  background: #ffb8b8;
  color: #473333;
}
.toast-container .toast-error:hover {
  background: #ffa4a4;
}
.toast-container .toast-success {
  background: #bde5a1;
  color: #34402d;
}
.toast-container .toast-success:hover {
  background: #b2e191;
}

.ngx-toastr {
  font-size: 15px;
  line-height: 1.375;
}

.toast-title {
  font-weight: 500;
}

.toast-message {
  font-size: 14px;
}

.toast-title + .toast-message {
  margin-top: 0.3125rem;
}

/*
// .nouislider
*/
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
}

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat;
}

.noUi-connect {
  height: 100%;
  width: 100%;
}

.noUi-origin {
  height: 10%;
  width: 10%;
}

/* Offset direction
 */
.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto;
}

/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  width: 0;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}

.noUi-touch-area {
  height: 100%;
  width: 100%;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 18px;
}

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  right: -17px;
  top: -6px;
}

.noUi-vertical {
  width: 18px;
}

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  right: -6px;
  top: -17px;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -17px;
  right: auto;
}

.noUi-target {
  height: 16px;
}

.noUi-base {
  padding-top: 5px;
}

.noUi-connects {
  background: #e0e0e0;
  border-radius: 3px;
  height: 6px;
}

.noUi-connect {
  border-radius: 2px;
  background: #e52727;
}

.noUi-horizontal .noUi-handle {
  cursor: ew-resize;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #e52727;
  box-shadow: 0 0 0 0 rgba(229, 39, 39, 0);
  transition: box-shadow 0.2s;
  left: auto;
  right: -8px;
}
.noUi-horizontal .noUi-handle:after {
  display: block;
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  margin: 5px;
  border-radius: 3px;
  background: #fff;
  transition: transform 0.2s;
  top: 0;
}

.noUi-horizontal .noUi-handle:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(229, 39, 39, 0.3);
}
.noUi-horizontal .noUi-handle:focus:after {
  transform: scale(0);
}

.noUi-horizontal .noUi-handle {
  top: 0px;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -8px;
  right: auto;
}

/*
// .owl-prevent-click
*/
.owl-prevent-click.owl-carousel::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 100;
}

/*
// .pagination
*/
.pagination {
  margin-bottom: 0;
  padding: 0;
  flex-wrap: wrap;
  justify-content: center;
}
[dir=ltr] .pagination .page-item {
  margin-right: 6px;
}
[dir=rtl] .pagination .page-item {
  margin-left: 6px;
}
[dir=ltr] .pagination .page-item:last-child {
  margin-right: 0;
}
[dir=rtl] .pagination .page-item:last-child {
  margin-left: 0;
}

.page-link {
  font-size: 15px;
  line-height: 18px;
  border-radius: 2px;
  padding: 6px 10px;
  transition: background 0.15s;
  cursor: pointer;
  user-select: none;
  background-color: #f0f0f0;
  color: #262626;
}
.page-link:hover {
  background-color: #e5e5e5;
  color: #262626;
}
.page-link:active {
  transition-duration: 0s;
  background-color: #e0e0e0;
  color: #262626;
}
.page-link:focus {
  box-shadow: none;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  border-radius: 2px;
}

.page-item.disabled .page-link {
  background-color: #f0f0f0;
  color: #b3b3b3;
}

.page-item.active .page-link {
  background-color: #e52727;
  color: #fff;
  cursor: default;
}

.pagination__dots {
  position: relative;
  width: 2px;
  height: 2px;
  border-radius: 1px;
  background: rgba(0, 0, 0, 0.25);
  margin: 0 4px;
}
.pagination__dots:before, .pagination__dots:after {
  display: block;
  content: "";
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: inherit;
}
.pagination__dots:before {
  top: -5px;
}
.pagination__dots:after {
  bottom: -5px;
}

.page-item--dots {
  display: flex;
  align-items: center;
}

.page-link--with-arrow {
  display: flex;
  align-items: center;
}

.page-link__arrow {
  display: block;
  margin: 3px 0 4px;
  fill: currentColor;
  opacity: 0.8;
}
[dir=ltr] .page-link__arrow {
  transform: scaleX(1);
}
[dir=rtl] .page-link__arrow {
  transform: scaleX(-1);
}
.page-link__arrow svg {
  display: block;
}

[dir=ltr] .page-link__arrow--left {
  margin-left: -1px;
  margin-right: 1px;
}
[dir=rtl] .page-link__arrow--left {
  margin-right: -1px;
  margin-left: 1px;
}

[dir=ltr] .page-link__arrow--right {
  margin-left: 1px;
  margin-right: -1px;
}
[dir=rtl] .page-link__arrow--right {
  margin-right: 1px;
  margin-left: -1px;
}

@media (max-width: 575.98px) {
  .page-link {
    padding: 5px 9px;
  }
}
/*
// .rating
*/
.rating__body {
  display: flex;
}

.rating__star {
  width: 13px;
  height: 12px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23d0d5d9' width='13' height='12'%3E%3Cpath d='M6.5,1.5l1.6,3L8.4,5H9h2.4l-2,1.6L8.9,7L9,7.6l0.7,3L7,9.1L6.5,8.9L6,9.1l-2.8,1.5l0.7-3L4.1,7L3.6,6.6L1.6,5 H4h0.6l0.3-0.5L6.5,1.5 M6.5,0C6.3,0,6.1,0.1,6,0.3L4,4H0.5C0,4.1-0.2,4.8,0.2,5.1L3,7.4l-1,4C1.9,11.7,2.2,12,2.5,12 c0.1,0,0.2,0,0.3-0.1L6.5,10l3.7,1.9c0.1,0,0.2,0.1,0.3,0.1c0.3,0,0.6-0.3,0.5-0.6l-1-4l2.8-2.3c0.3-0.3,0.1-1-0.3-1.1H9L7,0.3 C6.9,0.1,6.7,0,6.5,0L6.5,0z'/%3E%3C/svg%3E"), url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='rgba(0, 0, 0, 0.08)' width='13' height='12'%3E%3Cpath d='M7,0.3L9,4h3.5c0.4,0.1,0.6,0.8,0.3,1.1L10,7.4l1,4c0.1,0.4-0.4,0.8-0.8,0.6L6.5,10l-3.7,1.9c-0.4,0.2-0.9-0.1-0.8-0.6l1-4 L0.2,5.1C-0.2,4.8,0,4.1,0.5,4H4l2-3.7C6.2-0.1,6.8-0.1,7,0.3z'/%3E%3C/svg%3E");
}
[dir=ltr] .rating__star + .rating__star {
  margin-left: 3px;
}
[dir=rtl] .rating__star + .rating__star {
  margin-right: 3px;
}

.rating__star--active {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='rgba(0, 0, 0, 0.08)' width='13' height='12'%3E%3Cpath d='M6.5,1.5l1.6,3L8.4,5H9h2.4l-2,1.6L8.9,7L9,7.6l0.7,3L7,9.1L6.5,8.9L6,9.1l-2.8,1.5l0.7-3L4.1,7L3.6,6.6L1.6,5 H4h0.6l0.3-0.5L6.5,1.5 M6.5,0C6.3,0,6.1,0.1,6,0.3L4,4H0.5C0,4.1-0.2,4.8,0.2,5.1L3,7.4l-1,4C1.9,11.7,2.2,12,2.5,12 c0.1,0,0.2,0,0.3-0.1L6.5,10l3.7,1.9c0.1,0,0.2,0.1,0.3,0.1c0.3,0,0.6-0.3,0.5-0.6l-1-4l2.8-2.3c0.3-0.3,0.1-1-0.3-1.1H9L7,0.3 C6.9,0.1,6.7,0,6.5,0L6.5,0z'/%3E%3C/svg%3E"), url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffd333' width='13' height='12'%3E%3Cpath d='M7,0.3L9,4h3.5c0.4,0.1,0.6,0.8,0.3,1.1L10,7.4l1,4c0.1,0.4-0.4,0.8-0.8,0.6L6.5,10l-3.7,1.9c-0.4,0.2-0.9-0.1-0.8-0.6l1-4 L0.2,5.1C-0.2,4.8,0,4.1,0.5,4H4l2-3.7C6.2-0.1,6.8-0.1,7,0.3z'/%3E%3C/svg%3E");
}

/*
// .section-header
*/
.section-header__body {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}

.section-header__title {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  position: relative;
  top: 2px;
}

.section-header__spring {
  flex-grow: 1;
}

.section-header__divider {
  width: 100%;
  height: 2px;
  margin-top: 8px;
  background: #ebebeb;
}

.section-header__arrows {
  display: flex;
}

[dir=ltr] .section-header__arrow--next {
  margin-left: -5.37131px;
}
[dir=rtl] .section-header__arrow--next {
  margin-right: -5.37131px;
}

.section-header__links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  font-size: 14px;
  flex-wrap: wrap;
}

[dir=ltr] .section-header__links-item {
  padding-right: 18px;
}
[dir=rtl] .section-header__links-item {
  padding-left: 18px;
}
[dir=ltr] .section-header__links-item:last-child {
  padding-right: 0;
}
[dir=rtl] .section-header__links-item:last-child {
  padding-left: 0;
}

.section-header__links-link {
  color: #6c757d;
  position: relative;
  transition: color 0.1s;
}

.section-header__links-link:hover {
  color: inherit;
}

[dir=ltr] .section-header__links + .section-header__arrows {
  margin-left: 18px;
}
[dir=rtl] .section-header__links + .section-header__arrows {
  margin-right: 18px;
}

.section-header__groups {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

[dir=ltr] .section-header__groups-item + .section-header__groups-item {
  margin-left: -5.37131px;
}
[dir=rtl] .section-header__groups-item + .section-header__groups-item {
  margin-right: -5.37131px;
}

.section-header__groups-button {
  font-size: 14px;
  height: 23px;
  border: none;
  padding: 0 18.37131px;
  line-height: 23px;
  display: block;
  position: relative;
  z-index: 0;
  pointer-events: none;
  transition: color 0.1s;
  background: transparent;
  color: #6c757d;
}

.section-header__groups-button:focus {
  outline: none;
}

.section-header__groups-button:after {
  position: absolute;
  display: block;
  content: "";
  width: calc(100% - 8.37131px);
  height: 100%;
  top: 0;
  background: transparent;
  z-index: -1;
  pointer-events: auto;
  transition: background 0.1s;
}
[dir=ltr] .section-header__groups-button:after {
  left: 0;
  transform: skewX(-20deg);
  transform-origin: left bottom;
  border-top-left-radius: 2px;
  border-top-right-radius: 2.5px;
  border-bottom-left-radius: 2.5px;
  border-bottom-right-radius: 2px;
}
[dir=rtl] .section-header__groups-button:after {
  right: 0;
  transform: skewX(20deg);
  transform-origin: right bottom;
  border-top-right-radius: 2px;
  border-top-left-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
  border-bottom-left-radius: 2px;
}

.section-header__groups-button:hover {
  color: #262626;
}
.section-header__groups-button:hover:after {
  background: #ebebeb;
}

.section-header__groups-button:active {
  color: #262626;
}
.section-header__groups-button:active:after {
  background: #e0e0e0;
}

.section-header__groups-button--active,
.section-header__groups-button--active:hover,
.section-header__groups-button--active:active {
  color: #fff;
  cursor: default;
}
.section-header__groups-button--active:after,
.section-header__groups-button--active:hover:after,
.section-header__groups-button--active:active:after {
  background: #333;
}

[dir=ltr] .section-header__groups + .section-header__arrows {
  margin-left: -5.37131px;
}
[dir=rtl] .section-header__groups + .section-header__arrows {
  margin-right: -5.37131px;
}

@media (max-width: 767.98px) {
  .section-header__body {
    flex-wrap: wrap;
  }

  .section-header__links {
    order: 1;
    width: 100%;
    padding: 9px 0 10px;
    border-bottom: 2px solid #ebebeb;
  }

  .section-header__links-item {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .section-header__groups {
    order: 1;
    width: 100%;
    border-bottom: 2px solid #ebebeb;
    padding: 10px 0 11px;
  }

  .section-header__divider {
    width: 100%;
    height: 2px;
    background: #ebebeb;
    margin-top: 8px;
  }
}
/*
// .select2
*/
.form-control + .select2-container {
  width: 100%;
}
.form-control + .select2-container .select2-selection__arrow {
  display: none;
}
.form-control + .select2-container .select2-selection--single {
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  background-clip: border-box;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 10'%3E%3Cpath fill='rgba(0, 0, 0, 0.55)' d='M4.503,4 L0.497,4 C0.094,4 -0.142,3.492 0.089,3.122 L2.095,0.233 C2.293,-0.084 2.712,-0.084 2.911,0.233 L4.911,3.122 C5.142,3.492 4.906,4 4.503,4 ZM0.497,6 L4.503,6 C4.906,6 5.142,6.504 4.911,6.871 L2.905,9.737 C2.707,10.052 2.288,10.052 2.089,9.737 L0.089,6.871 C-0.142,6.504 0.094,6 0.497,6 Z'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 5px 10px;
  transition: border-color 0.15s ease-in-out, background 0.15s ease-in-out;
}
.form-control + .select2-container .select2-selection--single:focus {
  outline: none;
}
.form-control + .select2-container .select2-selection__rendered {
  box-sizing: border-box;
}

.form-control + .select2-container .select2-selection--single {
  background-color: #ebebeb;
  border-color: #ebebeb;
}
.form-control + .select2-container .select2-selection__rendered {
  color: #262626;
}

.form-control + .select2-container--disabled {
  opacity: 0.7;
}

.form-control + .select2-container:not(.select2-container--open):not(.select2-container--disabled):hover .select2-selection--single {
  border-color: #d9d9d9;
}
.form-control + .select2-container--open .select2-selection--single {
  background-color: #fff;
  border-color: #d9d9d9;
}
.form-control + .select2-container .select2-selection--single {
  height: 36px;
}
[dir=ltr] .form-control + .select2-container .select2-selection--single {
  background-position: right 10px center;
}
[dir=rtl] .form-control + .select2-container .select2-selection--single {
  background-position: left 10px center;
}
.form-control + .select2-container .select2-selection__rendered {
  line-height: 19px;
  padding: calc(15px * .5) 10px;
  font-size: 16px;
}
[dir=ltr] .form-control + .select2-container .select2-selection__rendered {
  padding-right: 27px;
}
[dir=rtl] .form-control + .select2-container .select2-selection__rendered {
  padding-left: 27px;
}

.form-control-lg + .select2-container .select2-selection--single {
  height: 42px;
}
[dir=ltr] .form-control-lg + .select2-container .select2-selection--single {
  background-position: right 12px center;
}
[dir=rtl] .form-control-lg + .select2-container .select2-selection--single {
  background-position: left 12px center;
}
.form-control-lg + .select2-container .select2-selection__rendered {
  line-height: 21px;
  padding: calc(19px * .5) 12px;
  font-size: 18px;
}
[dir=ltr] .form-control-lg + .select2-container .select2-selection__rendered {
  padding-right: 31px;
}
[dir=rtl] .form-control-lg + .select2-container .select2-selection__rendered {
  padding-left: 31px;
}

.form-control-sm + .select2-container .select2-selection--single {
  height: 30px;
}
[dir=ltr] .form-control-sm + .select2-container .select2-selection--single {
  background-position: right 8px center;
}
[dir=rtl] .form-control-sm + .select2-container .select2-selection--single {
  background-position: left 8px center;
}
.form-control-sm + .select2-container .select2-selection__rendered {
  line-height: 17px;
  padding: calc(11px * .5) 8px;
  font-size: 14px;
}
[dir=ltr] .form-control-sm + .select2-container .select2-selection__rendered {
  padding-right: 23px;
}
[dir=rtl] .form-control-sm + .select2-container .select2-selection__rendered {
  padding-left: 23px;
}

.select2-dropdown {
  background: #fff;
  border: none;
  border-radius: 0;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2), 0 1px 5px rgba(0, 0, 0, 0.1);
}

.select2-search--dropdown {
  padding: 0;
  border-bottom: 1px solid #e5e5e5;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: none;
  padding: 6px 10px;
  font-size: 15px;
}
.select2-container--default .select2-search--dropdown .select2-search__field:focus {
  outline: none;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background: #f2f2f2;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: #d9ecff;
  color: #36414d;
}

.select2-results__option {
  padding: 4px 10px;
  font-size: 15px;
}

.select2-results__options:before, .select2-results__options:after {
  display: list-item;
  content: "";
  height: 6px;
}

/*
// .share-links
*/
.share-links {
  display: block;
}

.share-links__list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  font-weight: 500;
  white-space: nowrap;
}

[dir=ltr] .share-links__item + .share-links__item {
  margin-left: 4px;
}
[dir=rtl] .share-links__item + .share-links__item {
  margin-right: 4px;
}

.share-links__item a {
  border-radius: 1.5px;
  display: block;
  padding: 2px 7px 1px;
}
.share-links__item a, .share-links__item a:hover {
  color: inherit;
  text-decoration: none;
}

.share-links__item--type--like a {
  background: #4267b2;
  color: #fff;
}
.share-links__item--type--like a:hover {
  color: #fff;
  background: #3b5c9f;
}

.share-links__item--type--tweet a {
  background: #1b95e0;
  color: #fff;
}
.share-links__item--type--tweet a:hover {
  color: #fff;
  background: #1886c9;
}

.share-links__item--type--pin a {
  background: #e63939;
  color: #fff;
}
.share-links__item--type--pin a:hover {
  color: #fff;
  background: #e32222;
}

.share-links__item--type--counter a {
  background: #fff;
  color: #737373;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
}
.share-links__item--type--counter a:hover {
  color: #666666;
}

/*
// .shop-features
*/
.shop-features__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  list-style: none;
  padding: 14px 0;
}

.shop-features__item {
  padding: 10px 28px;
  display: flex;
  align-items: center;
}

.shop-features__item-icon {
  color: #e52727;
}
[dir=ltr] .shop-features__item-icon {
  margin-right: 18px;
}
[dir=rtl] .shop-features__item-icon {
  margin-left: 18px;
}
.shop-features__item-icon svg {
  width: 38px;
  height: 38px;
  fill: currentColor;
  vertical-align: middle;
}

.shop-features__item-title {
  font-size: 14px;
  font-weight: 500;
}

.shop-features__item-subtitle {
  font-size: 14px;
  color: #6c757d;
  margin-top: -2px;
}

.shop-features__item {
  width: calc((100% - 0px) / 1);
}

.shop-features__divider {
  width: 1px;
}
.shop-features__divider:nth-child(2n) {
  width: 100%;
  height: 1px;
}
.shop-features__divider:nth-child(2n):last-child {
  display: none;
}

/*
// .sidebar
*/
.sidebar__header {
  display: none;
}

@media (max-width: 991.98px) {
  .sidebar--offcanvas--mobile {
    visibility: hidden;
    transition: visibility 0s 0.25s;
  }
  .sidebar--offcanvas--mobile .sidebar__backdrop {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background: rgba(51, 51, 51, 0.8);
    opacity: 0;
    will-change: opacity;
    transition: opacity 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .sidebar--offcanvas--mobile .sidebar__body {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1000;
    background: #fff;
    width: 300px;
    transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    will-change: transform;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  [dir=ltr] .sidebar--offcanvas--mobile .sidebar__body {
    left: 0;
    transform: translateX(-100%);
  }
  [dir=rtl] .sidebar--offcanvas--mobile .sidebar__body {
    right: 0;
    transform: translateX(100%);
  }
  .sidebar--offcanvas--mobile .sidebar__content {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .sidebar--offcanvas--mobile.sidebar--open {
    visibility: visible;
    transition-delay: 0s;
  }
  .sidebar--offcanvas--mobile.sidebar--open .sidebar__backdrop {
    opacity: 1;
  }
  .sidebar--offcanvas--mobile.sidebar--open .sidebar__body {
    transform: translateX(0);
  }
  .sidebar--offcanvas--mobile .sidebar__header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ebebeb;
    flex-shrink: 0;
  }
  .sidebar--offcanvas--mobile .sidebar__title {
    flex-grow: 1;
    font-size: 15px;
    font-weight: 500;
    padding: 0 20px;
  }
  .sidebar--offcanvas--mobile .sidebar__close {
    width: 48px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    fill: currentColor;
    padding: 0 0 2px;
    flex-shrink: 0;
    transition: background-color 0.15s, color 0.15s;
    background-color: #fff;
    color: #ccc;
  }
  .sidebar--offcanvas--mobile .sidebar__close:hover {
    background-color: #f2f2f2;
    color: #4d4d4d;
  }
  .sidebar--offcanvas--mobile .sidebar__close:active {
    background-color: #ebebeb;
    color: #4d4d4d;
  }
  .sidebar--offcanvas--mobile .sidebar__close:focus {
    outline: none;
  }
}

.sidebar--offcanvas--always {
  visibility: hidden;
  transition: visibility 0s 0.25s;
}
.sidebar--offcanvas--always .sidebar__backdrop {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(51, 51, 51, 0.8);
  opacity: 0;
  will-change: opacity;
  transition: opacity 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.sidebar--offcanvas--always .sidebar__body {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1000;
  background: #fff;
  width: 300px;
  transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  will-change: transform;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
[dir=ltr] .sidebar--offcanvas--always .sidebar__body {
  left: 0;
  transform: translateX(-100%);
}
[dir=rtl] .sidebar--offcanvas--always .sidebar__body {
  right: 0;
  transform: translateX(100%);
}
.sidebar--offcanvas--always .sidebar__content {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
.sidebar--offcanvas--always.sidebar--open {
  visibility: visible;
  transition-delay: 0s;
}
.sidebar--offcanvas--always.sidebar--open .sidebar__backdrop {
  opacity: 1;
}
.sidebar--offcanvas--always.sidebar--open .sidebar__body {
  transform: translateX(0);
}
.sidebar--offcanvas--always .sidebar__header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  flex-shrink: 0;
}
.sidebar--offcanvas--always .sidebar__title {
  flex-grow: 1;
  font-size: 15px;
  font-weight: 500;
  padding: 0 20px;
}
.sidebar--offcanvas--always .sidebar__close {
  width: 48px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  fill: currentColor;
  padding: 0 0 2px;
  flex-shrink: 0;
  transition: background-color 0.15s, color 0.15s;
  background-color: #fff;
  color: #ccc;
}
.sidebar--offcanvas--always .sidebar__close:hover {
  background-color: #f2f2f2;
  color: #4d4d4d;
}
.sidebar--offcanvas--always .sidebar__close:active {
  background-color: #ebebeb;
  color: #4d4d4d;
}
.sidebar--offcanvas--always .sidebar__close:focus {
  outline: none;
}

/*
// .social-links
*/
.social-links__list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: -4px;
}

.social-links__item {
  margin: 4px;
}
.social-links__item a {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  transition: all 0.2s;
}
.social-links__item a:hover {
  opacity: 0.8;
}

.social-links__item--facebook a {
  background: #3c5a99;
  color: #fff;
}

.social-links__item--twitter a {
  background: #00a2e8;
  color: #fff;
}

.social-links__item--youtube a {
  background: #e52e2e;
  color: #fff;
}

.social-links__item--instagram a {
  background: #815dc7;
  color: #fff;
}

.social-links__item--rss a {
  background: #ffc338;
  color: #664729;
}

/*
// .status-badge
*/
.status-badge {
  display: inline-block;
  vertical-align: middle;
  cursor: default;
}

.status-badge__body {
  position: relative;
  min-height: 23px;
  border-radius: 11.5px;
  min-width: 31px;
}

.status-badge__icon {
  fill: currentColor;
  position: absolute;
  top: calc(50% - 6.5px);
  opacity: 0.85;
}
[dir=ltr] .status-badge__icon {
  left: 9px;
}
[dir=rtl] .status-badge__icon {
  right: 9px;
}
.status-badge__icon svg {
  display: block;
}

.status-badge__text {
  font-size: 13px;
  line-height: 15px;
  padding-top: 4px;
  padding-bottom: 4px;
}
[dir=ltr] .status-badge__text {
  padding-left: 12px;
  padding-right: 12px;
}
[dir=rtl] .status-badge__text {
  padding-right: 12px;
  padding-left: 12px;
}

.status-badge__tooltip {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.status-badge__tooltip:focus {
  outline: none;
}

.status-badge--has-text .status-badge__tooltip {
  display: none;
}

[dir=ltr] .status-badge--has-icon .status-badge__text {
  padding-left: 28px;
}
[dir=rtl] .status-badge--has-icon .status-badge__text {
  padding-right: 28px;
}

.status-badge--style--success .status-badge__body {
  background-color: #e2f2da;
  color: #44782a;
}

.status-badge--style--failure .status-badge__body {
  background-color: #f2dada;
  color: #993d3d;
}

.status-badge--style--warning .status-badge__body {
  background-color: #f2eeda;
  color: #786b2a;
}

.status-badge--style--unknown .status-badge__body {
  background-color: #ebebeb;
  color: #6a6a6a;
}

/*
// .tag-badge
*/
.tag-badge {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  z-index: 0;
  height: 18px;
  padding: 4px 14px 0;
}
.tag-badge:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 2.5px;
  transform-origin: center;
}
.tag-badge:before {
  left: 3.5px;
  right: 3.5px;
}
[dir=ltr] .tag-badge:before {
  transform: skewX(-20deg);
}
[dir=rtl] .tag-badge:before {
  transform: skewX(20deg);
}

.tag-badge--theme {
  color: #fff;
}
.tag-badge--theme:before {
  background: #e52727;
}

.tag-badge--sale {
  color: #fff;
}
.tag-badge--sale:before {
  background: #ff3333;
}

.tag-badge--new {
  color: #fff;
}
.tag-badge--new:before {
  background: #3377ff;
}

.tag-badge--hot {
  color: #fff;
}
.tag-badge--hot:before {
  background: #614ba6;
}

/*
// .tags
*/
.tags {
  font-size: 13px;
}

.tags__list {
  display: flex;
  flex-wrap: wrap;
  font-weight: 500;
  margin: -2px;
}
.tags__list a {
  display: block;
  margin: 2px;
  background: #f2f2f2;
  padding: 4px 11px;
  color: inherit;
  border-radius: 1.5px;
  transition: background 0.12s;
}
.tags__list a:hover {
  color: inherit;
  background: #e5e5e5;
}
.tags__list a:active {
  transition-duration: 0s;
  background: #d9d9d9;
}

.tags--sm .tags__list a {
  padding: 2px 8px;
}

/*
// .teammate
*/
.teammate {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

.teammate__info {
  padding: 14px 16px 16px;
  text-align: center;
}

.teammate__position {
  font-size: 14px;
  color: #6c757d;
}

/*
// .timer
*/
.timer {
  display: flex;
}

.timer__dots {
  width: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.timer__dots:before, .timer__dots:after {
  display: block;
  content: "";
  width: 4px;
  height: 4px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  margin: 4px 0;
}

.timer__part {
  border-radius: 2px;
  width: 50px;
  line-height: 1;
  padding: 8px 0;
  text-align: center;
  background: #ffdf40;
  color: #262626;
}

.timer__part-value {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.04em;
}

.timer__part-label {
  font-size: 10px;
  text-transform: uppercase;
  margin-top: 4px;
  color: #99752e;
}

@media (max-width: 767.98px) {
  .timer__dots {
    width: 18px;
  }
  .timer__dots:before, .timer__dots:after {
    width: 3px;
    height: 3px;
    border-radius: 1.5px;
  }

  .timer__part {
    width: 40px;
    padding: 5px 0 6px;
  }

  .timer__part-value {
    font-size: 20px;
  }

  .timer__part-label {
    font-size: 9px;
    margin-top: 3px;
  }
}
/*
// .tooltip
*/
.tooltip {
  pointer-events: none;
  transition-duration: 0s;
  font-family: inherit;
}

.tooltip.show {
  opacity: 1;
}

.tooltip-inner {
  background: #333;
  color: #fff;
  border-radius: 2px;
  position: relative;
  padding: 5px 8px;
  box-sizing: content-box;
  font-size: 14px;
  line-height: 1.25;
}

.tooltip .arrow {
  width: 8px;
  height: 8px;
}
.tooltip .arrow:before {
  width: 8px;
  height: 8px;
  border-radius: 1px;
  border: none;
  background: #333;
  transform: rotateZ(45deg) scale(0.7843137255);
}

.bs-tooltip-auto[x-placement^=top],
.bs-tooltip-top {
  padding: 4px 0;
}
.bs-tooltip-auto[x-placement^=top] .arrow,
.bs-tooltip-auto[x-placement^=top] .tooltip-inner,
.bs-tooltip-top .arrow,
.bs-tooltip-top .tooltip-inner {
  bottom: 3px;
}

.bs-tooltip-auto[x-placement^=bottom],
.bs-tooltip-bottom {
  padding: 4px 0;
}
.bs-tooltip-auto[x-placement^=bottom] .arrow,
.bs-tooltip-auto[x-placement^=bottom] .tooltip-inner,
.bs-tooltip-bottom .arrow,
.bs-tooltip-bottom .tooltip-inner {
  top: 3px;
}

.bs-tooltip-auto[x-placement^=right],
.bs-tooltip-right {
  padding: 0 4px;
}
.bs-tooltip-auto[x-placement^=right] .arrow,
.bs-tooltip-auto[x-placement^=right] .tooltip-inner,
.bs-tooltip-right .arrow,
.bs-tooltip-right .tooltip-inner {
  left: 3px;
}

.bs-tooltip-auto[x-placement^=left],
.bs-tooltip-left {
  padding: 0 4px;
}
.bs-tooltip-auto[x-placement^=left] .arrow,
.bs-tooltip-auto[x-placement^=left] .tooltip-inner,
.bs-tooltip-left .arrow,
.bs-tooltip-left .tooltip-inner {
  right: 3px;
}

/*
// .typography
*/
.typography {
  line-height: 1.625;
}
.typography a:hover {
  text-decoration: underline;
}
.typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6 {
  margin-top: 1.5em;
  margin-bottom: 0.75em;
}
.typography h2, .typography h3 {
  padding-bottom: 8px;
  border-bottom: 1px solid #ebebeb;
}
.typography h3 {
  padding-bottom: 6px;
}
.typography p {
  margin-bottom: 1.5em;
}
.typography ol, .typography ul {
  margin: 1.5em 0;
}
[dir=ltr] .typography ol, [dir=ltr] .typography ul {
  padding-left: 2.5rem;
}
[dir=rtl] .typography ol, [dir=rtl] .typography ul {
  padding-right: 2.5rem;
}
.typography li {
  margin-bottom: 0.5em;
}
.typography hr {
  position: relative;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  border: none;
  background: #d9d9d9;
  margin: 2.5em auto;
}
.typography hr:after, .typography hr:before {
  position: absolute;
  content: "";
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: inherit;
}
.typography hr:after {
  left: -30px;
}
.typography hr:before {
  right: -30px;
}
.typography blockquote {
  margin: 2.25rem 0;
  text-align: center;
  font-size: 18px;
  font-style: italic;
  background: #f7f7f7;
  border-radius: 4px;
  padding: 2.25em 2.25em 2em;
}
.typography blockquote cite {
  margin-top: -0.5em;
  color: #6c757d;
  display: block;
  font-size: 15px;
  font-style: normal;
}
.typography blockquote p {
  margin-bottom: 1.25em;
}
.typography blockquote > *:first-child {
  margin-top: 0;
}
.typography blockquote > *:last-child {
  margin-bottom: 0;
}
.typography > *:first-child {
  margin-top: 0;
}
.typography > *:last-child {
  margin-bottom: 0;
}
.typography img {
  max-width: 100%;
  border-radius: 2px;
}
.typography figure {
  margin: 1.875rem 0 2.25rem;
}
.typography figcaption {
  font-size: 14px;
  text-align: center;
  color: #6c757d;
  padding-top: 0.875rem;
}
@media (max-width: 575.98px) {
  .typography {
    font-size: 15px;
    line-height: 1.75;
  }
  .typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6 {
    margin-top: 1.25em;
    margin-bottom: 0.5em;
  }
  .typography h2 {
    font-size: 28px;
  }
  .typography h3 {
    font-size: 24px;
  }
  .typography h4 {
    font-size: 20px;
  }
  .typography h5 {
    font-size: 18px;
  }
  .typography p {
    margin-bottom: 1em;
  }
  .typography ol, .typography ul {
    margin: 1.25em 0;
  }
  [dir=ltr] .typography ol, [dir=ltr] .typography ul {
    padding-left: 2rem;
  }
  [dir=rtl] .typography ol, [dir=rtl] .typography ul {
    padding-right: 2rem;
  }
  .typography hr {
    margin: 1.5em auto;
  }
  .typography blockquote {
    padding: 1em;
    font-size: 16px;
    margin: 1.5rem 0;
  }
  .typography blockquote p {
    margin-bottom: 0.75em;
  }
  .typography figure {
    margin: 1.5rem 0 1.5rem;
  }
}

/*
// .vehicle-form
*/
.vehicle-form {
  display: flex;
  flex-wrap: wrap;
  margin: -6px;
}
.vehicle-form .vehicle-form__item {
  width: calc(50% - 12px);
  margin: 6px;
}
.vehicle-form .vehicle-form__item:last-child {
  width: 100%;
}

.vehicle-form__divider {
  width: 100%;
  line-height: 1;
  font-size: 13px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  color: #999;
  margin: 12px 6px;
}
.vehicle-form__divider:before, .vehicle-form__divider:after {
  display: block;
  content: "";
  height: 1px;
  flex-grow: 1;
  background: #ebebeb;
}
[dir=ltr] .vehicle-form__divider:before {
  margin-right: 6px;
}
[dir=rtl] .vehicle-form__divider:before {
  margin-left: 6px;
}
[dir=ltr] .vehicle-form__divider:after {
  margin-left: 6px;
}
[dir=rtl] .vehicle-form__divider:after {
  margin-right: 6px;
}

.vehicle-form__item {
  position: relative;
}

.vehicle-form__item--select .vehicle-form__loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.vehicle-form__item--select .vehicle-form__loader:before {
  position: absolute;
  display: block;
  content: "";
  left: 0px;
  top: 0px;
  width: calc(100% + 0px);
  height: calc(100% + 0px);
  background: #ebebeb;
  opacity: 0.9;
}
.vehicle-form__item--select .vehicle-form__loader:after {
  left: calc(50% - 10px);
  top: calc(50% - 10px);
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}
.vehicle-form__item--select .vehicle-form__loader:before {
  opacity: 1;
  border-radius: 2.5px;
}

.vehicle-form__item-input {
  position: relative;
}
.vehicle-form__item-input .vehicle-form__loader {
  left: calc(50% - 9px);
  top: calc(50% - 9px);
  width: 18px;
  height: 18px;
  border-radius: 9px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
[dir=ltr] .vehicle-form__item-input .vehicle-form__loader {
  left: auto;
  right: 9px;
}
[dir=rtl] .vehicle-form__item-input .vehicle-form__loader {
  right: auto;
  left: 9px;
}

.vehicle-form__item-alert:before {
  display: block;
  content: "";
  height: 0.375rem;
}

.vehicle-form__item--loading .vehicle-form__loader {
  pointer-events: all;
  opacity: 1;
}

.vehicle-form--layout--account .vehicle-form__item {
  width: calc(25% - 12px);
}
@media (max-width: 767.98px) {
  .vehicle-form--layout--account .vehicle-form__item {
    width: calc(50% - 12px);
  }
}
@media (max-width: 459px) {
  .vehicle-form--layout--account .vehicle-form__item {
    width: calc(100% - 12px);
  }
}

@media (max-width: 399px) {
  .vehicle-form--layout--modal .vehicle-form__item {
    width: calc(100% - 12px);
  }
}

/*
// .vehicle-select
*/
.vehicle-select {
  display: block;
}

.vehicle-select__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -14px;
  margin-top: -14px;
}

.vehicle-select__item {
  position: relative;
  margin-left: 14px;
  margin-top: 14px;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 220px;
}

.vehicle-select__item-loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.vehicle-select__item-loader:before {
  position: absolute;
  display: block;
  content: "";
  left: 0px;
  top: 0px;
  width: calc(100% + 0px);
  height: calc(100% + 0px);
  background: #fff;
  opacity: 0.9;
}
.vehicle-select__item-loader:after {
  left: calc(50% - 10px);
  top: calc(50% - 10px);
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}
.vehicle-select__item-loader:before {
  opacity: 1;
  border-radius: 2.5px;
}

.vehicle-select__item--loading .vehicle-select__item-loader {
  pointer-events: all;
  opacity: 1;
}

.vehicle-select__item-control {
  width: 100%;
  height: 44px;
  border: none;
  appearance: none;
  cursor: pointer;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 2px;
  color: #262626;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 5px 10px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 10'%3E%3Cpath fill='%234d4d4d' d='M4.503,4 L0.497,4 C0.094,4 -0.142,3.492 0.089,3.122 L2.095,0.233 C2.293,-0.084 2.712,-0.084 2.911,0.233 L4.911,3.122 C5.142,3.492 4.906,4 4.503,4 ZM0.497,6 L4.503,6 C4.906,6 5.142,6.504 4.911,6.871 L2.905,9.737 C2.707,10.052 2.288,10.052 2.089,9.737 L0.089,6.871 C-0.142,6.504 0.094,6 0.497,6 Z'/%3E%3C/svg%3E%0A");
  transition: opacity 0.25s ease-in-out, color 0.25s ease-in-out;
}
[dir=ltr] .vehicle-select__item-control {
  padding-left: 16px;
  padding-right: 29px;
  background-position: right 12px center;
  text-align: left;
}
[dir=rtl] .vehicle-select__item-control {
  padding-right: 16px;
  padding-left: 29px;
  background-position: left 12px center;
  text-align: right;
}
.vehicle-select__item-control:focus {
  outline: none;
}
.vehicle-select__item-control:disabled {
  opacity: 0.65;
  color: #999;
}

@media (max-width: 767.98px) {
  .vehicle-select__item {
    width: 260px;
    min-width: 260px;
    flex-grow: 0;
  }

  .vehicle-select__item-control {
    height: 40px;
  }
}
/*
// .vehicles-list
*/
.vehicles-list__body {
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
}

.vehicles-list__item {
  display: flex;
  align-items: center;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  padding: 8px;
  width: 100%;
  margin: 4px;
}

.vehicles-list__item-radio {
  margin: 8px;
}

.vehicles-list__item-info {
  margin: 2px 8px;
  flex-grow: 1;
  line-height: 1.25;
}

.vehicles-list__item-remove {
  margin: 8px;
}
.vehicles-list__item-remove svg {
  display: block;
}

.vehicles-list__item-name {
  display: block;
  font-size: 15px;
  font-weight: 500;
}

.vehicles-list__item-details {
  display: block;
  font-size: 13px;
  color: #999;
  margin-top: 2px;
}

.vehicles-list__item-links {
  font-size: 14px;
  margin-top: 12px;
}
.vehicles-list__item-links a:hover {
  text-decoration: underline;
}

.vehicles-list__item-remove {
  position: relative;
  display: flex;
  padding: 8px;
  border: none;
  margin: 0;
  border-radius: 2px;
  fill: currentColor;
  transition: background-color 0.15s, color 0.15s;
  background-color: #fff;
  color: #ccc;
}
.vehicles-list__item-remove:hover {
  background-color: #f2f2f2;
  color: #999;
}
.vehicles-list__item-remove:active {
  background-color: #ebebeb;
  color: #808080;
}
.vehicles-list__item-remove:focus {
  outline: none;
}
.vehicles-list__item-remove.vehicles-list__item-remove--loading {
  cursor: default;
  fill: transparent;
  background: transparent;
}

.vehicles-list__item-remove--loading:before {
  left: calc(50% - 9px);
  top: calc(50% - 9px);
  width: 18px;
  height: 18px;
  border-radius: 9px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}

.vehicles-list--layout--account .vehicles-list__body {
  margin: -6px;
}
.vehicles-list--layout--account .vehicles-list__item {
  padding: 10px 8px;
  width: calc(50% - 12px);
  margin: 6px;
}
@media (max-width: 767.98px) {
  .vehicles-list--layout--account .vehicles-list__body {
    margin: -6px;
  }
  .vehicles-list--layout--account .vehicles-list__item {
    width: calc(100% - 12px);
    margin: 6px;
  }
}

/*
// .view-options
*/
.view-options {
  font-size: 0.9375rem;
  overflow: hidden;
}

.view-options__body {
  padding: 0.75rem 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  margin-top: -8px;
}
.view-options__body + .view-options__body {
  margin-top: 0;
  border-top: 1px solid #ebebeb;
}
[dir=ltr] .view-options__body {
  margin-right: -20px;
}
[dir=rtl] .view-options__body {
  margin-left: -20px;
}

.view-options__body--filters {
  padding: 0.875rem 1rem;
}

.view-options__label {
  position: absolute;
  background: #fff;
  padding: 0 8px;
  top: -5px;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 1;
  color: #999;
}
[dir=ltr] .view-options__label {
  left: 50%;
  transform: translateX(-50%);
}
[dir=rtl] .view-options__label {
  right: 50%;
  transform: translateX(50%);
}

[dir=ltr] .view-options__filters-button {
  margin-right: 1.25rem;
}
[dir=rtl] .view-options__filters-button {
  margin-left: 1.25rem;
}

[dir=ltr] .view-options__layout {
  margin-right: 1.25rem;
}
[dir=rtl] .view-options__layout {
  margin-left: 1.25rem;
}

.view-options__legend {
  white-space: nowrap;
}

.view-options__spring {
  flex-grow: 1;
}

.view-options__select {
  display: flex;
  align-items: center;
}
.view-options__select > label {
  margin-bottom: 0;
  white-space: nowrap;
}
[dir=ltr] .view-options__select > label {
  margin-right: 10px;
}
[dir=rtl] .view-options__select > label {
  margin-left: 10px;
}

[dir=ltr] .view-options__filters-button,
[dir=ltr] .view-options__layout,
[dir=ltr] .view-options__legend,
[dir=ltr] .view-options__spring,
[dir=ltr] .view-options__select,
[dir=ltr] .view-options__applied-filters {
  margin-right: 20px;
}
[dir=rtl] .view-options__filters-button,
[dir=rtl] .view-options__layout,
[dir=rtl] .view-options__legend,
[dir=rtl] .view-options__spring,
[dir=rtl] .view-options__select,
[dir=rtl] .view-options__applied-filters {
  margin-left: 20px;
}

.view-options__filters-button,
.view-options__layout,
.view-options__legend,
.view-options__select {
  margin-top: 8px;
}

@media (min-width: 992px) {
  .view-options--offcanvas--mobile .view-options__filters-button {
    display: none;
  }
}

@media (max-width: 991.98px) {
  [dir=ltr] .view-options__legend {
    margin-left: auto;
  }
  [dir=rtl] .view-options__legend {
    margin-right: auto;
  }

  .view-options__spring {
    width: 100%;
  }

  [dir=ltr] .view-options__select {
    margin-left: auto;
  }
  [dir=rtl] .view-options__select {
    margin-right: auto;
  }
  [dir=ltr] .view-options__select + .view-options__select {
    margin-left: 0;
  }
  [dir=rtl] .view-options__select + .view-options__select {
    margin-right: 0;
  }
}
@media (max-width: 575.98px) {
  .view-options__body + .view-options__body {
    margin-top: 2px;
  }

  .view-options__legend {
    width: 100%;
    padding-top: 2px;
  }

  .view-options__select {
    flex-direction: column;
    align-items: flex-start;
  }
  [dir=ltr] .view-options__select {
    margin-left: 0;
  }
  [dir=rtl] .view-options__select {
    margin-right: 0;
  }
  .view-options__select > label {
    margin: 0 0 2px;
  }

  [dir=ltr] .view-options__layout {
    margin-left: auto;
  }
  [dir=rtl] .view-options__layout {
    margin-right: auto;
  }
}
/*
// .widget
*/
.widget + .widget {
  margin-top: 20px;
}

.widget__header {
  padding: 1.375rem 1.5rem;
}
.widget__header h4 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
}

/* ----------------------------------------
// Filters
// ---------------------------------------- */
/*
// .filter
*/
.filter__title {
  display: block;
  border: none;
  padding: 6px 10px;
  cursor: pointer;
  width: calc(100% + 20px);
  position: relative;
  color: inherit;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  border-radius: 2px;
  margin: 0 -10px;
  transition: background-color 0.2s, fill 0.2s;
  background: transparent;
  fill: #ccc;
}
[dir=ltr] .filter__title {
  text-align: left;
}
[dir=rtl] .filter__title {
  text-align: right;
}
.filter__title:focus, .filter__title:hover {
  transition-duration: 0.1s, 0.1s;
  background: #f2f2f2;
  fill: #b3b3b3;
}
.filter__title:focus {
  outline: none;
}

.filter__arrow {
  position: absolute;
  top: 11px;
  transition: transform 0.2s;
}
[dir=ltr] .filter__arrow {
  right: 10px;
}
[dir=rtl] .filter__arrow {
  left: 10px;
}
.filter__arrow svg {
  display: block;
}

.filter__body {
  margin: 0 -1.5rem;
  overflow: hidden;
  visibility: hidden;
  height: 0;
  opacity: 0;
  transition: height 0.2s, opacity 0.2s, visibility 0s 0.2s;
}

.filter__container {
  padding: 10px 1.5rem 16px;
}

.filter--opened .filter__body {
  transition-delay: 0s, 0s;
  visibility: visible;
  height: auto;
  opacity: 1;
}
.filter--opened .filter__arrow {
  transform: rotateZ(180deg);
}

/*
// .filter-category
*/
.filter-category__list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 15px;
  line-height: 18px;
}
.filter-category__list a {
  color: inherit;
  transition: color 0.15s;
}
.filter-category__list a:hover {
  color: #007bff;
}

.filter-category__item {
  padding: 5px 0;
  display: flex;
}
.filter-category__item > a {
  flex-grow: 1;
}
[dir=ltr] .filter-category__item > a {
  padding-right: 10px;
}
[dir=rtl] .filter-category__item > a {
  padding-left: 10px;
}

.filter-category__item--parent {
  position: relative;
}
[dir=ltr] .filter-category__item--parent {
  padding-left: 14px;
}
[dir=rtl] .filter-category__item--parent {
  padding-right: 14px;
}

.filter-category__item--current {
  font-weight: 700;
}

.filter-category__item--child {
  position: relative;
  font-size: 15px;
}
[dir=ltr] .filter-category__item--child {
  padding-left: 14px;
}
[dir=rtl] .filter-category__item--child {
  padding-right: 14px;
}

.filter-category__arrow {
  fill: #ccc;
  position: absolute;
  top: 10px;
}
[dir=ltr] .filter-category__arrow {
  left: 0;
  transform: scaleX(1);
}
[dir=rtl] .filter-category__arrow {
  right: 0;
  transform: scaleX(-1);
}
.filter-category__arrow svg {
  display: block;
}

.filter-category__counter {
  font-size: 12px;
  line-height: 12px;
  padding-top: 4px;
  font-weight: 400;
  color: #6c757d;
}

/*
// .filter-color
*/
.filter-color__list {
  padding: 2px 0;
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
}

.filter-color__item {
  cursor: pointer;
  margin: 4px;
}

.filter-color__check {
  display: block;
}

/*
// .filter-list
*/
.filter-list__list {
  margin: -8px 0;
}

.filter-list__item {
  padding: 1px 0;
  cursor: pointer;
  display: flex;
  margin: 8px 0;
}

.filter-list__input {
  flex-shrink: 0;
}
[dir=ltr] .filter-list__input {
  margin-right: 7px;
}
[dir=rtl] .filter-list__input {
  margin-left: 7px;
}

.filter-list__title {
  padding: 1px 0;
  font-size: 14px;
  line-height: 16px;
  flex-grow: 1;
}

.filter-list__counter {
  padding-top: 3px;
  font-size: 12px;
  line-height: 12px;
  color: #6c757d;
}
[dir=ltr] .filter-list__counter {
  margin-left: 12px;
}
[dir=rtl] .filter-list__counter {
  margin-right: 12px;
}

.filter-list__item--disabled {
  cursor: default;
}
.filter-list__item--disabled .filter-list__title {
  color: #6c757d;
}

/*
// .filter-range
*/
.filter-range__slider {
  margin-top: 6px;
}

.filter-range__title {
  padding-top: 2px;
  font-size: 14px;
}

.filter-range__title-button {
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*
// .filter-rating
*/
.filter-rating__list {
  list-style: none;
  padding: 0;
  margin: -8px 0;
}

.filter-rating__item-label {
  padding: 2px 0;
  cursor: pointer;
  display: flex;
  margin: 8px 0;
}

.filter-rating__item-input {
  flex-shrink: 0;
}
[dir=ltr] .filter-rating__item-input {
  margin-right: 8px;
}
[dir=rtl] .filter-rating__item-input {
  margin-left: 8px;
}

.filter-rating__item-stars {
  padding: 2px 0;
  flex-grow: 1;
}

.filter-rating__item-counter {
  padding-top: 2px;
  font-size: 12px;
  line-height: 12px;
  color: #6c757d;
}
[dir=ltr] .filter-rating__item-counter {
  margin-left: 12px;
}
[dir=rtl] .filter-rating__item-counter {
  margin-right: 12px;
}

/*
// .filter-vehicle
*/
.filter-vehicle__empty {
  font-size: 15px;
  line-height: 19px;
}

.filter-vehicle__list {
  margin: -8px 0;
  padding: 0;
  list-style: none;
}

.filter-vehicle__item-label {
  padding: 1px 0;
  cursor: pointer;
  display: flex;
  margin: 8px 0;
}

.filter-vehicle__item-input {
  flex-shrink: 0;
}
[dir=ltr] .filter-vehicle__item-input {
  margin-right: 7px;
}
[dir=rtl] .filter-vehicle__item-input {
  margin-left: 7px;
}

.filter-vehicle__item-title {
  padding: 1px 0;
  font-size: 14px;
  line-height: 16px;
  flex-grow: 1;
  transition: color 0.2s;
}

.filter-vehicle__item-counter {
  padding-top: 3px;
  font-size: 12px;
  line-height: 12px;
  color: #6c757d;
}
[dir=ltr] .filter-vehicle__item-counter {
  margin-left: 12px;
}
[dir=rtl] .filter-vehicle__item-counter {
  margin-right: 12px;
}

.filter-vehicle__item--disabled .filter-vehicle__item-label {
  cursor: default;
}
.filter-vehicle__item--disabled .filter-vehicle__item-title {
  color: #999;
}

.filter-vehicle__vehicle {
  border: 1px solid #ebebeb;
  border-radius: 2px;
  padding: 6px 9px;
  line-height: 1.375;
  margin-bottom: 6px;
}

.filter-vehicle__vehicle-title {
  font-size: 14px;
  font-weight: 500;
}

.filter-vehicle__vehicle-subtitle {
  font-size: 13px;
  color: #999;
}

.filter-vehicle__button {
  padding-top: 14px;
}

/* ----------------------------------------
// Footer
// ---------------------------------------- */
/*
// .footer-contacts
*/
.footer-contacts {
  display: block;
}

.footer-contacts__title {
  font-size: 20px;
  color: #fff;
  margin-bottom: 26px;
  font-weight: 500;
}
@media (max-resolution: 1dppx) {
  .footer-contacts__title {
    font-weight: 400;
  }
}

.footer-contacts__text {
  line-height: 22px;
  list-style: none;
  margin: 0;
  font-size: 15px;
}
.footer-contacts__text a {
  color: inherit;
}

.footer-contacts__contacts {
  font-size: 14px;
  line-height: 20px;
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.footer-contacts__contacts a {
  color: inherit;
}
.footer-contacts__contacts dt {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin-bottom: 2px;
}
.footer-contacts__contacts dd {
  color: #fff;
  margin: 0;
  font-weight: 500;
}
@media (max-resolution: 1dppx) {
  .footer-contacts__contacts dd {
    font-weight: 400;
  }
}

.footer-contacts__icon {
  display: inline-block;
  text-align: center;
  width: 22px;
  margin-right: 2px;
}

@media (min-width: 1400px) {
  [dir=ltr] .footer-contacts {
    padding-right: 36px;
  }
  [dir=rtl] .footer-contacts {
    padding-left: 36px;
  }
}
@media (min-width: 1200px) {
  .footer-contacts__contacts {
    margin: 24px -15px 0;
  }
  .footer-contacts__contacts dl {
    width: calc(100% / 2 - 30px - 1px);
    margin: 0 15px;
  }
  .footer-contacts__contacts dl:nth-child(n+3) {
    margin-top: 18px;
  }
}
@media (max-width: 1199.98px) {
  .footer-contacts__contacts {
    margin: -6px -6px;
  }
  .footer-contacts__contacts dl {
    width: calc(100% / 4 - 12px);
    margin: 6px 6px;
  }

  .footer-contacts {
    text-align: center;
    margin-bottom: 42px;
  }

  .footer-contacts__title {
    font-size: 24px;
    margin-bottom: 12px;
  }

  .footer-contacts__contacts {
    padding-top: 28px;
  }
  .footer-contacts__contacts dl {
    background: rgba(255, 255, 255, 0.05);
    padding: 16px 12px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media (max-width: 991.98px) {
  .footer-contacts__contacts {
    margin: -6px -6px;
  }
  .footer-contacts__contacts dl {
    width: calc(100% / 2 - 12px);
    margin: 6px 6px;
  }

  .footer-contacts__contacts dl {
    padding: 20px 16px;
  }
}
@media (max-width: 767.98px) {
  .footer-contacts__contacts dl {
    padding: 16px;
  }
}
@media (max-width: 479px) {
  .footer-contacts__contacts {
    margin: -4px -4px;
  }
  .footer-contacts__contacts dl {
    width: calc(100% / 1 - 8px);
    margin: 4px 4px;
  }
}
/*
// .footer-links
*/
.footer-links {
  display: block;
}

.footer-links__title {
  font-size: 20px;
  color: #fff;
  margin-bottom: 22px;
  font-weight: 500;
}
@media (max-resolution: 1dppx) {
  .footer-links__title {
    font-weight: 400;
  }
}

.footer-links__list {
  font-size: 15px;
  line-height: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer-links__list a {
  color: inherit;
  transition: 0.15s;
}
.footer-links__list a:hover {
  color: #fff;
}

.footer-links__item {
  padding: 5px 0;
}

@media (max-width: 767.98px) {
  .footer-links {
    text-align: center;
  }

  .footer-links__title {
    margin-bottom: 12px;
  }
}
/*
// .footer-texts
*/
.footer-texts {
  display: block;
  text-align: center;
}

.footer-texts__list {
  font-size: 15px;
  line-height: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer-texts__list--content {
  color: inherit;
  transition: 0.15s;
}

.footer-texts__item {
  padding: 5px 0;
}

/*
// .footer-newsletter
*/
.footer-newsletter {
  display: block;
}

.footer-newsletter__title {
  font-size: 20px;
  color: #fff;
  margin-bottom: 26px;
  font-weight: 500;
}
@media (max-resolution: 1dppx) {
  .footer-newsletter__title {
    font-weight: 400;
  }
}

.footer-newsletter__text {
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 14px;
}

.footer-newsletter__text--social {
  margin-top: 20px;
  margin-bottom: 0;
}

.footer-newsletter__social-links {
  margin-top: 8px;
}

.footer-newsletter__form {
  display: flex;
  max-width: 380px;
}

.footer-newsletter__form-input {
  flex-shrink: 1;
  border-radius: 2px;
  font-size: 15px;
  height: 38px;
  padding: 0 12px;
  flex-basis: 0;
  min-width: 0;
  flex-grow: 1;
  font-family: inherit;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  background-clip: padding-box;
  transition: border 0.2s, background 0.2s;
  color: #fff;
  background-color: #4c4c4c;
  border-color: #4c4c4c;
}
.footer-newsletter__form-input::placeholder {
  color: #b2b2b2;
}
.footer-newsletter__form-input::placeholder {
  transition: color 0.2s;
}
.footer-newsletter__form-input:hover {
  background-color: rgba(255, 255, 255, 0.16);
  border-color: rgba(255, 255, 255, 0.16);
}
.footer-newsletter__form-input:focus {
  outline: none;
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.16);
}
.footer-newsletter__form-input:focus::placeholder {
  color: #9e9e9e;
}

.footer-newsletter__form-button {
  flex-shrink: 0;
  border-radius: 2px;
  border: none;
  padding: 0 20px;
  font-family: inherit;
  font-size: 15px;
  transition: background 0.2s, color 0.2s;
  background-color: #e52727;
  color: #fff;
  background-color: #e52727;
  color: #fff;
}
.footer-newsletter__form-button:hover {
  background-color: #545454;
  color: #fff;
}
.footer-newsletter__form-button:active {
  background-color: #4c4c4c;
  color: #fff;
}
[dir=ltr] .footer-newsletter__form-button {
  margin-left: 8px;
}
[dir=rtl] .footer-newsletter__form-button {
  margin-right: 8px;
}
.footer-newsletter__form-button:hover {
  background-color: #545454;
  color: #fff;
}
.footer-newsletter__form-button:active {
  background-color: #4c4c4c;
  color: #fff;
}
.footer-newsletter__form-button:active {
  transition-duration: 0.1s, 0.1s;
}
.footer-newsletter__form-button:focus {
  outline: none;
}

@media (max-width: 767.98px) {
  .footer-newsletter {
    text-align: center;
    margin-top: 28px;
  }

  .footer-newsletter__title {
    font-size: 24px;
    margin-bottom: 16px;
  }

  .footer-newsletter__form {
    margin: 0 auto;
  }

  .footer-newsletter__text--social {
    margin-top: 24px;
  }

  .footer-newsletter__social-links {
    margin-top: 12px;
    display: flex;
    justify-content: center;
  }
}
/*
// .site-footer
*/
.site-footer {
  position: relative;
  background-color: #333;
  color: #9e9e9e;
}

.site-footer__decor {
  position: absolute;
  bottom: calc(100% - 1px);
}
.site-footer__decor .decor__start,
.site-footer__decor .decor__end,
.site-footer__decor .decor__center {
  background: #333;
}
.site-footer__decor .decor__start,
.site-footer__decor .decor__end {
  box-shadow: none;
}

.site-footer__widgets {
  padding: 56px 0 50px;
}

.site-footer__bottom {
  background-color: #2b2b2b;
  font-size: 14px;
  color: #9e9e9e;
  font-weight: 400;
}
.site-footer__bottom a {
  color: #fff;
  font-weight: 500;
}
@media (max-resolution: 1dppx) {
  .site-footer__bottom a {
    font-weight: 400;
  }
}

.site-footer__bottom-row {
  display: flex;
  height: 72px;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767.98px) {
  .site-footer__decor {
    display: none;
  }

  .site-footer__widgets {
    padding: 40px 0 36px;
  }

  .site-footer__bottom-row {
    height: auto;
    flex-direction: column;
    padding: 20px 0 24px;
  }

  .site-footer__payments {
    margin-top: 12px;
  }
}
/* ----------------------------------------
// Pages
// ---------------------------------------- */
/*
// .about
*/
.about__body {
  display: grid;
  z-index: 0;
}

@media (min-width: 1200px) {
  .about__body {
    grid-template-columns: 1fr 380px 730px 1fr;
    grid-template-rows: 100px auto auto;
  }

  .about__image {
    grid-column: 1/5;
    grid-row: 1/3;
  }

  .about__card {
    grid-column: 2;
    grid-row: 2/4;
  }

  .about__indicators {
    grid-column: 3;
    grid-row: 3;
  }
}
@media (max-width: 1199.98px) {
  .about__image {
    grid-column: 1/4;
    grid-row: 1/3;
  }

  .about__card {
    grid-column: 2;
    grid-row: 2/4;
  }

  .about__indicators {
    grid-column: 2;
    grid-row: 4;
  }
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .about__body {
    grid-template-columns: 1fr 690px 1fr;
    grid-template-rows: 80px auto 60px auto;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .about__body {
    grid-template-columns: 1fr 510px 1fr;
    grid-template-rows: 40px auto 60px auto;
  }
}
@media (max-width: 575.98px) {
  .about__body {
    grid-template-columns: 20px calc(100% - 40px) 20px;
    grid-template-rows: 20px auto 60px auto;
  }
}
/*
// .about__image
*/
.about__image {
  position: relative;
  overflow: hidden;
  z-index: -1;
}

.about__image-decor {
  position: absolute;
  bottom: -1px;
}
.about__image-decor .decor__start,
.about__image-decor .decor__end,
.about__image-decor .decor__center {
  background: #fafafa;
}
.about__image-decor .decor__start,
.about__image-decor .decor__end {
  box-shadow: none;
}

.about__image-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center center;
  z-index: -1;
}
.about__image-bg:before {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(51, 51, 51, 0.7), rgba(51, 51, 51, 0.4));
}

/*
// .about__card
*/
.about__card {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  border-radius: 2px;
  padding: 32px 36px;
  display: flex;
  flex-direction: column;
}

.about__card-title {
  font-size: 40px;
  font-weight: 700;
  padding: 16px 0 40px;
  text-align: center;
  margin-bottom: 0;
}

.about__card-text {
  text-align: center;
  line-height: 1.75;
}

.about__card-author {
  color: #6c757d;
  font-size: 14px;
  position: relative;
  align-self: center;
  margin-top: 16px;
}
[dir=ltr] .about__card-author {
  padding-left: 28px;
}
[dir=rtl] .about__card-author {
  padding-right: 28px;
}
.about__card-author:before {
  display: block;
  height: 1px;
  width: 20px;
  background: currentColor;
  content: "";
  opacity: 0.8;
  position: absolute;
  top: 10px;
}
[dir=ltr] .about__card-author:before {
  left: 0;
}
[dir=rtl] .about__card-author:before {
  right: 0;
}

.about__card-signature {
  padding: 40px 0 12px;
  margin-top: auto;
  align-self: center;
}

@media (min-width: 1200px) {
  .about__card {
    min-height: 530px;
  }
}
@media (max-width: 1199.98px) {
  .about__card {
    padding: 32px 64px;
    margin: 0 auto;
    max-width: 510px;
  }
}
@media (max-width: 767.98px) {
  .about__card {
    padding: 32px 36px;
  }
}
@media (max-width: 575.98px) {
  .about__card-title {
    padding: 8px 0 24px;
    font-size: 32px;
  }

  .about__card {
    padding: 24px 28px;
    font-size: 15px;
  }
}
/*
// .about__indicators
*/
.about__indicators-body {
  margin: -12px;
}

.about__indicators-item {
  margin: 12px;
  width: calc(100% / 3 - 24px);
}

.about__indicators {
  align-self: center;
}

.about__indicators-body {
  display: flex;
  flex-wrap: wrap;
}

.about__indicators-item-value {
  font-size: 48px;
  font-weight: bold;
  line-height: 1;
}

.about__indicators-item-title {
  color: #6c757d;
  font-size: 15px;
  margin-top: 6px;
  line-height: 18px;
}

@media (min-width: 1200px) {
  .about__indicators {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  [dir=ltr] .about__indicators {
    margin-left: 52px;
  }
  [dir=rtl] .about__indicators {
    margin-right: 52px;
  }
}
@media (max-width: 1199.98px) {
  .about__indicators {
    text-align: center;
    margin-top: 48px;
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .about__indicators {
    margin-top: 40px;
  }

  .about__indicators-item-value {
    font-size: 36px;
  }
}
@media (max-width: 575.98px) {
  .about__indicators-body {
    margin: -16px;
  }

  .about__indicators-item {
    margin: 16px;
    width: calc(100% / 1 - 32px);
  }
}
/*
// .contacts
*/
.contacts__map {
  position: relative;
  height: 400px;
}
.contacts__map iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
}

@media (max-width: 991.98px) {
  .contacts__map {
    height: 380px;
  }
}
@media (max-width: 767.98px) {
  .contacts__map {
    height: 360px;
  }
}
/*
// .faq
*/
.faq__header {
  padding: 44px 0 52px;
  text-align: center;
}

.faq__header-title {
  font-weight: 700;
  margin: 0;
}

.faq__section {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  padding: 48px;
}

.faq__section + .faq__section {
  margin-top: 30px;
}

.faq__section-title {
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 8px;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 2.5rem;
  margin-top: -4px;
}

.faq__footer {
  margin-top: 56px;
  text-align: center;
}

.faq__footer-title {
  font-size: 28px;
  margin-bottom: 8px;
}

.faq__footer-subtitle {
  margin-bottom: 36px;
  color: #6c757d;
}

.faq__section-body {
  display: flex;
  flex-wrap: wrap;
  margin: -20px;
}

.faq__question {
  margin: 20px;
  width: calc(50% - 40px);
}

.faq__question-title {
  margin-bottom: 14px;
}

@media (max-width: 1199.98px) {
  .faq__header {
    padding: 48px 0 48px;
  }

  .faq__section {
    padding: 40px;
  }
}
@media (max-width: 991.98px) {
  .faq__section-body {
    margin: -12px;
  }

  .faq__question {
    margin: 12px;
    width: calc(100% - 24px);
  }
}
@media (max-width: 767.98px) {
  .faq__header {
    padding: 36px 0 32px;
  }

  .faq__header-title {
    font-size: 32px;
  }

  .faq__section {
    padding: 32px;
  }

  .faq__section-title {
    margin-bottom: 32px;
  }

  .faq__footer {
    margin-top: 40px;
  }

  .faq__footer-title {
    font-size: 24px;
  }

  .faq__footer-subtitle {
    font-size: 15px;
    margin-bottom: 24px;
  }
}
@media (max-width: 575.98px) {
  .faq__header {
    padding: 32px 0 28px;
  }

  .faq__section {
    padding: 24px;
  }

  .faq__section-title {
    margin-bottom: 28px;
  }

  .faq__question-title {
    font-size: 18px;
    margin-bottom: 12px;
  }

  .faq__question-answer .typography {
    font-size: 15px;
  }

  .faq__footer-subtitle {
    margin-bottom: 20px;
  }
}
/*
// .not-found
*/
.not-found {
  text-align: center;
  padding: 36px 0;
}

.not-found__404 {
  font-size: 80px;
  font-weight: 700;
  color: #ebebeb;
  padding: 20px 0 4px;
}

.not-found__content {
  width: 480px;
  max-width: 100%;
  margin: 0 auto;
}

.not-found__title {
  margin-bottom: 24px;
  font-weight: 700;
}

.not-found__text {
  margin-bottom: 20px;
}

.not-found__search {
  display: flex;
  margin-bottom: 30px;
}

.not-found__search-input {
  width: 1px;
  flex-grow: 1;
}
[dir=ltr] .not-found__search-input {
  margin-right: 10px;
}
[dir=rtl] .not-found__search-input {
  margin-left: 10px;
}

@media (max-width: 991.98px) {
  .not-found__404 {
    font-size: 60px;
  }
}
@media (max-width: 767.98px) {
  .not-found__404 {
    font-size: 50px;
    line-height: 54px;
    padding: 40px 0 32px;
  }

  .not-found__title {
    margin-bottom: 20px;
  }
}
/* ----------------------------------------
// Blocks
// ---------------------------------------- */
/*
// .block-banners
*/
.block-banners__list {
  display: flex;
  flex-wrap: wrap;
  margin: -15px;
}

.block-banners__item {
  margin: 15px;
  width: calc(50% - 30px);
  height: 210px;
  padding: 32px 34px;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  z-index: 0;
}
.block-banners__item, .block-banners__item:hover {
  color: #fff;
}
.block-banners__item:before {
  border-radius: 3px;
  z-index: -1;
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
[dir=ltr] .block-banners__item:before {
  background: linear-gradient(to right, rgba(26, 26, 26, 0.98) 25%, rgba(26, 26, 26, 0) 90%);
}
[dir=rtl] .block-banners__item:before {
  background: linear-gradient(to left, rgba(26, 26, 26, 0.98) 25%, rgba(26, 26, 26, 0) 90%);
}

.block-banners__item-title {
  display: block;
  font-size: 28px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  color: #ffdf40;
}

.block-banners__item-details {
  display: block;
  margin-top: 12px;
  font-size: 15px;
}

.block-banners__item-button {
  margin-top: 28px;
}

.block-banners__item-image {
  border-radius: 3px;
  z-index: -3;
  position: absolute;
  content: "";
  display: block;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  left: -10px;
  top: -10px;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}
.block-banners__item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.block-banners__item-image--blur {
  z-index: -2;
  opacity: 0;
}
.block-banners__item-image--blur img {
  filter: blur(3px);
}

.block-banners__item:hover .block-banners__item-image {
  transform: scale(1.03);
}
.block-banners__item:hover .block-banners__item-image--blur {
  opacity: 1;
}

.block-banners__item--style--two .block-banners__item-title {
  background: #ffdf40;
  display: inline-block;
  vertical-align: middle;
  padding: 2px 5px;
  border-radius: 1.5px;
  color: #262626;
  font-size: 24px;
  margin-top: -2px;
  margin-bottom: 2px;
}

@media (max-width: 1199.98px) {
  .block-banners__list {
    margin: -10px;
  }

  .block-banners__item {
    margin: 10px;
    width: calc(50% - 20px);
  }

  .block-banners__item {
    height: 190px;
    padding: 24px 24px;
  }
}
@media (max-width: 991.98px) {
  .block-banners__list {
    margin: -10px;
  }

  .block-banners__item {
    margin: 10px;
    width: calc(100% - 20px);
  }

  .block-banners__item {
    height: auto;
    padding: 24px 24px;
  }
}
@media (max-width: 474px) {
  [dir=ltr] .block-banners__item:before {
    background: linear-gradient(to right, rgba(26, 26, 26, 0.98) 35%, rgba(26, 26, 26, 0) 100%);
  }
  [dir=rtl] .block-banners__item:before {
    background: linear-gradient(to left, rgba(26, 26, 26, 0.98) 35%, rgba(26, 26, 26, 0) 100%);
  }
}
/*
// .block-brands
*/
.block-brands__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #ebebeb;
}

.block-brands__item {
  text-align: center;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.block-brands__item-link {
  position: relative;
  display: block;
  padding: 12px 28px;
  width: 100%;
}
.block-brands__item-link:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.02);
  opacity: 0;
  transition: opacity 0.15s;
}
.block-brands__item-link img {
  max-width: 100%;
  max-height: 72px;
  filter: grayscale(1);
  opacity: 0.7;
  transition: filter 0.15s, opacity 0.15s;
}

.block-brands__item-name {
  display: block;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  color: #b3b3b3;
  margin-top: 4px;
  margin-bottom: -2px;
  transition: color 0.15s;
}

.block-brands__item-link:hover:before {
  opacity: 1;
}
.block-brands__item-link:hover img {
  filter: grayscale(0);
  opacity: 1;
}
.block-brands__item-link:hover .block-brands__item-name {
  color: #737373;
}

.block-brands__divider {
  background: #ebebeb;
}

@media (min-width: 1400px) {
  .block-brands--layout--columns-8-full .block-brands__item {
    width: calc((100% - 8px) / 8);
  }
  .block-brands--layout--columns-8-full .block-brands__divider {
    width: 1px;
  }
  .block-brands--layout--columns-8-full .block-brands__divider:nth-child(16n) {
    height: 1px;
    width: 100%;
  }
  .block-brands--layout--columns-8-full .block-brands__divider:nth-child(16n):last-child {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .block-brands--layout--columns-8-full .block-brands__item {
    width: calc((100% - 7px) / 7);
  }
  .block-brands--layout--columns-8-full .block-brands__divider {
    width: 1px;
  }
  .block-brands--layout--columns-8-full .block-brands__divider:nth-child(14n) {
    height: 1px;
    width: 100%;
  }
  .block-brands--layout--columns-8-full .block-brands__divider:nth-child(14n):last-child {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .block-brands--layout--columns-8-full .block-brands__item {
    width: calc((100% - 6px) / 6);
  }
  .block-brands--layout--columns-8-full .block-brands__divider {
    width: 1px;
  }
  .block-brands--layout--columns-8-full .block-brands__divider:nth-child(12n) {
    height: 1px;
    width: 100%;
  }
  .block-brands--layout--columns-8-full .block-brands__divider:nth-child(12n):last-child {
    display: none;
  }
}

@media (min-width: 1400px) {
  .block-brands--layout--columns-7-sidebar .block-brands__item {
    width: calc((100% - 7px) / 7);
  }
  .block-brands--layout--columns-7-sidebar .block-brands__divider {
    width: 1px;
  }
  .block-brands--layout--columns-7-sidebar .block-brands__divider:nth-child(14n) {
    height: 1px;
    width: 100%;
  }
  .block-brands--layout--columns-7-sidebar .block-brands__divider:nth-child(14n):last-child {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .block-brands--layout--columns-7-sidebar .block-brands__item {
    width: calc((100% - 6px) / 6);
  }
  .block-brands--layout--columns-7-sidebar .block-brands__divider {
    width: 1px;
  }
  .block-brands--layout--columns-7-sidebar .block-brands__divider:nth-child(12n) {
    height: 1px;
    width: 100%;
  }
  .block-brands--layout--columns-7-sidebar .block-brands__divider:nth-child(12n):last-child {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .block-brands--layout--columns-7-sidebar .block-brands__item {
    width: calc((100% - 5px) / 5);
  }
  .block-brands--layout--columns-7-sidebar .block-brands__divider {
    width: 1px;
  }
  .block-brands--layout--columns-7-sidebar .block-brands__divider:nth-child(10n) {
    height: 1px;
    width: 100%;
  }
  .block-brands--layout--columns-7-sidebar .block-brands__divider:nth-child(10n):last-child {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .block-brands__item {
    width: calc((100% - 5px) / 5);
  }

  .block-brands__divider {
    width: 1px;
  }

  .block-brands__divider:nth-child(10n) {
    height: 1px;
    width: 100%;
  }
  .block-brands__divider:nth-child(10n):last-child {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .block-brands__item {
    width: calc((100% - 4px) / 4);
  }

  .block-brands__divider {
    width: 1px;
  }

  .block-brands__divider:nth-child(8n) {
    height: 1px;
    width: 100%;
  }
  .block-brands__divider:nth-child(8n):last-child {
    display: none;
  }
}
@media (min-width: 420px) and (max-width: 575px) {
  .block-brands__item {
    width: calc((100% - 3px) / 3);
  }

  .block-brands__divider {
    width: 1px;
  }

  .block-brands__divider:nth-child(6n) {
    height: 1px;
    width: 100%;
  }
  .block-brands__divider:nth-child(6n):last-child {
    display: none;
  }
}
@media (max-width: 419px) {
  .block-brands__item {
    width: calc((100% - 2px) / 2);
  }

  .block-brands__divider {
    width: 1px;
  }

  .block-brands__divider:nth-child(4n) {
    height: 1px;
    width: 100%;
  }
  .block-brands__divider:nth-child(4n):last-child {
    display: none;
  }
}
/*
// .block-categories
*/
.block-categories__list {
  display: flex;
  flex-wrap: wrap;
}

.block-categories__item {
  display: flex;
}

.block-categories__header {
  display: flex;
  justify-content: center;
}

.block-categories__title {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  position: relative;
  z-index: 1;
  margin-bottom: -24px;
  padding: 0 64px 28px;
}

.block-categories__title-decor {
  position: absolute;
  width: 100%;
  bottom: 1px;
}
[dir=ltr] .block-categories__title-decor {
  left: 0;
}
[dir=rtl] .block-categories__title-decor {
  right: 0;
}
.block-categories__title-decor .decor__start,
.block-categories__title-decor .decor__end,
.block-categories__title-decor .decor__center {
  background: #fafafa;
}
.block-categories__title-decor .decor__start,
.block-categories__title-decor .decor__end {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.08);
}
.block-categories__title-decor .decor__body {
  height: 35px;
  margin: 0 -10px -10px;
}
.block-categories__title-decor .decor__start,
.block-categories__title-decor .decor__end {
  height: 25px;
  width: calc(50% - 24.43375px);
}
.block-categories__title-decor .decor__start {
  left: 10px;
  transform: skewX(30deg);
}
.block-categories__title-decor .decor__end {
  right: 10px;
  transform: skewX(-30deg);
}
.block-categories__title-decor .decor__center {
  height: 25px;
  width: 30.8675px;
}

.block-categories__body {
  position: relative;
  background-color: #f6f6f6;
  padding: 76px 0 64px;
  box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.08);
}

.block-categories__body-decor {
  position: absolute;
  bottom: -1px;
  height: 35px;
}
.block-categories__body-decor .decor__start,
.block-categories__body-decor .decor__end,
.block-categories__body-decor .decor__center {
  background: #fafafa;
}
.block-categories__body-decor .decor__start,
.block-categories__body-decor .decor__end {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.08);
}
.block-categories__body-decor .decor__start,
.block-categories__body-decor .decor__end {
  height: 25px;
}

@media (min-width: 1400px) {
  .block-categories__list {
    margin: -12px;
  }

  .block-categories__item {
    width: calc((100% - 72px) / 3);
    margin: 12px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .block-categories__list {
    margin: -8px;
  }

  .block-categories__item {
    width: calc((100% - 48px) / 3);
    margin: 8px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .block-categories__list {
    margin: -8px;
  }

  .block-categories__item {
    width: calc((100% - 32px) / 2);
    margin: 8px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .block-categories__list {
    margin: -8px;
  }

  .block-categories__item {
    width: calc((100% - 48px) / 3);
    margin: 8px;
  }
}
@media (max-width: 767.98px) {
  .block-categories__list {
    margin: -8px;
  }

  .block-categories__item {
    width: calc((100% - 32px) / 2);
    margin: 8px;
  }
}
@media (max-width: 474px) {
  .block-categories__list {
    margin: -8px;
  }

  .block-categories__item {
    width: calc((100% - 16px) / 1);
    margin: 8px;
  }
}
/*
// .block-empty
*/
.block-empty__body {
  text-align: center;
}

.block-empty__title {
  margin-top: 12px;
  font-size: 36px;
  font-weight: 700;
}

.block-empty__message {
  margin-top: 16px;
}

.block-empty__action {
  margin-top: 32px;
}

/*
// .block-features
*/
.block-features__list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 8px 40px;
}

.block-features__item {
  display: flex;
  width: calc((100% - 3px) / 4);
  padding: 1.5rem 1rem;
  justify-content: center;
}

.block-features__item-icon {
  display: flex;
  align-items: center;
  fill: #e52727;
}
[dir=ltr] .block-features__item-icon {
  margin-right: 1.375rem;
}
[dir=rtl] .block-features__item-icon {
  margin-left: 1.375rem;
}

.block-features__item-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.block-features__item-title {
  font-size: 18px;
  line-height: 1.125;
  padding: 0.1666666667em 0;
  font-weight: 500;
}

.block-features__item-subtitle {
  color: #6c757d;
  font-size: 15px;
  line-height: 1.25;
  padding: 0.1111111111em 0;
}

.block-features--layout--top-strip .block-features__list {
  border-bottom: 2px solid #ebebeb;
}

.block-features--layout--bottom-strip .block-features__list {
  border-top: 2px solid #ebebeb;
}

@media (max-width: 1399.98px) {
  .block-features__list {
    padding: 0;
  }

  .block-features__item {
    padding: 1.5rem 1.5rem;
    justify-content: flex-start;
  }

  .block-features__item-title {
    font-size: 16px;
  }

  .block-features__item-subtitle {
    font-size: 14px;
  }
}
@media (max-width: 1199.98px) {
  .block-features__item {
    padding: 1.375rem 0.75rem;
  }

  [dir=ltr] .block-features__item-icon {
    margin-right: 1rem;
  }
  [dir=rtl] .block-features__item-icon {
    margin-left: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .block-features__list {
    padding: 4px 0;
  }

  .block-features__item {
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }

  .block-features__item-icon {
    display: block;
    margin-bottom: 12px;
  }
  [dir=ltr] .block-features__item-icon {
    margin-right: 0;
  }
  [dir=rtl] .block-features__item-icon {
    margin-left: 0;
  }

  .block-features__item-info {
    display: block;
  }
}
@media (max-width: 767.98px) {
  .block-features__list {
    flex-wrap: wrap;
    padding: 16px 0;
  }

  .block-features__item {
    width: 50%;
    padding: 0.75rem 1.25rem;
  }
}
@media (max-width: 575px) and (min-width: 400px) {
  .block-features__item {
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }

  .block-features__item-icon {
    display: block;
    margin-bottom: 12px;
  }
  [dir=ltr] .block-features__item-icon {
    margin-right: 0;
  }
  [dir=rtl] .block-features__item-icon {
    margin-left: 0;
  }

  .block-features__item-info {
    display: block;
  }
}
@media (max-width: 399px) {
  .block-features__item {
    width: 100%;
    padding: 0.675rem 1.25rem;
  }

  [dir=ltr] .block-features__item-icon {
    margin-right: 18px;
  }
  [dir=rtl] .block-features__item-icon {
    margin-left: 18px;
  }
}
/*
// .block-finder
*/
.block-finder {
  position: relative;
  height: 500px;
  overflow: hidden;
  padding-top: 24px;
  padding-bottom: 60px;
  display: flex;
  align-items: center;
  background: #333;
}

.block-finder__decor {
  position: absolute;
  bottom: -1px;
  z-index: 2;
}
.block-finder__decor .decor__start,
.block-finder__decor .decor__end,
.block-finder__decor .decor__center {
  background: #fafafa;
}
.block-finder__decor .decor__start,
.block-finder__decor .decor__end {
  box-shadow: none;
}

.block-finder__image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center center;
  z-index: 1;
  opacity: 0.22;
}

.block-finder__body {
  color: #fff;
  text-align: center;
  z-index: 2;
}

.block-finder__title {
  font-size: 48px;
  font-weight: 500;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  margin: 0;
}

.block-finder__subtitle {
  margin: 0 0 60px;
  font-size: 18px;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.block-finder__form {
  display: flex;
  margin: -7px;
}

.block-finder__select,
.block-finder__button {
  margin: 7px;
}

.block-finder__select {
  width: 100%;
}

.block-finder__button {
  height: 44px;
  min-height: 44px;
  border-radius: 2px;
  border: none;
  padding: 0 40px;
  transition: background 0.2s, color 0.2s;
  background-color: #e52727;
  color: #fff;
}
.block-finder__button:hover {
  background-color: #545454;
  color: #fff;
}
.block-finder__button:active {
  background-color: #4c4c4c;
  color: #fff;
}
.block-finder__button:active {
  transition-duration: 0.1s, 0.1s;
}
.block-finder__button:focus {
  outline: none;
}

@media (max-width: 1199.98px) {
  .block-finder {
    padding-bottom: 36px;
  }

  .block-finder__subtitle {
    margin-bottom: 48px;
  }

  .block-finder__form {
    flex-wrap: wrap;
    width: 548px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .block-finder__button {
    width: 260px;
    flex-basis: 260px;
    margin-top: 35px;
  }
}
@media (max-width: 991.98px) {
  .block-finder__title {
    font-size: 32px;
    line-height: 40px;
  }

  .block-finder__subtitle {
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 40px;
  }

  .block-finder {
    height: auto;
    padding: 64px 0 76px;
  }
}
@media (max-width: 767.98px) {
  .block-finder__title {
    font-size: 28px;
    line-height: 36px;
  }

  .block-finder__subtitle {
    font-size: 15px;
  }

  .block-finder__button {
    height: 40px;
    min-height: 40px;
    margin-top: 35px;
  }
}
@media (max-width: 399px) {
  .block-finder {
    height: auto;
    padding: 40px 0 48px;
  }

  .block-finder__title {
    font-size: 26px;
    line-height: 34px;
  }

  .block-finder__subtitle {
    font-size: 14px;
    margin-bottom: 32px;
  }

  .block-finder__button {
    margin-top: 21px;
  }
}
/*
// .block-header
*/
.block-header__body {
  display: flex;
  flex-direction: column;
}

.block-header__breadcrumb {
  padding-top: 16px;
  padding-bottom: 40px;
}

.block-header__title {
  font-size: 2.25rem;
  font-weight: 700;
  margin: 0;
  padding-top: 36px;
  padding-bottom: 40px;
}

@media (min-width: 1200px) {
  .block-header__title {
    margin: 0 auto;
  }

  .block-header__breadcrumb + .block-header__title {
    margin-top: -77px;
  }
}
@media (max-width: 1199.98px) {
  .block-header__title {
    padding-bottom: 20px;
    font-size: 2rem;
    padding-top: 32px;
  }

  .block-header__breadcrumb + .block-header__title {
    margin-top: -44px;
  }
}
/*
// .block-map
*/
.block-map {
  display: block;
  position: relative;
  height: 420px;
}
.block-map iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.block-map:before, .block-map:after {
  position: absolute;
  content: "";
  display: block;
  left: 0;
  width: 100%;
  height: 3px;
  z-index: 1;
  pointer-events: none;
}
.block-map:before {
  top: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0));
}
.block-map:after {
  bottom: 0;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0));
}

@media (max-width: 1399.98px) {
  .block-map {
    height: 400px;
  }
}
@media (max-width: 1199.98px) {
  .block-map {
    height: 380px;
  }
}
@media (max-width: 991.98px) {
  .block-map {
    height: 360px;
  }
}
@media (max-width: 767.98px) {
  .block-map {
    height: 340px;
  }
}
/*
// .block-posts-carousel
*/
.block-posts-carousel__carousel {
  position: relative;
}
.block-posts-carousel__carousel .owl-stage-outer {
  margin: -10px;
  padding: 10px;
  position: relative;
}
.block-posts-carousel__carousel .owl-stage {
  display: flex;
}
.block-posts-carousel__carousel .owl-item {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
}

.block-posts-carousel__carousel-loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0s 0.3s;
}
.block-posts-carousel__carousel-loader:before {
  position: absolute;
  display: block;
  content: "";
  left: -10px;
  top: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  background: #fafafa;
  opacity: 0.9;
}
.block-posts-carousel__carousel-loader:after {
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}

.block-posts-carousel--loading .block-posts-carousel__carousel:not(.block-posts-carousel__carousel--has-items) {
  min-height: 120px;
}
.block-posts-carousel--loading .block-posts-carousel__carousel-loader {
  visibility: visible;
  transition-delay: 0s;
  opacity: 1;
}

.block-posts-carousel__item {
  display: flex;
}

.block-posts-carousel--layout--grid .post-card {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}
.block-posts-carousel--layout--grid .post-card .post-card__image img {
  max-width: 100%;
  height: auto;
}
.block-posts-carousel--layout--grid .post-card .post-card__content {
  position: relative;
}
.block-posts-carousel--layout--grid .post-card .post-card__title h2 {
  margin: 0;
}
.block-posts-carousel--layout--grid .post-card .post-card__title a {
  color: inherit;
}
.block-posts-carousel--layout--grid .post-card .post-card__title a:hover {
  text-decoration: underline;
}
.block-posts-carousel--layout--grid .post-card .post-card__category {
  position: absolute;
  bottom: calc(100% - 1px);
  font-weight: 500;
  z-index: 0;
  font-size: 14px;
}
[dir=ltr] .block-posts-carousel--layout--grid .post-card .post-card__category {
  left: 0;
}
[dir=rtl] .block-posts-carousel--layout--grid .post-card .post-card__category {
  right: 0;
}
.block-posts-carousel--layout--grid .post-card .post-card__category a {
  color: #007bff;
  display: block;
}
.block-posts-carousel--layout--grid .post-card .post-card__category a:hover {
  text-decoration: underline;
}
.block-posts-carousel--layout--grid .post-card .post-card__category:before, .block-posts-carousel--layout--grid .post-card .post-card__category:after {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  background: #fff;
  z-index: -1;
  height: 100%;
}
.block-posts-carousel--layout--grid .post-card .post-card__category:before {
  width: 100%;
}
[dir=ltr] .block-posts-carousel--layout--grid .post-card .post-card__category:before {
  left: 0;
  border-top-right-radius: 2px;
  transform: skewX(30deg);
  transform-origin: left top;
}
[dir=rtl] .block-posts-carousel--layout--grid .post-card .post-card__category:before {
  right: 0;
  border-top-left-radius: 2px;
  transform: skewX(-30deg);
  transform-origin: right top;
}
.block-posts-carousel--layout--grid .post-card .post-card__category:after {
  width: 50px;
}
[dir=ltr] .block-posts-carousel--layout--grid .post-card .post-card__category:after {
  left: 0;
}
[dir=rtl] .block-posts-carousel--layout--grid .post-card .post-card__category:after {
  right: 0;
}
.block-posts-carousel--layout--grid .post-card .post-card__date {
  font-size: 14px;
  color: #999;
}
.block-posts-carousel--layout--grid .post-card .post-card__date a {
  color: inherit;
}
.block-posts-carousel--layout--grid .post-card .post-card__date a:hover {
  color: #007bff;
}
.block-posts-carousel--layout--grid .post-card .post-card__date:before {
  display: block;
  content: "";
  height: 1px;
  background: currentColor;
  opacity: 0.6;
}
.block-posts-carousel--layout--grid .post-card .post-card__excerpt {
  overflow: hidden;
}
.block-posts-carousel--layout--grid .post-card .post-card__category {
  font-size: 13px;
}
.block-posts-carousel--layout--grid .post-card .post-card__title h2 {
  font-size: 15px;
  line-height: 20px;
  overflow: hidden;
  max-height: 40px;
}
.block-posts-carousel--layout--grid .post-card .post-card__category {
  height: 22px;
  padding: 0.75rem 1.25rem 0;
}
.block-posts-carousel--layout--grid .post-card .post-card__content {
  display: flex;
  flex-direction: column;
  padding: 1.125rem 1.25rem 1rem;
}
.block-posts-carousel--layout--grid .post-card .post-card__date {
  order: 1;
  font-size: 13px;
  margin-top: 6px;
}
.block-posts-carousel--layout--grid .post-card .post-card__date:before {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  top: -1px;
}
[dir=ltr] .block-posts-carousel--layout--grid .post-card .post-card__date:before {
  margin-right: 2px;
}
[dir=rtl] .block-posts-carousel--layout--grid .post-card .post-card__date:before {
  margin-left: 2px;
}
.block-posts-carousel--layout--grid .post-card .post-card__excerpt,
.block-posts-carousel--layout--grid .post-card .post-card__more {
  display: none;
}

@media (min-width: 576px) {
  .block-posts-carousel--layout--list .post-card {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    display: flex;
  }
  .block-posts-carousel--layout--list .post-card .post-card__image {
    display: flex;
    flex-shrink: 0;
  }
  .block-posts-carousel--layout--list .post-card .post-card__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .block-posts-carousel--layout--list .post-card .post-card__content {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: flex-start;
  }
  .block-posts-carousel--layout--list .post-card .post-card__title h2 {
    margin-bottom: 0;
  }
  .block-posts-carousel--layout--list .post-card .post-card__title a {
    color: inherit;
  }
  .block-posts-carousel--layout--list .post-card .post-card__title a:hover {
    text-decoration: underline;
  }
  .block-posts-carousel--layout--list .post-card .post-card__category {
    font-size: 14px;
    font-weight: 500;
  }
  .block-posts-carousel--layout--list .post-card .post-card__category a {
    color: #007bff;
  }
  .block-posts-carousel--layout--list .post-card .post-card__category a:hover {
    text-decoration: underline;
  }
  .block-posts-carousel--layout--list .post-card .post-card__excerpt {
    overflow: hidden;
    margin-bottom: auto;
  }
  .block-posts-carousel--layout--list .post-card .post-card__date {
    font-size: 14px;
    color: #999;
  }
  .block-posts-carousel--layout--list .post-card .post-card__date a {
    color: inherit;
  }
  .block-posts-carousel--layout--list .post-card .post-card__date a:hover {
    color: #007bff;
  }
  .block-posts-carousel--layout--list .post-card .post-card__date:before {
    content: "";
    height: 1px;
    background: currentColor;
    opacity: 0.6;
  }
}
@media (min-width: 1400px) {
  .block-posts-carousel--layout--list .post-card .post-card__image {
    width: 280px;
    min-height: 220px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__content {
    padding: 20px 26px 22px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__category {
    margin-bottom: 6px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__title {
    margin-bottom: 8px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__title h2 {
    font-size: 17px;
    line-height: 24px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__excerpt {
    font-size: 15px;
    max-height: 66px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__excerpt .typography {
    line-height: 22px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__date {
    order: 1;
  }
  .block-posts-carousel--layout--list .post-card .post-card__date:before {
    display: block;
    width: 32px;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__more {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1399.98px) {
  .block-posts-carousel--layout--list .post-card .post-card__image {
    width: 240px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__content {
    padding: 16px 22px 16px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__category {
    margin-bottom: 6px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__title {
    margin-bottom: 8px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__title h2 {
    font-size: 16px;
    line-height: 22px;
    overflow: hidden;
    max-height: 44px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__excerpt {
    font-size: 15px;
    max-height: 66px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__excerpt .typography {
    line-height: 22px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__date {
    order: 1;
    line-height: 19px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__date:before {
    display: block;
    width: 32px;
    margin-top: 12px;
    margin-bottom: 10px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__more {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .block-posts-carousel--layout--list .post-card .post-card__image {
    width: 210px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__title {
    margin-bottom: 0;
  }
  .block-posts-carousel--layout--list .post-card .post-card__excerpt {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .block-posts-carousel--layout--list .post-card .post-card__image {
    width: 280px;
    min-height: 220px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__content {
    padding: 20px 26px 22px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__category {
    margin-bottom: 6px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__title {
    margin-bottom: 8px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__title h2 {
    font-size: 17px;
    line-height: 24px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__excerpt {
    font-size: 15px;
    max-height: 66px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__excerpt .typography {
    line-height: 22px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__date {
    order: 1;
  }
  .block-posts-carousel--layout--list .post-card .post-card__date:before {
    display: block;
    width: 32px;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__more {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .block-posts-carousel--layout--list .post-card .post-card__image {
    width: 240px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__content {
    padding: 16px 22px 16px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__category {
    margin-bottom: 6px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__title {
    margin-bottom: 8px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__title h2 {
    font-size: 16px;
    line-height: 22px;
    overflow: hidden;
    max-height: 44px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__excerpt {
    font-size: 15px;
    max-height: 66px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__excerpt .typography {
    line-height: 22px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__date {
    order: 1;
    line-height: 19px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__date:before {
    display: block;
    width: 32px;
    margin-top: 12px;
    margin-bottom: 10px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__more {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .block-posts-carousel--layout--list .post-card {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  }
  .block-posts-carousel--layout--list .post-card .post-card__image img {
    max-width: 100%;
    height: auto;
  }
  .block-posts-carousel--layout--list .post-card .post-card__content {
    position: relative;
  }
  .block-posts-carousel--layout--list .post-card .post-card__title h2 {
    margin: 0;
  }
  .block-posts-carousel--layout--list .post-card .post-card__title a {
    color: inherit;
  }
  .block-posts-carousel--layout--list .post-card .post-card__title a:hover {
    text-decoration: underline;
  }
  .block-posts-carousel--layout--list .post-card .post-card__category {
    position: absolute;
    bottom: calc(100% - 1px);
    font-weight: 500;
    z-index: 0;
    font-size: 14px;
  }
  [dir=ltr] .block-posts-carousel--layout--list .post-card .post-card__category {
    left: 0;
  }
  [dir=rtl] .block-posts-carousel--layout--list .post-card .post-card__category {
    right: 0;
  }
  .block-posts-carousel--layout--list .post-card .post-card__category a {
    color: #007bff;
    display: block;
  }
  .block-posts-carousel--layout--list .post-card .post-card__category a:hover {
    text-decoration: underline;
  }
  .block-posts-carousel--layout--list .post-card .post-card__category:before, .block-posts-carousel--layout--list .post-card .post-card__category:after {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    background: #fff;
    z-index: -1;
    height: 100%;
  }
  .block-posts-carousel--layout--list .post-card .post-card__category:before {
    width: 100%;
  }
  [dir=ltr] .block-posts-carousel--layout--list .post-card .post-card__category:before {
    left: 0;
    border-top-right-radius: 2px;
    transform: skewX(30deg);
    transform-origin: left top;
  }
  [dir=rtl] .block-posts-carousel--layout--list .post-card .post-card__category:before {
    right: 0;
    border-top-left-radius: 2px;
    transform: skewX(-30deg);
    transform-origin: right top;
  }
  .block-posts-carousel--layout--list .post-card .post-card__category:after {
    width: 50px;
  }
  [dir=ltr] .block-posts-carousel--layout--list .post-card .post-card__category:after {
    left: 0;
  }
  [dir=rtl] .block-posts-carousel--layout--list .post-card .post-card__category:after {
    right: 0;
  }
  .block-posts-carousel--layout--list .post-card .post-card__date {
    font-size: 14px;
    color: #999;
  }
  .block-posts-carousel--layout--list .post-card .post-card__date a {
    color: inherit;
  }
  .block-posts-carousel--layout--list .post-card .post-card__date a:hover {
    color: #007bff;
  }
  .block-posts-carousel--layout--list .post-card .post-card__date:before {
    display: block;
    content: "";
    height: 1px;
    background: currentColor;
    opacity: 0.6;
  }
  .block-posts-carousel--layout--list .post-card .post-card__excerpt {
    overflow: hidden;
  }
  .block-posts-carousel--layout--list .post-card .post-card__category {
    font-size: 13px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__title h2 {
    font-size: 15px;
    line-height: 20px;
    overflow: hidden;
    max-height: 40px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__category {
    height: 22px;
    padding: 0.75rem 1.25rem 0;
  }
  .block-posts-carousel--layout--list .post-card .post-card__content {
    display: flex;
    flex-direction: column;
    padding: 1.125rem 1.25rem 1rem;
  }
  .block-posts-carousel--layout--list .post-card .post-card__date {
    order: 1;
    font-size: 13px;
    margin-top: 6px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__date:before {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    top: -1px;
  }
  [dir=ltr] .block-posts-carousel--layout--list .post-card .post-card__date:before {
    margin-right: 2px;
  }
  [dir=rtl] .block-posts-carousel--layout--list .post-card .post-card__date:before {
    margin-left: 2px;
  }
  .block-posts-carousel--layout--list .post-card .post-card__excerpt,
.block-posts-carousel--layout--list .post-card .post-card__more {
    display: none;
  }
}

/*
// .block-products-carousel
*/
.block-products-carousel__carousel {
  position: relative;
}
.block-products-carousel__carousel .owl-stage-outer {
  margin: -10px;
  padding: 10px;
}
.block-products-carousel__carousel .owl-stage {
  display: flex;
}
.block-products-carousel__carousel .owl-item {
  flex-shrink: 0;
  display: flex;
}

.block-products-carousel__carousel-loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0s 0.3s;
}
.block-products-carousel__carousel-loader:before {
  position: absolute;
  display: block;
  content: "";
  left: -10px;
  top: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  background: #fafafa;
  opacity: 0.9;
}
.block-products-carousel__carousel-loader:after {
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}

.block-products-carousel__carousel--loading:not(.block-products-carousel__carousel--has-items) {
  min-height: 120px;
}
.block-products-carousel__carousel--loading .block-products-carousel__carousel-loader {
  visibility: visible;
  transition-delay: 0s;
  opacity: 1;
}

.block-products-carousel__column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.block-products-carousel__cell {
  display: flex;
  width: 100%;
  flex-basis: 100%;
  flex-grow: 1;
}
.block-products-carousel__cell .product-card {
  width: 100%;
}

.block-products-carousel[data-layout=grid-4] .block-products-carousel__cell + .block-products-carousel__cell,
.block-products-carousel[data-layout=grid-5] .block-products-carousel__cell + .block-products-carousel__cell {
  margin-top: 20px;
}

.block-products-carousel[data-layout=grid-6] .block-products-carousel__cell + .block-products-carousel__cell {
  margin-top: 16px;
}

.block-products-carousel[data-layout|=horizontal] .block-products-carousel__cell + .block-products-carousel__cell {
  margin-top: 14px;
}

/*
// .block-products-columns
*/
.block-products-columns__title {
  border-bottom: 2px solid #ebebeb;
  margin-bottom: 20px;
  padding-bottom: 8px;
  font-size: 20px;
  font-weight: 700;
}

.block-products-columns__list-item + .block-products-columns__list-item {
  margin-top: 16px;
}
.block-products-columns__list-item .product-card {
  position: relative;
  display: flex;
}
.block-products-columns__list-item .product-card .product-card__action--wishlist,
.block-products-columns__list-item .product-card .product-card__action--compare,
.block-products-columns__list-item .product-card .product-card__features,
.block-products-columns__list-item .product-card .product-card__addtocart-icon,
.block-products-columns__list-item .product-card .product-card__addtocart-full,
.block-products-columns__list-item .product-card .product-card__wishlist,
.block-products-columns__list-item .product-card .product-card__compare,
.block-products-columns__list-item .product-card .product-card__meta,
.block-products-columns__list-item .product-card .product-card__fit {
  display: none;
}
.block-products-columns__list-item .product-card .product-card__image {
  padding: 8px 10px;
  width: 112px;
  flex-shrink: 0;
}
.block-products-columns__list-item .product-card .product-card__badges {
  position: absolute;
  top: 10px;
  display: flex;
  z-index: 1;
  flex-direction: column;
  align-items: flex-start;
}
[dir=ltr] .block-products-columns__list-item .product-card .product-card__badges {
  left: 10px;
}
[dir=rtl] .block-products-columns__list-item .product-card .product-card__badges {
  right: 10px;
}
.block-products-columns__list-item .product-card .product-card__badges > * + * {
  margin-top: 3px;
}
.block-products-columns__list-item .product-card .tag-badge {
  height: 16px;
  padding: 3px 11px 0;
}
.block-products-columns__list-item .product-card .tag-badge:before {
  left: 3px;
  right: 3px;
}
[dir=ltr] .block-products-columns__list-item .product-card .tag-badge:before {
  transform: skewX(-20deg);
}
[dir=rtl] .block-products-columns__list-item .product-card .tag-badge:before {
  transform: skewX(20deg);
}
.block-products-columns__list-item .product-card .product-card__actions-list {
  position: absolute;
}
[dir=ltr] .block-products-columns__list-item .product-card .product-card__actions-list {
  right: 0;
}
[dir=rtl] .block-products-columns__list-item .product-card .product-card__actions-list {
  left: 0;
}
.block-products-columns__list-item .product-card .product-card__info {
  padding-top: 12px;
  padding-bottom: 40px;
}
[dir=ltr] .block-products-columns__list-item .product-card .product-card__info {
  padding-right: 10px;
}
[dir=rtl] .block-products-columns__list-item .product-card .product-card__info {
  padding-left: 10px;
}
.block-products-columns__list-item .product-card .product-card__name {
  font-size: 15px;
  line-height: 18px;
  max-height: 36px;
  overflow: hidden;
}
[dir=ltr] .block-products-columns__list-item .product-card .product-card__name {
  padding-right: 26px;
}
[dir=rtl] .block-products-columns__list-item .product-card .product-card__name {
  padding-left: 26px;
}
.block-products-columns__list-item .product-card .product-card__rating {
  margin: -3px 0 -2px 0;
  padding-top: 7px;
  display: flex;
  flex-wrap: wrap;
}
.block-products-columns__list-item .product-card .product-card__rating-stars,
.block-products-columns__list-item .product-card .product-card__rating-label {
  margin: 3px 0 2px 0;
}
[dir=ltr] .block-products-columns__list-item .product-card .product-card__rating-stars {
  margin-right: 7px;
}
[dir=rtl] .block-products-columns__list-item .product-card .product-card__rating-stars {
  margin-left: 7px;
}
.block-products-columns__list-item .product-card .product-card__rating-label {
  line-height: 1;
}
.block-products-columns__list-item .product-card .product-card__brand {
  margin: -3px 0 -2px 0;
  padding-top: 7px;
  display: flex;
  flex-wrap: wrap;
}
.block-products-columns__list-item .product-card .product-card__brand-stars,
.block-products-columns__list-item .product-card .product-card__brand-label {
  margin: 3px 0 2px 0;
}
[dir=ltr] .block-products-columns__list-item .product-card .product-card__brand-stars {
  margin-right: 7px;
}
[dir=rtl] .block-products-columns__list-item .product-card .product-card__brand-stars {
  margin-left: 7px;
}
.block-products-columns__list-item .product-card .product-card__brand-label {
  line-height: 1;
}
.block-products-columns__list-item .product-card .product-card__code {
  margin: -3px 0 -2px 0;
  padding-top: 7px;
  display: flex;
  flex-wrap: wrap;
}
.block-products-columns__list-item .product-card .product-card__code-label {
  margin: 3px 0 2px 0;
}
.block-products-columns__list-item .product-card .product-card__code-label {
  line-height: 1;
}
.block-products-columns__list-item .product-card .product-card__prices {
  position: absolute;
  bottom: 14px;
  flex-grow: 1;
  font-size: 16px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
[dir=ltr] .block-products-columns__list-item .product-card .product-card__prices {
  left: 112px;
}
[dir=rtl] .block-products-columns__list-item .product-card .product-card__prices {
  right: 112px;
}
.block-products-columns__list-item .product-card .product-card__price--old {
  font-size: 14px;
}
[dir=ltr] .block-products-columns__list-item .product-card .product-card__price--old {
  margin-left: 6px;
}
[dir=rtl] .block-products-columns__list-item .product-card .product-card__price--old {
  margin-right: 6px;
}

@media (min-width: 1400px) {
  .block-products-columns__list-item .product-card .product-card__image {
    padding: 8px 16px;
    width: 136px;
  }
  .block-products-columns__list-item .product-card .product-card__info {
    padding-top: 14px;
    padding-bottom: 42px;
  }
  .block-products-columns__list-item .product-card .product-card__name {
    font-size: 16px;
    line-height: 20px;
    max-height: 40px;
  }
  .block-products-columns__list-item .product-card .product-card__prices {
    bottom: 16px;
  }
  [dir=ltr] .block-products-columns__list-item .product-card .product-card__prices {
    left: 136px;
  }
  [dir=rtl] .block-products-columns__list-item .product-card .product-card__prices {
    right: 136px;
  }
}
@media (max-width: 1199.98px) {
  .block-products-columns {
    display: none;
  }
}
/*
// .block-reviews
*/
.block-reviews__title {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 28px;
}

.block-reviews__subtitle {
  color: #6c757d;
  text-align: center;
  margin-bottom: 28px;
  font-size: 15px;
  margin-top: -24px;
}

.block-reviews__list .owl-stage-outer {
  margin: -10px;
  padding: 10px;
}
.block-reviews__list .owl-dots {
  margin-top: 18px;
  display: flex;
  justify-content: center;
}
.block-reviews__list .owl-carousel .owl-dot:before {
  display: block;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.12);
  transition: background 0.2s;
  margin: 6px;
}
.block-reviews__list .owl-carousel .owl-dot:focus {
  outline: none;
}
.block-reviews__list .owl-carousel .owl-dot:hover:before {
  background: rgba(0, 0, 0, 0.22);
}
.block-reviews__list .owl-carousel .owl-dot.active:before {
  background: #e52727;
}

.block-reviews__item {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  max-width: 690px;
  margin: 0 auto;
  display: flex;
}

.block-reviews__item-avatar {
  width: 190px;
  flex-shrink: 0;
}
[dir=ltr] .block-reviews__item-avatar {
  margin-left: 12px;
  margin-right: -12px;
}
[dir=rtl] .block-reviews__item-avatar {
  margin-right: 12px;
  margin-left: -12px;
}
.block-reviews__item-avatar img {
  max-width: 100%;
}

.block-reviews__item-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 32px;
}

.block-reviews__item-text {
  font-size: 15px;
  font-style: italic;
  line-height: 24px;
}

.block-reviews__item-meta {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.block-reviews__item-author {
  color: #6c757d;
  font-size: 14px;
  position: relative;
  line-height: 20px;
}
.block-reviews__item-author:before {
  display: inline-block;
  height: 1px;
  width: 18px;
  background: currentColor;
  content: "";
  opacity: 0.8;
  position: relative;
  vertical-align: middle;
}
[dir=ltr] .block-reviews__item-author:before {
  left: 0;
  margin-right: 4px;
}
[dir=rtl] .block-reviews__item-author:before {
  right: 0;
  margin-left: 4px;
}

.block-reviews__item-rating {
  padding-bottom: 4px;
}

@media (max-width: 767.98px) {
  .block-reviews__item {
    flex-direction: column;
  }

  .block-reviews__item-avatar {
    margin: 36px 36px 0;
    overflow: hidden;
    align-self: center;
    width: 140px;
    border-radius: 70px;
  }
  [dir=ltr] .block-reviews__item-avatar {
    margin-left: 0;
    margin-right: 0;
  }
  [dir=rtl] .block-reviews__item-avatar {
    margin-right: 0;
    margin-left: 0;
  }

  .block-reviews__item-content {
    text-align: center;
  }

  .block-reviews__item-meta {
    flex-direction: column;
    margin-top: 0;
    padding: 28px 0 20px;
  }

  .block-reviews__item-rating {
    order: 1;
    padding-top: 8px;
    padding-bottom: 0;
  }
}
/*
// .block-sale
*/
.block-sale__content {
  display: flex;
  flex-direction: column;
}

.block-sale__header {
  text-align: center;
  position: relative;
  padding: 14px 32px 0;
  margin: 0 auto -28px;
  z-index: 1;
}

.block-sale__header-decor {
  position: absolute;
  width: 100%;
  bottom: 1px;
  left: 0;
  z-index: -1;
}
.block-sale__header-decor .decor__start,
.block-sale__header-decor .decor__end,
.block-sale__header-decor .decor__center {
  background: #fafafa;
}
.block-sale__header-decor .decor__start,
.block-sale__header-decor .decor__end {
  box-shadow: none;
}
.block-sale__header-decor .decor__body {
  height: 38px;
  margin: 0 -10px -10px;
}
.block-sale__header-decor .decor__start,
.block-sale__header-decor .decor__end {
  height: 28px;
  width: calc(50% - 26.1658px);
}
.block-sale__header-decor .decor__start {
  left: 10px;
  transform: skewX(30deg);
}
.block-sale__header-decor .decor__end {
  right: 10px;
  transform: skewX(-30deg);
}
.block-sale__header-decor .decor__center {
  height: 28px;
  width: 34.3316px;
}
.block-sale__header-decor .decor__start {
  border-bottom-left-radius: 2px;
}
.block-sale__header-decor .decor__end {
  border-bottom-right-radius: 2px;
}

.block-sale__controls {
  display: flex;
  align-items: center;
}

.block-sale__link {
  flex-grow: 1;
  font-size: 15px;
  line-height: 22px;
  padding: 18px 0;
}
.block-sale__link a {
  color: #6c757d;
  transition: color 0.12s;
}
.block-sale__link a:hover {
  color: inherit;
}

.block-sale__arrow {
  position: absolute;
  bottom: 3px;
}
.block-sale__arrow .arrow__button {
  height: 31px;
  padding: 0 24.89785px;
}
.block-sale__arrow .arrow__button:before {
  width: calc(100% - 17.89785px);
}
[dir=ltr] .block-sale__arrow .arrow__button:before {
  left: 8.948925px;
}
[dir=rtl] .block-sale__arrow .arrow__button:before {
  right: 8.948925px;
}
[dir=ltr] .block-sale__arrow .arrow__button:before {
  transform: skewX(30deg);
}
[dir=rtl] .block-sale__arrow .arrow__button:before {
  transform: skewX(-30deg);
}

[dir=ltr] .block-sale__arrow--prev {
  left: -49.7957px;
}
[dir=rtl] .block-sale__arrow--prev {
  right: -49.7957px;
}

[dir=ltr] .block-sale__arrow--next .arrow__button:before {
  transform: skewX(-30deg);
}
[dir=rtl] .block-sale__arrow--next .arrow__button:before {
  transform: skewX(30deg);
}
[dir=ltr] .block-sale__arrow--next {
  right: -49.7957px;
}
[dir=rtl] .block-sale__arrow--next {
  left: -49.7957px;
}

.block-sale__title {
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
}

.block-sale__subtitle {
  color: #6c757d;
  line-height: 1;
  margin-top: 10px;
}

.block-sale__timer {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.block-sale__arrows {
  position: absolute;
  z-index: 1;
  bottom: 3px;
  left: 0;
  width: 100%;
}

.block-sale__body {
  position: relative;
  padding-top: 104px;
  padding-bottom: 100px;
}

.block-sale__body-decor {
  position: absolute;
  width: 100%;
  bottom: -1px;
}
.block-sale__body-decor .decor__start,
.block-sale__body-decor .decor__end,
.block-sale__body-decor .decor__center {
  background: #fafafa;
}
.block-sale__body-decor .decor__start,
.block-sale__body-decor .decor__end {
  box-shadow: none;
}

.block-sale__image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center center;
  z-index: -1;
}
.block-sale__image:before {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(38, 38, 38, 0.8);
}

.block-sale__carousel .owl-stage-outer {
  margin: -10px;
  padding: 10px;
}
.block-sale__carousel .owl-stage {
  display: flex;
}
.block-sale__carousel .owl-item {
  display: flex;
  flex-shrink: 0;
}
.block-sale__carousel .owl-dots {
  margin-top: 28px;
  display: flex;
  justify-content: center;
  margin-bottom: -38px;
}
.block-sale__carousel .owl-carousel .owl-dot:before {
  display: block;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  transition: background 0.12s;
  margin: 6px;
}
.block-sale__carousel .owl-carousel .owl-dot:focus {
  outline: none;
}
.block-sale__carousel .owl-carousel .owl-dot:hover:before {
  background: rgba(255, 255, 255, 0.5);
}
.block-sale__carousel .owl-carousel .owl-dot:active:before {
  background: rgba(255, 255, 255, 0.7);
}
.block-sale__carousel .owl-carousel .owl-dot.active:before {
  background: #e52727;
}

.block-sale__item {
  height: 100%;
  display: flex;
  justify-content: stretch;
  width: 100%;
}
.block-sale__item .product-card {
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  border-radius: 2px;
}
.block-sale__item .product-card .product-card__addtocart-full,
.block-sale__item .product-card .product-card__wishlist,
.block-sale__item .product-card .product-card__compare {
  display: none;
}
.block-sale__item .product-card:after {
  display: block;
  position: static;
  content: "";
  order: 1;
  flex-grow: 1;
}
.block-sale__item .product-card .product-card__badges {
  position: absolute;
  top: 16px;
  display: flex;
  z-index: 1;
  flex-direction: column;
  align-items: flex-start;
}
[dir=ltr] .block-sale__item .product-card .product-card__badges {
  left: 16px;
}
[dir=rtl] .block-sale__item .product-card .product-card__badges {
  right: 16px;
}
.block-sale__item .product-card .product-card__badges > * + * {
  margin-top: 3px;
}
.block-sale__item .product-card .product-card__actions-list {
  position: absolute;
  overflow: hidden;
  z-index: 1;
}
[dir=ltr] .block-sale__item .product-card .product-card__actions-list {
  right: 0;
}
[dir=rtl] .block-sale__item .product-card .product-card__actions-list {
  left: 0;
}
.block-sale__item .product-card .product-card__image {
  display: block;
  position: relative;
}
.block-sale__item .product-card .product-card__fit {
  position: absolute;
  bottom: 8px;
}
.block-sale__item .product-card .product-card__fit .status-badge__text {
  display: none;
}
.block-sale__item .product-card .product-card__fit .status-badge__tooltip {
  display: block;
}
[dir=ltr] .block-sale__item .product-card .product-card__fit {
  left: calc(16px - 3px);
}
[dir=rtl] .block-sale__item .product-card .product-card__fit {
  right: calc(16px - 3px);
}
.block-sale__item .product-card .product-card__meta {
  padding: 6px 16px;
}
.block-sale__item .product-card .product-card__name {
  padding: 0 16px;
  line-height: 1.1875;
}
.block-sale__item .product-card .product-card__rating {
  margin: -3px 0 -2px 0;
  padding: 8px 16px 0;
  display: flex;
  flex-wrap: wrap;
}
.block-sale__item .product-card .product-card__rating-stars,
.block-sale__item .product-card .product-card__rating-label {
  margin: 3px 0 2px 0;
}
[dir=ltr] .block-sale__item .product-card .product-card__rating-stars {
  margin-right: 7px;
}
[dir=rtl] .block-sale__item .product-card .product-card__rating-stars {
  margin-left: 7px;
}
.block-sale__item .product-card .product-card__rating-label {
  line-height: 1;
}
.block-sale__item .product-card .product-card__brand {
  margin: -3px 0 -2px 0;
  padding: 8px 16px 0;
  display: flex;
  flex-wrap: wrap;
}
.block-sale__item .product-card .product-card__brand-stars,
.block-sale__item .product-card .product-card__brand-label {
  margin: 3px 0 2px 0;
}
[dir=ltr] .block-sale__item .product-card .product-card__brand-stars {
  margin-right: 7px;
}
[dir=rtl] .block-sale__item .product-card .product-card__brand-stars {
  margin-left: 7px;
}
.block-sale__item .product-card .product-card__brand-label {
  line-height: 1;
}
.block-sale__item .product-card .product-card__code {
  margin: -3px 0 -2px 0;
  padding: 8px 16px 0;
  display: flex;
  flex-wrap: wrap;
}
.block-sale__item .product-card .product-card__code-label {
  margin: 3px 0 2px 0;
}
.block-sale__item .product-card .product-card__code-label {
  line-height: 1;
}
.block-sale__item .product-card .product-card__features {
  padding: 10px 16px 0;
  margin-bottom: -2px;
  display: none;
}
.block-sale__item .product-card .product-card__footer {
  padding: 16px;
  align-items: center;
  display: flex;
  order: 2;
}
.block-sale__item .product-card .product-card__prices {
  flex-grow: 1;
  font-size: 18px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.block-sale__item .product-card .product-card__price--old {
  font-size: 14px;
}
[dir=ltr] .block-sale__item .product-card .product-card__price--old {
  margin-left: 6px;
}
[dir=rtl] .block-sale__item .product-card .product-card__price--old {
  margin-right: 6px;
}
.block-sale__item .product-card .product-card__addtocart-icon {
  margin: -3px;
}
@media (hover: hover) {
  .block-sale__item .product-card .product-card__action--wishlist,
.block-sale__item .product-card .product-card__action--compare {
    transition: transform 0.2s, opacity 0.2s, color 0.12s, background 0.12s;
    opacity: 0;
    will-change: transform;
  }
  [dir=ltr] .block-sale__item .product-card .product-card__action--wishlist,
[dir=ltr] .block-sale__item .product-card .product-card__action--compare {
    transform: translateX(100%) translateX(6px);
  }
  [dir=rtl] .block-sale__item .product-card .product-card__action--wishlist,
[dir=rtl] .block-sale__item .product-card .product-card__action--compare {
    transform: translateX(-100%) translateX(6px);
  }
  .block-sale__item .product-card:hover .product-card__action--wishlist,
.block-sale__item .product-card:hover .product-card__action--compare, .block-sale__item .product-card:active .product-card__action--wishlist,
.block-sale__item .product-card:active .product-card__action--compare {
    transform: none;
    opacity: 1;
  }
}
[dir=ltr] .block-sale__item .product-card .product-card__image img {
  border-top-left-radius: 2.5px;
  border-top-right-radius: 2.5px;
}
[dir=rtl] .block-sale__item .product-card .product-card__image img {
  border-top-right-radius: 2.5px;
  border-top-left-radius: 2.5px;
}
[dir=ltr] .block-sale__item .product-card .product-card__action:first-child {
  border-top-right-radius: 2.5px;
}
[dir=rtl] .block-sale__item .product-card .product-card__action:first-child {
  border-top-left-radius: 2.5px;
}

.block-sale--loading .block-sale__loader {
  position: relative;
  height: 100px;
}
.block-sale--loading .block-sale__loader:before {
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.1);
  border-top-color: rgba(255, 255, 255, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}

@media (max-width: 1399.98px) {
  .block-sale__body {
    padding-top: 96px;
    padding-bottom: 92px;
  }
}
@media (max-width: 1199.98px) {
  .block-sale__body {
    padding-top: 88px;
    padding-bottom: 84px;
  }
}
@media (max-width: 991.98px) {
  .block-sale__body {
    padding-top: 80px;
    padding-bottom: 76px;
  }
}
@media (max-width: 767.98px) {
  .block-sale__header {
    display: flex;
    flex-direction: column;
  }

  .block-sale__link {
    padding: 16px 24px;
    min-width: 300px;
  }

  .block-sale__controls {
    padding: 0;
    position: relative;
    align-self: center;
    min-width: 220px;
  }
}
@media (max-width: 575.98px) {
  .block-sale__body {
    padding-top: 51px;
    padding-bottom: 28px;
  }

  .block-sale__carousel .owl-dots {
    margin-bottom: 0;
    margin-top: 24px;
  }
}
@media (max-width: 499px) {
  .block-sale__header {
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto -23px;
    display: flex;
    flex-direction: column;
  }

  .block-sale__header-decor .decor__body {
    height: 33px;
    margin: 0 -10px -10px;
  }
  .block-sale__header-decor .decor__start,
.block-sale__header-decor .decor__end {
    height: 23px;
    width: calc(50% - 23.27905px);
  }
  .block-sale__header-decor .decor__start {
    left: 10px;
    transform: skewX(30deg);
  }
  .block-sale__header-decor .decor__end {
    right: 10px;
    transform: skewX(-30deg);
  }
  .block-sale__header-decor .decor__center {
    height: 23px;
    width: 28.5581px;
  }

  .block-sale__link {
    padding: 14px 20px;
  }

  .block-sale__arrow .arrow__button {
    height: 27px;
    padding: 0 20.58845px;
  }
  .block-sale__arrow .arrow__button:before {
    width: calc(100% - 15.58845px);
  }
  [dir=ltr] .block-sale__arrow .arrow__button:before {
    left: 7.794225px;
  }
  [dir=rtl] .block-sale__arrow .arrow__button:before {
    right: 7.794225px;
  }
  [dir=ltr] .block-sale__arrow .arrow__button:before {
    transform: skewX(30deg);
  }
  [dir=rtl] .block-sale__arrow .arrow__button:before {
    transform: skewX(-30deg);
  }

  [dir=ltr] .block-sale__arrow--prev {
    left: -42.1769px;
  }
  [dir=rtl] .block-sale__arrow--prev {
    right: -42.1769px;
  }

  [dir=ltr] .block-sale__arrow--next .arrow__button:before {
    transform: skewX(-30deg);
  }
  [dir=rtl] .block-sale__arrow--next .arrow__button:before {
    transform: skewX(30deg);
  }
  [dir=ltr] .block-sale__arrow--next {
    right: -42.1769px;
  }
  [dir=rtl] .block-sale__arrow--next {
    left: -42.1769px;
  }
}
@media (max-width: 474px) {
  .block-sale__title {
    font-size: 28px;
    line-height: 30px;
  }

  .block-sale__subtitle {
    margin-top: 6px;
    font-size: 15px;
  }

  .block-sale__timer {
    margin-top: 16px;
  }

  .block-sale__link {
    padding: 13px 20px;
    min-width: 220px;
    font-size: 14px;
  }
}
/*
// .block-slideshow
*/
.block-slideshow__item {
  border-radius: 2.5px;
  overflow: hidden;
  height: 500px;
  display: flex;
  flex-direction: column;
  padding: 84px 100px;
  position: relative;
  z-index: 0;
}
.block-slideshow__item, .block-slideshow__item:hover {
  color: #262626;
}

.block-slideshow__item-image {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-size: cover;
}

.block-slideshow__item-image--mobile {
  display: none;
  background-position: top center;
}

.block-slideshow__item-offer {
  background: #ffdf40;
  color: #262626;
  align-self: flex-start;
  font-size: 36px;
  line-height: 40px;
  font-weight: 700;
  padding: 2px 8px 0;
  margin-bottom: 12px;
  opacity: 0;
  transition: transform 0.5s 0.5s, opacity 0.5s 0.5s;
}
[dir=ltr] .block-slideshow__item-offer {
  transform: translateX(-24px);
}
[dir=rtl] .block-slideshow__item-offer {
  transform: translateX(24px);
}
.owl-item.active .block-slideshow__item-offer {
  transform: translateX(0);
  opacity: 1;
}

.block-slideshow__item-title {
  font-size: 48px;
  line-height: 56px;
  font-weight: 700;
  display: block;
  opacity: 0;
  transition: opacity 0.8s 0.2s;
}
.owl-item.active .block-slideshow__item-title {
  opacity: 1;
}

.block-slideshow__item-details {
  color: #6c757d;
  font-size: 18px;
  line-height: 30px;
  display: block;
  margin-top: 16px;
  flex-grow: 1;
  transform: translateY(12px);
  opacity: 0;
  transition: transform 0.5s 0.5s, opacity 0.5s 0.5s;
}
.owl-item.active .block-slideshow__item-details {
  transform: translateX(0);
  opacity: 1;
}

.block-slideshow__item-button {
  margin-top: 24px;
  align-self: flex-start;
  font-size: 18px;
  line-height: 28px;
  padding: 10px 40px;
  border-radius: 2px;
  transition: background-color 0.2s, color 0.2s, opacity 0.5s 0.5s;
  background: #e52727;
  color: #fff;
  opacity: 0;
}
.owl-item.active .block-slideshow__item-button {
  opacity: 1;
}
.block-slideshow__item-button:hover {
  background: #333;
  color: #fff;
}
.block-slideshow__item-button:active {
  background: #4d4d4d;
  color: #fff;
}

.block-slideshow__carousel {
  position: relative;
}
.block-slideshow__carousel .owl-dots {
  position: absolute;
  bottom: 26px;
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
}
.block-slideshow__carousel .owl-carousel button.owl-dot {
  pointer-events: auto;
}
.block-slideshow__carousel .owl-carousel button.owl-dot:focus {
  outline: none;
}
.block-slideshow__carousel .owl-carousel button.owl-dot:before {
  display: block;
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background: rgba(204, 204, 204, 0.7);
  margin: 5px;
  transition: background 0.12s;
}
.block-slideshow__carousel .owl-carousel button.owl-dot:hover:before {
  background: rgba(179, 179, 179, 0.7);
}
.block-slideshow__carousel .owl-carousel button.owl-dot:active:before {
  background: rgba(153, 153, 153, 0.7);
}
.block-slideshow__carousel .owl-carousel button.owl-dot.active:before {
  background: #e52727;
}

@media (max-width: 1399.98px) {
  .block-slideshow__item {
    padding: 68px 80px;
    height: 460px;
  }

  .block-slideshow__item-details {
    margin-top: 12px;
  }
}
@media (max-width: 1199.98px) {
  .block-slideshow__item {
    padding: 60px;
    height: 380px;
  }

  .block-slideshow__item-offer {
    font-size: 24px;
    line-height: 28px;
    padding: 1px 6px 1px;
    margin-bottom: 8px;
  }

  .block-slideshow__item-title {
    font-size: 36px;
    line-height: 44px;
  }

  .block-slideshow__item-details {
    font-size: 16px;
    line-height: 26px;
    margin-top: 8px;
  }

  .block-slideshow__item-button {
    font-size: 16px;
    line-height: 24px;
    padding: 8px 28px;
  }

  .block-slideshow__carousel .owl-dots {
    bottom: 22px;
  }
}
@media (max-width: 991.98px) {
  .block-slideshow__item {
    padding: 36px 40px 40px;
    height: 320px;
  }

  .block-slideshow__item-offer {
    margin-bottom: 10px;
  }

  .block-slideshow__item-title {
    font-size: 32px;
    line-height: 38px;
  }

  .block-slideshow__item-button {
    font-size: 15px;
    line-height: 24px;
    padding: 6px 24px;
  }

  .block-slideshow__carousel .owl-dots {
    bottom: 18px;
  }
}
@media (max-width: 767.98px) {
  .block-slideshow__item {
    align-items: center;
    text-align: center;
    height: 480px;
  }

  .block-slideshow__item-image--desktop {
    display: none;
  }

  .block-slideshow__item-image--mobile {
    display: block;
  }

  .block-slideshow__item-offer {
    align-self: center;
  }

  .block-slideshow__item-details {
    display: none;
  }

  .block-slideshow__item-button {
    align-self: center;
  }

  .block-slideshow__item--dark {
    justify-content: center;
  }
}
@media (max-width: 474px) {
  .block-slideshow__item {
    padding: 28px 32px 32px;
    height: 360px;
  }

  .block-slideshow__item-offer {
    font-size: 20px;
    line-height: 24px;
    padding: 1px 6px 1px;
    margin-bottom: 8px;
  }

  .block-slideshow__item-title {
    font-size: 24px;
    line-height: 30px;
  }

  .block-slideshow__item-button {
    font-size: 14px;
    line-height: 21px;
    padding: 5.5px 20px;
    margin-top: 20px;
  }
}
/*
// .block-space
*/
.block-space {
  display: block;
}

@media (min-width: 1200px) {
  .block-space--layout--after-header {
    height: 84px;
  }
  .block-space--layout--after-header.block-space--header--classic {
    height: 72px;
  }
}
@media (max-width: 1199.98px) {
  .block-space--layout--after-header {
    height: 56px;
  }
}
@media (max-width: 767.98px) {
  .block-space--layout--after-header {
    height: 48px;
  }
}
@media (max-width: 575.98px) {
  .block-space--layout--after-header {
    height: 36px;
  }
}

.block-space--layout--before-footer {
  height: 80px;
}
@media (max-width: 1199.98px) {
  .block-space--layout--before-footer {
    height: 72px;
  }
}
@media (max-width: 575.98px) {
  .block-space--layout--before-footer {
    height: 48px;
  }
}

.block-space--layout--divider-xl {
  height: 72px;
}

.block-space--layout--divider-lg {
  height: 60px;
}

.block-space--layout--divider-nl {
  height: 52px;
}

.block-space--layout--divider-sm {
  height: 40px;
}

.block-space--layout--divider-xs {
  height: 36px;
}

.block-space--layout--spaceship-ledge-height {
  height: 24px;
}
@media (max-width: 1199.98px) {
  .block-space--layout--spaceship-ledge-height {
    display: none;
  }
}

/*
// .block-split
*/
@media (min-width: 1400px) {
  .block-split--has-sidebar .block-split__item-sidebar {
    width: 270px;
  }
  .block-split--has-sidebar .block-split__item-content {
    width: calc(100% - 310px);
  }
  [dir=ltr] .block-split--has-sidebar .block-split__item + .block-split__item {
    margin-left: 40px;
  }
  [dir=rtl] .block-split--has-sidebar .block-split__item + .block-split__item {
    margin-right: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1399.98px) {
  .block-split--has-sidebar .block-split__item-sidebar {
    width: 260px;
  }
  .block-split--has-sidebar .block-split__item-content {
    width: calc(100% - 292px);
  }
  [dir=ltr] .block-split--has-sidebar .block-split__item + .block-split__item {
    margin-left: 32px;
  }
  [dir=rtl] .block-split--has-sidebar .block-split__item + .block-split__item {
    margin-right: 32px;
  }
}
@media (max-width: 991.98px) {
  .block-split__item-sidebar {
    width: 100%;
    order: 1;
    margin-top: 52px;
  }

  .block-split__item-content {
    width: 100%;
  }
}
/*
// .block-teammates
*/
.block-teammates__title {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
}

.block-teammates__subtitle {
  color: #6c757d;
  text-align: center;
  margin-bottom: 32px;
  font-size: 15px;
  margin-top: -36px;
}

.block-teammates__list .owl-stage-outer {
  margin: -10px;
  padding: 10px;
}
.block-teammates__list .owl-dots {
  margin-top: 18px;
  display: flex;
  justify-content: center;
}
.block-teammates__list .owl-carousel .owl-dot:before {
  display: block;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.12);
  transition: background 0.2s;
  margin: 6px;
}
.block-teammates__list .owl-carousel .owl-dot:focus {
  outline: none;
}
.block-teammates__list .owl-carousel .owl-dot:hover:before {
  background: rgba(0, 0, 0, 0.22);
}
.block-teammates__list .owl-carousel .owl-dot.active:before {
  background: #e52727;
}

.block-teammates__item {
  flex-shrink: 0;
  max-width: 240px;
  margin: 0 auto;
}

@media (max-width: 767.98px) {
  .block-teammates__list {
    padding: 0 10px;
  }
}
/*
// .block-zone
*/
.block-zone__body {
  display: flex;
}

.block-zone__card {
  display: flex;
}

.block-zone__widget {
  display: flex;
  flex-direction: column;
}

.block-zone__widget-header {
  border-bottom: 2px solid #ebebeb;
  margin-bottom: 24px;
  display: flex;
}

.block-zone__widget-body {
  flex-grow: 1;
  display: flex;
}

.block-zone__tabs {
  margin-bottom: -2px;
  display: flex;
}

.block-zone__tabs-button {
  white-space: nowrap;
  padding: 7px 16px 6px;
  margin: 0;
  border: none;
  background: transparent;
  font-family: inherit;
  color: inherit;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #ebebeb;
  transition: background 0.12s, border-color 0.12s;
}
[dir=ltr] .block-zone__tabs-button {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
[dir=rtl] .block-zone__tabs-button {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.block-zone__tabs-button:focus {
  outline: none;
}

.block-zone__tabs-button:not(.block-zone__tabs-button--active):hover {
  background: #f2f2f2;
  border-color: #e0e0e0;
}

.block-zone__tabs-button--active {
  border-color: #e52727;
}

.block-zone__arrows {
  align-self: center;
}

.block-zone__arrow {
  align-self: center;
}
[dir=ltr] .block-zone__arrow {
  margin-left: auto;
}
[dir=rtl] .block-zone__arrow {
  margin-right: auto;
}

[dir=ltr] .block-zone__arrow--next {
  margin-left: -5.37131px;
}
[dir=rtl] .block-zone__arrow--next {
  margin-right: -5.37131px;
}

.block-zone__carousel {
  position: relative;
  width: 100%;
  display: flex;
}
.block-zone__carousel owl-carousel-o {
  width: 100%;
  display: block;
}
.block-zone__carousel .owl-stage-outer {
  margin: -10px;
  padding: 10px;
}
.block-zone__carousel .owl-stage {
  display: flex;
}
.block-zone__carousel .owl-item {
  flex-shrink: 0;
  display: flex;
}

.block-zone__carousel-item {
  display: flex;
  width: 100%;
}
.block-zone__carousel-item .product-card {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}
.block-zone__carousel-item .product-card .product-card__addtocart-full,
.block-zone__carousel-item .product-card .product-card__wishlist,
.block-zone__carousel-item .product-card .product-card__compare {
  display: none;
}
.block-zone__carousel-item .product-card:after {
  display: block;
  position: static;
  content: "";
  order: 1;
  flex-grow: 1;
}
.block-zone__carousel-item .product-card .product-card__badges {
  position: absolute;
  top: 16px;
  display: flex;
  z-index: 1;
  flex-direction: column;
  align-items: flex-start;
}
[dir=ltr] .block-zone__carousel-item .product-card .product-card__badges {
  left: 16px;
}
[dir=rtl] .block-zone__carousel-item .product-card .product-card__badges {
  right: 16px;
}
.block-zone__carousel-item .product-card .product-card__badges > * + * {
  margin-top: 3px;
}
.block-zone__carousel-item .product-card .product-card__actions-list {
  position: absolute;
  overflow: hidden;
  z-index: 1;
}
[dir=ltr] .block-zone__carousel-item .product-card .product-card__actions-list {
  right: 0;
}
[dir=rtl] .block-zone__carousel-item .product-card .product-card__actions-list {
  left: 0;
}
.block-zone__carousel-item .product-card .product-card__image {
  display: block;
  position: relative;
}
.block-zone__carousel-item .product-card .product-card__fit {
  position: absolute;
  bottom: 8px;
}
.block-zone__carousel-item .product-card .product-card__fit .status-badge__text {
  display: none;
}
.block-zone__carousel-item .product-card .product-card__fit .status-badge__tooltip {
  display: block;
}
[dir=ltr] .block-zone__carousel-item .product-card .product-card__fit {
  left: calc(16px - 3px);
}
[dir=rtl] .block-zone__carousel-item .product-card .product-card__fit {
  right: calc(16px - 3px);
}
.block-zone__carousel-item .product-card .product-card__meta {
  padding: 6px 16px;
}
.block-zone__carousel-item .product-card .product-card__name {
  padding: 0 16px;
  line-height: 1.1875;
}
.block-zone__carousel-item .product-card .product-card__rating {
  margin: -3px 0 -2px 0;
  padding: 8px 16px 0;
  display: flex;
  flex-wrap: wrap;
}
.block-zone__carousel-item .product-card .product-card__rating-stars,
.block-zone__carousel-item .product-card .product-card__rating-label {
  margin: 3px 0 2px 0;
}
[dir=ltr] .block-zone__carousel-item .product-card .product-card__rating-stars {
  margin-right: 7px;
}
[dir=rtl] .block-zone__carousel-item .product-card .product-card__rating-stars {
  margin-left: 7px;
}
.block-zone__carousel-item .product-card .product-card__rating-label {
  line-height: 1;
}
.block-zone__carousel-item .product-card .product-card__brand {
  margin: -3px 0 -2px 0;
  padding: 8px 16px 0;
  display: flex;
  flex-wrap: wrap;
}
.block-zone__carousel-item .product-card .product-card__brand-stars,
.block-zone__carousel-item .product-card .product-card__brand-label {
  margin: 3px 0 2px 0;
}
[dir=ltr] .block-zone__carousel-item .product-card .product-card__brand-stars {
  margin-right: 7px;
}
[dir=rtl] .block-zone__carousel-item .product-card .product-card__brand-stars {
  margin-left: 7px;
}
.block-zone__carousel-item .product-card .product-card__brand-label {
  line-height: 1;
}
.block-zone__carousel-item .product-card .product-card__code {
  margin: -3px 0 -2px 0;
  padding: 8px 16px 0;
  display: flex;
  flex-wrap: wrap;
}
.block-zone__carousel-item .product-card .product-card__code-label {
  margin: 3px 0 2px 0;
}
.block-zone__carousel-item .product-card .product-card__code-label {
  line-height: 1;
}
.block-zone__carousel-item .product-card .product-card__features {
  padding: 10px 16px 0;
  margin-bottom: -2px;
  display: none;
}
.block-zone__carousel-item .product-card .product-card__footer {
  padding: 16px;
  align-items: center;
  display: flex;
  order: 2;
}
.block-zone__carousel-item .product-card .product-card__prices {
  flex-grow: 1;
  font-size: 18px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.block-zone__carousel-item .product-card .product-card__price--old {
  font-size: 14px;
}
[dir=ltr] .block-zone__carousel-item .product-card .product-card__price--old {
  margin-left: 6px;
}
[dir=rtl] .block-zone__carousel-item .product-card .product-card__price--old {
  margin-right: 6px;
}
.block-zone__carousel-item .product-card .product-card__addtocart-icon {
  margin: -3px;
}
@media (hover: hover) {
  .block-zone__carousel-item .product-card .product-card__action--wishlist,
.block-zone__carousel-item .product-card .product-card__action--compare {
    transition: transform 0.2s, opacity 0.2s, color 0.12s, background 0.12s;
    opacity: 0;
    will-change: transform;
  }
  [dir=ltr] .block-zone__carousel-item .product-card .product-card__action--wishlist,
[dir=ltr] .block-zone__carousel-item .product-card .product-card__action--compare {
    transform: translateX(100%) translateX(6px);
  }
  [dir=rtl] .block-zone__carousel-item .product-card .product-card__action--wishlist,
[dir=rtl] .block-zone__carousel-item .product-card .product-card__action--compare {
    transform: translateX(-100%) translateX(6px);
  }
  .block-zone__carousel-item .product-card:hover .product-card__action--wishlist,
.block-zone__carousel-item .product-card:hover .product-card__action--compare, .block-zone__carousel-item .product-card:active .product-card__action--wishlist,
.block-zone__carousel-item .product-card:active .product-card__action--compare {
    transform: none;
    opacity: 1;
  }
}

.block-zone__carousel-loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0s 0.3s;
}
.block-zone__carousel-loader:before {
  position: absolute;
  display: block;
  content: "";
  left: -10px;
  top: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  background: #fafafa;
  opacity: 0.9;
}
.block-zone__carousel-loader:after {
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}

.block-zone__carousel--loading .block-zone__carousel-loader {
  visibility: visible;
  transition-delay: 0s;
  opacity: 1;
}

@media (min-width: 1200px) {
  .block-zone__card {
    width: 285px;
  }
  [dir=ltr] .block-zone__card {
    margin-right: 36px;
  }
  [dir=rtl] .block-zone__card {
    margin-left: 36px;
  }

  .block-zone__widget {
    width: calc(100% - 321px);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .block-zone__card {
    width: 264px;
  }
  [dir=ltr] .block-zone__card {
    margin-right: 28px;
  }
  [dir=rtl] .block-zone__card {
    margin-left: 28px;
  }

  .block-zone__widget {
    width: calc(100% - 292px);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .block-zone__card {
    width: 232px;
  }
  [dir=ltr] .block-zone__card {
    margin-right: 24px;
  }
  [dir=rtl] .block-zone__card {
    margin-left: 24px;
  }

  .block-zone__widget {
    width: calc(100% - 256px);
  }

  .block-zone__tabs {
    flex-grow: 1;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .block-zone__body {
    display: block;
  }

  .block-zone__widget {
    margin-top: 16px;
  }

  .block-zone__tabs {
    flex-grow: 1;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  [dir=ltr] .block-zone__tabs > :first-child {
    margin-left: auto;
  }
  [dir=rtl] .block-zone__tabs > :first-child {
    margin-right: auto;
  }
  [dir=ltr] .block-zone__tabs > :last-child {
    margin-right: auto;
  }
  [dir=rtl] .block-zone__tabs > :last-child {
    margin-left: auto;
  }

  .block-zone__arrow--prev {
    order: -1;
  }
  [dir=ltr] .block-zone__arrow--prev .arrow__button:before {
    transform: skewX(20deg);
  }
  [dir=rtl] .block-zone__arrow--prev .arrow__button:before {
    transform: skewX(-20deg);
  }
  [dir=ltr] .block-zone__arrow--prev {
    margin-left: 0;
    margin-right: auto;
  }
  [dir=rtl] .block-zone__arrow--prev {
    margin-right: 0;
    margin-left: auto;
  }

  [dir=ltr] .block-zone__arrow--next {
    margin-left: 8px;
  }
  [dir=rtl] .block-zone__arrow--next {
    margin-right: 8px;
  }
}
/* ----------------------------------------
// Widgets
// ---------------------------------------- */
/*
// .widget-about-us
*/
.widget-about-us__body {
  padding: 0 1.5rem 1.5rem;
  margin-top: -0.25rem;
}

.widget-about-us__text {
  font-size: 16px;
  line-height: 26px;
}

.widget-about-us__social-links {
  margin-top: 16px;
}

/*
// .widget-categories-list
*/
.widget-categories-list__body {
  padding: 1.5rem;
}
.widget-categories-list__body a {
  color: inherit;
  transition: color 0.1s;
}
.widget-categories-list__body a:hover {
  color: #007bff;
}

.widget-categories-list__root {
  list-style: none;
  margin: 0;
  padding: 0;
}

.widget-categories-list__root-item {
  line-height: 20px;
}

.widget-categories-list__root-item + .widget-categories-list__root-item {
  margin-top: 0.375rem;
}

.widget-categories-list__root-link {
  font-weight: 500;
  font-size: 17px;
}

.widget-categories-list__root-item--has-children + .widget-categories-list__root-item {
  margin-top: 1.375rem;
}

.widget-categories-list__child {
  list-style: none;
  padding: 0;
  font-size: 15px;
  margin: 10px 0 0;
}

.widget-categories-list__child + .widget-categories-list__child {
  margin-top: 0;
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: height 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.widget-categories-list__child-item {
  padding: 3px 0;
}

.widget-categories-list__show-more {
  position: relative;
  display: inline;
  border: none;
  background: transparent;
  padding: 0;
  color: #999;
  font-size: 14px;
  font-family: inherit;
  margin-top: 4px;
}
[dir=ltr] .widget-categories-list__show-more {
  padding-left: 16px;
}
[dir=rtl] .widget-categories-list__show-more {
  padding-right: 16px;
}
.widget-categories-list__show-more:hover {
  text-decoration: underline;
}
.widget-categories-list__show-more:focus {
  outline: none;
}

.widget-categories-list__show-more-arrow {
  fill: currentColor;
  position: absolute;
  top: 6px;
  transition: transform 0.2s;
  opacity: 0.78;
}
[dir=ltr] .widget-categories-list__show-more-arrow {
  left: 0;
}
[dir=rtl] .widget-categories-list__show-more-arrow {
  right: 0;
}
.widget-categories-list__show-more-arrow svg {
  display: block;
}

.widget-categories-list__show-more-collapse-text {
  display: none;
}

.widget-categories-list--open .widget-categories-list__child + .widget-categories-list__child {
  height: auto;
  opacity: 1;
}
.widget-categories-list--open .widget-categories-list__show-more-expand-text {
  display: none;
}
.widget-categories-list--open .widget-categories-list__show-more-collapse-text {
  display: inline;
}
.widget-categories-list--open .widget-categories-list__show-more-arrow {
  transform: rotate(180deg);
}

/*
// .widget-categories
*/
.widget-categories {
  display: block;
}

.widget-categories__list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.widget-categories__item {
  position: relative;
}

.widget-categories__link {
  display: block;
  color: inherit;
  padding: 5px 0;
  transition: color 0.12s;
}
[dir=ltr] .widget-categories__link {
  margin-right: 34px;
}
[dir=rtl] .widget-categories__link {
  margin-left: 34px;
}
.widget-categories__link:hover {
  color: #007bff;
}

.widget-categories__expander {
  position: absolute;
  top: 2px;
  padding: 0;
  border: none;
  width: 26px;
  height: 26px;
  border-radius: 2px;
  transition: color 0.15s, background 0.15s;
  background-color: #fff;
  color: #ccc;
}
.widget-categories__expander:hover {
  background-color: #f2f2f2;
  color: #4d4d4d;
}
.widget-categories__expander:active {
  background-color: #ebebeb;
  color: #4d4d4d;
}
[dir=ltr] .widget-categories__expander {
  right: 0;
}
[dir=rtl] .widget-categories__expander {
  left: 0;
}
.widget-categories__expander:focus {
  outline: none;
}
.widget-categories__expander:before, .widget-categories__expander:after {
  display: block;
  position: absolute;
  content: "";
  width: 10px;
  height: 2px;
  background: currentColor;
  top: 12px;
}
[dir=ltr] .widget-categories__expander:before, [dir=ltr] .widget-categories__expander:after {
  left: 8px;
}
[dir=rtl] .widget-categories__expander:before, [dir=rtl] .widget-categories__expander:after {
  right: 8px;
}
.widget-categories__expander:after {
  transition: transform 0.2s ease-in-out;
  transform: rotateZ(90deg);
}
.widget-categories__expander:active {
  transition-duration: 0s;
}

.widget-categories__container {
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: height 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
.widget-categories__container:before, .widget-categories__container:after {
  display: block;
  content: "";
}
.widget-categories__container:before {
  height: 4px;
}
.widget-categories__container:after {
  height: 12px;
}

.widget-categories__list--root {
  padding: 0 1.5rem 1.5rem;
  font-size: 15px;
  line-height: 20px;
}
[dir=ltr] .widget-categories__list--root > .widget-categories__item {
  padding-left: 16px;
}
[dir=rtl] .widget-categories__list--root > .widget-categories__item {
  padding-right: 16px;
}
.widget-categories__list--root > .widget-categories__item:before {
  position: absolute;
  display: block;
  content: "";
  width: 4px;
  height: 4px;
  background: currentColor;
  opacity: 0.2;
  top: 13px;
}
[dir=ltr] .widget-categories__list--root > .widget-categories__item:before {
  left: 2px;
}
[dir=rtl] .widget-categories__list--root > .widget-categories__item:before {
  right: 2px;
}

.widget-categories__list--child {
  background: #f5f5f5;
  border-radius: 3px;
  font-size: 14px;
  line-height: 18px;
  padding: 9px 18px;
}
.widget-categories__list--child .widget-categories__link {
  padding: 5px 0 3px;
}

.widget-categories__item--open .widget-categories__container {
  opacity: 1;
  height: auto;
}
.widget-categories__item--open .widget-categories__expander:after {
  transform: none;
}

/*
// .widget-comments
*/
.widget-comments__body {
  padding: 0 1.5rem 1.5rem;
}
.widget-comments__body a {
  color: inherit;
  transition: color 0.15s;
}
.widget-comments__body a:hover {
  color: #007bff;
}

.widget-comments__list {
  list-style: none;
  margin: 0;
  padding: 0 0 2px;
}

.widget-comments__item + .widget-comments__item {
  margin-top: 20px;
}

.widget-comments__author {
  font-size: 15px;
  line-height: 18px;
}
.widget-comments__author a {
  border-bottom: 2px solid #ebebeb;
  transition: border 0.15s;
}
.widget-comments__author a:hover {
  border-color: rgba(0, 123, 255, 0.8);
}

.widget-comments__content {
  margin-top: 12px;
  font-size: 15px;
  line-height: 20px;
}

.widget-comments__meta {
  margin-top: 4px;
  font-size: 13px;
  color: #999;
  display: flex;
  white-space: nowrap;
}

.widget-comments__date {
  flex-shrink: 0;
}

.widget-comments__name {
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
[dir=ltr] .widget-comments__name {
  padding-left: 20px;
}
[dir=rtl] .widget-comments__name {
  padding-right: 20px;
}
.widget-comments__name:before {
  position: absolute;
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  background: currentColor;
  border-radius: 2px;
  top: 8px;
  opacity: 0.8;
}
[dir=ltr] .widget-comments__name:before {
  left: 8px;
}
[dir=rtl] .widget-comments__name:before {
  right: 8px;
}

/*
// .widget-filters
*/
.widget-filters {
  display: block;
}

.widget-filters__item {
  padding: 8px 1.5rem;
  border-bottom: 1px solid #ebebeb;
}
.widget-filters__item:first-child {
  border-top: 1px solid #ebebeb;
}

.widget-filters__actions {
  padding: 20px 1.5rem 1.5rem;
}
[dir=ltr] .widget-filters__actions > * + * {
  margin-left: 8px;
}
[dir=rtl] .widget-filters__actions > * + * {
  margin-right: 8px;
}

.widget-filters--offcanvas--none {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

@media (min-width: 992px) {
  .widget-filters--offcanvas--mobile {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  }
}
@media (max-width: 991.98px) {
  .widget-filters--offcanvas--mobile .widget-filters__header {
    display: none;
  }
  .widget-filters--offcanvas--mobile .widget-filters__item {
    padding: 8px 20px;
  }
  .widget-filters--offcanvas--mobile .widget-filters__item:first-child {
    border-top: none;
  }
}

.widget-filters--offcanvas--always .widget-filters__header {
  display: none;
}
.widget-filters--offcanvas--always .widget-filters__item {
  padding: 8px 20px;
}
.widget-filters--offcanvas--always .widget-filters__item:first-child {
  border-top: none;
}

/*
// .widget-newsletter
*/
.widget-newsletter {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  display: block;
  background: #333;
  border-radius: 2px;
  padding: 2.25rem 2.5rem 2.5rem;
  color: #fff;
  text-align: center;
}

.widget-newsletter__title {
  font-size: 24px;
  letter-spacing: 0.02em;
  position: relative;
  margin-bottom: 1.375rem;
  padding-bottom: 1.25rem;
}
.widget-newsletter__title:after {
  position: absolute;
  display: block;
  content: "";
  height: 1px;
  width: 56px;
  background: #4d4d4d;
  bottom: 0;
}
[dir=ltr] .widget-newsletter__title:after {
  left: calc(50% - 28px);
}
[dir=rtl] .widget-newsletter__title:after {
  right: calc(50% - 28px);
}
.widget-newsletter__title h4 {
  margin-bottom: 0;
  font-weight: 500;
}
@media (max-resolution: 1dppx) {
  .widget-newsletter__title h4 {
    font-weight: 400;
  }
}

.widget-newsletter__text {
  font-size: 15px;
  color: #9e9e9e;
  line-height: 24px;
  margin-bottom: 1.625rem;
}

.widget-newsletter__email {
  display: block;
  width: 100%;
  border-radius: 2px;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  height: 38px;
  padding: 0 12px;
  font-size: 15px;
  font-family: inherit;
  background-clip: padding-box;
  transition: border 0.2s, background 0.2s;
  color: #fff;
  background-color: #4c4c4c;
  border-color: #4c4c4c;
}
.widget-newsletter__email::placeholder {
  color: #b2b2b2;
}
.widget-newsletter__email::placeholder {
  transition: color 0.2s;
}
.widget-newsletter__email:hover {
  background-color: rgba(255, 255, 255, 0.16);
  border-color: rgba(255, 255, 255, 0.16);
}
.widget-newsletter__email:focus {
  outline: none;
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.16);
}
.widget-newsletter__email:focus::placeholder {
  color: #9e9e9e;
}

.widget-newsletter__button {
  border: none;
  font-size: 15px;
  border-radius: 2px;
  padding: 0.5rem 1.3125rem;
  margin-top: 1.25rem;
  width: 100%;
  transition: background 0.2s, color 0.2s;
  background-color: #e52727;
  color: #fff;
}
.widget-newsletter__button:hover {
  background-color: #545454;
  color: #fff;
}
.widget-newsletter__button:active {
  background-color: #4c4c4c;
  color: #fff;
}
.widget-newsletter__button:active {
  transition-duration: 0.1s, 0.1s;
}
.widget-newsletter__button:focus {
  outline: none;
}

/*
// .widget-posts
*/
.widget-posts__list {
  list-style: none;
  padding: 0 1.5rem 1.5rem;
  margin: 0;
}

.widget-posts__item {
  display: flex;
}
.widget-posts__item + .widget-posts__item {
  margin-top: 1rem;
}

.widget-posts__image {
  flex-shrink: 0;
  position: relative;
}
[dir=ltr] .widget-posts__image {
  margin-right: 14px;
}
[dir=rtl] .widget-posts__image {
  margin-left: 14px;
}
.widget-posts__image img {
  width: 70px;
  height: 70px;
  display: block;
  border-radius: 1.5px;
  object-fit: cover;
}
.widget-posts__image:before {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  bottom: 0;
  background: rgba(26, 26, 26, 0.2);
  border-radius: 1.5px;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
}
[dir=ltr] .widget-posts__image:before {
  left: 0;
  right: 0;
}
[dir=rtl] .widget-posts__image:before {
  right: 0;
  left: 0;
}
.widget-posts__image:hover:before {
  opacity: 1;
}

.widget-posts__info {
  padding-top: 5px;
}

.widget-posts__name {
  font-size: 15px;
  line-height: 19px;
  overflow: hidden;
  max-height: 38px;
}
.widget-posts__name a {
  color: inherit;
  transition: color 0.12s;
}
.widget-posts__name a:hover {
  color: #007bff;
}

.widget-posts__date {
  font-size: 13px;
  color: #999;
  margin-top: 3px;
  position: relative;
}
[dir=ltr] .widget-posts__date {
  padding-left: 22px;
}
[dir=rtl] .widget-posts__date {
  padding-right: 22px;
}
.widget-posts__date:before {
  position: absolute;
  display: block;
  content: "";
  height: 1px;
  width: 16px;
  background: currentColor;
  opacity: 0.6;
  top: 10px;
}
[dir=ltr] .widget-posts__date:before {
  left: 0;
}
[dir=rtl] .widget-posts__date:before {
  right: 0;
}

/*
// .widget-products
*/
.widget-products__list {
  padding-bottom: 1.5rem;
}

.widget-products__item {
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
}
.widget-products__item + .widget-products__item {
  margin-top: 12px;
}

.widget-products__image {
  position: relative;
  border-radius: 2px;
  overflow: hidden;
  flex-shrink: 0;
  width: 64px;
}
[dir=ltr] .widget-products__image {
  margin-right: 12px;
}
[dir=rtl] .widget-products__image {
  margin-left: 12px;
}
.widget-products__image:before {
  display: block;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: inherit;
  pointer-events: none;
  left: 0;
  top: 0;
}
.widget-products__image img {
  max-width: 100%;
  height: auto;
}

.widget-products__info {
  padding-top: 2px;
}

.widget-products__name {
  font-size: 14px;
  line-height: 17px;
  height: 34px;
  overflow: hidden;
}
.widget-products__name a {
  color: inherit;
  transition: color 0.12s;
}
.widget-products__name a:hover {
  color: #007bff;
}

.widget-products__prices {
  display: flex;
  padding-top: 2px;
}

.widget-products__price {
  font-size: 14px;
  font-weight: 500;
  color: #262626;
}
[dir=ltr] .widget-products__price + .widget-products__price {
  margin-left: 3px;
}
[dir=rtl] .widget-products__price + .widget-products__price {
  margin-right: 3px;
}

.widget-products__price--new {
  color: #262626;
}

.widget-products__price--old {
  font-weight: 400;
  font-size: 13px;
  text-decoration: line-through;
  color: #999;
  padding-top: 1px;
}

/*
// .widget-search
*/
.widget-search__form {
  display: flex;
  position: relative;
  z-index: 0;
}

.widget-search__input {
  flex-grow: 1;
  appearance: none;
  border: none;
  background: none;
  padding: 15.5px 24px;
  font-family: inherit;
  font-size: 16px;
  line-height: 21px;
}
.widget-search__input:focus {
  outline: none;
}

.widget-search__button {
  flex-shrink: 0;
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #bfbfbf;
  fill: currentColor;
  width: 52px;
  height: 52px;
  padding: 0;
}
.widget-search__button:focus {
  outline: none;
}
.widget-search__button:hover {
  color: #a6a6a6;
  background: #f5f5f5;
}
.widget-search__button:active {
  color: #a6a6a6;
  background: #f0f0f0;
}

.widget-search__field {
  position: absolute;
  display: block;
  content: "";
  z-index: -1;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 1px;
  transition: box-shadow 0.2s;
}

.widget-search__form:hover .widget-search__field {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.widget-search__input:focus ~ .widget-search__field {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
}

/*
// .widget-tags
*/
.widget-tags__body {
  padding: 0 1.5rem 1.5rem;
}

/* ----------------------------------------
// Shop
// ---------------------------------------- */
/*
// .analogs-table
*/
.analogs-table {
  display: block;
}

.analogs-table__rating {
  display: inline-block;
  vertical-align: middle;
}

.analogs-table__rating-stars {
  display: flex;
  justify-content: center;
}

.analogs-table__rating-label {
  color: #6c757d;
  font-size: 13px;
  line-height: 1;
}

.analogs-table__sku,
.analogs-table__country {
  color: #6c757d;
  font-size: 13px;
  line-height: 1;
  margin-top: 1px;
  margin-bottom: 4px;
}

.analogs-table__product-name {
  color: inherit;
  transition: color 0.12s;
}
.analogs-table__product-name:hover {
  color: #007bff;
}

@media (min-width: 768px) {
  .analogs-table table {
    width: 100%;
    font-size: 15px;
  }
  .analogs-table th,
.analogs-table td {
    padding: 0;
  }
  [dir=ltr] .analogs-table th:first-child,
[dir=ltr] .analogs-table td:first-child {
    padding-left: 14px;
  }
  [dir=rtl] .analogs-table th:first-child,
[dir=rtl] .analogs-table td:first-child {
    padding-right: 14px;
  }
  [dir=ltr] .analogs-table th:last-child,
[dir=ltr] .analogs-table td:last-child {
    padding-right: 14px;
  }
  [dir=rtl] .analogs-table th:last-child,
[dir=rtl] .analogs-table td:last-child {
    padding-left: 14px;
  }
  [dir=ltr] .analogs-table th:not(:first-child),
[dir=ltr] .analogs-table td:not(:first-child) {
    padding-left: 16px;
  }
  [dir=rtl] .analogs-table th:not(:first-child),
[dir=rtl] .analogs-table td:not(:first-child) {
    padding-right: 16px;
  }
  .analogs-table thead {
    font-size: 13px;
    text-transform: uppercase;
  }
  .analogs-table thead th,
.analogs-table thead td {
    padding-bottom: 6px;
  }
  .analogs-table tbody th,
.analogs-table tbody td {
    border-top: 1px solid #ebebeb;
    padding: 10px 0;
  }
  [dir=ltr] .analogs-table tbody th:first-child,
[dir=ltr] .analogs-table tbody td:first-child {
    border-left: 1px solid #ebebeb;
  }
  [dir=rtl] .analogs-table tbody th:first-child,
[dir=rtl] .analogs-table tbody td:first-child {
    border-right: 1px solid #ebebeb;
  }
  [dir=ltr] .analogs-table tbody th:last-child,
[dir=ltr] .analogs-table tbody td:last-child {
    border-right: 1px solid #ebebeb;
  }
  [dir=rtl] .analogs-table tbody th:last-child,
[dir=rtl] .analogs-table tbody td:last-child {
    border-left: 1px solid #ebebeb;
  }
  .analogs-table tbody tr:last-child th,
.analogs-table tbody tr:last-child td {
    border-bottom: 1px solid #ebebeb;
  }
  .analogs-table tbody tr:hover th,
.analogs-table tbody tr:hover td {
    background: #f7f7f7;
  }

  .analogs-table__column--rating {
    text-align: center;
  }

  .analogs-table__column--brand {
    text-align: center;
  }

  [dir=ltr] .analogs-table__column--price {
    text-align: right;
  }
  [dir=rtl] .analogs-table__column--price {
    text-align: left;
  }

  .analogs-table__rating-label {
    margin-top: 6px;
  }
}
@media (max-width: 767.98px) {
  .analogs-table table {
    display: block;
    font-size: 15px;
  }
  .analogs-table thead {
    display: none;
  }
  .analogs-table tbody {
    display: block;
  }
  .analogs-table tr,
.analogs-table td {
    display: block;
  }
  .analogs-table tr {
    padding: 14px 16px;
    background: #f5f5f5;
    border-radius: 2px;
  }
  .analogs-table tr + tr {
    margin-top: 12px;
  }
  .analogs-table td {
    padding: 0;
  }
  .analogs-table .analogs-table__column--rating {
    margin-top: 11px;
  }
  .analogs-table .analogs-table__column--brand {
    font-size: 13px;
    padding-top: 8px;
    margin-top: 12px;
    border-top: 1px solid #e5e5e5;
  }
  .analogs-table .analogs-table__column--brand:before {
    content: attr(data-title) ": ";
  }
  .analogs-table .analogs-table__column--price {
    font-weight: 500;
    margin-top: 16px;
  }
  .analogs-table .analogs-table__sku {
    margin: 2px 0 0;
  }
  .analogs-table .analogs-table__sku:before {
    content: attr(data-title) ": ";
  }
  .analogs-table .analogs-table__rating {
    display: flex;
  }
  [dir=ltr] .analogs-table .analogs-table__rating-label {
    margin-left: 8px;
  }
  [dir=rtl] .analogs-table .analogs-table__rating-label {
    margin-right: 8px;
  }
  .analogs-table .analogs-table__country {
    display: inline;
  }
}
/*
// .applied-filters
*/
.applied-filters__list {
  list-style: none;
  padding: 0;
  margin: -2px;
  display: flex;
  flex-wrap: wrap;
}

.applied-filters__item {
  margin: 2px;
}

.applied-filters__button {
  padding: 4px 11px;
  display: block;
  height: 21px;
  font-size: 13px;
  line-height: 1;
  color: inherit;
  background-color: #f0f0f0;
  transition: background 0.12s, color 0.12s;
  border-radius: 11.5px;
  position: relative;
  border: none;
}
.applied-filters__button:focus {
  outline: none;
}
.applied-filters__button:hover {
  background-color: #e5e5e5;
  color: inherit;
}

[dir=ltr] .applied-filters__button--filter {
  padding-right: 33px;
}
[dir=rtl] .applied-filters__button--filter {
  padding-left: 33px;
}
.applied-filters__button--filter:after {
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  top: 4px;
  bottom: 4px;
  background-color: rgba(0, 0, 0, 0.15);
}
[dir=ltr] .applied-filters__button--filter:after {
  right: 25px;
}
[dir=rtl] .applied-filters__button--filter:after {
  left: 25px;
}
.applied-filters__button--filter svg {
  position: absolute;
  top: 6px;
  fill: rgba(0, 0, 0, 0.4);
}
[dir=ltr] .applied-filters__button--filter svg {
  right: 10px;
}
[dir=rtl] .applied-filters__button--filter svg {
  left: 10px;
}

.applied-filters__button--clear {
  background: transparent;
  color: #999;
}

/*
// .cart
*/
.cart {
  display: flex;
}

.cart__table {
  flex-grow: 1;
}

.cart__totals {
  width: 360px;
  flex-shrink: 0;
}
[dir=ltr] .cart__totals {
  margin-left: 50px;
}
[dir=rtl] .cart__totals {
  margin-right: 50px;
}

.cart__totals-table {
  width: 100%;
  margin-bottom: 32px;
  font-size: 15px;
}
.cart__totals-table a:hover {
  text-decoration: underline;
}
.cart__totals-table,
.cart__totals-table thead,
.cart__totals-table tbody,
.cart__totals-table tfoot,
.cart__totals-table tr,
.cart__totals-table th,
.cart__totals-table td {
  display: block;
}
.cart__totals-table tr:after {
  display: block;
  content: "";
  clear: both;
}
.cart__totals-table th,
.cart__totals-table td {
  padding: 0;
}
[dir=ltr] .cart__totals-table th {
  float: left;
}
[dir=rtl] .cart__totals-table th {
  float: right;
}
[dir=ltr] .cart__totals-table td {
  text-align: right;
}
[dir=rtl] .cart__totals-table td {
  text-align: left;
}
.cart__totals-table thead,
.cart__totals-table tbody {
  line-height: 20px;
}
.cart__totals-table tbody tr > * {
  padding-top: 8px;
}
.cart__totals-table tbody tr:last-child > * {
  padding-bottom: 10px;
}
.cart__totals-table tfoot {
  font-size: 20px;
}
.cart__totals-table tfoot th {
  font-weight: 500;
}
.cart__totals-table tfoot td, .cart__totals-table tfoot th {
  padding-top: 12px;
  border-top: 1px solid #ebebeb;
}
.cart__totals-table tfoot td {
  letter-spacing: -0.02em;
}

@media (max-width: 1399.98px) {
  .cart {
    flex-wrap: wrap;
  }

  .cart__table {
    width: 100%;
  }

  .cart__totals {
    margin-top: 40px;
    width: 400px;
  }
  [dir=ltr] .cart__totals {
    margin-left: auto;
  }
  [dir=rtl] .cart__totals {
    margin-right: auto;
  }
}
@media (max-width: 991.98px) {
  .cart__totals {
    width: 360px;
  }
  [dir=ltr] .cart__totals {
    margin-left: 0;
  }
  [dir=rtl] .cart__totals {
    margin-right: 0;
  }
}
@media (max-width: 767.98px) {
  .cart__totals {
    width: 100%;
  }
}
/*
// .cart-table
*/
.cart-table__table {
  width: 100%;
  border-spacing: 0;
}

.cart-table__head {
  font-size: 13px;
  text-transform: uppercase;
}

.cart-table__column--product {
  line-height: 1.25;
}

.cart-table__column--price {
  width: 130px;
}
[dir=ltr] .cart-table__column--price {
  text-align: right;
}
[dir=rtl] .cart-table__column--price {
  text-align: left;
}

.cart-table__column--quantity {
  width: 150px;
  text-align: center;
}
[dir=ltr] .cart-table__column--quantity {
  padding-left: 36px;
}
[dir=rtl] .cart-table__column--quantity {
  padding-right: 36px;
}

.cart-table__column--total {
  width: 130px;
}
[dir=ltr] .cart-table__column--total {
  text-align: right;
}
[dir=rtl] .cart-table__column--total {
  text-align: left;
}

.cart-table__column--remove {
  width: 1px;
  white-space: nowrap;
}
[dir=ltr] .cart-table__column--remove {
  padding-left: 0;
}
[dir=rtl] .cart-table__column--remove {
  padding-right: 0;
}

.cart-table__product-name {
  color: inherit;
  transition: color 0.15s;
}
.cart-table__product-name:hover {
  color: #007bff;
}

.cart-table__options {
  margin: 4px 0 0;
  padding: 0;
  list-style: none;
  font-size: 14px;
  line-height: 1.375;
  color: #6c757d;
}

@media (min-width: 992px) {
  .cart-table {
    align-self: flex-start;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  }

  .cart-table__foot td {
    padding: 20px 24px;
  }

  .cart-table__column {
    padding: 14px 16px;
    border-bottom: 1px solid #ebebeb;
  }
  [dir=ltr] .cart-table__column:first-child {
    padding-left: 28px;
  }
  [dir=rtl] .cart-table__column:first-child {
    padding-right: 28px;
  }
  [dir=ltr] .cart-table__column:last-child {
    padding-right: 28px;
  }
  [dir=rtl] .cart-table__column:last-child {
    padding-left: 28px;
  }

  .cart-table__column--image {
    width: 1px;
    text-align: center;
  }
  .cart-table__column--image .image {
    width: 80px;
  }

  .cart-table__options li {
    position: relative;
  }
  [dir=ltr] .cart-table__options li {
    padding-left: 12px;
  }
  [dir=rtl] .cart-table__options li {
    padding-right: 12px;
  }
  .cart-table__options li:before {
    display: block;
    position: absolute;
    content: "";
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background: currentColor;
    top: 7px;
    opacity: 0.7;
  }
  [dir=ltr] .cart-table__options li:before {
    left: 2px;
  }
  [dir=rtl] .cart-table__options li:before {
    right: 2px;
  }

  [dir=ltr] .cart-table__remove {
    margin-right: -12px;
  }
  [dir=rtl] .cart-table__remove {
    margin-left: -12px;
  }

  .cart-table__actions {
    display: flex;
    justify-content: space-between;
  }

  .cart-table__coupon-form {
    width: 360px;
  }
}
@media (max-width: 991.98px) {
  .cart-table__table {
    display: block;
  }

  .cart-table__head {
    display: none;
  }

  .cart-table__column {
    display: block;
  }

  .cart-table__column {
    padding: 0;
  }

  .cart-table__body {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
  }

  .cart-table__row {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 8px;
    width: calc(100% / 3 - 16px);
  }

  .cart-table__column--image {
    text-align: center;
    padding: 16px 14px;
  }
  .cart-table__column--image .image {
    max-width: 220px;
    margin: 0 auto;
  }

  .cart-table__column--product {
    flex-grow: 1;
    text-align: center;
    padding: 0 14px 16px;
  }

  .cart-table__column--price,
.cart-table__column--quantity,
.cart-table__column--total {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #ebebeb;
    padding: 10px 14px;
    font-size: 15px;
  }
  [dir=ltr] .cart-table__column--price,
[dir=ltr] .cart-table__column--quantity,
[dir=ltr] .cart-table__column--total {
    padding-left: 14px;
  }
  [dir=rtl] .cart-table__column--price,
[dir=rtl] .cart-table__column--quantity,
[dir=rtl] .cart-table__column--total {
    padding-right: 14px;
  }
  .cart-table__column--price:before,
.cart-table__column--quantity:before,
.cart-table__column--total:before {
    content: attr(data-title) ":";
    display: block;
    width: calc(40% - 6px);
    flex-shrink: 0;
    font-weight: 500;
  }
  [dir=ltr] .cart-table__column--price:before,
[dir=ltr] .cart-table__column--quantity:before,
[dir=ltr] .cart-table__column--total:before {
    margin-right: 12px;
    text-align: left;
  }
  [dir=rtl] .cart-table__column--price:before,
[dir=rtl] .cart-table__column--quantity:before,
[dir=rtl] .cart-table__column--total:before {
    margin-left: 12px;
    text-align: right;
  }

  .cart-table__remove {
    position: absolute;
    top: 0;
  }
  [dir=ltr] .cart-table__remove {
    right: 0;
  }
  [dir=rtl] .cart-table__remove {
    left: 0;
  }

  .cart-table__foot,
.cart-table__foot tr,
.cart-table__foot td {
    display: block;
    padding: 0;
  }

  .cart-table__actions {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  .cart-table__coupon-form {
    padding: 16px;
  }

  .cart-table__update-button {
    padding: 16px;
  }

  .cart-table__quantity {
    max-width: 120px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .cart-table__coupon-form {
    width: 60%;
  }
}
@media (max-width: 767.98px) {
  .cart-table__row {
    width: calc(50% - 16px);
  }

  .cart-table__update-button {
    border-top: 1px solid #ebebeb;
  }
}
@media (max-width: 767.98px) {
  .cart-table__actions {
    display: block;
  }

  .cart-table__update-button {
    border-top: 1px solid #ebebeb;
    text-align: center;
  }
}
@media (max-width: 474px) {
  .cart-table__row {
    width: 100%;
  }
}
/*
// .categories-list
*/
.categories-list__body {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.categories-list__item {
  padding: 1.125rem;
  text-align: center;
  position: relative;
}
.categories-list__item a {
  display: block;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  color: inherit;
}
.categories-list__item .image {
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
}
.categories-list__item:before {
  position: absolute;
  display: block;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.03);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.12s;
  z-index: 1;
}
.categories-list__item:hover:before {
  opacity: 1;
}

.categories-list__item-products {
  margin-top: 4px;
  font-size: 14px;
  color: #6c757d;
}

.categories-list__divider {
  width: 1px;
  background-color: #ebebeb;
}

@media (min-width: 576px) {
  .categories-list--layout--columns-3-sidebar .categories-list__item {
    width: calc((100% - 2px) / 3);
  }
  .categories-list--layout--columns-3-sidebar .categories-list__divider:nth-child(6n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-3-sidebar .categories-list__divider:last-child:nth-child(6n) {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .categories-list--layout--columns-3-sidebar .categories-list__item {
    width: calc((100% - 1px) / 2);
  }
  .categories-list--layout--columns-3-sidebar .categories-list__divider:nth-child(4n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-3-sidebar .categories-list__divider:last-child:nth-child(4n) {
    display: none;
  }
}

@media (min-width: 1200px) {
  .categories-list--layout--columns-4-sidebar .categories-list__item {
    width: calc((100% - 3px) / 4);
  }
  .categories-list--layout--columns-4-sidebar .categories-list__divider:nth-child(8n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-4-sidebar .categories-list__divider:last-child:nth-child(8n) {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 1199.98px) {
  .categories-list--layout--columns-4-sidebar .categories-list__item {
    width: calc((100% - 2px) / 3);
  }
  .categories-list--layout--columns-4-sidebar .categories-list__divider:nth-child(6n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-4-sidebar .categories-list__divider:last-child:nth-child(6n) {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .categories-list--layout--columns-4-sidebar .categories-list__item {
    width: calc((100% - 1px) / 2);
  }
  .categories-list--layout--columns-4-sidebar .categories-list__divider:nth-child(4n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-4-sidebar .categories-list__divider:last-child:nth-child(4n) {
    display: none;
  }
}

@media (min-width: 1400px) {
  .categories-list--layout--columns-5-sidebar .categories-list__item {
    width: calc((100% - 4px) / 5);
  }
  .categories-list--layout--columns-5-sidebar .categories-list__divider:nth-child(10n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-5-sidebar .categories-list__divider:last-child:nth-child(10n) {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .categories-list--layout--columns-5-sidebar .categories-list__item {
    width: calc((100% - 3px) / 4);
  }
  .categories-list--layout--columns-5-sidebar .categories-list__divider:nth-child(8n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-5-sidebar .categories-list__divider:last-child:nth-child(8n) {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 1199.98px) {
  .categories-list--layout--columns-5-sidebar .categories-list__item {
    width: calc((100% - 2px) / 3);
  }
  .categories-list--layout--columns-5-sidebar .categories-list__divider:nth-child(6n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-5-sidebar .categories-list__divider:last-child:nth-child(6n) {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .categories-list--layout--columns-5-sidebar .categories-list__item {
    width: calc((100% - 1px) / 2);
  }
  .categories-list--layout--columns-5-sidebar .categories-list__divider:nth-child(4n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-5-sidebar .categories-list__divider:last-child:nth-child(4n) {
    display: none;
  }
}

@media (min-width: 992px) {
  .categories-list--layout--columns-4-full .categories-list__item {
    width: calc((100% - 3px) / 4);
  }
  .categories-list--layout--columns-4-full .categories-list__divider:nth-child(8n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-4-full .categories-list__divider:last-child:nth-child(8n) {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .categories-list--layout--columns-4-full .categories-list__item {
    width: calc((100% - 2px) / 3);
  }
  .categories-list--layout--columns-4-full .categories-list__divider:nth-child(6n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-4-full .categories-list__divider:last-child:nth-child(6n) {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .categories-list--layout--columns-4-full .categories-list__item {
    width: calc((100% - 1px) / 2);
  }
  .categories-list--layout--columns-4-full .categories-list__divider:nth-child(4n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-4-full .categories-list__divider:last-child:nth-child(4n) {
    display: none;
  }
}

@media (min-width: 1200px) {
  .categories-list--layout--columns-5-full .categories-list__item {
    width: calc((100% - 4px) / 5);
  }
  .categories-list--layout--columns-5-full .categories-list__divider:nth-child(10n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-5-full .categories-list__divider:last-child:nth-child(10n) {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .categories-list--layout--columns-5-full .categories-list__item {
    width: calc((100% - 3px) / 4);
  }
  .categories-list--layout--columns-5-full .categories-list__divider:nth-child(8n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-5-full .categories-list__divider:last-child:nth-child(8n) {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .categories-list--layout--columns-5-full .categories-list__item {
    width: calc((100% - 2px) / 3);
  }
  .categories-list--layout--columns-5-full .categories-list__divider:nth-child(6n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-5-full .categories-list__divider:last-child:nth-child(6n) {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .categories-list--layout--columns-5-full .categories-list__item {
    width: calc((100% - 1px) / 2);
  }
  .categories-list--layout--columns-5-full .categories-list__divider:nth-child(4n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-5-full .categories-list__divider:last-child:nth-child(4n) {
    display: none;
  }
}

@media (min-width: 1200px) {
  .categories-list--layout--columns-6-full .categories-list__item {
    width: calc((100% - 5px) / 6);
  }
  .categories-list--layout--columns-6-full .categories-list__divider:nth-child(12n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-6-full .categories-list__divider:last-child:nth-child(12n) {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .categories-list--layout--columns-6-full .categories-list__item {
    width: calc((100% - 3px) / 4);
  }
  .categories-list--layout--columns-6-full .categories-list__divider:nth-child(8n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-6-full .categories-list__divider:last-child:nth-child(8n) {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .categories-list--layout--columns-6-full .categories-list__item {
    width: calc((100% - 2px) / 3);
  }
  .categories-list--layout--columns-6-full .categories-list__divider:nth-child(6n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-6-full .categories-list__divider:last-child:nth-child(6n) {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .categories-list--layout--columns-6-full .categories-list__item {
    width: calc((100% - 1px) / 2);
  }
  .categories-list--layout--columns-6-full .categories-list__divider:nth-child(4n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-6-full .categories-list__divider:last-child:nth-child(4n) {
    display: none;
  }
}

@media (min-width: 1400px) {
  .categories-list--layout--columns-7-full .categories-list__item {
    width: calc((100% - 6px) / 7);
  }
  .categories-list--layout--columns-7-full .categories-list__divider:nth-child(14n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-7-full .categories-list__divider:last-child:nth-child(14n) {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .categories-list--layout--columns-7-full .categories-list__item {
    width: calc((100% - 5px) / 6);
  }
  .categories-list--layout--columns-7-full .categories-list__divider:nth-child(12n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-7-full .categories-list__divider:last-child:nth-child(12n) {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .categories-list--layout--columns-7-full .categories-list__item {
    width: calc((100% - 3px) / 4);
  }
  .categories-list--layout--columns-7-full .categories-list__divider:nth-child(8n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-7-full .categories-list__divider:last-child:nth-child(8n) {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .categories-list--layout--columns-7-full .categories-list__item {
    width: calc((100% - 2px) / 3);
  }
  .categories-list--layout--columns-7-full .categories-list__divider:nth-child(6n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-7-full .categories-list__divider:last-child:nth-child(6n) {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .categories-list--layout--columns-7-full .categories-list__item {
    width: calc((100% - 1px) / 2);
  }
  .categories-list--layout--columns-7-full .categories-list__divider:nth-child(4n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-7-full .categories-list__divider:last-child:nth-child(4n) {
    display: none;
  }
}

/*
// .category-card
*/
.category-card__body {
  position: relative;
}

.category-card__overlay-image {
  display: none;
}

.category-card--layout--classic .category-card__body {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
  flex-grow: 1;
  width: 100%;
}
.category-card--layout--classic .category-card__content {
  display: flex;
}
.category-card--layout--classic .category-card__image {
  width: 160px;
  padding: 24px 0;
  margin: 0 32px;
  flex-shrink: 0;
}
.category-card--layout--classic .category-card__image img {
  max-width: 100%;
}
.category-card--layout--classic .category-card__info {
  flex-grow: 1;
  padding-top: 24px;
  padding-bottom: 24px;
}
[dir=ltr] .category-card--layout--classic .category-card__info {
  padding-left: 0;
  padding-right: 28px;
}
[dir=rtl] .category-card--layout--classic .category-card__info {
  padding-right: 0;
  padding-left: 28px;
}
.category-card--layout--classic .category-card__name a,
.category-card--layout--classic .category-card__children a {
  transition: color 0.12s;
}
.category-card--layout--classic .category-card__name a:hover,
.category-card--layout--classic .category-card__children a:hover {
  color: #007bff;
}
.category-card--layout--classic .category-card__name {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}
.category-card--layout--classic .category-card__name a {
  color: inherit;
}
.category-card--layout--classic .category-card__children {
  list-style: none;
  margin: 10px 0 0;
  padding: 0;
  font-size: 15px;
}
.category-card--layout--classic .category-card__children li {
  position: relative;
  color: #6c757d;
  padding-top: 1px;
  padding-bottom: 1px;
}
[dir=ltr] .category-card--layout--classic .category-card__children li {
  padding-left: 11px;
  padding-right: 0;
}
[dir=rtl] .category-card--layout--classic .category-card__children li {
  padding-right: 11px;
  padding-left: 0;
}
.category-card--layout--classic .category-card__children li:before {
  display: block;
  position: absolute;
  content: "";
  width: 3px;
  height: 3px;
  background: currentColor;
  opacity: 0.7;
  top: 10px;
}
[dir=ltr] .category-card--layout--classic .category-card__children li:before {
  left: 0;
}
[dir=rtl] .category-card--layout--classic .category-card__children li:before {
  right: 0;
}
.category-card--layout--classic .category-card__children a {
  color: inherit;
}
.category-card--layout--classic .category-card__actions {
  margin-top: 8px;
}
.category-card--layout--classic .category-card__link {
  font-size: 14px;
  font-weight: 500;
  color: #007bff;
}
.category-card--layout--classic .category-card__link:hover {
  text-decoration: underline;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .category-card--layout--classic .category-card__image {
    width: 120px;
    margin: 0 26px;
  }
}
@media (max-width: 991px) and (min-width: 475px) {
  .category-card--layout--classic .category-card__image {
    width: 132px;
    margin: 0 26px;
    padding: 20px 0;
    align-self: center;
  }
  .category-card--layout--classic .category-card__content {
    flex-direction: column;
  }
  .category-card--layout--classic .category-card__info {
    padding-top: 0;
    padding-bottom: 20px;
  }
  [dir=ltr] .category-card--layout--classic .category-card__info {
    padding-left: 26px;
    padding-right: 26px;
  }
  [dir=rtl] .category-card--layout--classic .category-card__info {
    padding-right: 26px;
    padding-left: 26px;
  }
}
@media (max-width: 474px) and (min-width: 360px) {
  .category-card--layout--classic .category-card__image {
    width: 132px;
  }
}
@media (max-width: 474px) and (min-width: 360px) and (max-width: 420px) {
  .category-card--layout--classic .category-card__image {
    width: 100px;
    margin: 0 28px;
  }
}
@media (max-width: 359px) {
  .category-card--layout--classic .category-card__image {
    width: 132px;
    margin: 0 26px;
    padding: 24px 0;
    align-self: center;
  }
  .category-card--layout--classic .category-card__content {
    flex-direction: column;
  }
  .category-card--layout--classic .category-card__info {
    padding-top: 0;
    padding-bottom: 24px;
  }
  [dir=ltr] .category-card--layout--classic .category-card__info {
    padding-left: 26px;
    padding-right: 26px;
  }
  [dir=rtl] .category-card--layout--classic .category-card__info {
    padding-right: 26px;
    padding-left: 26px;
  }
}

.category-card--layout--overlay .category-card__body {
  flex-grow: 1;
  z-index: 0;
  overflow: hidden;
  border-radius: 2.5px;
  padding: 32px 20px 40px;
}
.category-card--layout--overlay .category-card__body:before {
  position: absolute;
  display: block;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(26, 26, 26, 0.6);
  background-image: linear-gradient(to top, rgba(26, 26, 26, 0.4) 20%, rgba(26, 26, 26, 0) 80%);
  z-index: -1;
  transition: background-color 0.2s;
}
.category-card--layout--overlay .category-card__overlay-image {
  display: block;
  position: absolute;
  z-index: -3;
  left: -10px;
  right: -10px;
  top: -10px;
  bottom: -10px;
  transition: transform 0.3s ease-out;
}
.category-card--layout--overlay .category-card__overlay-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.category-card--layout--overlay .category-card__overlay-image--blur {
  z-index: -2;
  opacity: 0;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}
.category-card--layout--overlay .category-card__overlay-image--blur img {
  filter: blur(3px);
}
.category-card--layout--overlay .category-card__content,
.category-card--layout--overlay .category-card__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  flex-grow: 1;
  text-align: center;
}
.category-card--layout--overlay .category-card__content {
  color: #fff;
}
.category-card--layout--overlay .category-card__name,
.category-card--layout--overlay .category-card__children {
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}
.category-card--layout--overlay .category-card__name a,
.category-card--layout--overlay .category-card__children a {
  color: inherit;
  transition: color 0.12s;
}
.category-card--layout--overlay .category-card__name a:hover,
.category-card--layout--overlay .category-card__children a:hover {
  color: #ffdf40;
}
.category-card--layout--overlay .category-card__name {
  font-size: 24px;
  margin-bottom: 20px;
}
.category-card--layout--overlay .category-card__children {
  flex-grow: 1;
  list-style: none;
  padding: 0;
  font-size: 16px;
  margin: 0 0 28px;
  opacity: 1;
}
.category-card--layout--overlay .category-card__children li {
  padding: 5px 0 6px;
}
.category-card--layout--overlay:hover .category-card__overlay-image {
  transform: scale(1.03);
}
.category-card--layout--overlay:hover .category-card__overlay-image--blur {
  opacity: 1;
}
.category-card--layout--overlay:hover .category-card__body:before {
  background-color: rgba(26, 26, 26, 0.7);
}
@media (max-width: 767.98px) {
  .category-card--layout--overlay .category-card__body {
    padding: 22px 28px 28px;
  }
  .category-card--layout--overlay .category-card__content {
    align-items: flex-start;
  }
  .category-card--layout--overlay .category-card__info {
    align-items: flex-start;
    align-content: flex-start;
  }
  .category-card--layout--overlay .category-card__name {
    margin-bottom: 10px;
    font-size: 22px;
  }
  .category-card--layout--overlay .category-card__children {
    display: flex;
    flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 24px;
  }
  [dir=ltr] .category-card--layout--overlay .category-card__children {
    text-align: left;
  }
  [dir=rtl] .category-card--layout--overlay .category-card__children {
    text-align: right;
  }
  .category-card--layout--overlay .category-card__children li {
    padding: 1px 0;
  }
  [dir=ltr] .category-card--layout--overlay .category-card__children li {
    margin-right: 16px;
  }
  [dir=rtl] .category-card--layout--overlay .category-card__children li {
    margin-left: 16px;
  }
}

/*
// .checkout
*/
.checkout__totals {
  width: 100%;
  font-size: 15px;
  line-height: 19px;
  border-spacing: 0;
  margin-bottom: 40px;
}
.checkout__totals th,
.checkout__totals td {
  padding: 0;
}
[dir=ltr] .checkout__totals th:last-child,
[dir=ltr] .checkout__totals td:last-child {
  padding-left: 20px;
  text-align: right;
}
[dir=rtl] .checkout__totals th:last-child,
[dir=rtl] .checkout__totals td:last-child {
  padding-right: 20px;
  text-align: left;
}

.checkout__totals-header {
  font-size: 13px;
  text-transform: uppercase;
}
.checkout__totals-header th {
  padding-bottom: 4px;
  border-bottom: 1px solid #ebebeb;
}

.checkout__totals-products td {
  padding: 4px 0;
}
.checkout__totals-products tr:first-child td {
  padding-top: 16px;
}
.checkout__totals-products tr:last-child td {
  padding-bottom: 16px;
}

.checkout__totals-subtotals th {
  font-weight: 500;
}
.checkout__totals-subtotals td,
.checkout__totals-subtotals th {
  padding: 4px 0;
}
.checkout__totals-subtotals tr:first-child td,
.checkout__totals-subtotals tr:first-child th {
  padding-top: 16px;
  border-top: 1px solid #ebebeb;
}
.checkout__totals-subtotals tr:last-child td,
.checkout__totals-subtotals tr:last-child th {
  padding-bottom: 16px;
}

.checkout__totals-footer {
  font-size: 24px;
}
.checkout__totals-footer th {
  font-weight: 500;
}
.checkout__totals-footer tr:first-child td,
.checkout__totals-footer tr:first-child th {
  padding-top: 20px;
  border-top: 1px solid #ebebeb;
}

.checkout__payment-methods {
  margin-bottom: 20px;
}

.checkout__agree {
  margin-bottom: 40px;
}
.checkout__agree a:hover {
  text-decoration: underline;
}

/*
// .compare
*/
.compare__options-list {
  padding: 16px 20px;
  border-bottom: 1px solid #ebebeb;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.compare__option {
  display: flex;
  align-items: center;
}
[dir=ltr] .compare__option + .compare__option {
  margin-left: 16px;
}
[dir=rtl] .compare__option + .compare__option {
  margin-right: 16px;
}

[dir=ltr] .compare__option-label {
  margin-right: 8px;
}
[dir=rtl] .compare__option-label {
  margin-left: 8px;
}

/*
// .compare-table
*/
.compare-table {
  width: 100%;
  font-size: 15px;
  border-spacing: 0;
}
.compare-table tr:not(:last-child) {
  border-bottom: 1px solid #ebebeb;
}

.compare-table__column--header,
.compare-table__column--product {
  padding: 14px 16px;
}

[dir=ltr] .compare-table__column--product,
[dir=ltr] .compare-table__column--fake {
  border-left: 1px solid #ebebeb;
}
[dir=rtl] .compare-table__column--product,
[dir=rtl] .compare-table__column--fake {
  border-right: 1px solid #ebebeb;
}

.compare-table__column--header {
  width: 15%;
  background: #f7f7f7;
  font-weight: 500;
  min-width: 140px;
}
[dir=ltr] .compare-table__column--header {
  text-align: right;
}
[dir=rtl] .compare-table__column--header {
  text-align: left;
}

.compare-table__column--product {
  width: 17%;
  text-align: center;
  vertical-align: top;
  min-width: 180px;
}

.compare-table__column--fake {
  padding: 0;
}

.compare-table__column--product:nth-child(6) + .compare-table__column--fake {
  display: none;
}

.compare-table__product {
  display: block;
  line-height: 20px;
  color: inherit;
  transition: color 0.12s;
}
.compare-table__product:hover {
  color: #007bff;
}

.compare-table__product-image {
  width: 150px;
  margin: 0 auto;
}

.compare-table__product-name {
  color: inherit;
}

.compare-table__rating-stars {
  display: flex;
  justify-content: center;
  padding-top: 1px;
}

.compare-table__rating-title {
  font-size: 13px;
  color: #6c757d;
  margin-top: 5px;
}

/*
// .order-header
*/
.order-header {
  padding: 1.625rem 2rem 1.375rem;
}
.order-header:after {
  display: block;
  content: "";
  clear: both;
}

.order-header__actions {
  margin-top: -1px;
}
[dir=ltr] .order-header__actions {
  float: right;
}
[dir=rtl] .order-header__actions {
  float: left;
}

.order-header__title {
  border-bottom: 2px solid #e5e5e5;
  padding-bottom: 4px;
  margin-bottom: 0;
}
[dir=ltr] .order-header__title {
  padding-right: 3.5rem;
  float: left;
}
[dir=rtl] .order-header__title {
  padding-left: 3.5rem;
  float: right;
}

.order-header__subtitle {
  color: #6c757d;
  font-size: 14px;
  line-height: 1.375;
  clear: both;
  padding-top: 12px;
}
.order-header__subtitle mark {
  padding: 0;
  color: #262626;
  background: transparent;
  font-weight: 500;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .order-header {
    padding: 1.375rem 1.5rem 1.25rem;
  }
}
@media (max-width: 767.98px) {
  .order-header {
    padding: 1.125rem 1.375rem 1rem;
  }
}
@media (max-width: 440px) {
  .order-header__actions {
    margin-bottom: 0.875rem;
  }
  [dir=ltr] .order-header__actions {
    float: none;
  }
  [dir=rtl] .order-header__actions {
    float: none;
  }
}
/*
// .order-list
*/
.order-list a {
  color: inherit;
}
.order-list a:hover {
  color: inherit;
  text-decoration: underline;
}
.order-list table {
  width: 100%;
  font-size: 15px;
}

.order-list__header th,
.order-list__header td {
  text-transform: uppercase;
  color: #6c757d;
  font-size: 13px;
  font-weight: 500;
}

.order-list__footer {
  font-size: 20px;
}
.order-list__footer th {
  font-weight: 500;
}

.order-list__column-image {
  width: 1px;
}
.order-list__column-image .image {
  width: 40px;
}

.order-list__column-product {
  line-height: 1.25;
}

.order-list__column-quantity {
  text-align: center;
}

[dir=ltr] .order-list__column-total {
  text-align: right;
}
[dir=rtl] .order-list__column-total {
  text-align: left;
}

.order-list__options {
  color: #6c757d;
  font-size: 13px;
  margin-top: 2px;
}

.order-list__options-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.order-list__options-item:not(:last-child) {
  position: relative;
}
[dir=ltr] .order-list__options-item:not(:last-child) {
  padding-right: 19px;
}
[dir=rtl] .order-list__options-item:not(:last-child) {
  padding-left: 19px;
}
.order-list__options-item:not(:last-child):after {
  position: absolute;
  content: "";
  display: block;
  background: #ccc;
  width: 1px;
  height: 10px;
  top: calc(50% - 5px);
  transform: skewX(-20deg);
}
[dir=ltr] .order-list__options-item:not(:last-child):after {
  right: 9px;
}
[dir=rtl] .order-list__options-item:not(:last-child):after {
  left: 9px;
}

@media (min-width: 576px) {
  .order-list th,
.order-list td {
    padding: 4px 12px;
  }
  [dir=ltr] .order-list th:first-child,
[dir=ltr] .order-list td:first-child {
    padding-left: 1.5rem;
  }
  [dir=rtl] .order-list th:first-child,
[dir=rtl] .order-list td:first-child {
    padding-right: 1.5rem;
  }
  [dir=ltr] .order-list th:last-child,
[dir=ltr] .order-list td:last-child {
    padding-right: 1.5rem;
  }
  [dir=rtl] .order-list th:last-child,
[dir=rtl] .order-list td:last-child {
    padding-left: 1.5rem;
  }

  .order-list__header th,
.order-list__header td {
    border-bottom: 1px solid #ebebeb;
    padding: 10px 12px;
  }

  .order-list__products th,
.order-list__products td {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .order-list__products tr:first-child th,
.order-list__products tr:first-child td {
    padding-top: 14px;
  }
  .order-list__products tr:last-child th,
.order-list__products tr:last-child td {
    padding-bottom: 14px;
  }
  [dir=ltr] .order-list__products .order-list__column-product {
    padding-left: 4px;
  }
  [dir=rtl] .order-list__products .order-list__column-product {
    padding-right: 4px;
  }

  .order-list__subtotals th,
.order-list__subtotals td {
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .order-list__subtotals tr:first-child th,
.order-list__subtotals tr:first-child td {
    padding-top: 12px;
    border-top: 1px solid #ebebeb;
  }
  .order-list__subtotals tr:last-child th,
.order-list__subtotals tr:last-child td {
    padding-bottom: 12px;
  }

  .order-list__footer th,
.order-list__footer td {
    padding-top: 14px;
    padding-bottom: 14px;
    border-top: 1px solid #ebebeb;
  }
}
@media (max-width: 575.98px) {
  .order-list table,
.order-list tr,
.order-list th,
.order-list td {
    display: block;
    padding: 0;
  }

  .order-list__header,
.order-list__products,
.order-list__subtotals,
.order-list__footer {
    display: block;
  }
  .order-list__header tr,
.order-list__products tr,
.order-list__subtotals tr,
.order-list__footer tr {
    padding: 0 1rem;
  }

  .order-list__header tr,
.order-list__subtotals tr,
.order-list__footer tr {
    display: flex;
  }
  .order-list__header .order-list__column-label,
.order-list__subtotals .order-list__column-label,
.order-list__footer .order-list__column-label {
    flex-grow: 1;
  }
  .order-list__header .order-list__column-quantity,
.order-list__subtotals .order-list__column-quantity,
.order-list__footer .order-list__column-quantity {
    display: none;
  }

  .order-list__header tr {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ebebeb;
  }

  .order-list__products tr {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    display: flex;
    flex-wrap: wrap;
  }
  .order-list__products tr:not(:first-child) {
    border-top: 1px solid #ebebeb;
  }
  .order-list__products .order-list__column-image {
    width: 40px;
    flex-shrink: 0;
  }
  .order-list__products .order-list__column-product {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 40px);
  }
  [dir=ltr] .order-list__products .order-list__column-product {
    padding-left: 1rem;
  }
  [dir=rtl] .order-list__products .order-list__column-product {
    padding-right: 1rem;
  }
  .order-list__products .order-list__column-quantity:before {
    content: attr(data-title) " ";
  }
  .order-list__products .order-list__column-total {
    flex-grow: 1;
  }
  .order-list__products .order-list__column-quantity,
.order-list__products .order-list__column-total {
    margin-top: 0.625rem;
    margin-bottom: -0.125rem;
  }

  .order-list__subtotals {
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid #ebebeb;
  }
  .order-list__subtotals th,
.order-list__subtotals td {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .order-list__footer {
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid #ebebeb;
  }
}
/*
// .order-success
*/
.order-success__body {
  max-width: 690px;
  margin: 0 auto;
}

.order-success__header {
  padding: 60px 0 52px;
  text-align: center;
}

.order-success__icon {
  fill: #e52727;
  margin: 0 auto;
  display: block;
}

.order-success__title {
  margin-top: 24px;
  margin-bottom: 2px;
}

.order-success__subtitle {
  font-size: 19px;
  font-weight: 500;
}

.order-success__actions {
  margin-top: 20px;
}

.order-success__meta {
  padding: 16px 0;
  margin-bottom: 20px;
  text-align: center;
}

.order-success__meta-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.order-success__meta-item {
  flex-basis: 25%;
  line-height: 1.25;
  position: relative;
  padding: 2px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.order-success__meta-item:not(:last-child):before {
  position: absolute;
  display: block;
  content: "";
  border-left: 2px dashed rgba(0, 0, 0, 0.1);
  width: 0;
  height: 100%;
  top: 0;
}
[dir=ltr] .order-success__meta-item:not(:last-child):before {
  right: -1px;
}
[dir=rtl] .order-success__meta-item:not(:last-child):before {
  left: -1px;
}

.order-success__meta-title {
  display: block;
  font-size: 13px;
  color: #6c757d;
  margin-bottom: 4px;
}

.order-success__meta-value {
  display: block;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}

.order-success__addresses {
  margin-left: -20px;
  display: flex;
}

.order-success__address {
  margin-left: 20px;
  margin-top: 20px;
  flex-basis: 0;
  flex-grow: 1;
}

@media (max-width: 767.98px) {
  .order-success__header {
    padding: 44px 0 40px;
  }

  .order-success__meta-list {
    flex-wrap: wrap;
  }

  .order-success__meta-item {
    flex-basis: 50%;
  }
  .order-success__meta-item:nth-child(2n):before {
    display: none;
  }
  .order-success__meta-item:nth-child(n+3) {
    margin-top: 16px;
  }

  .order-success__addresses {
    display: block;
  }
}
/*
// .payment-methods
*/
.payment-methods__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.payment-methods__item {
  border-radius: 2px;
  padding-bottom: 2px;
  border: 1px solid #ebebeb;
  transition: background 0.12s, border-color 0.12s;
}
.payment-methods__item + .payment-methods__item {
  margin-top: 6px;
}
.payment-methods__item:hover {
  background: #f5f5f5;
  border-color: #e5e5e5;
}

.payment-methods__item-header {
  display: flex;
  align-items: center;
  line-height: 18px;
  margin: 0;
  padding: 10px 12px 8px;
  cursor: pointer;
}

[dir=ltr] .payment-methods__item-radio {
  margin-right: 8px;
}
[dir=rtl] .payment-methods__item-radio {
  margin-left: 8px;
}

.payment-methods__item-details {
  font-size: 14px;
  line-height: 22px;
  padding: 0 12px 8px;
}

.payment-methods__item-container {
  overflow: hidden;
  opacity: 0;
  height: 0;
  transition: height 0.3s, opacity 0.3s;
}

.payment-methods__item--active, .payment-methods__item--active:hover {
  background: #f5f5f5;
  border-color: #e5e5e5;
}
.payment-methods__item--active .payment-methods__item-container {
  opacity: 1;
  height: auto;
}

/*
// .product
*/
.product__header h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
  margin: 0;
}

.product__tabs {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

.product__subtitle {
  padding-top: 8px;
  display: flex;
  align-items: center;
}

.product__rating {
  display: flex;
  color: #6c757d;
  font-size: 14px;
  line-height: 1;
}

[dir=ltr] .product__rating-stars {
  margin-right: 8px;
}
[dir=rtl] .product__rating-stars {
  margin-left: 8px;
}

.product__rating-label a {
  color: inherit;
}
.product__rating-label a:hover {
  text-decoration: underline;
}

[dir=ltr] .product__fit {
  margin-left: 12px;
}
[dir=rtl] .product__fit {
  margin-right: 12px;
}

.product__info-card {
  position: relative;
  width: 100%;
}

.product__info-body {
  padding: 28px 28px 24px;
}

.product__prices-stock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 22px;
}

.product__meta {
  margin: -3px 0;
}
.product__meta table {
  width: 100%;
  font-size: 14px;
}
.product__meta th,
.product__meta td {
  padding: 3px 0;
}
.product__meta th {
  font-weight: 500;
}
[dir=ltr] .product__meta th {
  padding-right: 12px;
}
[dir=rtl] .product__meta th {
  padding-left: 12px;
}
.product__meta tr + tr > * {
  border-top: 1px solid #ebebeb;
}
.product__meta a {
  color: #007bff;
}
.product__meta a:hover {
  text-decoration: underline;
}

.product__shop-features {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  margin-top: 30px;
}

.product__badge {
  top: -7px;
  position: absolute;
}
[dir=ltr] .product__badge {
  left: 22px;
}
[dir=rtl] .product__badge {
  right: 22px;
}

.product__prices {
  padding: 8px 0 7px;
  line-height: 1;
}

.product__price {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -0.04em;
  color: #262626;
}

.product__price--new {
  color: #262626;
}

.product__price--old {
  font-size: 16px;
  text-decoration: line-through;
  font-weight: 400;
  color: #999;
  margin-bottom: 5px;
}

.product__form {
  background: #f9f9f9;
  margin-bottom: 24px;
  margin-top: -4px;
  padding: 16px 28px 18px;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}

.product__actions {
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
  padding: 0 28px 24px;
  margin-top: -20px;
}

.product__actions-item--quantity {
  width: 100px;
}
[dir=ltr] .product__actions-item--quantity {
  margin-right: 8px;
}
[dir=rtl] .product__actions-item--quantity {
  margin-left: 8px;
}

.product__actions-item--addtocart {
  flex-grow: 1;
}

.product__actions-item--quantity,
.product__actions-item--addtocart {
  margin-top: 20px;
}

.product__actions-item--wishlist,
.product__actions-item--compare {
  position: relative;
  margin-top: 14px;
  margin-bottom: -8px;
  width: 50%;
  font-size: 13px;
  line-height: 16px;
  background: transparent;
  color: #262626;
  fill: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding-top: 7px;
  padding-bottom: 5px;
  border-radius: 2px;
  transition: background 0.15s, fill 0.15s;
}
[dir=ltr] .product__actions-item--wishlist,
[dir=ltr] .product__actions-item--compare {
  padding-left: 9px;
  padding-right: 10px;
}
[dir=rtl] .product__actions-item--wishlist,
[dir=rtl] .product__actions-item--compare {
  padding-right: 9px;
  padding-left: 10px;
}
.product__actions-item--wishlist svg,
.product__actions-item--compare svg {
  position: relative;
  top: -1px;
}
[dir=ltr] .product__actions-item--wishlist svg,
[dir=ltr] .product__actions-item--compare svg {
  margin-right: 8px;
}
[dir=rtl] .product__actions-item--wishlist svg,
[dir=rtl] .product__actions-item--compare svg {
  margin-left: 8px;
}
.product__actions-item--wishlist:focus,
.product__actions-item--compare:focus {
  outline: none;
}
.product__actions-item--wishlist:hover,
.product__actions-item--compare:hover {
  background: #f5f5f5;
  fill: #bfbfbf;
}
.product__actions-item--wishlist:active,
.product__actions-item--compare:active {
  background: #f0f0f0;
  fill: #b3b3b3;
}
.product__actions-item--wishlist.product__actions-item--loading,
.product__actions-item--compare.product__actions-item--loading {
  background: transparent;
  fill: transparent;
  cursor: default;
}
.product__actions-item--wishlist.product__actions-item--loading:before,
.product__actions-item--compare.product__actions-item--loading:before {
  left: calc(50% - 8px);
  top: calc(50% - 8px);
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
  position: static;
}
[dir=ltr] .product__actions-item--wishlist.product__actions-item--loading:before,
[dir=ltr] .product__actions-item--compare.product__actions-item--loading:before {
  margin-right: -16px;
}
[dir=rtl] .product__actions-item--wishlist.product__actions-item--loading:before,
[dir=rtl] .product__actions-item--compare.product__actions-item--loading:before {
  margin-left: -16px;
}

.product__actions-divider {
  width: 100%;
}

.product__tags-and-share-links {
  border-top: 1px solid #ebebeb;
  padding: 24px 28px;
}

.product__tags + .product__share-links {
  margin-top: 18px;
}

.product--layout--sidebar {
  --product-gutter: 30px;
  --product-gallery-image-max-width: 400px;
}
.product--layout--sidebar .product__body {
  display: grid;
  grid-template-columns: calc(100% - 370px) 370px;
  grid-template-rows: auto auto 1fr;
}
.product--layout--sidebar .product__card--one {
  grid-column: 1;
  grid-row: 1/3;
}
.product--layout--sidebar .product__header {
  grid-column: 1;
  grid-row: 1;
}
.product--layout--sidebar .product__gallery {
  grid-column: 1;
  grid-row: 2;
}
.product--layout--sidebar .product__tabs {
  grid-column: 1;
  grid-row: 3;
}
.product--layout--sidebar .product__info {
  grid-column: 2;
  grid-row: 1/4;
}
.product--layout--sidebar .product__card {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}
.product--layout--sidebar .product__card--two {
  display: none;
}
.product--layout--sidebar .product__gallery {
  padding: 0 40px 40px;
}
.product--layout--sidebar .product__header {
  padding: 36px 40px 16px;
}
[dir=ltr] .product--layout--sidebar .product__info {
  margin-left: var(--product-gutter);
}
[dir=rtl] .product--layout--sidebar .product__info {
  margin-right: var(--product-gutter);
}
.product--layout--sidebar .product__info-card {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}
.product--layout--sidebar .product__shop-features,
.product--layout--sidebar .product__tabs {
  margin-top: var(--product-gutter);
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  [dir=ltr] .product--layout--sidebar .product__title {
    padding-right: 80px;
  }
  [dir=rtl] .product--layout--sidebar .product__title {
    padding-left: 80px;
  }
  .product--layout--sidebar .product__actions-item--wishlist,
.product--layout--sidebar .product__actions-item--compare {
    position: absolute;
    top: 19px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    padding: 0;
    margin: 0;
  }
  [dir=ltr] .product--layout--sidebar .product__actions-item--wishlist,
[dir=ltr] .product--layout--sidebar .product__actions-item--compare {
    right: 16px;
  }
  [dir=rtl] .product--layout--sidebar .product__actions-item--wishlist,
[dir=rtl] .product--layout--sidebar .product__actions-item--compare {
    left: 16px;
  }
  .product--layout--sidebar .product__actions-item--wishlist span,
.product--layout--sidebar .product__actions-item--compare span {
    display: none;
  }
  .product--layout--sidebar .product__actions-item--wishlist svg,
.product--layout--sidebar .product__actions-item--compare svg {
    top: 0;
    display: block;
    margin: 0;
  }
  [dir=ltr] .product--layout--sidebar .product__actions-item--compare {
    right: 52px;
  }
  [dir=rtl] .product--layout--sidebar .product__actions-item--compare {
    left: 52px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  [dir=ltr] .product--layout--sidebar .product__info-body,
[dir=ltr] .product--layout--sidebar .product__form,
[dir=ltr] .product--layout--sidebar .product__actions,
[dir=ltr] .product--layout--sidebar .product__tags-and-share-links {
    padding-left: 20px;
    padding-right: 20px;
  }
  [dir=rtl] .product--layout--sidebar .product__info-body,
[dir=rtl] .product--layout--sidebar .product__form,
[dir=rtl] .product--layout--sidebar .product__actions,
[dir=rtl] .product--layout--sidebar .product__tags-and-share-links {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media (min-width: 576px) and (max-width: 1199.98px) {
  .product--layout--sidebar .product__meta table,
.product--layout--sidebar .product__meta th,
.product--layout--sidebar .product__meta td {
    display: block;
  }
  .product--layout--sidebar .product__meta tbody {
    display: flex;
    flex-wrap: wrap;
  }
  .product--layout--sidebar .product__meta tr,
.product--layout--sidebar .product__meta th {
    width: calc(50% - 10px);
  }
  .product--layout--sidebar .product__meta tr {
    display: flex;
  }
  [dir=ltr] .product--layout--sidebar .product__meta tr:nth-child(2n) {
    margin-left: 20px;
  }
  [dir=rtl] .product--layout--sidebar .product__meta tr:nth-child(2n) {
    margin-right: 20px;
  }
}
@media (max-width: 1199.98px) {
  .product--layout--sidebar .product__info-card {
    padding-top: 24px;
    padding-bottom: 28px;
  }
  [dir=ltr] .product--layout--sidebar .product__info-card {
    padding-left: 24px;
    padding-right: 24px;
  }
  [dir=rtl] .product--layout--sidebar .product__info-card {
    padding-right: 24px;
    padding-left: 24px;
  }
  .product--layout--sidebar .product__info-body {
    padding-top: 0;
    padding-bottom: 16px;
  }
  [dir=ltr] .product--layout--sidebar .product__info-body {
    padding-left: 0;
    padding-right: 0;
  }
  [dir=rtl] .product--layout--sidebar .product__info-body {
    padding-right: 0;
    padding-left: 0;
  }
  .product--layout--sidebar .product__prices-stock {
    padding-top: 0;
    padding-bottom: 10px;
  }
  [dir=ltr] .product--layout--sidebar .product__prices-stock {
    padding-left: 0;
    padding-right: 0;
  }
  [dir=rtl] .product--layout--sidebar .product__prices-stock {
    padding-right: 0;
    padding-left: 0;
  }
  .product--layout--sidebar .product__price {
    font-size: 26px;
  }
  .product--layout--sidebar .product__price--old {
    font-size: 16px;
  }
  .product--layout--sidebar .product__meta {
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    padding: 10px 0;
    margin: 8px 0;
  }
  .product--layout--sidebar .product__meta th,
.product--layout--sidebar .product__meta td {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .product--layout--sidebar .product__meta tr + tr > * {
    border: none;
  }
  .product--layout--sidebar .product__form {
    background: transparent;
    padding: 0;
    border: none;
    margin-bottom: 16px;
  }
  .product--layout--sidebar .product__actions {
    padding: 0;
  }
  .product--layout--sidebar .product__tags-and-share-links {
    border: none;
    padding: 20px 0 0;
  }
}
@media (max-width: 767.98px) {
  .product--layout--sidebar .product__actions {
    margin-bottom: -6px;
  }
  .product--layout--sidebar .product__actions-divider {
    height: 10px;
  }
  .product--layout--sidebar .product__actions-item--wishlist,
.product--layout--sidebar .product__actions-item--compare {
    width: auto;
    margin-top: 2px;
    margin-bottom: -2px;
  }
}
@media (max-width: 575.98px) {
  .product--layout--sidebar .product__subtitle {
    flex-direction: column;
    align-items: flex-start;
  }
  .product--layout--sidebar .product__fit {
    margin-bottom: 11px;
    order: -1;
  }
  [dir=ltr] .product--layout--sidebar .product__fit {
    margin-left: -4px;
  }
  [dir=rtl] .product--layout--sidebar .product__fit {
    margin-right: -4px;
  }
}
@media (max-width: 1399.98px) {
  .product--layout--sidebar {
    --product-gutter: 20px;
    --product-gallery-image-max-width: 360px;
  }
  .product--layout--sidebar .product__body {
    grid-template-rows: max-content;
    grid-template-columns: calc(100% - 332px) 332px;
  }
  .product--layout--sidebar .product__info {
    grid-column: 2;
    grid-row: 1/3;
  }
  .product--layout--sidebar .product__gallery {
    padding-top: 0;
    padding-bottom: 28px;
  }
  [dir=ltr] .product--layout--sidebar .product__gallery {
    padding-left: 28px;
    padding-right: 28px;
  }
  [dir=rtl] .product--layout--sidebar .product__gallery {
    padding-right: 28px;
    padding-left: 28px;
  }
  .product--layout--sidebar .product__header {
    padding-top: 24px;
    padding-bottom: 16px;
  }
  [dir=ltr] .product--layout--sidebar .product__header {
    padding-left: 28px;
    padding-right: 28px;
  }
  [dir=rtl] .product--layout--sidebar .product__header {
    padding-right: 28px;
    padding-left: 28px;
  }
  .product--layout--sidebar .product__shop-features {
    display: none;
  }
  .product--layout--sidebar .product__tabs {
    grid-column: 1/3;
  }
}
@media (max-width: 1199.98px) {
  .product--layout--sidebar {
    --product-gutter: 16px;
  }
  .product--layout--sidebar .product__body {
    grid-template-columns: 100%;
  }
  .product--layout--sidebar .product__info {
    grid-column: 1;
    grid-row: 3;
  }
  .product--layout--sidebar .product__tabs {
    grid-column: 1;
    grid-row: 4;
  }
  .product--layout--sidebar .product__card--one {
    display: none;
  }
  .product--layout--sidebar .product__header {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    padding-bottom: 24px;
  }
  .product--layout--sidebar .product__gallery {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    width: 100%;
    margin-top: var(--product-gutter);
    padding-top: 24px;
    padding-bottom: 28px;
  }
  [dir=ltr] .product--layout--sidebar .product__gallery {
    padding-left: 24px;
    padding-right: 24px;
  }
  [dir=rtl] .product--layout--sidebar .product__gallery {
    padding-right: 24px;
    padding-left: 24px;
  }
  .product--layout--sidebar .product__info {
    margin-top: var(--product-gutter);
  }
  [dir=ltr] .product--layout--sidebar .product__info {
    margin-left: 0;
  }
  [dir=rtl] .product--layout--sidebar .product__info {
    margin-right: 0;
  }
  .product--layout--sidebar .product__info-card {
    position: static;
  }
  .product--layout--sidebar .product__actions-item--addtocart .btn {
    width: auto;
  }
  .product--layout--sidebar .product__actions {
    margin-top: -12px;
  }
  .product--layout--sidebar .product__actions-item--quantity,
.product--layout--sidebar .product__actions-item--addtocart {
    margin-top: 10px;
  }
}
@media (max-width: 767.98px) {
  .product--layout--sidebar .product__header {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    padding-top: 24px;
    padding-bottom: 22px;
  }
  [dir=ltr] .product--layout--sidebar .product__header {
    padding-left: 24px;
    padding-right: 24px;
  }
  [dir=rtl] .product--layout--sidebar .product__header {
    padding-right: 24px;
    padding-left: 24px;
  }
  .product--layout--sidebar .product__title {
    font-size: 22px;
    line-height: 28px;
  }
}

.product--layout--full {
  --product-gutter: 30px;
}
.product--layout--full .product__body {
  display: grid;
  grid-template-columns: auto 1fr 370px;
  grid-template-rows: max-content auto auto 1fr;
}
.product--layout--full .product__card--one {
  grid-column: 1/3;
  grid-row: 1/4;
}
.product--layout--full .product__gallery {
  grid-column: 1;
  grid-row: 1/4;
}
.product--layout--full .product__header {
  grid-column: 2;
  grid-row: 1;
}
.product--layout--full .product__main {
  grid-column: 2;
  grid-row: 2;
}
.product--layout--full .product__info {
  grid-column: 3;
  grid-row: 1/5;
}
.product--layout--full .product__tabs {
  grid-column: 1/3;
  grid-row: 4;
}
.product--layout--full .product__card {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}
.product--layout--full .product__card--two {
  display: none;
}
.product--layout--full .product__gallery {
  padding-top: 36px;
  padding-bottom: 48px;
  width: 440px;
}
[dir=ltr] .product--layout--full .product__gallery {
  padding-left: 40px;
  padding-right: 0;
}
[dir=rtl] .product--layout--full .product__gallery {
  padding-right: 40px;
  padding-left: 0;
}
.product--layout--full .product__header {
  padding-top: 36px;
  padding-bottom: 16px;
}
[dir=ltr] .product--layout--full .product__header {
  padding-left: 36px;
  padding-right: 40px;
}
[dir=rtl] .product--layout--full .product__header {
  padding-right: 36px;
  padding-left: 40px;
}
.product--layout--full .product__main {
  padding-top: 0;
  padding-bottom: 48px;
}
[dir=ltr] .product--layout--full .product__main {
  padding-left: 36px;
  padding-right: 40px;
}
[dir=rtl] .product--layout--full .product__main {
  padding-right: 36px;
  padding-left: 40px;
}
[dir=ltr] .product--layout--full .product__info {
  margin-left: var(--product-gutter);
}
[dir=rtl] .product--layout--full .product__info {
  margin-right: var(--product-gutter);
}
.product--layout--full .product__shop-features,
.product--layout--full .product__tabs {
  margin-top: var(--product-gutter);
}
.product--layout--full .product__subtitle {
  padding-top: 8px;
}
.product--layout--full .product__gallery-intro {
  display: flex;
}
.product--layout--full .product__excerpt {
  font-size: 15px;
}
.product--layout--full .product__features {
  margin: 20px 0 0;
  font-size: 14px;
  border-radius: 3px;
}
.product--layout--full .product__features ul {
  list-style: none;
  padding: 0;
  color: #6c757d;
  margin: -6px;
  display: flex;
  flex-wrap: wrap;
}
.product--layout--full .product__features li {
  padding-top: 3px;
  padding-bottom: 2px;
  position: relative;
  margin: 0 6px;
  width: calc(100% / 1 - 12px);
}
[dir=ltr] .product--layout--full .product__features li {
  padding-left: 11px;
  padding-right: 0;
}
[dir=rtl] .product--layout--full .product__features li {
  padding-right: 11px;
  padding-left: 0;
}
.product--layout--full .product__features li:before {
  position: absolute;
  display: block;
  content: "";
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  background: currentColor;
  top: 12px;
  opacity: 0.85;
}
[dir=ltr] .product--layout--full .product__features li:before {
  left: 0;
}
[dir=rtl] .product--layout--full .product__features li:before {
  right: 0;
}
.product--layout--full .product__features-title {
  font-weight: 500;
  padding-bottom: 12px;
}
.product--layout--full .product__features-link {
  margin: 20px -2px 0;
  padding: 20px 2px 0;
  font-size: 14px;
  border-top: 1px solid #ebebeb;
}
.product--layout--full .product__features-link a:hover {
  text-decoration: underline;
}
@media (min-width: 1200px) {
  .product--layout--full .product__subtitle {
    flex-direction: column;
    align-items: flex-start;
  }
  .product--layout--full .product__fit {
    margin-bottom: 11px;
    order: -1;
  }
  [dir=ltr] .product--layout--full .product__fit {
    margin-left: -4px;
  }
  [dir=rtl] .product--layout--full .product__fit {
    margin-right: -4px;
  }
}
@media (min-width: 768px) {
  .product--layout--full .product__info-card {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .product--layout--full .product__gallery {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  }
  [dir=ltr] .product--layout--full .product__actions-item--addtocart .btn {
    padding-left: 16px;
    padding-right: 16px;
  }
  [dir=rtl] .product--layout--full .product__actions-item--addtocart .btn {
    padding-right: 16px;
    padding-left: 16px;
  }
}
@media (max-width: 575.98px) {
  .product--layout--full .product__subtitle {
    flex-direction: column;
    align-items: flex-start;
  }
  .product--layout--full .product__fit {
    margin-bottom: 11px;
    order: -1;
  }
  [dir=ltr] .product--layout--full .product__fit {
    margin-left: -4px;
  }
  [dir=rtl] .product--layout--full .product__fit {
    margin-right: -4px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  [dir=ltr] .product--layout--full .product__title {
    padding-right: 80px;
  }
  [dir=rtl] .product--layout--full .product__title {
    padding-left: 80px;
  }
  .product--layout--full .product__actions-item--wishlist,
.product--layout--full .product__actions-item--compare {
    position: absolute;
    top: 19px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    padding: 0;
    margin: 0;
  }
  [dir=ltr] .product--layout--full .product__actions-item--wishlist,
[dir=ltr] .product--layout--full .product__actions-item--compare {
    right: 16px;
  }
  [dir=rtl] .product--layout--full .product__actions-item--wishlist,
[dir=rtl] .product--layout--full .product__actions-item--compare {
    left: 16px;
  }
  .product--layout--full .product__actions-item--wishlist span,
.product--layout--full .product__actions-item--compare span {
    display: none;
  }
  .product--layout--full .product__actions-item--wishlist svg,
.product--layout--full .product__actions-item--compare svg {
    top: 0;
    display: block;
    margin: 0;
  }
  [dir=ltr] .product--layout--full .product__actions-item--compare {
    right: 52px;
  }
  [dir=rtl] .product--layout--full .product__actions-item--compare {
    left: 52px;
  }
}
@media (max-width: 1399.98px) {
  .product--layout--full {
    --product-gutter: 24px;
  }
  .product--layout--full .product__body {
    grid-template-columns: auto 1fr 336px;
  }
  .product--layout--full .product__gallery {
    padding-top: 24px;
    padding-bottom: 36px;
    width: 348px;
  }
  [dir=ltr] .product--layout--full .product__gallery {
    padding-left: 28px;
    padding-right: 0;
  }
  [dir=rtl] .product--layout--full .product__gallery {
    padding-right: 28px;
    padding-left: 0;
  }
  .product--layout--full .product__header {
    padding-top: 24px;
    padding-bottom: 16px;
  }
  [dir=ltr] .product--layout--full .product__header {
    padding-left: 28px;
    padding-right: 28px;
  }
  [dir=rtl] .product--layout--full .product__header {
    padding-right: 28px;
    padding-left: 28px;
  }
  .product--layout--full .product__main {
    padding-top: 0;
    padding-bottom: 36px;
  }
  [dir=ltr] .product--layout--full .product__main {
    padding-left: 28px;
    padding-right: 28px;
  }
  [dir=rtl] .product--layout--full .product__main {
    padding-right: 28px;
    padding-left: 28px;
  }
  [dir=ltr] .product--layout--full .product__info-body,
[dir=ltr] .product--layout--full .product__form,
[dir=ltr] .product--layout--full .product__actions,
[dir=ltr] .product--layout--full .product__tags-and-share-links {
    padding-left: 20px;
    padding-right: 20px;
  }
  [dir=rtl] .product--layout--full .product__info-body,
[dir=rtl] .product--layout--full .product__form,
[dir=rtl] .product--layout--full .product__actions,
[dir=rtl] .product--layout--full .product__tags-and-share-links {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media (max-width: 1199.98px) {
  .product--layout--full .product__body {
    grid-template-columns: auto 1fr 336px;
    grid-template-rows: max-content auto auto 1fr;
  }
  .product--layout--full .product__card {
    grid-column: 1/3;
    grid-row: 1/4;
  }
  .product--layout--full .product__gallery {
    grid-column: 1/3;
    grid-row: 3;
  }
  .product--layout--full .product__header {
    grid-column: 1/3;
    grid-row: 1;
  }
  .product--layout--full .product__main {
    grid-column: 1/3;
    grid-row: 2;
  }
  .product--layout--full .product__info {
    grid-column: 3;
    grid-row: 1/4;
  }
  .product--layout--full .product__tabs {
    grid-column: 1/4;
    grid-row: 4;
  }
  .product--layout--full .product__features {
    display: none;
  }
  .product--layout--full .product__main {
    padding-bottom: 16px;
  }
  .product--layout--full .product__excerpt {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 14px;
  }
  .product--layout--full .product__gallery {
    --product-gallery-image-max-width: 380px;
    padding-top: 0;
    width: 100%;
  }
  [dir=ltr] .product--layout--full .product__gallery {
    padding-right: 28px;
  }
  [dir=rtl] .product--layout--full .product__gallery {
    padding-left: 28px;
  }
  .product--layout--full .product__header {
    padding-bottom: 10px;
  }
  .product--layout--full .product__info {
    display: flex;
  }
  .product--layout--full .product__shop-features {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .product--layout--full {
    --product-gutter: 16px;
  }
  .product--layout--full .product__body {
    grid-template-columns: auto 1fr;
    grid-template-rows: max-content auto auto 1fr;
  }
  .product--layout--full .product__card {
    grid-column: 1/3;
    grid-row: 1/3;
  }
  .product--layout--full .product__header {
    grid-column: 1/3;
    grid-row: 1;
  }
  .product--layout--full .product__main {
    grid-column: 1/3;
    grid-row: 2;
  }
  .product--layout--full .product__gallery {
    grid-column: 1;
    grid-row: 3;
  }
  .product--layout--full .product__info {
    grid-column: 2;
    grid-row: 3;
  }
  .product--layout--full .product__gallery {
    margin-top: var(--product-gutter);
    width: 385px;
    padding-top: 24px;
    padding-bottom: 28px;
  }
  [dir=ltr] .product--layout--full .product__gallery {
    padding-left: 24px;
    padding-right: 24px;
  }
  [dir=rtl] .product--layout--full .product__gallery {
    padding-right: 24px;
    padding-left: 24px;
  }
  [dir=ltr] .product--layout--full .product__info {
    margin-left: var(--product-gutter);
  }
  [dir=rtl] .product--layout--full .product__info {
    margin-right: var(--product-gutter);
  }
  .product--layout--full .product__excerpt {
    border: none;
    padding-bottom: 8px;
  }
  .product--layout--full .product__info-card {
    position: static;
    margin-top: var(--product-gutter);
    padding-top: 24px;
    padding-bottom: 28px;
  }
  [dir=ltr] .product--layout--full .product__info-card {
    padding-left: 24px;
    padding-right: 24px;
  }
  [dir=rtl] .product--layout--full .product__info-card {
    padding-right: 24px;
    padding-left: 24px;
  }
  .product--layout--full .product__info-body {
    padding-top: 0;
    padding-bottom: 16px;
  }
  [dir=ltr] .product--layout--full .product__info-body {
    padding-left: 0px;
    padding-right: 0px;
  }
  [dir=rtl] .product--layout--full .product__info-body {
    padding-right: 0px;
    padding-left: 0px;
  }
  .product--layout--full .product__prices-stock {
    padding-top: 0;
    padding-bottom: 10px;
  }
  [dir=ltr] .product--layout--full .product__prices-stock {
    padding-left: 0px;
    padding-right: 0px;
  }
  [dir=rtl] .product--layout--full .product__prices-stock {
    padding-right: 0px;
    padding-left: 0px;
  }
  .product--layout--full .product__price {
    font-size: 26px;
  }
  .product--layout--full .product__price--old {
    font-size: 16px;
  }
  .product--layout--full .product__meta {
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    padding: 10px 0;
    margin: 8px 0;
  }
  .product--layout--full .product__meta th,
.product--layout--full .product__meta td {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .product--layout--full .product__meta tr + tr > * {
    border: none;
  }
  .product--layout--full .product__form {
    background: transparent;
    border: none;
    margin-bottom: 16px;
    padding-top: 0;
    padding-bottom: 0;
  }
  [dir=ltr] .product--layout--full .product__form {
    padding-left: 0;
    padding-right: 0;
  }
  [dir=rtl] .product--layout--full .product__form {
    padding-right: 0;
    padding-left: 0;
  }
  .product--layout--full .product__actions {
    padding-top: 0;
    padding-bottom: 0;
  }
  [dir=ltr] .product--layout--full .product__actions {
    padding-left: 0;
    padding-right: 0;
  }
  [dir=rtl] .product--layout--full .product__actions {
    padding-right: 0;
    padding-left: 0;
  }
  .product--layout--full .product__tags-and-share-links {
    border: none;
    padding-top: 20px;
    padding-bottom: 0;
  }
  [dir=ltr] .product--layout--full .product__tags-and-share-links {
    padding-left: 0;
    padding-right: 0;
  }
  [dir=rtl] .product--layout--full .product__tags-and-share-links {
    padding-right: 0;
    padding-left: 0;
  }
  .product--layout--full .product-gallery[data-layout=product-full] .product-gallery__zoom {
    top: -15px;
  }
  [dir=ltr] .product--layout--full .product-gallery[data-layout=product-full] .product-gallery__zoom {
    right: -15px;
  }
  [dir=rtl] .product--layout--full .product-gallery[data-layout=product-full] .product-gallery__zoom {
    left: -15px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .product--layout--full .product__meta table,
.product--layout--full .product__meta th,
.product--layout--full .product__meta td {
    display: block;
  }
  .product--layout--full .product__meta tbody {
    display: flex;
    flex-wrap: wrap;
  }
  .product--layout--full .product__meta tr,
.product--layout--full .product__meta th {
    width: calc(50% - 10px);
  }
  .product--layout--full .product__meta tr {
    display: flex;
  }
  [dir=ltr] .product--layout--full .product__meta tr:nth-child(2n) {
    margin-left: 20px;
  }
  [dir=rtl] .product--layout--full .product__meta tr:nth-child(2n) {
    margin-right: 20px;
  }
}
@media (max-width: 767.98px) {
  .product--layout--full .product__body {
    grid-template-columns: 100%;
    grid-template-rows: auto;
  }
  .product--layout--full .product__header {
    grid-column: 1;
    grid-row: 1;
  }
  .product--layout--full .product__gallery {
    grid-column: 1;
    grid-row: 2;
  }
  .product--layout--full .product__card--two {
    grid-column: 1;
    grid-row: 3/5;
  }
  .product--layout--full .product__main {
    grid-column: 1;
    grid-row: 3;
  }
  .product--layout--full .product__info {
    grid-column: 1;
    grid-row: 4;
  }
  .product--layout--full .product__tabs {
    grid-column: 1;
    grid-row: 5;
  }
  .product--layout--full .product__card--one {
    display: none;
  }
  .product--layout--full .product__card--two {
    display: block;
  }
  .product--layout--full .product__header {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    padding-top: 24px;
    padding-bottom: 22px;
  }
  [dir=ltr] .product--layout--full .product__header {
    padding-left: 24px;
    padding-right: 24px;
  }
  [dir=rtl] .product--layout--full .product__header {
    padding-right: 24px;
    padding-left: 24px;
  }
  .product--layout--full .product__title {
    font-size: 22px;
    line-height: 28px;
  }
  .product--layout--full .product__subtitle {
    padding-top: 8px;
  }
  .product--layout--full .product__body {
    width: 100%;
  }
  .product--layout--full .product__gallery {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    --product-gallery-image-max-width: 360px;
    width: 100%;
  }
  .product--layout--full .product__main,
.product--layout--full .product__card--two {
    margin-top: var(--product-gutter);
  }
  .product--layout--full .product__main {
    padding-top: 24px;
    padding-bottom: 18px;
  }
  [dir=ltr] .product--layout--full .product__main {
    padding-left: 24px;
    padding-right: 24px;
  }
  [dir=rtl] .product--layout--full .product__main {
    padding-right: 24px;
    padding-left: 24px;
  }
  .product--layout--full .product__excerpt {
    padding-bottom: 0;
  }
  .product--layout--full .product__info {
    width: 100%;
  }
  [dir=ltr] .product--layout--full .product__info {
    margin-left: 0;
  }
  [dir=rtl] .product--layout--full .product__info {
    margin-right: 0;
  }
  .product--layout--full .product__info-card {
    margin-top: 0;
    padding-top: 0;
  }
  .product--layout--full .product__actions {
    margin-top: -12px;
    margin-bottom: -6px;
  }
  .product--layout--full .product__actions-item--quantity,
.product--layout--full .product__actions-item--addtocart {
    margin-top: 10px;
  }
  .product--layout--full .product__actions-item--addtocart {
    flex-grow: 0;
  }
  .product--layout--full .product__actions-divider {
    height: 10px;
  }
  .product--layout--full .product__actions-item--wishlist,
.product--layout--full .product__actions-item--compare {
    width: auto;
    margin-top: 2px;
    margin-bottom: -2px;
  }
}

/*
// .product-card
*/
.product-card {
  display: block;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}
.product-card .product-card__action {
  position: relative;
  display: block;
  padding: 8px;
  margin: 0;
  border: none;
  transition: color 0.08s, background 0.08s;
  background-color: #fff;
  color: #ccc;
}
.product-card .product-card__action:hover {
  background-color: #f2f2f2;
  color: #4d4d4d;
}
.product-card .product-card__action:active {
  background-color: #ebebeb;
  color: #4d4d4d;
}
.product-card .product-card__action svg {
  fill: currentColor;
  display: block;
}
.product-card .product-card__action:focus {
  outline: none;
}
.product-card .product-card__action:active {
  transition-duration: 0s;
}
[dir=ltr] .product-card .product-card__action:last-child {
  border-bottom-left-radius: 2px;
}
[dir=rtl] .product-card .product-card__action:last-child {
  border-bottom-right-radius: 2px;
}
.product-card .product-card__action--loading, .product-card .product-card__action--loading:hover {
  cursor: default;
  background: transparent;
  color: transparent;
  transition-duration: 0s;
}
.product-card .product-card__action--loading:before {
  left: calc(50% - 8px);
  top: calc(50% - 8px);
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}
.product-card .product-card__image {
  display: block;
}
.product-card .product-card__rating {
  color: #6c757d;
  font-size: 0.8125rem;
}
.product-card .product-card__brand {
  color: #6c757d;
  font-size: 0.8125rem;
}
.product-card .product-card__code {
  color: #6c757d;
  font-size: 0.8125rem;
}
.product-card .product-card__features {
  color: #6c757d;
  font-size: 13px;
  line-height: 21px;
}
.product-card .product-card__features ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.product-card .product-card__features li {
  position: relative;
}
[dir=ltr] .product-card .product-card__features li {
  padding-left: 12px;
}
[dir=rtl] .product-card .product-card__features li {
  padding-right: 12px;
}
.product-card .product-card__features li:before {
  display: block;
  position: absolute;
  content: "";
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  background: currentColor;
  top: 9px;
  opacity: 0.7;
}
[dir=ltr] .product-card .product-card__features li:before {
  left: 3px;
}
[dir=rtl] .product-card .product-card__features li:before {
  right: 3px;
}
.product-card .product-card__name a {
  color: inherit;
}
.product-card .product-card__name a:hover {
  text-decoration: none;
}
.product-card .product-card__meta {
  font-size: 12px;
  line-height: 1;
  color: #999;
}
.product-card .product-card__prices {
  font-weight: 500;
  letter-spacing: -0.04em;
  line-height: 1;
  color: #262626;
}
.product-card .product-card__price--new {
  color: #262626;
}
.product-card .product-card__price--old {
  font-weight: 400;
  text-decoration: line-through;
  color: #999;
}
.product-card .product-card__addtocart-full {
  position: relative;
  background: #e52727;
  color: #fff;
  border: none;
  border-radius: 2px;
  font-weight: 500;
  transition: background-color 0.15s, color 0.15s;
}
.product-card .product-card__addtocart-full:focus {
  outline: none;
}
.product-card .product-card__addtocart-full:hover {
  background: #333;
  color: #fff;
}
.product-card .product-card__addtocart-full:active {
  background: #4d4d4d;
  color: #fff;
}
.product-card .product-card__addtocart-full.product-card__addtocart-full--loading {
  background: #e52727;
  color: transparent;
  cursor: default;
}
.product-card .product-card__addtocart-full.product-card__addtocart-full--loading:after {
  left: calc(50% - 11px);
  top: calc(50% - 11px);
  width: 22px;
  height: 22px;
  border-radius: 11px;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.1);
  border-top-color: rgba(255, 255, 255, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}
.product-card .product-card__wishlist,
.product-card .product-card__compare {
  position: relative;
  border: none;
  background: transparent;
  color: #262626;
  fill: #d9d9d9;
  border-radius: 2px;
  transition: background 0.15s, fill 0.15s;
}
.product-card .product-card__wishlist:focus,
.product-card .product-card__compare:focus {
  outline: none;
}
.product-card .product-card__wishlist:hover,
.product-card .product-card__compare:hover {
  background: #f5f5f5;
  fill: #bfbfbf;
}
.product-card .product-card__wishlist:active,
.product-card .product-card__compare:active {
  background: #f0f0f0;
  fill: #b3b3b3;
}
.product-card .product-card__wishlist.product-card__wishlist--loading,
.product-card .product-card__compare.product-card__compare--loading {
  background: transparent;
  fill: transparent;
  cursor: default;
}
.product-card .product-card__wishlist--loading:after,
.product-card .product-card__compare--loading:after {
  left: calc(50% - 8px);
  top: calc(50% - 8px);
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}
.product-card .product-card__addtocart-icon {
  position: relative;
  border: none;
  padding: 8px;
  border-radius: 2px;
  background: transparent;
  color: #ccc;
  z-index: 0;
  transition: color 0.2s;
}
.product-card .product-card__addtocart-icon svg {
  display: block;
  fill: currentColor;
}
.product-card .product-card__addtocart-icon:before {
  top: -2px;
  left: -2px;
  display: block;
  content: "";
  position: absolute;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background: transparent;
  z-index: -1;
  border-radius: 50%;
  transform: scale(0);
  transition: transform 0.2s, background 0.2s;
}
.product-card .product-card__addtocart-icon:focus {
  outline: none;
}
.product-card:hover .product-card__addtocart-icon, .product-card:active .product-card__addtocart-icon {
  color: #fff;
}
.product-card:hover .product-card__addtocart-icon:before, .product-card:active .product-card__addtocart-icon:before {
  transform: scale(1);
  background: #e52727;
}
.product-card:hover .product-card__addtocart-icon:hover, .product-card:active .product-card__addtocart-icon:hover {
  color: #fff;
}
.product-card:hover .product-card__addtocart-icon:hover:before, .product-card:active .product-card__addtocart-icon:hover:before {
  background: #333;
}
.product-card:hover .product-card__addtocart-icon:active, .product-card:active .product-card__addtocart-icon:active {
  color: #fff;
}
.product-card:hover .product-card__addtocart-icon:active:before, .product-card:active .product-card__addtocart-icon:active:before {
  transition-duration: 0.05s;
  background: #4d4d4d;
}
.product-card .product-card__addtocart-icon--loading {
  cursor: default;
}
.product-card .product-card__addtocart-icon--loading:after {
  left: calc(50% - 11px);
  top: calc(50% - 11px);
  width: 22px;
  height: 22px;
  border-radius: 11px;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.1);
  border-top-color: rgba(255, 255, 255, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}
.product-card .product-card__addtocart-icon--loading, .product-card .product-card__addtocart-icon--loading:hover, .product-card .product-card__addtocart-icon--loading:active, .product-card:hover .product-card__addtocart-icon--loading, .product-card:hover .product-card__addtocart-icon--loading:hover, .product-card:hover .product-card__addtocart-icon--loading:active, .product-card:active .product-card__addtocart-icon--loading, .product-card:active .product-card__addtocart-icon--loading:hover, .product-card:active .product-card__addtocart-icon--loading:active {
  color: transparent;
}
.product-card .product-card__addtocart-icon--loading:before, .product-card .product-card__addtocart-icon--loading:hover:before, .product-card .product-card__addtocart-icon--loading:active:before, .product-card:hover .product-card__addtocart-icon--loading:before, .product-card:hover .product-card__addtocart-icon--loading:hover:before, .product-card:hover .product-card__addtocart-icon--loading:active:before, .product-card:active .product-card__addtocart-icon--loading:before, .product-card:active .product-card__addtocart-icon--loading:hover:before, .product-card:active .product-card__addtocart-icon--loading:active:before {
  transform: scale(1);
  background: #e52727;
}

.product-card--layout--grid {
  display: flex;
  flex-direction: column;
  position: relative;
}
.product-card--layout--grid .product-card__addtocart-full,
.product-card--layout--grid .product-card__wishlist,
.product-card--layout--grid .product-card__compare {
  display: none;
}
.product-card--layout--grid:after {
  display: block;
  position: static;
  content: "";
  order: 1;
  flex-grow: 1;
}
.product-card--layout--grid .product-card__badges {
  position: absolute;
  top: 16px;
  display: flex;
  z-index: 1;
  flex-direction: column;
  align-items: flex-start;
}
[dir=ltr] .product-card--layout--grid .product-card__badges {
  left: 16px;
}
[dir=rtl] .product-card--layout--grid .product-card__badges {
  right: 16px;
}
.product-card--layout--grid .product-card__badges > * + * {
  margin-top: 3px;
}
.product-card--layout--grid .product-card__actions-list {
  position: absolute;
  overflow: hidden;
  z-index: 1;
}
[dir=ltr] .product-card--layout--grid .product-card__actions-list {
  right: 0;
}
[dir=rtl] .product-card--layout--grid .product-card__actions-list {
  left: 0;
}
.product-card--layout--grid .product-card__image {
  display: block;
  position: relative;
}
.product-card--layout--grid .product-card__fit {
  position: absolute;
  bottom: 8px;
}
.product-card--layout--grid .product-card__fit .status-badge__text {
  display: none;
}
.product-card--layout--grid .product-card__fit .status-badge__tooltip {
  display: block;
}
[dir=ltr] .product-card--layout--grid .product-card__fit {
  left: calc(16px - 3px);
}
[dir=rtl] .product-card--layout--grid .product-card__fit {
  right: calc(16px - 3px);
}
.product-card--layout--grid .product-card__meta {
  padding: 6px 16px;
}
.product-card--layout--grid .product-card__name {
  padding: 0 16px;
  line-height: 1.1875;
}
.product-card--layout--grid .product-card__rating {
  margin: -3px 0 -2px 0;
  padding: 8px 16px 0;
  display: flex;
  flex-wrap: wrap;
}
.product-card--layout--grid .product-card__rating-stars,
.product-card--layout--grid .product-card__rating-label {
  margin: 3px 0 2px 0;
}
[dir=ltr] .product-card--layout--grid .product-card__rating-stars {
  margin-right: 7px;
}
[dir=rtl] .product-card--layout--grid .product-card__rating-stars {
  margin-left: 7px;
}
.product-card--layout--grid .product-card__rating-label {
  line-height: 1;
}
.product-card--layout--grid .product-card__brand {
  margin: -3px 0 -2px 0;
  padding: 8px 16px 0;
  display: flex;
  flex-wrap: wrap;
}
.product-card--layout--grid .product-card__brand-stars,
.product-card--layout--grid .product-card__brand-label {
  margin: 3px 0 2px 0;
}
[dir=ltr] .product-card--layout--grid .product-card__brand-stars {
  margin-right: 7px;
}
[dir=rtl] .product-card--layout--grid .product-card__brand-stars {
  margin-left: 7px;
}
.product-card--layout--grid .product-card__brand-label {
  line-height: 1;
}
.product-card--layout--grid .product-card__code {
  margin: -3px 0 -2px 0;
  padding: 8px 16px 0;
  display: flex;
  flex-wrap: wrap;
}
.product-card--layout--grid .product-card__code-label {
  margin: 3px 0 2px 0;
}
.product-card--layout--grid .product-card__code-label {
  line-height: 1;
}
.product-card--layout--grid .product-card__features {
  padding: 10px 16px 0;
  margin-bottom: -2px;
  display: none;
}
.product-card--layout--grid .product-card__footer {
  padding: 16px;
  align-items: center;
  display: flex;
  order: 2;
}
.product-card--layout--grid .product-card__prices {
  flex-grow: 1;
  font-size: 18px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.product-card--layout--grid .product-card__price--old {
  font-size: 14px;
}
[dir=ltr] .product-card--layout--grid .product-card__price--old {
  margin-left: 6px;
}
[dir=rtl] .product-card--layout--grid .product-card__price--old {
  margin-right: 6px;
}
.product-card--layout--grid .product-card__addtocart-icon {
  margin: -3px;
}
@media (hover: hover) {
  .product-card--layout--grid .product-card__action--wishlist,
.product-card--layout--grid .product-card__action--compare {
    transition: transform 0.2s, opacity 0.2s, color 0.12s, background 0.12s;
    opacity: 0;
    will-change: transform;
  }
  [dir=ltr] .product-card--layout--grid .product-card__action--wishlist,
[dir=ltr] .product-card--layout--grid .product-card__action--compare {
    transform: translateX(100%) translateX(6px);
  }
  [dir=rtl] .product-card--layout--grid .product-card__action--wishlist,
[dir=rtl] .product-card--layout--grid .product-card__action--compare {
    transform: translateX(-100%) translateX(6px);
  }
  .product-card--layout--grid:hover .product-card__action--wishlist,
.product-card--layout--grid:hover .product-card__action--compare, .product-card--layout--grid:active .product-card__action--wishlist,
.product-card--layout--grid:active .product-card__action--compare {
    transform: none;
    opacity: 1;
  }
}

.product-card--layout--horizontal {
  position: relative;
  display: flex;
}
.product-card--layout--horizontal .product-card__action--wishlist,
.product-card--layout--horizontal .product-card__action--compare,
.product-card--layout--horizontal .product-card__features,
.product-card--layout--horizontal .product-card__addtocart-icon,
.product-card--layout--horizontal .product-card__addtocart-full,
.product-card--layout--horizontal .product-card__wishlist,
.product-card--layout--horizontal .product-card__compare,
.product-card--layout--horizontal .product-card__meta,
.product-card--layout--horizontal .product-card__fit {
  display: none;
}
.product-card--layout--horizontal .product-card__image {
  padding: 8px 10px;
  width: 112px;
  flex-shrink: 0;
}
.product-card--layout--horizontal .product-card__badges {
  position: absolute;
  top: 10px;
  display: flex;
  z-index: 1;
  flex-direction: column;
  align-items: flex-start;
}
[dir=ltr] .product-card--layout--horizontal .product-card__badges {
  left: 10px;
}
[dir=rtl] .product-card--layout--horizontal .product-card__badges {
  right: 10px;
}
.product-card--layout--horizontal .product-card__badges > * + * {
  margin-top: 3px;
}
.product-card--layout--horizontal .tag-badge {
  height: 16px;
  padding: 3px 11px 0;
}
.product-card--layout--horizontal .tag-badge:before {
  left: 3px;
  right: 3px;
}
[dir=ltr] .product-card--layout--horizontal .tag-badge:before {
  transform: skewX(-20deg);
}
[dir=rtl] .product-card--layout--horizontal .tag-badge:before {
  transform: skewX(20deg);
}
.product-card--layout--horizontal .product-card__actions-list {
  position: absolute;
}
[dir=ltr] .product-card--layout--horizontal .product-card__actions-list {
  right: 0;
}
[dir=rtl] .product-card--layout--horizontal .product-card__actions-list {
  left: 0;
}
.product-card--layout--horizontal .product-card__info {
  padding-top: 12px;
  padding-bottom: 40px;
}
[dir=ltr] .product-card--layout--horizontal .product-card__info {
  padding-right: 10px;
}
[dir=rtl] .product-card--layout--horizontal .product-card__info {
  padding-left: 10px;
}
.product-card--layout--horizontal .product-card__name {
  font-size: 15px;
  line-height: 18px;
  max-height: 36px;
  overflow: hidden;
}
[dir=ltr] .product-card--layout--horizontal .product-card__name {
  padding-right: 26px;
}
[dir=rtl] .product-card--layout--horizontal .product-card__name {
  padding-left: 26px;
}
.product-card--layout--horizontal .product-card__rating {
  margin: -3px 0 -2px 0;
  padding-top: 7px;
  display: flex;
  flex-wrap: wrap;
}
.product-card--layout--horizontal .product-card__rating-stars,
.product-card--layout--horizontal .product-card__rating-label {
  margin: 3px 0 2px 0;
}
[dir=ltr] .product-card--layout--horizontal .product-card__rating-stars {
  margin-right: 7px;
}
[dir=rtl] .product-card--layout--horizontal .product-card__rating-stars {
  margin-left: 7px;
}
.product-card--layout--horizontal .product-card__rating-label {
  line-height: 1;
}
.product-card--layout--horizontal .product-card__brand {
  margin: -3px 0 -2px 0;
  padding-top: 7px;
  display: flex;
  flex-wrap: wrap;
}
.product-card--layout--horizontal .product-card__brand-stars,
.product-card--layout--horizontal .product-card__brand-label {
  margin: 3px 0 2px 0;
}
[dir=ltr] .product-card--layout--horizontal .product-card__brand-stars {
  margin-right: 7px;
}
[dir=rtl] .product-card--layout--horizontal .product-card__brand-stars {
  margin-left: 7px;
}
.product-card--layout--horizontal .product-card__brand-label {
  line-height: 1;
}
.product-card--layout--horizontal .product-card__code {
  margin: -3px 0 -2px 0;
  padding-top: 7px;
  display: flex;
  flex-wrap: wrap;
}
.product-card--layout--horizontal .product-card__code-label {
  margin: 3px 0 2px 0;
}
.product-card--layout--horizontal .product-card__code-label {
  line-height: 1;
}
.product-card--layout--horizontal .product-card__prices {
  position: absolute;
  bottom: 14px;
  flex-grow: 1;
  font-size: 16px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
[dir=ltr] .product-card--layout--horizontal .product-card__prices {
  left: 112px;
}
[dir=rtl] .product-card--layout--horizontal .product-card__prices {
  right: 112px;
}
.product-card--layout--horizontal .product-card__price--old {
  font-size: 14px;
}
[dir=ltr] .product-card--layout--horizontal .product-card__price--old {
  margin-left: 6px;
}
[dir=rtl] .product-card--layout--horizontal .product-card__price--old {
  margin-right: 6px;
}

/*
// .product-form
*/
.product-form {
  display: block;
}

.product-form__row + .product-form__row {
  margin-top: 12px;
}

.product-form__title {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  color: #6c7177;
  margin-bottom: 4px;
}

/*
// .product-gallery
*/
.product-gallery__featured {
  position: relative;
}
.product-gallery__featured .owl-carousel a {
  display: block;
}
.product-gallery__featured .image {
  max-width: 420px;
  margin: 0 auto;
}

.product-gallery__zoom {
  display: var(--product-gallery-zoom-display, block);
  top: 0;
  position: absolute;
  padding: 9px;
  margin: 0;
  border: none;
  border-radius: 21px;
  fill: currentColor;
  z-index: 2;
  transition: background 0.15s, color 0.15s;
  background-color: #fff;
  color: #ccc;
}
.product-gallery__zoom:hover {
  background-color: #f2f2f2;
  color: #999;
}
.product-gallery__zoom:active {
  background-color: #ebebeb;
  color: #808080;
}
[dir=ltr] .product-gallery__zoom {
  right: -21px;
}
[dir=rtl] .product-gallery__zoom {
  left: -21px;
}
.product-gallery__zoom:focus {
  outline: none;
}
.product-gallery__zoom svg {
  display: block;
}

.product-gallery__thumbnails .owl-stage {
  margin: 0 auto;
}

.product-gallery__thumbnails-item {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 1.5px;
  padding: 2px;
  cursor: pointer;
}
.product-gallery__thumbnails-item:before {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 0 2px #f2f2f2 inset;
  transition: box-shadow 0.12s;
}
[dir=ltr] .product-gallery__thumbnails-item:before {
  left: 0;
}
[dir=rtl] .product-gallery__thumbnails-item:before {
  right: 0;
}
.product-gallery__thumbnails-item:hover:before {
  box-shadow: 0 0 0 2px #e0e0e0 inset;
}

.product-gallery__thumbnails-item--active {
  cursor: default;
}
.product-gallery__thumbnails-item--active:before, .product-gallery__thumbnails-item--active:hover:before {
  box-shadow: 0 0 0 2px #e52727 inset;
}

.product-gallery--layout--quickview .product-gallery__featured .image {
  width: 320px;
  max-width: 100%;
}
.product-gallery--layout--quickview .product-gallery__zoom {
  display: none;
}

.product-gallery--layout--product-sidebar .product-gallery__featured .image {
  max-width: var(--product-gallery-image-max-width, 100%);
}

.product-gallery--layout--product-full .product-gallery__featured .image {
  max-width: var(--product-gallery-image-max-width, 100%);
}
.product-gallery--layout--product-full .product-gallery__zoom {
  top: 0;
}
[dir=ltr] .product-gallery--layout--product-full .product-gallery__zoom {
  right: 0;
}
[dir=rtl] .product-gallery--layout--product-full .product-gallery__zoom {
  left: 0;
}

/*
// .product-tabs
*/
.product-tabs__list {
  display: flex;
  padding: 0;
  margin: 0 0 -1px;
  list-style: none;
  overflow-x: auto;
}

.product-tabs__content {
  border-top: 1px solid #ebebeb;
}

.product-tabs__item {
  position: relative;
}
.product-tabs__item a {
  font-size: 16px;
  font-weight: 500;
  color: inherit;
  display: flex;
  padding: 14px 18px 13px;
  transition: background 0.12s, box-shadow 0.12s;
}
.product-tabs__item a:hover {
  background: #f7f7f7;
  box-shadow: 0 -1px #e5e5e5 inset;
}
.product-tabs__item a:active {
  background: #f2f2f2;
  box-shadow: 0 -1px #e0e0e0 inset;
}
[dir=ltr] .product-tabs__item:first-child {
  margin-left: auto;
}
[dir=rtl] .product-tabs__item:first-child {
  margin-right: auto;
}
[dir=ltr] .product-tabs__item:last-child {
  margin-right: auto;
}
[dir=rtl] .product-tabs__item:last-child {
  margin-left: auto;
}

.product-tabs__item-counter {
  color: #e52727;
  font-size: 14px;
  top: -4px;
  position: relative;
}
[dir=ltr] .product-tabs__item-counter {
  margin-left: 3px;
  margin-right: -3px;
}
[dir=rtl] .product-tabs__item-counter {
  margin-right: 3px;
  margin-left: -3px;
}

.product-tabs__item--active a, .product-tabs__item--active a:hover, .product-tabs__item--active a:active {
  background: transparent;
  box-shadow: 0 -2px #e52727 inset;
}

.product-tabs__pane {
  display: block;
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: opacity 0.5s;
}

.product-tabs__pane--active {
  padding: 42px 48px 48px;
  overflow: visible;
  height: auto;
  opacity: 1;
  max-width: calc(100% - 0.001px);
}

.product-tabs--layout--full .product-tabs__pane--active {
  padding: 48px;
}
@media (max-width: 991.98px) {
  .product-tabs--layout--full .product-tabs__pane--active {
    padding: 40px 44px 44px;
  }
}
@media (max-width: 767.98px) {
  .product-tabs--layout--full .product-tabs__pane--active {
    padding: 24px 28px 28px;
  }
}
@media (max-width: 419px) {
  .product-tabs--layout--full .product-tabs__pane--active {
    padding: 20px 24px 24px;
  }
}

@media (min-width: 1400px) {
  .product-tabs--layout--sidebar .product-tabs__pane--active {
    padding: 36px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .product-tabs--layout--sidebar .product-tabs__pane--active {
    padding: 48px;
  }
}
@media (max-width: 1199.98px) {
  .product-tabs--layout--sidebar .product-tabs__pane--active {
    padding: 36px;
  }
}
@media (max-width: 767.98px) {
  .product-tabs--layout--sidebar .product-tabs__pane--active {
    padding: 28px;
  }
}
@media (max-width: 419px) {
  .product-tabs--layout--sidebar .product-tabs__pane--active {
    padding: 24px;
  }
}

/*
// .products-list
*/
.products-list__content {
  display: flex;
  flex-wrap: wrap;
}

.products-list__content + .products-list__content {
  margin-top: 10px;
}

.products-list__item {
  flex-shrink: 0;
  display: flex;
}
.products-list__item .product-card {
  width: 100%;
}

.products-list[data-layout=grid] .product-card {
  display: flex;
  flex-direction: column;
  position: relative;
}
.products-list[data-layout=grid] .product-card .product-card__addtocart-full,
.products-list[data-layout=grid] .product-card .product-card__wishlist,
.products-list[data-layout=grid] .product-card .product-card__compare {
  display: none;
}
.products-list[data-layout=grid] .product-card:after {
  display: block;
  position: static;
  content: "";
  order: 1;
  flex-grow: 1;
}
.products-list[data-layout=grid] .product-card .product-card__badges {
  position: absolute;
  top: 16px;
  display: flex;
  z-index: 1;
  flex-direction: column;
  align-items: flex-start;
}
[dir=ltr] .products-list[data-layout=grid] .product-card .product-card__badges {
  left: 16px;
}
[dir=rtl] .products-list[data-layout=grid] .product-card .product-card__badges {
  right: 16px;
}
.products-list[data-layout=grid] .product-card .product-card__badges > * + * {
  margin-top: 3px;
}
.products-list[data-layout=grid] .product-card .product-card__actions-list {
  position: absolute;
  overflow: hidden;
  z-index: 1;
}
[dir=ltr] .products-list[data-layout=grid] .product-card .product-card__actions-list {
  right: 0;
}
[dir=rtl] .products-list[data-layout=grid] .product-card .product-card__actions-list {
  left: 0;
}
.products-list[data-layout=grid] .product-card .product-card__image {
  display: block;
  position: relative;
}
.products-list[data-layout=grid] .product-card .product-card__fit {
  position: absolute;
  bottom: 8px;
}
.products-list[data-layout=grid] .product-card .product-card__fit .status-badge__text {
  display: none;
}
.products-list[data-layout=grid] .product-card .product-card__fit .status-badge__tooltip {
  display: block;
}
[dir=ltr] .products-list[data-layout=grid] .product-card .product-card__fit {
  left: calc(16px - 3px);
}
[dir=rtl] .products-list[data-layout=grid] .product-card .product-card__fit {
  right: calc(16px - 3px);
}
.products-list[data-layout=grid] .product-card .product-card__meta {
  padding: 6px 16px;
}
.products-list[data-layout=grid] .product-card .product-card__name {
  padding: 0 16px;
  line-height: 1.1875;
}
.products-list[data-layout=grid] .product-card .product-card__rating {
  margin: -3px 0 -2px 0;
  padding: 8px 16px 0;
  display: flex;
  flex-wrap: wrap;
}
.products-list[data-layout=grid] .product-card .product-card__rating-stars,
.products-list[data-layout=grid] .product-card .product-card__rating-label {
  margin: 3px 0 2px 0;
}
[dir=ltr] .products-list[data-layout=grid] .product-card .product-card__rating-stars {
  margin-right: 7px;
}
[dir=rtl] .products-list[data-layout=grid] .product-card .product-card__rating-stars {
  margin-left: 7px;
}
.products-list[data-layout=grid] .product-card .product-card__rating-label {
  line-height: 1;
}
.products-list[data-layout=grid] .product-card .product-card__brand {
  margin: -3px 0 -2px 0;
  padding: 8px 16px 0;
  display: flex;
  flex-wrap: wrap;
}
.products-list[data-layout=grid] .product-card .product-card__brand-stars,
.products-list[data-layout=grid] .product-card .product-card__brand-label {
  margin: 3px 0 2px 0;
}
[dir=ltr] .products-list[data-layout=grid] .product-card .product-card__brand-stars {
  margin-right: 7px;
}
[dir=rtl] .products-list[data-layout=grid] .product-card .product-card__brand-stars {
  margin-left: 7px;
}
.products-list[data-layout=grid] .product-card .product-card__brand-label {
  line-height: 1;
}
.products-list[data-layout=grid] .product-card .product-card__code {
  margin: -3px 0 -2px 0;
  padding: 8px 16px 0;
  display: flex;
  flex-wrap: wrap;
}
.products-list[data-layout=grid] .product-card .product-card__code-label {
  margin: 3px 0 2px 0;
}
.products-list[data-layout=grid] .product-card .product-card__code-label {
  line-height: 1;
}
.products-list[data-layout=grid] .product-card .product-card__features {
  padding: 10px 16px 0;
  margin-bottom: -2px;
  display: none;
}
.products-list[data-layout=grid] .product-card .product-card__footer {
  padding: 16px;
  align-items: center;
  display: flex;
  order: 2;
}
.products-list[data-layout=grid] .product-card .product-card__prices {
  flex-grow: 1;
  font-size: 18px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.products-list[data-layout=grid] .product-card .product-card__price--old {
  font-size: 14px;
}
[dir=ltr] .products-list[data-layout=grid] .product-card .product-card__price--old {
  margin-left: 6px;
}
[dir=rtl] .products-list[data-layout=grid] .product-card .product-card__price--old {
  margin-right: 6px;
}
.products-list[data-layout=grid] .product-card .product-card__addtocart-icon {
  margin: -3px;
}
@media (hover: hover) {
  .products-list[data-layout=grid] .product-card .product-card__action--wishlist,
.products-list[data-layout=grid] .product-card .product-card__action--compare {
    transition: transform 0.2s, opacity 0.2s, color 0.12s, background 0.12s;
    opacity: 0;
    will-change: transform;
  }
  [dir=ltr] .products-list[data-layout=grid] .product-card .product-card__action--wishlist,
[dir=ltr] .products-list[data-layout=grid] .product-card .product-card__action--compare {
    transform: translateX(100%) translateX(6px);
  }
  [dir=rtl] .products-list[data-layout=grid] .product-card .product-card__action--wishlist,
[dir=rtl] .products-list[data-layout=grid] .product-card .product-card__action--compare {
    transform: translateX(-100%) translateX(6px);
  }
  .products-list[data-layout=grid] .product-card:hover .product-card__action--wishlist,
.products-list[data-layout=grid] .product-card:hover .product-card__action--compare, .products-list[data-layout=grid] .product-card:active .product-card__action--wishlist,
.products-list[data-layout=grid] .product-card:active .product-card__action--compare {
    transform: none;
    opacity: 1;
  }
}

.products-list--grid--3[data-layout=grid] .products-list__content {
  margin: -10px;
}
.products-list--grid--3[data-layout=grid] .products-list__item {
  margin: 10px;
  width: calc((100% - 60px) / 3);
}
.products-list--grid--3[data-layout=grid] .products-list__head {
  display: none;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .products-list--grid--3[data-layout=grid] .products-list__content {
    margin: -8px;
  }
  .products-list--grid--3[data-layout=grid] .products-list__item {
    margin: 8px;
    width: calc((100% - 48px) / 3);
  }
}
@media (min-width: 420px) and (max-width: 767px) {
  .products-list--grid--3[data-layout=grid] .products-list__content {
    margin: -8px;
  }
  .products-list--grid--3[data-layout=grid] .products-list__item {
    margin: 8px;
    width: calc((100% - 32px) / 2);
  }
}
@media (max-width: 419px) {
  .products-list--grid--3[data-layout=grid] .products-list__content {
    margin: -8px;
  }
  .products-list--grid--3[data-layout=grid] .products-list__item {
    margin: 8px;
    width: calc((100% - 16px) / 1);
  }
}

.products-list--grid--4[data-layout=grid] .products-list__content {
  margin: -10px;
}
.products-list--grid--4[data-layout=grid] .products-list__item {
  margin: 10px;
  width: calc((100% - 80px) / 4);
}
.products-list--grid--4[data-layout=grid] .products-list__head {
  display: none;
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .products-list--grid--4[data-layout=grid] .products-list__content {
    margin: -8px;
  }
  .products-list--grid--4[data-layout=grid] .products-list__item {
    margin: 8px;
    width: calc((100% - 48px) / 3);
  }
}
@media (min-width: 420px) and (max-width: 767px) {
  .products-list--grid--4[data-layout=grid] .products-list__content {
    margin: -8px;
  }
  .products-list--grid--4[data-layout=grid] .products-list__item {
    margin: 8px;
    width: calc((100% - 32px) / 2);
  }
}
@media (max-width: 419px) {
  .products-list--grid--4[data-layout=grid] .products-list__content {
    margin: -8px;
  }
  .products-list--grid--4[data-layout=grid] .products-list__item {
    margin: 8px;
    width: calc((100% - 16px) / 1);
  }
}

.products-list--grid--5[data-layout=grid] .products-list__content {
  margin: -10px;
}
.products-list--grid--5[data-layout=grid] .products-list__item {
  margin: 10px;
  width: calc((100% - 100px) / 5);
}
.products-list--grid--5[data-layout=grid] .products-list__head {
  display: none;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .products-list--grid--5[data-layout=grid] .products-list__content {
    margin: -8px;
  }
  .products-list--grid--5[data-layout=grid] .products-list__item {
    margin: 8px;
    width: calc((100% - 64px) / 4);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .products-list--grid--5[data-layout=grid] .products-list__content {
    margin: -8px;
  }
  .products-list--grid--5[data-layout=grid] .products-list__item {
    margin: 8px;
    width: calc((100% - 48px) / 3);
  }
}
@media (min-width: 420px) and (max-width: 767px) {
  .products-list--grid--5[data-layout=grid] .products-list__content {
    margin: -8px;
  }
  .products-list--grid--5[data-layout=grid] .products-list__item {
    margin: 8px;
    width: calc((100% - 32px) / 2);
  }
}
@media (max-width: 419px) {
  .products-list--grid--5[data-layout=grid] .products-list__content {
    margin: -8px;
  }
  .products-list--grid--5[data-layout=grid] .products-list__item {
    margin: 8px;
    width: calc((100% - 16px) / 1);
  }
}

.products-list--grid--6[data-layout=grid] .products-list__content {
  margin: -8px;
}
.products-list--grid--6[data-layout=grid] .products-list__item {
  margin: 8px;
  width: calc((100% - 96px) / 6);
}
.products-list--grid--6[data-layout=grid] .products-list__head {
  display: none;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .products-list--grid--6[data-layout=grid] .products-list__content {
    margin: -10px;
  }
  .products-list--grid--6[data-layout=grid] .products-list__item {
    margin: 10px;
    width: calc((100% - 100px) / 5);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .products-list--grid--6[data-layout=grid] .products-list__content {
    margin: -10px;
  }
  .products-list--grid--6[data-layout=grid] .products-list__item {
    margin: 10px;
    width: calc((100% - 80px) / 4);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .products-list--grid--6[data-layout=grid] .products-list__content {
    margin: -8px;
  }
  .products-list--grid--6[data-layout=grid] .products-list__item {
    margin: 8px;
    width: calc((100% - 48px) / 3);
  }
}
@media (min-width: 420px) and (max-width: 767px) {
  .products-list--grid--6[data-layout=grid] .products-list__content {
    margin: -8px;
  }
  .products-list--grid--6[data-layout=grid] .products-list__item {
    margin: 8px;
    width: calc((100% - 32px) / 2);
  }
}
@media (max-width: 419px) {
  .products-list--grid--6[data-layout=grid] .products-list__content {
    margin: -8px;
  }
  .products-list--grid--6[data-layout=grid] .products-list__item {
    margin: 8px;
    width: calc((100% - 16px) / 1);
  }
}

.products-list[data-with-features=true] .product-card .product-card__features {
  display: block;
}

.products-list[data-layout=list] .products-list__content {
  margin: -10px;
}
.products-list[data-layout=list] .products-list__item {
  margin: 10px;
  width: calc((100% - 20px) / 1);
}
.products-list[data-layout=list] .products-list__head {
  display: none;
}
.products-list[data-layout=list] .product-card {
  display: flex;
  position: relative;
}
.products-list[data-layout=list] .product-card .product-card__addtocart-icon,
.products-list[data-layout=list] .product-card .product-card__action--wishlist,
.products-list[data-layout=list] .product-card .product-card__action--compare {
  display: none;
}
.products-list[data-layout=list] .product-card .product-card__image {
  flex-shrink: 0;
}
.products-list[data-layout=list] .product-card .product-card__badges {
  position: absolute;
  display: flex;
  z-index: 1;
  flex-direction: column;
  align-items: flex-start;
}
.products-list[data-layout=list] .product-card .product-card__badges > * + * {
  margin-top: 3px;
}
.products-list[data-layout=list] .product-card .product-card__actions-list {
  position: absolute;
  overflow: hidden;
  z-index: 1;
}
[dir=ltr] .products-list[data-layout=list] .product-card .product-card__actions-list {
  right: 0;
}
[dir=rtl] .products-list[data-layout=list] .product-card .product-card__actions-list {
  left: 0;
}
.products-list[data-layout=list] .product-card .product-card__rating {
  margin: -3px 0 -2px 0;
  padding: 8px 0 0;
  display: flex;
  flex-wrap: wrap;
}
.products-list[data-layout=list] .product-card .product-card__rating-stars,
.products-list[data-layout=list] .product-card .product-card__rating-label {
  margin: 3px 0 2px 0;
}
[dir=ltr] .products-list[data-layout=list] .product-card .product-card__rating-stars {
  margin-right: 7px;
}
[dir=rtl] .products-list[data-layout=list] .product-card .product-card__rating-stars {
  margin-left: 7px;
}
.products-list[data-layout=list] .product-card .product-card__rating-label {
  line-height: 1;
}
.products-list[data-layout=list] .product-card .product-card__brand {
  margin: -3px 0 -2px 0;
  padding: 8px 0 0;
  display: flex;
  flex-wrap: wrap;
}
.products-list[data-layout=list] .product-card .product-card__brand-stars,
.products-list[data-layout=list] .product-card .product-card__brand-label {
  margin: 3px 0 2px 0;
}
[dir=ltr] .products-list[data-layout=list] .product-card .product-card__brand-stars {
  margin-right: 7px;
}
[dir=rtl] .products-list[data-layout=list] .product-card .product-card__brand-stars {
  margin-left: 7px;
}
.products-list[data-layout=list] .product-card .product-card__brand-label {
  line-height: 1;
}
.products-list[data-layout=list] .product-card .product-card__code {
  margin: -3px 0 -2px 0;
  padding: 8px 0 0;
  display: flex;
  flex-wrap: wrap;
}
.products-list[data-layout=list] .product-card .product-card__code-label {
  margin: 3px 0 2px 0;
}
.products-list[data-layout=list] .product-card .product-card__code-label {
  line-height: 1;
}
.products-list[data-layout=list] .product-card .product-card__fit .status-badge__text {
  display: block;
}
.products-list[data-layout=list] .product-card .product-card__fit .status-badge__tooltip {
  display: none;
}
@media (min-width: 768px) {
  .products-list[data-layout=list] .product-card .product-card__image {
    padding: 16px 24px;
    width: 260px;
  }
  .products-list[data-layout=list] .product-card .product-card__badges {
    top: 16px;
  }
  [dir=ltr] .products-list[data-layout=list] .product-card .product-card__badges {
    left: 16px;
  }
  [dir=rtl] .products-list[data-layout=list] .product-card .product-card__badges {
    right: 16px;
  }
  .products-list[data-layout=list] .product-card .product-card__fit {
    position: absolute;
    bottom: 24px;
  }
  [dir=ltr] .products-list[data-layout=list] .product-card .product-card__fit {
    left: 258px;
  }
  [dir=rtl] .products-list[data-layout=list] .product-card .product-card__fit {
    right: 258px;
  }
  .products-list[data-layout=list] .product-card .product-card__info {
    padding-top: 20px;
    padding-bottom: 57px;
    flex-grow: 1;
  }
  [dir=ltr] .products-list[data-layout=list] .product-card .product-card__info {
    padding-left: 0;
    padding-right: 20px;
  }
  [dir=rtl] .products-list[data-layout=list] .product-card .product-card__info {
    padding-right: 0;
    padding-left: 20px;
  }
  .products-list[data-layout=list] .product-card .product-card__meta {
    position: absolute;
    bottom: 0;
    padding: 0;
    height: 38px;
    line-height: 36px;
    border-top: 1px solid #ebebeb;
    text-align: center;
    width: 180px;
  }
  [dir=ltr] .products-list[data-layout=list] .product-card .product-card__meta {
    right: 20px;
  }
  [dir=rtl] .products-list[data-layout=list] .product-card .product-card__meta {
    left: 20px;
  }
  .products-list[data-layout=list] .product-card .product-card__name {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
  .products-list[data-layout=list] .product-card .product-card__features {
    font-size: 14px;
    padding-top: 10px;
  }
  .products-list[data-layout=list] .product-card .product-card__features li + li {
    margin-top: 2px;
  }
  .products-list[data-layout=list] .product-card .product-card__footer {
    width: 220px;
    align-items: center;
    display: flex;
    order: 2;
    flex-shrink: 0;
    flex-direction: column;
    padding: 0 22px 49px;
  }
  [dir=ltr] .products-list[data-layout=list] .product-card .product-card__footer {
    border-left: 1px solid #ebebeb;
  }
  [dir=rtl] .products-list[data-layout=list] .product-card .product-card__footer {
    border-right: 1px solid #ebebeb;
  }
  .products-list[data-layout=list] .product-card .product-card__prices {
    padding: 28px 0;
    font-size: 20px;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: center;
  }
  .products-list[data-layout=list] .product-card .product-card__price--old {
    font-size: 15px;
  }
  [dir=ltr] .products-list[data-layout=list] .product-card .product-card__price--old {
    margin-left: 6px;
  }
  [dir=rtl] .products-list[data-layout=list] .product-card .product-card__price--old {
    margin-right: 6px;
  }
  .products-list[data-layout=list] .product-card .product-card__addtocart-full {
    display: block;
    height: 36px;
    padding: 0 20px;
    width: 100%;
    margin-bottom: 10px;
  }
  .products-list[data-layout=list] .product-card .product-card__wishlist,
.products-list[data-layout=list] .product-card .product-card__compare {
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 10px 5px;
  }
  .products-list[data-layout=list] .product-card .product-card__wishlist svg,
.products-list[data-layout=list] .product-card .product-card__compare svg {
    position: relative;
    top: -1px;
  }
  [dir=ltr] .products-list[data-layout=list] .product-card .product-card__wishlist svg,
[dir=ltr] .products-list[data-layout=list] .product-card .product-card__compare svg {
    margin-right: 8px;
  }
  [dir=rtl] .products-list[data-layout=list] .product-card .product-card__wishlist svg,
[dir=rtl] .products-list[data-layout=list] .product-card .product-card__compare svg {
    margin-left: 8px;
  }
  [dir=ltr] .products-list[data-layout=list] .product-card .product-card__wishlist svg {
    margin-left: -3px;
    margin-right: 7px;
  }
  [dir=rtl] .products-list[data-layout=list] .product-card .product-card__wishlist svg {
    margin-right: -3px;
    margin-left: 7px;
  }
  [dir=ltr] .products-list[data-layout=list] .product-card .product-card__compare svg {
    margin-left: -2px;
  }
  [dir=rtl] .products-list[data-layout=list] .product-card .product-card__compare svg {
    margin-right: -2px;
  }
  [dir=ltr] .products-list[data-layout=list] .product-card .product-card__wishlist--loading:after {
    left: 7px;
    right: auto;
  }
  [dir=rtl] .products-list[data-layout=list] .product-card .product-card__wishlist--loading:after {
    right: 7px;
    left: auto;
  }
  [dir=ltr] .products-list[data-layout=list] .product-card .product-card__compare--loading:after {
    left: 8px;
    right: auto;
  }
  [dir=rtl] .products-list[data-layout=list] .product-card .product-card__compare--loading:after {
    right: 8px;
    left: auto;
  }
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .products-list[data-layout=list] .product-card .product-card__image {
    width: 192px;
    padding-left: 16px;
    padding-right: 16px;
  }
  [dir=ltr] .products-list[data-layout=list] .product-card .product-card__fit {
    left: 190px;
  }
  [dir=rtl] .products-list[data-layout=list] .product-card .product-card__fit {
    right: 190px;
  }
  .products-list[data-layout=list] .product-card .product-card__footer {
    width: 180px;
    padding: 0 16px 49px;
  }
  .products-list[data-layout=list] .product-card .product-card__prices {
    padding: 24px 0;
  }
  .products-list[data-layout=list] .product-card .product-card__meta {
    position: absolute;
    bottom: 0;
    width: 148px;
  }
  [dir=ltr] .products-list[data-layout=list] .product-card .product-card__meta {
    right: 16px;
  }
  [dir=rtl] .products-list[data-layout=list] .product-card .product-card__meta {
    left: 16px;
  }
  .products-list[data-layout=list] .product-card .product-card__wishlist,
.products-list[data-layout=list] .product-card .product-card__compare {
    margin-top: 1px;
  }
}
@media (max-width: 767.98px) {
  .products-list[data-layout=list] .product-card {
    flex-direction: column;
  }
  .products-list[data-layout=list] .product-card .product-card__image {
    padding: 20px;
    width: 100%;
    display: flex;
    position: relative;
  }
  .products-list[data-layout=list] .product-card .product-card__image .image {
    width: 180px;
    max-width: 100%;
  }
  .products-list[data-layout=list] .product-card .product-card__fit {
    position: absolute;
    bottom: 14px;
    display: flex;
  }
  [dir=ltr] .products-list[data-layout=list] .product-card .product-card__fit {
    left: 15px;
    right: 15px;
  }
  [dir=rtl] .products-list[data-layout=list] .product-card .product-card__fit {
    right: 15px;
    left: 15px;
  }
  .products-list[data-layout=list] .product-card .product-card__badges {
    top: 16px;
  }
  [dir=ltr] .products-list[data-layout=list] .product-card .product-card__badges {
    left: 16px;
  }
  [dir=rtl] .products-list[data-layout=list] .product-card .product-card__badges {
    right: 16px;
  }
  .products-list[data-layout=list] .product-card .product-card__info {
    padding: 0 20px 20px;
  }
  .products-list[data-layout=list] .product-card .product-card__features {
    padding-top: 12px;
  }
  .products-list[data-layout=list] .product-card .product-card__footer {
    border-top: 1px solid #ebebeb;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 58px;
  }
  .products-list[data-layout=list] .product-card .product-card__prices {
    padding-top: 20px;
    padding-bottom: 16px;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
  }
  .products-list[data-layout=list] .product-card .product-card__price--old {
    font-size: 15px;
  }
  [dir=ltr] .products-list[data-layout=list] .product-card .product-card__price--old {
    margin-left: 6px;
  }
  [dir=rtl] .products-list[data-layout=list] .product-card .product-card__price--old {
    margin-right: 6px;
  }
  .products-list[data-layout=list] .product-card .product-card__addtocart-full {
    height: 30px;
    padding: 0 16px;
    font-size: 15px;
  }
  [dir=ltr] .products-list[data-layout=list] .product-card .product-card__addtocart-full {
    margin-right: 8px;
  }
  [dir=rtl] .products-list[data-layout=list] .product-card .product-card__addtocart-full {
    margin-left: 8px;
  }
  .products-list[data-layout=list] .product-card .product-card__addtocart-full.product-card__addtocart-full--loading:after {
    left: calc(50% - 9px);
    top: calc(50% - 9px);
    width: 18px;
    height: 18px;
    border-radius: 9px;
  }
  .products-list[data-layout=list] .product-card .product-card__wishlist,
.products-list[data-layout=list] .product-card .product-card__compare {
    height: 30px;
    width: 32px;
    padding: 0;
  }
  [dir=ltr] .products-list[data-layout=list] .product-card .product-card__wishlist svg,
[dir=ltr] .products-list[data-layout=list] .product-card .product-card__compare svg {
    margin-left: 0;
    margin-right: 0;
  }
  [dir=rtl] .products-list[data-layout=list] .product-card .product-card__wishlist svg,
[dir=rtl] .products-list[data-layout=list] .product-card .product-card__compare svg {
    margin-right: 0;
    margin-left: 0;
  }
  .products-list[data-layout=list] .product-card .product-card__wishlist span,
.products-list[data-layout=list] .product-card .product-card__compare span {
    display: none;
  }
  .products-list[data-layout=list] .product-card .product-card__meta {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 20px;
    height: 38px;
    line-height: 36px;
    border-top: 1px solid #ebebeb;
  }
  .products-list[data-layout=list] .product-card .product-card__wishlist--loading:after,
.products-list[data-layout=list] .product-card .product-card__compare--loading:after {
    left: calc(50% - 9px);
    top: calc(50% - 9px);
    width: 18px;
    height: 18px;
    border-radius: 9px;
  }
}

.products-list[data-layout=table] .product-card {
  background: transparent;
  box-shadow: none;
}
.products-list[data-layout=table] .products-list__item {
  width: 100%;
}
.products-list[data-layout=table] .products-list__head {
  display: flex;
  border-bottom: 1px solid #ebebeb;
  padding: 10px 0;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
}
.products-list[data-layout=table] .products-list__head:after {
  display: block;
  content: "";
  width: 36px;
  margin: 0 16px;
}
.products-list[data-layout=table] .products-list__column--image {
  width: 90px;
  box-sizing: content-box;
  text-align: center;
  padding: 0 16px;
}
.products-list[data-layout=table] .products-list__column--meta {
  width: 140px;
  text-align: center;
  padding: 0 16px;
}
[dir=ltr] .products-list[data-layout=table] .products-list__column--meta {
  margin-left: 1px;
}
[dir=rtl] .products-list[data-layout=table] .products-list__column--meta {
  margin-right: 1px;
}
.products-list[data-layout=table] .products-list__column--product {
  padding: 0 18px;
  flex-grow: 1;
}
[dir=ltr] .products-list[data-layout=table] .products-list__column--product {
  margin-left: 1px;
}
[dir=rtl] .products-list[data-layout=table] .products-list__column--product {
  margin-right: 1px;
}
.products-list[data-layout=table] .products-list__column--rating {
  width: 140px;
  text-align: center;
  padding: 0 16px;
}
[dir=ltr] .products-list[data-layout=table] .products-list__column--rating {
  margin-left: 1px;
}
[dir=rtl] .products-list[data-layout=table] .products-list__column--rating {
  margin-right: 1px;
}
.products-list[data-layout=table] .products-list__column--price {
  width: 136px;
  padding: 0 16px;
  margin: 0 1px;
}
[dir=ltr] .products-list[data-layout=table] .products-list__column--price {
  text-align: right;
}
[dir=rtl] .products-list[data-layout=table] .products-list__column--price {
  text-align: left;
}
.products-list[data-layout=table] .product-card {
  display: flex;
  align-items: center;
}
.products-list[data-layout=table] .product-card .product-card__fit,
.products-list[data-layout=table] .product-card .product-card__features,
.products-list[data-layout=table] .product-card .product-card__wishlist,
.products-list[data-layout=table] .product-card .product-card__compare,
.products-list[data-layout=table] .product-card .product-card__action,
.products-list[data-layout=table] .product-card .product-card__addtocart-full {
  display: none;
}
.products-list[data-layout=table] .product-card .product-card__badges {
  position: relative;
  top: -2px;
  display: inline-block;
  vertical-align: middle;
}
.products-list[data-layout=table] .product-card .product-card__badges > * {
  display: inline-block;
  vertical-align: middle;
}
[dir=ltr] .products-list[data-layout=table] .product-card .product-card__badges {
  left: 0;
  margin-right: 2px;
}
[dir=rtl] .products-list[data-layout=table] .product-card .product-card__badges {
  right: 0;
  margin-left: 2px;
}
.products-list[data-layout=table] .product-card .tag-badge {
  padding: 3px 5px;
  height: 15px;
  font-size: 10px;
}
.products-list[data-layout=table] .product-card .tag-badge:before {
  transform: none;
  border-radius: 1.5px;
}
[dir=ltr] .products-list[data-layout=table] .product-card .tag-badge:before {
  left: 0;
  right: 0;
}
[dir=rtl] .products-list[data-layout=table] .product-card .tag-badge:before {
  right: 0;
  left: 0;
}
@media (min-width: 576px) {
  [dir=ltr] .products-list[data-layout=table] .product-card {
    padding-left: 16px;
    padding-right: 0;
  }
  [dir=rtl] .products-list[data-layout=table] .product-card {
    padding-right: 16px;
    padding-left: 0;
  }
  .products-list[data-layout=table] .product-card .product-card__image {
    width: 90px;
    flex-shrink: 0;
  }
  [dir=ltr] .products-list[data-layout=table] .product-card .product-card__image {
    margin-right: 16px;
  }
  [dir=rtl] .products-list[data-layout=table] .product-card .product-card__image {
    margin-left: 16px;
  }
  .products-list[data-layout=table] .product-card .product-card__info {
    flex-grow: 1;
    display: flex;
    align-self: stretch;
    margin: 16px 0;
  }
  .products-list[data-layout=table] .product-card .product-card__meta {
    font-size: 13px;
  }
  .products-list[data-layout=table] .product-card .product-card__meta-title {
    display: none;
  }
  .products-list[data-layout=table] .product-card .product-card__name {
    flex-grow: 1;
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 18px;
  }
  .products-list[data-layout=table] .product-card .product-card__rating {
    padding: 0 16px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 4px;
  }
  .products-list[data-layout=table] .product-card .product-card__rating-label {
    margin-top: 3px;
  }
  .products-list[data-layout=table] .product-card .product-card__brand {
    padding: 0 16px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 4px;
  }
  .products-list[data-layout=table] .product-card .product-card__brand-label {
    margin-top: 3px;
  }
  .products-list[data-layout=table] .product-card .product-card__code {
    padding: 0 16px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 4px;
  }
  .products-list[data-layout=table] .product-card .product-card__code-label {
    margin-top: 3px;
  }
  .products-list[data-layout=table] .product-card .product-card__footer {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-shrink: 0;
    margin: 16px 0;
  }
  [dir=ltr] .products-list[data-layout=table] .product-card .product-card__footer {
    border-left: 1px solid #ebebeb;
    padding-left: 16px;
  }
  [dir=rtl] .products-list[data-layout=table] .product-card .product-card__footer {
    border-right: 1px solid #ebebeb;
    padding-right: 16px;
  }
  .products-list[data-layout=table] .product-card .product-card__prices {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: center;
    align-self: stretch;
    align-items: flex-end;
    width: 120px;
    font-size: 15px;
  }
  [dir=ltr] .products-list[data-layout=table] .product-card .product-card__prices {
    border-right: 1px solid #ebebeb;
    padding-right: 16px;
  }
  [dir=rtl] .products-list[data-layout=table] .product-card .product-card__prices {
    border-left: 1px solid #ebebeb;
    padding-left: 16px;
  }
  .products-list[data-layout=table] .product-card .product-card__price--old {
    font-size: 14px;
  }
  [dir=ltr] .products-list[data-layout=table] .product-card .product-card__price--old {
    margin-left: 6px;
  }
  [dir=rtl] .products-list[data-layout=table] .product-card .product-card__price--old {
    margin-right: 6px;
  }
  .products-list[data-layout=table] .product-card .product-card__price--old {
    margin-top: 4px;
  }
  [dir=ltr] .products-list[data-layout=table] .product-card .product-card__price--old {
    margin-left: 0;
  }
  [dir=rtl] .products-list[data-layout=table] .product-card .product-card__price--old {
    margin-right: 0;
  }
  .products-list[data-layout=table] .product-card .product-card__addtocart-icon {
    margin: 0 16px;
  }
  .products-list[data-layout=table] .product-card .product-card__actions-list {
    padding: 0;
    display: none;
  }
}
@media (min-width: 1200px) {
  .products-list[data-layout=table] .product-card .product-card__name {
    padding: 0 18px;
  }
  [dir=ltr] .products-list[data-layout=table] .product-card .product-card__name {
    border-left: 1px solid #ebebeb;
  }
  [dir=rtl] .products-list[data-layout=table] .product-card .product-card__name {
    border-right: 1px solid #ebebeb;
  }
  .products-list[data-layout=table] .product-card .product-card__rating {
    width: 140px;
    padding-top: 0;
    flex-shrink: 0;
    margin-top: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  [dir=ltr] .products-list[data-layout=table] .product-card .product-card__rating {
    border-left: 1px solid #ebebeb;
  }
  [dir=rtl] .products-list[data-layout=table] .product-card .product-card__rating {
    border-right: 1px solid #ebebeb;
  }
  .products-list[data-layout=table] .product-card .product-card__brand {
    width: 140px;
    padding-top: 0;
    flex-shrink: 0;
    margin-top: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  [dir=ltr] .products-list[data-layout=table] .product-card .product-card__brand {
    border-left: 1px solid #ebebeb;
  }
  [dir=rtl] .products-list[data-layout=table] .product-card .product-card__brand {
    border-right: 1px solid #ebebeb;
  }
  .products-list[data-layout=table] .product-card .product-card__code {
    width: 140px;
    padding-top: 0;
    flex-shrink: 0;
    margin-top: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  [dir=ltr] .products-list[data-layout=table] .product-card .product-card__code {
    border-left: 1px solid #ebebeb;
  }
  [dir=rtl] .products-list[data-layout=table] .product-card .product-card__code {
    border-right: 1px solid #ebebeb;
  }
  .products-list[data-layout=table] .product-card .product-card__meta {
    width: 140px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  [dir=ltr] .products-list[data-layout=table] .product-card .product-card__meta {
    border-left: 1px solid #ebebeb;
  }
  [dir=rtl] .products-list[data-layout=table] .product-card .product-card__meta {
    border-right: 1px solid #ebebeb;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .products-list[data-layout=table] .product-card .product-card__image {
    width: 60px;
  }
  [dir=ltr] .products-list[data-layout=table] .product-card .product-card__image {
    margin-right: 16px;
  }
  [dir=rtl] .products-list[data-layout=table] .product-card .product-card__image {
    margin-left: 16px;
  }
}
@media (min-width: 576px) and (max-width: 1199.98px) {
  .products-list[data-layout=table] .product-card .product-card__info {
    flex-direction: column;
    padding: 0 16px;
  }
  [dir=ltr] .products-list[data-layout=table] .product-card .product-card__info {
    border-left: 1px solid #ebebeb;
  }
  [dir=rtl] .products-list[data-layout=table] .product-card .product-card__info {
    border-right: 1px solid #ebebeb;
  }
  .products-list[data-layout=table] .product-card .product-card__name {
    padding: 0;
  }
  .products-list[data-layout=table] .product-card .product-card__rating {
    margin: -3px 0 -2px 0;
    padding: 5px 0 0;
    display: flex;
    flex-wrap: wrap;
  }
  .products-list[data-layout=table] .product-card .product-card__rating-stars,
.products-list[data-layout=table] .product-card .product-card__rating-label {
    margin: 3px 0 2px 0;
  }
  [dir=ltr] .products-list[data-layout=table] .product-card .product-card__rating-stars {
    margin-right: 7px;
  }
  [dir=rtl] .products-list[data-layout=table] .product-card .product-card__rating-stars {
    margin-left: 7px;
  }
  .products-list[data-layout=table] .product-card .product-card__rating-label {
    line-height: 1;
  }
  .products-list[data-layout=table] .product-card .product-card__brand {
    margin: -3px 0 -2px 0;
    padding: 5px 0 0;
    display: flex;
    flex-wrap: wrap;
  }
  .products-list[data-layout=table] .product-card .product-card__brand-stars,
.products-list[data-layout=table] .product-card .product-card__brand-label {
    margin: 3px 0 2px 0;
  }
  [dir=ltr] .products-list[data-layout=table] .product-card .product-card__brand-stars {
    margin-right: 7px;
  }
  [dir=rtl] .products-list[data-layout=table] .product-card .product-card__brand-stars {
    margin-left: 7px;
  }
  .products-list[data-layout=table] .product-card .product-card__brand-label {
    line-height: 1;
  }
  .products-list[data-layout=table] .product-card .product-card__code {
    margin: -3px 0 -2px 0;
    padding: 5px 0 0;
    display: flex;
    flex-wrap: wrap;
  }
  .products-list[data-layout=table] .product-card .product-card__code-label {
    margin: 3px 0 2px 0;
  }
  .products-list[data-layout=table] .product-card .product-card__code-label {
    line-height: 1;
  }
  .products-list[data-layout=table] .product-card .product-card__meta {
    padding: 0 0 5px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .products-list[data-layout=table] .product-card .product-card__image {
    width: 60px;
  }
  [dir=ltr] .products-list[data-layout=table] .product-card .product-card__image {
    margin-right: 16px;
  }
  [dir=rtl] .products-list[data-layout=table] .product-card .product-card__image {
    margin-left: 16px;
  }
  .products-list[data-layout=table] .product-card .product-card__prices {
    width: 90px;
  }
}
@media (max-width: 575.98px) {
  .products-list[data-layout=table] .product-card {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    flex-wrap: wrap;
    align-items: stretch;
    padding-top: 0;
    padding-bottom: 0;
  }
  [dir=ltr] .products-list[data-layout=table] .product-card {
    padding-left: 0;
    padding-right: 0;
  }
  [dir=rtl] .products-list[data-layout=table] .product-card {
    padding-right: 0;
    padding-left: 0;
  }
  .products-list[data-layout=table] .product-card .product-card__image {
    padding: 4px;
    margin: 0 8px;
    width: 60px;
  }
  .products-list[data-layout=table] .product-card .product-card__info {
    order: 3;
    width: 100%;
    margin: 0;
    padding: 14px;
    border-top: 1px solid #ebebeb;
  }
  [dir=ltr] .products-list[data-layout=table] .product-card .product-card__info {
    border-left: none;
  }
  [dir=rtl] .products-list[data-layout=table] .product-card .product-card__info {
    border-right: none;
  }
  .products-list[data-layout=table] .product-card .product-card__name {
    line-height: 20px;
    margin-top: 6px;
  }
  .products-list[data-layout=table] .product-card .product-card__rating {
    margin: -3px 0 -2px 0;
    padding-top: 5px;
    display: flex;
    flex-wrap: wrap;
  }
  .products-list[data-layout=table] .product-card .product-card__rating-stars,
.products-list[data-layout=table] .product-card .product-card__rating-label {
    margin: 3px 0 2px 0;
  }
  [dir=ltr] .products-list[data-layout=table] .product-card .product-card__rating-stars {
    margin-right: 7px;
  }
  [dir=rtl] .products-list[data-layout=table] .product-card .product-card__rating-stars {
    margin-left: 7px;
  }
  .products-list[data-layout=table] .product-card .product-card__rating-label {
    line-height: 1;
  }
  .products-list[data-layout=table] .product-card .product-card__brand {
    margin: -3px 0 -2px 0;
    padding-top: 5px;
    display: flex;
    flex-wrap: wrap;
  }
  .products-list[data-layout=table] .product-card .product-card__brand-stars,
.products-list[data-layout=table] .product-card .product-card__brand-label {
    margin: 3px 0 2px 0;
  }
  [dir=ltr] .products-list[data-layout=table] .product-card .product-card__brand-stars {
    margin-right: 7px;
  }
  [dir=rtl] .products-list[data-layout=table] .product-card .product-card__brand-stars {
    margin-left: 7px;
  }
  .products-list[data-layout=table] .product-card .product-card__brand-label {
    line-height: 1;
  }
  .products-list[data-layout=table] .product-card .product-card__code {
    margin: -3px 0 -2px 0;
    padding-top: 5px;
    display: flex;
    flex-wrap: wrap;
  }
  .products-list[data-layout=table] .product-card .product-card__code-label {
    margin: 3px 0 2px 0;
  }
  .products-list[data-layout=table] .product-card .product-card__code-label {
    line-height: 1;
  }
  .products-list[data-layout=table] .product-card .product-card__footer {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
  }
  .products-list[data-layout=table] .product-card .product-card__prices {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    padding: 12px 14px;
    height: 100%;
  }
  [dir=ltr] .products-list[data-layout=table] .product-card .product-card__prices {
    border-left: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
  }
  [dir=rtl] .products-list[data-layout=table] .product-card .product-card__prices {
    border-right: 1px solid #ebebeb;
    border-left: 1px solid #ebebeb;
  }
  .products-list[data-layout=table] .product-card .product-card__price--old {
    font-size: 14px;
    margin-top: 4px;
  }
  .products-list[data-layout=table] .product-card .product-card__addtocart-icon {
    margin: 10px 18px;
    flex-shrink: 0;
    align-self: center;
  }
}
@media (max-width: 1399.98px) {
  .products-list[data-layout=table] .products-list__column--image {
    width: 60px;
  }
}
@media (max-width: 1199.98px) {
  .products-list[data-layout=table] .products-list__column--image {
    width: 90px;
  }
  .products-list[data-layout=table] .products-list__column--meta,
.products-list[data-layout=table] .products-list__column--rating {
    display: none;
  }
}
@media (min-width: 576px) {
  .products-list[data-layout=table] {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  }
  .products-list[data-layout=table] .products-list__item + .products-list__item {
    border-top: 1px solid #ebebeb;
  }
}
@media (max-width: 575.98px) {
  .products-list[data-layout=table] .products-list__content {
    margin: -8px;
  }
  .products-list[data-layout=table] .products-list__item {
    margin: 8px;
    width: calc((100% - 16px) / 1);
  }
  .products-list[data-layout=table] .products-list__head {
    display: none;
  }
}

/*
// .products-view
*/
.products-view__body {
  position: relative;
}

.products-view__loader {
  position: absolute;
  left: -10px;
  top: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  z-index: 4;
  background: rgba(250, 250, 250, 0.7);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}

.products-view--loading .products-view__loader {
  opacity: 1;
  pointer-events: auto;
}

.products-view__options {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  margin-bottom: 20px;
}

.products-view__pagination {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 0.75rem 1rem;
}

.products-view__pagination-legend {
  font-size: 0.9375rem;
  padding: 0 0.375rem;
}

.products-view__empty {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 2.5rem;
  text-align: center;
}

.products-view__empty-title {
  font-size: 22px;
  font-weight: 500;
}

.products-view__empty-subtitle {
  margin-top: 4px;
}

.products-view__empty-actions {
  margin-top: 1.25rem;
}

@media (max-width: 767.98px) {
  .products-view__pagination {
    padding-top: 1rem;
    flex-direction: column;
  }

  .products-view__pagination-legend {
    padding-top: 9px;
  }
}
/*
// .quickview
*/
.quickview {
  max-width: 800px;
}

.quickview__body {
  padding: 32px 32px 36px;
  display: flex;
  flex-direction: row;
}

.quickview__close {
  z-index: 2;
  position: absolute;
  padding: 16px;
  border: none;
  top: 0;
  border-radius: 0 2.5px 0 2.5px;
  background: transparent;
  fill: currentColor;
  transition: background 0.2s, color 0.2s;
  background-color: #fff;
  color: #ccc;
}
.quickview__close:hover {
  background-color: #f2f2f2;
  color: #4d4d4d;
}
.quickview__close:active {
  background-color: #ebebeb;
  color: #4d4d4d;
}
[dir=ltr] .quickview__close {
  right: 0;
}
[dir=rtl] .quickview__close {
  left: 0;
}
.quickview__close svg {
  display: block;
}
.quickview__close:active {
  transition-duration: 0s;
}
.quickview__close:focus {
  outline: none;
}

.quickview__gallery {
  width: 320px;
  flex-shrink: 0;
}
[dir=ltr] .quickview__gallery {
  margin-right: 28px;
}
[dir=rtl] .quickview__gallery {
  margin-left: 28px;
}

.quickview__product {
  flex-grow: 1;
}

.quickview__product-name {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  margin-top: -2px;
}

.quickview__product-rating {
  display: flex;
  margin-top: 6px;
}

.quickview__product-rating-title {
  font-size: 13px;
  line-height: 1;
  color: #6c757d;
}
[dir=ltr] .quickview__product-rating-title {
  margin-left: 8px;
}
[dir=rtl] .quickview__product-rating-title {
  margin-right: 8px;
}

.quickview__product-description {
  margin-top: 10px;
  font-size: 15px;
  line-height: 22px;
}

.quickview__product-meta {
  margin: 12px 0 0;
}
.quickview__product-meta table {
  display: block;
  margin: -3px;
}
.quickview__product-meta tbody {
  display: flex;
  flex-wrap: wrap;
}
.quickview__product-meta tr,
.quickview__product-meta th,
.quickview__product-meta td {
  display: block;
}
.quickview__product-meta tr {
  background: #f2f2f2;
  border-radius: 1.5px;
  margin: 3px;
  padding-top: 5px;
  padding-bottom: 5px;
}
[dir=ltr] .quickview__product-meta tr {
  padding-left: 7px;
  padding-right: 14px;
}
[dir=rtl] .quickview__product-meta tr {
  padding-right: 7px;
  padding-left: 14px;
}
.quickview__product-meta th {
  font-size: 11px;
  font-weight: 400;
  color: #808080;
  line-height: 1;
}
.quickview__product-meta td {
  font-size: 13px;
  line-height: 1;
  font-weight: 500;
}
.quickview__product-meta a {
  color: inherit;
}

.quickview__product-prices-stock {
  display: flex;
  align-items: center;
  margin: 12px 0 16px;
}

.quickview__product-prices {
  line-height: 1;
  padding: 6px 0;
}

.quickview__product-price {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.04em;
  color: #262626;
}

.quickview__product-price--new {
  color: #262626;
}

.quickview__product-price--old {
  font-size: 14px;
  text-decoration: line-through;
  font-weight: 400;
  color: #999;
  margin-bottom: 3px;
}

.quickview__product-stock {
  margin-bottom: 2px;
}
[dir=ltr] .quickview__product-stock {
  margin-left: 12px;
}
[dir=rtl] .quickview__product-stock {
  margin-right: 12px;
}

.quickview__product-form {
  margin-bottom: 16px;
}

.quickview__product-actions {
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
}

.quickview__product-actions-item {
  margin: 4px;
}

.quickview__product-actions-item--quantity {
  width: 100px;
}

.quickview__see-details {
  border-radius: 0 0 2.5px 2.5px;
  border-top: 1px solid #ebebeb;
  display: block;
  text-align: center;
  color: #6c757d;
  font-size: 15px;
  height: 52px;
  line-height: 50px;
  transition: background 0.15s, border-color 0.15s;
}
.quickview__see-details:hover {
  color: #6c757d;
  background: #f7f7f7;
}
.quickview__see-details:active {
  background: #f0f0f0;
  transition-duration: 0s;
}

@media (max-width: 991.98px) {
  .quickview {
    max-width: 520px;
  }

  .quickview__body {
    flex-direction: column;
  }

  .quickview__gallery {
    width: 100%;
    margin-bottom: 28px;
  }
  [dir=ltr] .quickview__gallery {
    margin-right: 0;
  }
  [dir=rtl] .quickview__gallery {
    margin-left: 0;
  }
}
@media (max-width: 575.98px) {
  .quickview {
    max-width: 100%;
  }
}
@media (max-width: 474px) {
  .quickview__body {
    padding: 20px 20px 24px;
  }
}
/*
// .review
*/
.review__body {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.review__content {
  width: 100%;
  font-size: 15px;
  line-height: 1.5;
  margin-top: 12px;
}

.review__avatar {
  overflow: hidden;
  border-radius: 1.5px;
  width: 42px;
}
[dir=ltr] .review__avatar {
  margin-right: 12px;
}
[dir=rtl] .review__avatar {
  margin-left: 12px;
}
.review__avatar img {
  max-width: 100%;
  height: auto;
}

.review__meta {
  flex-grow: 1;
}
[dir=ltr] .review__meta {
  margin-right: 20px;
}
[dir=rtl] .review__meta {
  margin-left: 20px;
}

.review__author {
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
  margin-top: 2px;
}

.review__date {
  font-size: 13px;
  color: #999;
}

@media (max-width: 474px) {
  .review__rating {
    width: 100%;
    margin-top: 12px;
    margin-bottom: -3px;
  }
}
/*
// .reviews-list
*/
.reviews-list__content {
  list-style: none;
  margin: 0;
  padding: 0;
}

.reviews-list__item + .reviews-list__item {
  margin-top: 18px;
  padding-top: 24px;
  border-top: 1px solid #ebebeb;
}

.reviews-list__pagination {
  margin-top: 18px;
  padding-top: 18px;
  border-top: 1px solid #ebebeb;
}

/*
// .reviews-view
*/
.reviews-view__form {
  margin-top: 48px;
}

.reviews-view__header {
  margin-bottom: 24px;
  font-size: 28px;
  font-weight: 500;
}

/*
// .spec
*/
.spec {
  font-size: 14px;
  line-height: 18px;
}

.spec__header {
  margin-bottom: 50px;
}

.spec__section + .spec__section {
  margin-top: 40px;
}

.spec__section-title {
  font-weight: 500;
  font-size: 17px;
  letter-spacing: -0.03em;
  line-height: 20px;
  margin: 0 0 12px;
}

.spec__row {
  padding: 8px 0;
  display: flex;
  border-bottom: 1px solid #ebebeb;
}
.spec__row:first-of-type {
  border-top: 1px solid #ebebeb;
}

.spec__name {
  width: 230px;
  flex-shrink: 0;
  color: #6c757d;
}
[dir=ltr] .spec__name {
  padding-right: 30px;
}
[dir=rtl] .spec__name {
  padding-left: 30px;
}

.spec__disclaimer {
  margin-top: 40px;
  font-size: 13px;
  line-height: 20px;
  color: #6c757d;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .spec__name {
    width: 170px;
  }
}
@media (max-width: 575.98px) {
  .spec__header {
    margin-bottom: 30px;
  }

  .spec__section + .spec__section {
    margin-top: 24px;
  }

  .spec__row {
    display: block;
    padding: 8px 0;
  }

  .spec__name {
    width: auto;
    margin-bottom: 2px;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 15px;
  }

  .spec__disclaimer {
    margin-top: 28px;
  }
}
/*
// .vehicle-picker-modal
*/
.vehicle-picker-modal__panel {
  padding: 32px 32px 36px;
  display: none;
}

.vehicle-picker-modal__panel--active {
  display: block;
}

.vehicle-picker-modal__close {
  z-index: 2;
  position: absolute;
  padding: 16px;
  border: none;
  top: 0;
  border-radius: 0 2.5px 0 2.5px;
  background: transparent;
  fill: currentColor;
  transition: background 0.2s, color 0.2s;
  background-color: #fff;
  color: #ccc;
}
.vehicle-picker-modal__close:hover {
  background-color: #f2f2f2;
  color: #4d4d4d;
}
.vehicle-picker-modal__close:active {
  background-color: #ebebeb;
  color: #4d4d4d;
}
[dir=ltr] .vehicle-picker-modal__close {
  right: 0;
}
[dir=rtl] .vehicle-picker-modal__close {
  left: 0;
}
.vehicle-picker-modal__close svg {
  display: block;
}
.vehicle-picker-modal__close:active {
  transition-duration: 0s;
}
.vehicle-picker-modal__close:focus {
  outline: none;
}

.vehicle-picker-modal__text {
  font-size: 15px;
  color: #6c757d;
  margin-bottom: 10px;
  margin-top: -10px;
}

.vehicle-picker-modal__add-button {
  min-height: 44px;
}

.vehicle-picker-modal__actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}
[dir=ltr] .vehicle-picker-modal__actions > *:not(:last-child) {
  margin-right: 12px;
}
[dir=rtl] .vehicle-picker-modal__actions > *:not(:last-child) {
  margin-left: 12px;
}

@media (max-width: 479px) {
  .vehicle-picker-modal__panel {
    padding: 24px 24px 28px;
  }

  .vehicle-picker-modal__actions {
    margin-top: 32px;
  }
}
@media (max-width: 399px) {
  .vehicle-picker-modal__panel {
    padding: 20px 20px 24px;
  }

  .vehicle-picker-modal__actions {
    margin-top: 28px;
  }
}
/*
// .wishlist
*/
.wishlist__table {
  width: 100%;
  border-spacing: 0;
}

.wishlist__column--head {
  font-size: 13px;
  text-transform: uppercase;
}

.wishlist__product-name {
  line-height: 20px;
}
.wishlist__product-name a {
  color: inherit;
  transition: color 0.15s;
}
.wishlist__product-name a:hover {
  color: #007bff;
}

.wishlist__product-rating {
  display: flex;
  margin-top: 6px;
}

.wishlist__product-rating-title {
  font-size: 13px;
  line-height: 1;
  color: #6c757d;
}
[dir=ltr] .wishlist__product-rating-title {
  margin-left: 8px;
}
[dir=rtl] .wishlist__product-rating-title {
  margin-right: 8px;
}

@media (min-width: 768px) {
  .wishlist {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  }

  .wishlist__column {
    padding: 14px 16px;
  }
  [dir=ltr] .wishlist__column:first-child {
    padding-left: 28px;
  }
  [dir=rtl] .wishlist__column:first-child {
    padding-right: 28px;
  }
  [dir=ltr] .wishlist__column:last-child {
    padding-right: 28px;
  }
  [dir=rtl] .wishlist__column:last-child {
    padding-left: 28px;
  }

  .wishlist__column--image {
    text-align: center;
    width: 1px;
  }
  .wishlist__column--image .image {
    width: 80px;
  }

  .wishlist__column--body {
    border-top: 1px solid #ebebeb;
  }

  .wishlist__column--stock {
    text-align: center;
    width: 1px;
    white-space: nowrap;
  }

  .wishlist__column--price {
    white-space: nowrap;
    width: 140px;
  }
  [dir=ltr] .wishlist__column--price {
    text-align: right;
  }
  [dir=rtl] .wishlist__column--price {
    text-align: left;
  }

  .wishlist__column--button {
    white-space: nowrap;
    width: 200px;
  }
  [dir=ltr] .wishlist__column--button {
    text-align: right;
  }
  [dir=rtl] .wishlist__column--button {
    text-align: left;
  }

  .wishlist__column--remove {
    width: 1px;
    white-space: nowrap;
  }
  [dir=ltr] .wishlist__column--remove {
    padding-left: 0;
  }
  [dir=rtl] .wishlist__column--remove {
    padding-right: 0;
  }

  [dir=ltr] .wishlist__remove {
    margin-right: -12px;
  }
  [dir=rtl] .wishlist__remove {
    margin-left: -12px;
  }
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .wishlist__column--button {
    width: 1px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  [dir=ltr] .wishlist__column:first-child {
    padding-left: 16px;
  }
  [dir=rtl] .wishlist__column:first-child {
    padding-right: 16px;
  }
  [dir=ltr] .wishlist__column:last-child {
    padding-right: 16px;
  }
  [dir=rtl] .wishlist__column:last-child {
    padding-left: 16px;
  }

  [dir=ltr] .wishlist__column--product,
[dir=ltr] .wishlist__column--stock,
[dir=ltr] .wishlist__column--price,
[dir=ltr] .wishlist__column--button {
    padding-left: 0;
  }
  [dir=rtl] .wishlist__column--product,
[dir=rtl] .wishlist__column--stock,
[dir=rtl] .wishlist__column--price,
[dir=rtl] .wishlist__column--button {
    padding-right: 0;
  }

  .wishlist__column--price {
    width: 1px;
  }

  .wishlist__remove {
    margin-top: 0;
    margin-bottom: 0;
  }
  [dir=ltr] .wishlist__remove {
    margin-left: -8px;
    margin-right: -8px;
  }
  [dir=rtl] .wishlist__remove {
    margin-right: -8px;
    margin-left: -8px;
  }
}
@media (max-width: 767.98px) {
  .wishlist__table {
    display: block;
  }

  .wishlist__head {
    display: none;
  }

  .wishlist__body {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
  }

  .wishlist__row,
.wishlist__column {
    display: block;
  }

  .wishlist__column {
    padding: 0;
  }

  .wishlist__row {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    position: relative;
    margin: 8px;
    width: calc(50% - 16px);
    text-align: center;
    padding: 16px;
  }

  .wishlist__product-rating {
    display: flex;
    justify-content: center;
  }

  .wishlist__column--stock {
    display: flex;
    justify-content: center;
  }

  .wishlist__column--image {
    padding-bottom: 12px;
  }
  .wishlist__column--image .image {
    max-width: 220px;
    margin: 0 auto;
  }

  .wishlist__column--stock {
    margin-top: 16px;
    display: none;
  }

  .wishlist__column--price {
    margin-top: 8px;
    font-weight: 500;
  }

  .wishlist__column--button {
    padding: 20px 0 12px;
  }

  .wishlist__remove {
    position: absolute;
    top: 0;
  }
  [dir=ltr] .wishlist__remove {
    right: 0;
  }
  [dir=rtl] .wishlist__remove {
    left: 0;
  }
}
@media (max-width: 474px) {
  .wishlist__row {
    width: calc(100% - 16px);
  }
}
/* ----------------------------------------
// Blog
// ---------------------------------------- */
/*
// .blog-view
*/
.blog-view__body {
  display: flex;
  justify-content: center;
}

.blog-view__item-posts {
  flex-grow: 1;
}

.blog-view__item-sidebar {
  width: 332px;
  flex-shrink: 0;
}

[dir=ltr] .blog-view__item + .blog-view__item {
  margin-left: 48px;
}
[dir=rtl] .blog-view__item + .blog-view__item {
  margin-right: 48px;
}

.blog-view--layout--classic .blog-view__item-posts {
  max-width: 730px;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .blog-view__item-sidebar {
    width: 290px;
  }

  [dir=ltr] .blog-view__item + .blog-view__item {
    margin-left: 36px;
  }
  [dir=rtl] .blog-view__item + .blog-view__item {
    margin-right: 36px;
  }
}
@media (max-width: 991.98px) {
  .blog-view__body {
    flex-direction: column;
  }

  .blog-view__item-sidebar {
    width: 100%;
    margin-top: 48px;
    order: 1;
  }

  [dir=ltr] .blog-view__item + .blog-view__item {
    margin-left: 0;
  }
  [dir=rtl] .blog-view__item + .blog-view__item {
    margin-right: 0;
  }
}
/*
// .comment
*/
.comment__body {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.comment__content {
  width: 100%;
  background: #f5f5f5;
  padding: 12px 17px;
  border-radius: 5px;
  margin-top: 12px;
  font-size: 15px;
  position: relative;
}
.comment__content:before {
  position: absolute;
  content: "";
  display: block;
  left: 14px;
  top: -10px;
  border: 5px solid transparent;
  border-bottom-color: #f5f5f5;
  pointer-events: none;
}

.comment__avatar {
  overflow: hidden;
  border-radius: 50%;
  width: 38px;
}
[dir=ltr] .comment__avatar {
  margin-right: 14px;
}
[dir=rtl] .comment__avatar {
  margin-left: 14px;
}
.comment__avatar img {
  width: 38px;
  height: 38px;
  object-fit: cover;
}

.comment__meta {
  flex-grow: 1;
}
[dir=ltr] .comment__meta {
  margin-right: 12px;
}
[dir=rtl] .comment__meta {
  margin-left: 12px;
}

.comment__author {
  font-size: 15px;
  line-height: 17px;
  font-weight: 500;
  margin-top: 3px;
}

.comment__date {
  font-size: 13px;
  color: #999;
  margin-top: -1px;
}

@media (max-width: 419px) {
  .comment__content {
    font-size: 14px;
  }

  .comment__avatar {
    display: none;
  }
}
/*
// .comments-list
*/
.comments-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.comments-list__item + .comments-list__item {
  margin-top: 28px;
}

.comments-list__children {
  margin-top: 14px;
  padding-top: 18px;
  margin-bottom: -12px;
  padding-bottom: 15px;
}
[dir=ltr] .comments-list__children {
  border-left: 1px solid #ebebeb;
  padding-left: 31px;
  margin-left: 18px;
}
[dir=rtl] .comments-list__children {
  border-right: 1px solid #ebebeb;
  padding-right: 31px;
  margin-right: 18px;
}

.comments-list__item:last-child > .comments-list__children {
  padding-bottom: 0;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .comments-list__item + .comments-list__item {
    margin-top: 24px;
  }

  .comments-list__children {
    padding-top: 14px;
    margin-bottom: -8px;
  }

  .comments-list__item:last-child > .comments-list__children {
    padding-bottom: 0;
  }
}
@media (max-width: 419px) {
  .comments-list__item + .comments-list__item {
    margin-top: 20px;
  }

  .comments-list__children {
    padding-top: 12px;
  }
  [dir=ltr] .comments-list__children {
    padding-left: 20px;
    margin-left: 12px;
  }
  [dir=rtl] .comments-list__children {
    padding-right: 20px;
    margin-right: 12px;
  }
}
/*
// .comments-view
*/
.comments-view__pagination {
  margin-top: 36px;
}

@media (max-width: 991.98px) {
  .comments-view__pagination {
    margin-top: 32px;
  }
}
@media (max-width: 575.98px) {
  .comments-view__pagination {
    margin-top: 24px;
  }
}
/*
// .post
*/
.post__body {
  padding: 48px;
}

.post__pagination {
  border-top: 1px solid #ebebeb;
  margin: -28px 48px 0;
  padding-top: 20px;
  padding-bottom: 48px;
}

.post__pagination-title {
  font-size: 12px;
  color: #999;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.post__pagination-list {
  font-size: 15px;
}
.post__pagination-list ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: -3px;
}
.post__pagination-list li {
  margin: 3px;
}

.post__pagination-link {
  padding: 2px 8px;
  display: block;
  border-radius: 1.5px;
  transition: background 0.2s;
  background-color: #f0f0f0;
  color: #262626;
}
.post__pagination-link:hover {
  background-color: #e5e5e5;
  color: #262626;
}
.post__pagination-link:active {
  transition-duration: 0s;
  background-color: #e0e0e0;
  color: #262626;
}

.post__pagination-link--current {
  cursor: default;
}
.post__pagination-link--current, .post__pagination-link--current:hover, .post__pagination-link--current:active {
  background-color: #e52727;
  color: #fff;
}

.post__footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 48px 48px;
  margin-top: -24px;
}

[dir=ltr] .post__tags {
  margin-right: 32px;
}
[dir=rtl] .post__tags {
  margin-left: 32px;
}

.post__share-links {
  padding-bottom: 1px;
}

.post__author {
  display: flex;
  align-items: center;
  margin: -24px 48px 0;
  padding: 24px 0 48px;
  border-top: 1px solid #ebebeb;
}
.post__author img {
  display: block;
}

.post__author-avatar {
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 2px;
}
[dir=ltr] .post__author-avatar {
  margin-right: 20px;
}
[dir=rtl] .post__author-avatar {
  margin-left: 20px;
}

.post__author-name {
  font-weight: 500;
}

.post__author-about {
  font-size: 15px;
  margin-top: 4px;
}

@media (max-width: 767.98px) {
  .post__body {
    padding: 32px;
  }

  .post__pagination {
    margin: 0 32px 0;
  }

  .post__pagination {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .post__footer {
    flex-direction: column;
    align-items: center;
    padding: 0 32px 24px;
  }

  [dir=ltr] .post__tags {
    margin-right: 0;
  }
  [dir=rtl] .post__tags {
    margin-left: 0;
  }
  .post__tags .tags__list {
    justify-content: center;
  }

  .post__share-links {
    margin-top: 20px;
    padding-bottom: 0;
  }

  .post__author {
    margin: 0 32px 0;
    padding: 32px 0 32px;
    flex-direction: column;
  }

  .post__author-avatar {
    border-radius: 50%;
  }
  [dir=ltr] .post__author-avatar {
    margin-right: 0;
  }
  [dir=rtl] .post__author-avatar {
    margin-left: 0;
  }

  .post__author-info {
    text-align: center;
    margin-top: 16px;
  }

  .post__author-about {
    margin-top: 6px;
  }
}
@media (max-width: 575.98px) {
  .post__body {
    padding: 24px;
  }

  .post__pagination {
    margin: 0 24px 0;
  }

  .post__author {
    margin: 0 24px 0;
    padding: 24px 0 24px;
  }
}
/*
// .post-card
*/
.post-card--layout--grid {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}
.post-card--layout--grid .post-card__image img {
  max-width: 100%;
  height: auto;
}
.post-card--layout--grid .post-card__content {
  position: relative;
}
.post-card--layout--grid .post-card__title h2 {
  margin: 0;
}
.post-card--layout--grid .post-card__title a {
  color: inherit;
}
.post-card--layout--grid .post-card__title a:hover {
  text-decoration: underline;
}
.post-card--layout--grid .post-card__category {
  position: absolute;
  bottom: calc(100% - 1px);
  font-weight: 500;
  z-index: 0;
  font-size: 14px;
}
[dir=ltr] .post-card--layout--grid .post-card__category {
  left: 0;
}
[dir=rtl] .post-card--layout--grid .post-card__category {
  right: 0;
}
.post-card--layout--grid .post-card__category a {
  color: #007bff;
  display: block;
}
.post-card--layout--grid .post-card__category a:hover {
  text-decoration: underline;
}
.post-card--layout--grid .post-card__category:before, .post-card--layout--grid .post-card__category:after {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  background: #fff;
  z-index: -1;
  height: 100%;
}
.post-card--layout--grid .post-card__category:before {
  width: 100%;
}
[dir=ltr] .post-card--layout--grid .post-card__category:before {
  left: 0;
  border-top-right-radius: 2px;
  transform: skewX(30deg);
  transform-origin: left top;
}
[dir=rtl] .post-card--layout--grid .post-card__category:before {
  right: 0;
  border-top-left-radius: 2px;
  transform: skewX(-30deg);
  transform-origin: right top;
}
.post-card--layout--grid .post-card__category:after {
  width: 50px;
}
[dir=ltr] .post-card--layout--grid .post-card__category:after {
  left: 0;
}
[dir=rtl] .post-card--layout--grid .post-card__category:after {
  right: 0;
}
.post-card--layout--grid .post-card__date {
  font-size: 14px;
  color: #999;
}
.post-card--layout--grid .post-card__date a {
  color: inherit;
}
.post-card--layout--grid .post-card__date a:hover {
  color: #007bff;
}
.post-card--layout--grid .post-card__date:before {
  display: block;
  content: "";
  height: 1px;
  background: currentColor;
  opacity: 0.6;
}
.post-card--layout--grid .post-card__excerpt {
  overflow: hidden;
}
@media (min-width: 768px) {
  .post-card--layout--grid .post-card__content {
    padding: 1.875rem 2.1875rem 2.1875rem;
  }
  .post-card--layout--grid .post-card__title h2 {
    font-size: 26px;
    font-weight: 500;
    line-height: 34px;
  }
  .post-card--layout--grid .post-card__category {
    height: 26px;
    padding: 1.125rem 2.1875rem 0;
  }
  .post-card--layout--grid .post-card__date {
    margin-top: 11px;
    margin-bottom: 15px;
  }
  .post-card--layout--grid .post-card__date:before {
    position: relative;
    top: -1px;
    display: inline-block;
    vertical-align: middle;
    width: 32px;
  }
  [dir=ltr] .post-card--layout--grid .post-card__date:before {
    margin-right: 4px;
  }
  [dir=rtl] .post-card--layout--grid .post-card__date:before {
    margin-left: 4px;
  }
  .post-card--layout--grid .post-card__more {
    margin-top: 18px;
  }
}
@media (max-width: 767.98px) and (min-width: 475px) {
  .post-card--layout--grid .post-card__content {
    padding: 1.675rem 1.675rem 1.75rem;
  }
  .post-card--layout--grid .post-card__title h2 {
    font-size: 20px;
    line-height: 28px;
  }
  .post-card--layout--grid .post-card__category {
    height: 26px;
    padding: 1.125rem 1.675rem 0;
  }
  .post-card--layout--grid .post-card__date {
    margin-top: 10px;
    margin-bottom: 12px;
  }
  .post-card--layout--grid .post-card__date:before {
    position: relative;
    top: -1px;
    display: inline-block;
    vertical-align: middle;
    width: 32px;
  }
  [dir=ltr] .post-card--layout--grid .post-card__date:before {
    margin-right: 4px;
  }
  [dir=rtl] .post-card--layout--grid .post-card__date:before {
    margin-left: 4px;
  }
  .post-card--layout--grid .post-card__more {
    margin-top: 18px;
  }
}
@media (max-width: 474px) {
  .post-card--layout--grid .post-card__category {
    font-size: 13px;
  }
  .post-card--layout--grid .post-card__title {
    margin-bottom: 10px;
  }
  .post-card--layout--grid .post-card__title h2 {
    font-size: 18px;
    line-height: 24px;
  }
  .post-card--layout--grid .post-card__excerpt {
    font-size: 15px;
    max-height: 96px;
  }
  .post-card--layout--grid .post-card__excerpt .typography {
    line-height: 24px;
  }
  .post-card--layout--grid .post-card__category {
    height: 22px;
    padding: 0.875rem 1.25rem 0;
  }
  .post-card--layout--grid .post-card__content {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 1.25rem 1.125rem;
  }
  .post-card--layout--grid .post-card__date {
    order: 1;
    margin-top: 12px;
  }
  .post-card--layout--grid .post-card__date:before {
    display: block;
    width: 32px;
    margin-bottom: 8px;
  }
  .post-card--layout--grid .post-card__more {
    display: none;
  }
}

@media (min-width: 768px) {
  .post-card--layout--list {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    display: flex;
  }
  .post-card--layout--list .post-card__image {
    display: flex;
    flex-shrink: 0;
  }
  .post-card--layout--list .post-card__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .post-card--layout--list .post-card__content {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: flex-start;
  }
  .post-card--layout--list .post-card__title h2 {
    margin-bottom: 0;
  }
  .post-card--layout--list .post-card__title a {
    color: inherit;
  }
  .post-card--layout--list .post-card__title a:hover {
    text-decoration: underline;
  }
  .post-card--layout--list .post-card__category {
    font-size: 14px;
    font-weight: 500;
  }
  .post-card--layout--list .post-card__category a {
    color: #007bff;
  }
  .post-card--layout--list .post-card__category a:hover {
    text-decoration: underline;
  }
  .post-card--layout--list .post-card__excerpt {
    overflow: hidden;
    margin-bottom: auto;
  }
  .post-card--layout--list .post-card__date {
    font-size: 14px;
    color: #999;
  }
  .post-card--layout--list .post-card__date a {
    color: inherit;
  }
  .post-card--layout--list .post-card__date a:hover {
    color: #007bff;
  }
  .post-card--layout--list .post-card__date:before {
    content: "";
    height: 1px;
    background: currentColor;
    opacity: 0.6;
  }
}
@media (min-width: 1400px) {
  .post-card--layout--list .post-card__image {
    width: 400px;
    min-height: 300px;
  }
  .post-card--layout--list .post-card__content {
    padding: 30px 32px 32px;
  }
  .post-card--layout--list .post-card__title {
    margin-bottom: 10px;
  }
  .post-card--layout--list .post-card__title h2 {
    font-size: 22px;
    line-height: 30px;
  }
  .post-card--layout--list .post-card__date {
    position: absolute;
    bottom: 36px;
  }
  [dir=ltr] .post-card--layout--list .post-card__date {
    right: 32px;
  }
  [dir=rtl] .post-card--layout--list .post-card__date {
    left: 32px;
  }
  .post-card--layout--list .post-card__date:before {
    display: inline-block;
    vertical-align: middle;
    width: 32px;
  }
  [dir=ltr] .post-card--layout--list .post-card__date:before {
    margin-right: 4px;
  }
  [dir=rtl] .post-card--layout--list .post-card__date:before {
    margin-left: 4px;
  }
  .post-card--layout--list .post-card__category {
    margin-bottom: 10px;
    margin-top: -4px;
  }
  .post-card--layout--list .post-card__excerpt {
    max-height: 104px;
    margin-bottom: 48px;
  }
  .post-card--layout--list .post-card__more {
    position: absolute;
    bottom: 32px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .post-card--layout--list .post-card__image {
    width: 320px;
    min-height: 260px;
  }
  .post-card--layout--list .post-card__content {
    padding: 26px 28px 24px;
  }
  .post-card--layout--list .post-card__category {
    margin-top: -2px;
    margin-bottom: 10px;
  }
  .post-card--layout--list .post-card__title {
    margin-bottom: 10px;
  }
  .post-card--layout--list .post-card__title h2 {
    font-size: 20px;
    line-height: 26px;
  }
  .post-card--layout--list .post-card__excerpt {
    max-height: 78px;
  }
  .post-card--layout--list .post-card__date {
    order: 1;
  }
  .post-card--layout--list .post-card__date:before {
    display: block;
    width: 36px;
    margin-top: 14px;
    margin-bottom: 10px;
  }
  .post-card--layout--list .post-card__more {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .post-card--layout--list .post-card__image {
    width: 280px;
    min-height: 220px;
  }
  .post-card--layout--list .post-card__content {
    padding: 20px 26px 22px;
  }
  .post-card--layout--list .post-card__category {
    margin-bottom: 6px;
  }
  .post-card--layout--list .post-card__title {
    margin-bottom: 8px;
  }
  .post-card--layout--list .post-card__title h2 {
    font-size: 17px;
    line-height: 24px;
  }
  .post-card--layout--list .post-card__excerpt {
    font-size: 15px;
    max-height: 66px;
  }
  .post-card--layout--list .post-card__excerpt .typography {
    line-height: 22px;
  }
  .post-card--layout--list .post-card__date {
    order: 1;
  }
  .post-card--layout--list .post-card__date:before {
    display: block;
    width: 32px;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  .post-card--layout--list .post-card__more {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .post-card--layout--list .post-card__image {
    width: 320px;
    min-height: 260px;
  }
  .post-card--layout--list .post-card__content {
    padding: 26px 28px 24px;
  }
  .post-card--layout--list .post-card__category {
    margin-top: -2px;
    margin-bottom: 10px;
  }
  .post-card--layout--list .post-card__title {
    margin-bottom: 10px;
  }
  .post-card--layout--list .post-card__title h2 {
    font-size: 20px;
    line-height: 26px;
  }
  .post-card--layout--list .post-card__excerpt {
    max-height: 78px;
  }
  .post-card--layout--list .post-card__date {
    order: 1;
  }
  .post-card--layout--list .post-card__date:before {
    display: block;
    width: 36px;
    margin-top: 14px;
    margin-bottom: 10px;
  }
  .post-card--layout--list .post-card__more {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .post-card--layout--list {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  }
  .post-card--layout--list .post-card__image img {
    max-width: 100%;
    height: auto;
  }
  .post-card--layout--list .post-card__content {
    position: relative;
  }
  .post-card--layout--list .post-card__title h2 {
    margin: 0;
  }
  .post-card--layout--list .post-card__title a {
    color: inherit;
  }
  .post-card--layout--list .post-card__title a:hover {
    text-decoration: underline;
  }
  .post-card--layout--list .post-card__category {
    position: absolute;
    bottom: calc(100% - 1px);
    font-weight: 500;
    z-index: 0;
    font-size: 14px;
  }
  [dir=ltr] .post-card--layout--list .post-card__category {
    left: 0;
  }
  [dir=rtl] .post-card--layout--list .post-card__category {
    right: 0;
  }
  .post-card--layout--list .post-card__category a {
    color: #007bff;
    display: block;
  }
  .post-card--layout--list .post-card__category a:hover {
    text-decoration: underline;
  }
  .post-card--layout--list .post-card__category:before, .post-card--layout--list .post-card__category:after {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    background: #fff;
    z-index: -1;
    height: 100%;
  }
  .post-card--layout--list .post-card__category:before {
    width: 100%;
  }
  [dir=ltr] .post-card--layout--list .post-card__category:before {
    left: 0;
    border-top-right-radius: 2px;
    transform: skewX(30deg);
    transform-origin: left top;
  }
  [dir=rtl] .post-card--layout--list .post-card__category:before {
    right: 0;
    border-top-left-radius: 2px;
    transform: skewX(-30deg);
    transform-origin: right top;
  }
  .post-card--layout--list .post-card__category:after {
    width: 50px;
  }
  [dir=ltr] .post-card--layout--list .post-card__category:after {
    left: 0;
  }
  [dir=rtl] .post-card--layout--list .post-card__category:after {
    right: 0;
  }
  .post-card--layout--list .post-card__date {
    font-size: 14px;
    color: #999;
  }
  .post-card--layout--list .post-card__date a {
    color: inherit;
  }
  .post-card--layout--list .post-card__date a:hover {
    color: #007bff;
  }
  .post-card--layout--list .post-card__date:before {
    display: block;
    content: "";
    height: 1px;
    background: currentColor;
    opacity: 0.6;
  }
  .post-card--layout--list .post-card__excerpt {
    overflow: hidden;
  }
}
@media (max-width: 767.98px) and (min-width: 475px) {
  .post-card--layout--list .post-card__content {
    padding: 1.675rem 1.675rem 1.75rem;
  }
  .post-card--layout--list .post-card__title h2 {
    font-size: 20px;
    line-height: 28px;
  }
  .post-card--layout--list .post-card__category {
    height: 26px;
    padding: 1.125rem 1.675rem 0;
  }
  .post-card--layout--list .post-card__date {
    margin-top: 10px;
    margin-bottom: 12px;
  }
  .post-card--layout--list .post-card__date:before {
    position: relative;
    top: -1px;
    display: inline-block;
    vertical-align: middle;
    width: 32px;
  }
  [dir=ltr] .post-card--layout--list .post-card__date:before {
    margin-right: 4px;
  }
  [dir=rtl] .post-card--layout--list .post-card__date:before {
    margin-left: 4px;
  }
  .post-card--layout--list .post-card__more {
    margin-top: 18px;
  }
}
@media (max-width: 474px) {
  .post-card--layout--list .post-card__category {
    font-size: 13px;
  }
  .post-card--layout--list .post-card__title {
    margin-bottom: 10px;
  }
  .post-card--layout--list .post-card__title h2 {
    font-size: 18px;
    line-height: 24px;
  }
  .post-card--layout--list .post-card__excerpt {
    font-size: 15px;
    max-height: 96px;
  }
  .post-card--layout--list .post-card__excerpt .typography {
    line-height: 24px;
  }
  .post-card--layout--list .post-card__category {
    height: 22px;
    padding: 0.875rem 1.25rem 0;
  }
  .post-card--layout--list .post-card__content {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 1.25rem 1.125rem;
  }
  .post-card--layout--list .post-card__date {
    order: 1;
    margin-top: 12px;
  }
  .post-card--layout--list .post-card__date:before {
    display: block;
    width: 32px;
    margin-bottom: 8px;
  }
  .post-card--layout--list .post-card__more {
    display: none;
  }
}

.post-card--layout--grid-sm {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}
.post-card--layout--grid-sm .post-card__image img {
  max-width: 100%;
  height: auto;
}
.post-card--layout--grid-sm .post-card__content {
  position: relative;
}
.post-card--layout--grid-sm .post-card__title h2 {
  margin: 0;
}
.post-card--layout--grid-sm .post-card__title a {
  color: inherit;
}
.post-card--layout--grid-sm .post-card__title a:hover {
  text-decoration: underline;
}
.post-card--layout--grid-sm .post-card__category {
  position: absolute;
  bottom: calc(100% - 1px);
  font-weight: 500;
  z-index: 0;
  font-size: 14px;
}
[dir=ltr] .post-card--layout--grid-sm .post-card__category {
  left: 0;
}
[dir=rtl] .post-card--layout--grid-sm .post-card__category {
  right: 0;
}
.post-card--layout--grid-sm .post-card__category a {
  color: #007bff;
  display: block;
}
.post-card--layout--grid-sm .post-card__category a:hover {
  text-decoration: underline;
}
.post-card--layout--grid-sm .post-card__category:before, .post-card--layout--grid-sm .post-card__category:after {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  background: #fff;
  z-index: -1;
  height: 100%;
}
.post-card--layout--grid-sm .post-card__category:before {
  width: 100%;
}
[dir=ltr] .post-card--layout--grid-sm .post-card__category:before {
  left: 0;
  border-top-right-radius: 2px;
  transform: skewX(30deg);
  transform-origin: left top;
}
[dir=rtl] .post-card--layout--grid-sm .post-card__category:before {
  right: 0;
  border-top-left-radius: 2px;
  transform: skewX(-30deg);
  transform-origin: right top;
}
.post-card--layout--grid-sm .post-card__category:after {
  width: 50px;
}
[dir=ltr] .post-card--layout--grid-sm .post-card__category:after {
  left: 0;
}
[dir=rtl] .post-card--layout--grid-sm .post-card__category:after {
  right: 0;
}
.post-card--layout--grid-sm .post-card__date {
  font-size: 14px;
  color: #999;
}
.post-card--layout--grid-sm .post-card__date a {
  color: inherit;
}
.post-card--layout--grid-sm .post-card__date a:hover {
  color: #007bff;
}
.post-card--layout--grid-sm .post-card__date:before {
  display: block;
  content: "";
  height: 1px;
  background: currentColor;
  opacity: 0.6;
}
.post-card--layout--grid-sm .post-card__excerpt {
  overflow: hidden;
}
@media (min-width: 1400px) {
  .post-card--layout--grid-sm .post-card__content {
    padding: 1.675rem 1.675rem 1.75rem;
  }
  .post-card--layout--grid-sm .post-card__title h2 {
    font-size: 20px;
    line-height: 28px;
  }
  .post-card--layout--grid-sm .post-card__category {
    height: 26px;
    padding: 1.125rem 1.675rem 0;
  }
  .post-card--layout--grid-sm .post-card__date {
    margin-top: 10px;
    margin-bottom: 12px;
  }
  .post-card--layout--grid-sm .post-card__date:before {
    position: relative;
    top: -1px;
    display: inline-block;
    vertical-align: middle;
    width: 32px;
  }
  [dir=ltr] .post-card--layout--grid-sm .post-card__date:before {
    margin-right: 4px;
  }
  [dir=rtl] .post-card--layout--grid-sm .post-card__date:before {
    margin-left: 4px;
  }
  .post-card--layout--grid-sm .post-card__more {
    margin-top: 18px;
  }
}
@media (min-width: 768px) and (max-width: 1399.98px) {
  .post-card--layout--grid-sm .post-card__category {
    font-size: 13px;
  }
  .post-card--layout--grid-sm .post-card__title {
    margin-bottom: 10px;
  }
  .post-card--layout--grid-sm .post-card__title h2 {
    font-size: 18px;
    line-height: 24px;
  }
  .post-card--layout--grid-sm .post-card__excerpt {
    font-size: 15px;
    max-height: 96px;
  }
  .post-card--layout--grid-sm .post-card__excerpt .typography {
    line-height: 24px;
  }
  .post-card--layout--grid-sm .post-card__category {
    height: 22px;
    padding: 0.875rem 1.25rem 0;
  }
  .post-card--layout--grid-sm .post-card__content {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 1.25rem 1.125rem;
  }
  .post-card--layout--grid-sm .post-card__date {
    order: 1;
    margin-top: 12px;
  }
  .post-card--layout--grid-sm .post-card__date:before {
    display: block;
    width: 32px;
    margin-bottom: 8px;
  }
  .post-card--layout--grid-sm .post-card__more {
    display: none;
  }
}
@media (max-width: 767.98px) and (min-width: 475px) {
  .post-card--layout--grid-sm .post-card__content {
    padding: 1.675rem 1.675rem 1.75rem;
  }
  .post-card--layout--grid-sm .post-card__title h2 {
    font-size: 20px;
    line-height: 28px;
  }
  .post-card--layout--grid-sm .post-card__category {
    height: 26px;
    padding: 1.125rem 1.675rem 0;
  }
  .post-card--layout--grid-sm .post-card__date {
    margin-top: 10px;
    margin-bottom: 12px;
  }
  .post-card--layout--grid-sm .post-card__date:before {
    position: relative;
    top: -1px;
    display: inline-block;
    vertical-align: middle;
    width: 32px;
  }
  [dir=ltr] .post-card--layout--grid-sm .post-card__date:before {
    margin-right: 4px;
  }
  [dir=rtl] .post-card--layout--grid-sm .post-card__date:before {
    margin-left: 4px;
  }
  .post-card--layout--grid-sm .post-card__more {
    margin-top: 18px;
  }
}
@media (max-width: 474px) {
  .post-card--layout--grid-sm .post-card__category {
    font-size: 13px;
  }
  .post-card--layout--grid-sm .post-card__title {
    margin-bottom: 10px;
  }
  .post-card--layout--grid-sm .post-card__title h2 {
    font-size: 18px;
    line-height: 24px;
  }
  .post-card--layout--grid-sm .post-card__excerpt {
    font-size: 15px;
    max-height: 96px;
  }
  .post-card--layout--grid-sm .post-card__excerpt .typography {
    line-height: 24px;
  }
  .post-card--layout--grid-sm .post-card__category {
    height: 22px;
    padding: 0.875rem 1.25rem 0;
  }
  .post-card--layout--grid-sm .post-card__content {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 1.25rem 1.125rem;
  }
  .post-card--layout--grid-sm .post-card__date {
    order: 1;
    margin-top: 12px;
  }
  .post-card--layout--grid-sm .post-card__date:before {
    display: block;
    width: 32px;
    margin-bottom: 8px;
  }
  .post-card--layout--grid-sm .post-card__more {
    display: none;
  }
}

/*
// .post-header
*/
.post-header {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 0;
  overflow: hidden;
}

.post-header__decor {
  position: absolute;
  bottom: -1px;
}
.post-header__decor .decor__start,
.post-header__decor .decor__end,
.post-header__decor .decor__center {
  background: #fafafa;
}
.post-header__decor .decor__start,
.post-header__decor .decor__end {
  box-shadow: none;
}

.post-header__image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center center;
  z-index: -1;
  opacity: 0.22;
}

.post-header__body {
  max-width: 620px;
  margin: 0 auto;
  text-align: center;
  padding: 52px 30px;
}

.post-header__title {
  margin: 0;
  font-size: 36px;
  font-weight: 700;
}

.post-header__categories {
  margin-bottom: 28px;
}

.post-header__categories-list {
  list-style: none;
  margin: -3px;
  padding: 0;
  display: flex;
  justify-content: center;
}

.post-header__categories-item {
  margin: 3px;
}

.post-header__categories-link {
  display: block;
  font-size: 10px;
  text-transform: uppercase;
  background: #e52727;
  padding: 3px 9px;
  border-radius: 1.5px;
  font-weight: 500;
}
@media (max-resolution: 1dppx) {
  .post-header__categories-link {
    font-weight: 400;
  }
}
.post-header__categories-link, .post-header__categories-link:hover {
  color: #fff;
}

.post-header__meta {
  font-size: 14px;
  margin-top: 24px;
  color: #999;
}

.post-header__meta-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.post-header__meta-item + .post-header__meta-item {
  position: relative;
}
[dir=ltr] .post-header__meta-item + .post-header__meta-item {
  margin-left: 24px;
}
[dir=rtl] .post-header__meta-item + .post-header__meta-item {
  margin-right: 24px;
}
.post-header__meta-item + .post-header__meta-item:before {
  display: block;
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background: currentColor;
  top: 9px;
}
[dir=ltr] .post-header__meta-item + .post-header__meta-item:before {
  left: -14px;
}
[dir=rtl] .post-header__meta-item + .post-header__meta-item:before {
  right: -14px;
}

.post-header__meta-link {
  color: inherit;
  transition: color 0.1s;
}
.post-header__meta-link:hover {
  color: #007bff;
}

.post-header--has-image {
  background: #262626;
  margin-bottom: -150px;
  padding-bottom: 150px;
}
.post-header--has-image .post-header__body {
  color: #fff;
  padding: 60px 30px;
}
.post-header--has-image .post-header__title,
.post-header--has-image .post-header__meta {
  text-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
}
.post-header--has-image .post-header__meta {
  color: inherit;
}
.post-header--has-image .post-header__meta-link:hover {
  color: #ffdf40;
}

@media (max-width: 1199.98px) {
  .post-header {
    padding-top: 0;
  }
}
@media (max-width: 575.98px) {
  .post-header__body {
    padding: 36px 30px;
  }

  .post-header__categories {
    margin-bottom: 20px;
  }

  .post-header__title {
    font-size: 28px;
  }

  .post-header__meta {
    margin-top: 16px;
  }

  .post-header--has-image .post-header__body {
    padding: 48px 30px;
  }
}
@media (max-width: 419px) {
  .post-header--has-image {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
/*
// .post-navigation
*/
.post-navigation__body {
  display: flex;
}

.post-navigation__item {
  padding: 20px;
  display: flex;
  align-items: center;
  width: 50%;
  color: inherit;
  transition: background 0.12s;
}
.post-navigation__item:hover {
  background: #f2f2f2;
  color: inherit;
}

.post-navigation__item-image {
  overflow: hidden;
  border-radius: 2px;
  flex-shrink: 0;
}
.post-navigation__item-image img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.post-navigation__item-title {
  font-size: 15px;
  line-height: 22px;
  margin-top: 3px;
}

.post-navigation__direction {
  display: flex;
  font-size: 14px;
  line-height: 21px;
  color: #999;
}

.post-navigation__direction-arrow {
  display: flex;
  align-items: center;
  fill: currentColor;
  padding-bottom: 1px;
  opacity: 0.8;
}
.post-navigation__direction-arrow svg {
  display: block;
}
[dir=ltr] .post-navigation__direction-arrow svg {
  transform: scaleX(1);
}
[dir=rtl] .post-navigation__direction-arrow svg {
  transform: scaleX(-1);
}

[dir=ltr] .post-navigation__item--prev {
  text-align: left;
}
[dir=rtl] .post-navigation__item--prev {
  text-align: right;
}
[dir=ltr] .post-navigation__item--prev .post-navigation__item-image {
  margin-right: 18px;
}
[dir=rtl] .post-navigation__item--prev .post-navigation__item-image {
  margin-left: 18px;
}
.post-navigation__item--prev .post-navigation__direction {
  justify-content: flex-start;
}
[dir=ltr] .post-navigation__item--prev .post-navigation__direction-arrow {
  margin-right: 8px;
}
[dir=rtl] .post-navigation__item--prev .post-navigation__direction-arrow {
  margin-left: 8px;
}

[dir=ltr] .post-navigation__item--next {
  text-align: right;
}
[dir=rtl] .post-navigation__item--next {
  text-align: left;
}
[dir=ltr] .post-navigation__item--next .post-navigation__item-image {
  margin-left: 18px;
}
[dir=rtl] .post-navigation__item--next .post-navigation__item-image {
  margin-right: 18px;
}
.post-navigation__item--next .post-navigation__direction {
  justify-content: flex-end;
}
[dir=ltr] .post-navigation__item--next .post-navigation__direction-arrow {
  margin-left: 8px;
}
[dir=rtl] .post-navigation__item--next .post-navigation__direction-arrow {
  margin-right: 8px;
}

@media (max-width: 767.98px) {
  .post-navigation__body {
    flex-direction: column;
  }

  .post-navigation__item {
    width: 100%;
  }

  .post-navigation__item--next {
    border-top: 1px solid #ebebeb;
  }
}
/*
// .post-view
*/
.post-view__body {
  display: flex;
  justify-content: center;
}

.post-view__item-post {
  flex-grow: 1;
  max-width: 760px;
}

.post-view__item-sidebar {
  width: 332px;
  flex-shrink: 0;
}

[dir=ltr] .post-view__item + .post-view__item {
  margin-left: 48px;
}
[dir=rtl] .post-view__item + .post-view__item {
  margin-right: 48px;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .post-view__item-sidebar {
    width: 290px;
  }

  [dir=ltr] .post-view__item + .post-view__item {
    margin-left: 36px;
  }
  [dir=rtl] .post-view__item + .post-view__item {
    margin-right: 36px;
  }
}
@media (max-width: 991.98px) {
  .post-view__body {
    flex-direction: column;
  }

  .post-view__item-sidebar {
    width: 100%;
    margin-top: 20px;
    order: 1;
  }

  [dir=ltr] .post-view__item + .post-view__item {
    margin-left: 0;
  }
  [dir=rtl] .post-view__item + .post-view__item {
    margin-right: 0;
  }
}
.post-view__card {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  position: relative;
}

.post-view__card + .post-view__card {
  margin-top: 24px;
}

.post-view__card-title {
  padding: 44px 0 8px;
  margin: 0 48px 40px;
  font-size: 28px;
  font-weight: 700;
  border-bottom: 1px solid #ebebeb;
}

.post-view__card-body {
  padding: 0 48px 48px;
}

@media (max-width: 767.98px) {
  .post-view__card-title {
    padding: 28px 0 8px;
    margin: 0 32px 28px;
  }

  .post-view__card-body {
    padding: 0 32px 32px;
  }
}
@media (max-width: 575.98px) {
  .post-view__card-title {
    padding: 22px 0 4px;
    margin: 0 24px 24px;
    font-size: 24px;
  }

  .post-view__card-body {
    padding: 0 24px 24px;
  }
}
@media (max-width: 419px) {
  .post-view__card {
    margin-left: -15px;
    margin-right: -15px;
  }
}
/*
// .posts-list
*/
.posts-list__body {
  display: flex;
  flex-wrap: wrap;
}

.posts-list__item {
  display: flex;
}

.posts-list--layout--classic .posts-list__body {
  margin: -20px;
}
.posts-list--layout--classic .posts-list__item {
  margin: 20px;
  width: calc(100% - 40px);
}

.posts-list--layout--grid-2 .posts-list__body {
  margin: -14px;
}
.posts-list--layout--grid-2 .posts-list__item {
  margin: 14px;
  width: calc(50% - 28px);
}
@media (max-width: 1399.98px) {
  .posts-list--layout--grid-2 .posts-list__body {
    margin: -10px;
  }
  .posts-list--layout--grid-2 .posts-list__item {
    margin: 10px;
    width: calc(50% - 20px);
  }
}
@media (max-width: 767.98px) {
  .posts-list--layout--grid-2 .posts-list__body {
    margin: -16px;
  }
  .posts-list--layout--grid-2 .posts-list__item {
    margin: 16px;
    width: calc(100% - 32px);
  }
}

.posts-list--layout--list .posts-list__body {
  margin: -14px;
}
.posts-list--layout--list .posts-list__item {
  margin: 14px;
  width: calc(100% - 28px);
}

/*
// .posts-view
*/
.posts-view__pagination {
  margin-top: 48px;
  display: flex;
  justify-content: center;
}

/* ----------------------------------------
// Account
// ---------------------------------------- */
/*
// .account-nav
*/
.account-nav {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

.account-nav__title {
  padding: 1.375rem 1.5rem;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
}

.account-nav__list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 15px;
  line-height: 20px;
}

.account-nav__item a {
  cursor: pointer;
  display: block;
  color: #6c757d;
  padding: 7px 1.5rem;
}

.account-nav__item:hover a {
  background: #f2f2f2;
}

.account-nav__item--active a {
  color: inherit;
  font-weight: 500;
}

.account-nav__divider {
  height: 1px;
  background: #ebebeb;
  margin: 10px 0;
}

@media (min-width: 992px) {
  .account-nav__list {
    padding-bottom: 1.375rem;
  }

  [dir=ltr] .account-nav__item--active a {
    box-shadow: 3px 0 #e52727 inset;
  }
  [dir=rtl] .account-nav__item--active a {
    box-shadow: -3px 0 #e52727 inset;
  }
}
@media (max-width: 991.98px) {
  .account-nav {
    overflow-x: auto;
    max-width: 100%;
  }

  .account-nav__list {
    display: flex;
    white-space: nowrap;
    padding: 0 8px;
  }

  .account-nav__title {
    display: none;
  }

  .account-nav__item a {
    padding: 14px 20px;
  }

  .account-nav__item--active a {
    box-shadow: 0 -3px #e52727 inset;
  }
}
/*
// .address-card
*/
.address-card {
  position: relative;
}

.address-card__badge {
  position: absolute;
  top: 12px;
}
[dir=ltr] .address-card__badge {
  right: 12px;
}
[dir=rtl] .address-card__badge {
  left: 12px;
}

.address-card__body {
  padding: 1.5rem;
  font-size: 15px;
  line-height: 18px;
}

.address-card__name {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 1.125rem;
}

.address-card__row + .address-card__row {
  margin-top: 0.75rem;
}

.address-card__row-title {
  font-size: 13px;
  color: #6c757d;
}

.address-card__footer {
  margin-top: 1.625rem;
}
.address-card__footer a:hover {
  text-decoration: underline;
}

.address-card--featured .address-card__body {
  padding: 2rem;
}
.address-card--featured .address-card__name {
  font-weight: 700;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .address-card--featured .address-card__body {
    padding: 1.5rem;
  }
}
@media (max-width: 767.98px) {
  .address-card--featured .address-card__body {
    padding: 1.375rem;
  }
}

/*
// .addresses-list
*/
.addresses-list {
  display: flex;
  flex-wrap: wrap;
}

.addresses-list__item {
  flex-shrink: 0;
}

.addresses-list__item--new {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

.addresses-list__divider:last-child {
  display: none;
}

.addresses-list__plus {
  width: 60px;
  height: 60px;
  position: relative;
  border-radius: 30px;
  margin-bottom: 24px;
  color: #e0e0e0;
  border: 2px solid currentColor;
  transition: color 0.2s;
}
.addresses-list__plus:before, .addresses-list__plus:after {
  content: "";
  position: absolute;
  width: 32px;
  height: 4px;
  background: currentColor;
  border-radius: 1.5px;
  top: calc(50% - 2px);
}
[dir=ltr] .addresses-list__plus:before, [dir=ltr] .addresses-list__plus:after {
  left: calc(50% - 16px);
}
[dir=rtl] .addresses-list__plus:before, [dir=rtl] .addresses-list__plus:after {
  right: calc(50% - 16px);
}
.addresses-list__plus:after {
  transform: rotateZ(90deg);
}

.addresses-list__item--new:hover .addresses-list__plus {
  color: #ccc;
}

@media (min-width: 768px) {
  .addresses-list__item {
    width: calc((100% - 32px - 1px) / 3);
  }

  .addresses-list__divider:nth-child(2n) {
    width: 16px;
  }

  .addresses-list__divider:nth-child(6n) {
    width: 100%;
    height: 16px;
  }
}
@media (min-width: 480px) and (max-width: 767.98px) {
  .addresses-list__item {
    width: calc((100% - 16px - 1px) / 2);
  }

  .addresses-list__divider:nth-child(2n) {
    width: 16px;
  }

  .addresses-list__divider:nth-child(4n) {
    width: 100%;
    height: 16px;
  }
}
@media (max-width: 479px) {
  .addresses-list__item {
    width: calc((100% - 0px - 1px) / 1);
  }

  .addresses-list__divider:nth-child(2n) {
    width: 16px;
  }

  .addresses-list__divider:nth-child(2n) {
    width: 100%;
    height: 16px;
  }
}
/*
// .dashboard
*/
.dashboard {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.dashboard__orders {
  margin-top: 24px;
  width: 100%;
}

@media (min-width: 768px) {
  .dashboard__profile,
.dashboard__address {
    width: calc(50% - 12px);
  }
}
@media (max-width: 767.98px) {
  .dashboard__profile,
.dashboard__address {
    width: 100%;
  }

  .dashboard__address {
    margin-top: 24px;
  }
}
/*
// .profile-card
*/
.profile-card__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-card__avatar {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-bottom: 16px;
}
.profile-card__avatar img {
  border-radius: 50%;
  max-width: 100%;
}

.profile-card__name {
  font-weight: 500;
  line-height: 20px;
}

.profile-card__email {
  font-size: 15px;
  margin-bottom: 24px;
}

/* hide item start */
.hide {
  display: none;
  opacity: 0;
  visibility: hidden;
}

/* hide item end */
.f-14 {
  font-size: 14px !important;
}

.cart-table__column--price--old,
.wishlist__column--price--old,
.compare-table__column--product--old {
  font-weight: 400;
  text-decoration: line-through;
  color: #999;
}

.checkout-featured-address {
  display: flex;
}
@media (max-width: 474px) {
  .checkout-featured-address {
    flex-wrap: wrap;
  }
}
.checkout-featured-address .addresses-list__item {
  width: calc((100% - 16px - 0.5px) / 2);
}
@media (max-width: 474px) {
  .checkout-featured-address .addresses-list__item {
    width: 100%;
  }
}

@media (max-width: 991.98px) {
  .cart-table__column--price--old {
    font-size: 12px;
  }
}
@media (max-width: 575.98px) {
  .cart-table__column--price--old {
    font-size: 12px;
  }
}
@media (max-width: 474px) {
  .cart-table__column--price--old {
    font-size: 12px;
  }
}
.address-card__name {
  margin-top: 0.625rem;
}

/* adding quantity product card start */
.product-card__footer__before {
  padding: 16px;
  align-items: center;
  display: flex;
  order: 2;
}

.product-card__footer .product__actions-fix {
  padding: 0;
  width: 100%;
}

/* adding quantity product card end */
/* new menu start */
.site--desktop-header--classic-one .header__navbar {
  position: relative;
}

.site--desktop-header--classic-one .main-menu__list,
.site--desktop-header--classic-one .main-menu__item--submenu--menu {
  position: unset !important;
}

.site--desktop-header--classic-one .main-menu__item--submenu--menu .main-menu__submenu {
  z-index: 0 !important;
}

.site--desktop-header--classic-one .menu__list {
  justify-content: center !important;
}

/* new menu end */
/* topbar disable start */
.site--desktop-header--classic-one .header {
  grid-template-rows: 0px auto auto !important;
}

/* topbar disable end */
/* brand new start*/
.product-card--layout--grid .product-card__brand {
  margin: 0;
  padding: 4px 8px;
  margin-top: 4px;
  position: absolute;
  top: 0;
  background: #e52727;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.product-card--layout--grid .product-card__brand a {
  color: white;
}

.products-list[data-layout=grid] .product-card .product-card__brand {
  margin: 0;
  padding: 4px 8px;
  margin-top: 4px;
  position: absolute;
  top: 0;
  background: #e52727;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.products-list[data-layout=grid] .product-card .product-card__brand a {
  color: white;
}

/* brand new end*/
/* code new start */
.product-card--layout--grid .product-card__code {
  margin: 0;
  padding: 4px 8px;
  margin-top: 34px;
  position: absolute;
  top: 0;
  background: #262626;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.product-card--layout--grid .product-card__code a {
  color: white;
}

.products-list[data-layout=grid] .product-card .product-card__code {
  margin: 0;
  padding: 4px 8px;
  margin-top: 34px;
  position: absolute;
  top: 0;
  background: #262626;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.products-list[data-layout=grid] .product-card .product-card__code a {
  color: white;
}

/* code new end*/