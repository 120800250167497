/*
// .reviews-view
*/
@import '../variables';


.reviews-view {}
.reviews-view__form {
    margin-top: 48px;
}
.reviews-view__header {
    margin-bottom: 24px;
    font-size: 28px;
    font-weight: $font-weight-medium;
}
