/*
// .widget-tags
*/
@import '../variables';


.widget-tags {}
.widget-tags__body {
    padding: 0 $widget-padding $widget-padding;
}
